import React from 'react'
import {
  Text,
  Tooltip,
  VisuallyHidden,
  WarningIcon,
} from '@buffer-mono/popcorn'
import { isPast } from 'date-fns'

import type { PostStatus } from '~publish/gql/graphql'
import { useDateTimeFormatter } from '~publish/hooks/useDateTimeFormatter'

import styles from './PostDueDate.module.css'

type PostDueDateProps = {
  date: string | null | undefined
  status: PostStatus
  channelTimeZone?: string | null
}

export const PostDueDate = ({
  date,
  status,
  channelTimeZone,
}: PostDueDateProps): JSX.Element => {
  const dateTimeFormatter = useDateTimeFormatter()

  if (!date) {
    return <Text color="subtle">No date & time</Text>
  }

  const isPastDue = isPast(date) && status !== 'sent' && status !== 'sending'
  const isTentativeTime = status === 'draft'

  const dateTime = (
    <Text color={isPastDue ? 'critical' : 'default'} className={styles.wrapper}>
      {isTentativeTime && (
        <Text color="subtle" weight="medium">
          Tentative:
        </Text>
      )}
      {isPastDue && (
        <>
          <WarningIcon size="small" aria-hidden />
          <VisuallyHidden>Past due</VisuallyHidden>
        </>
      )}
      <time dateTime={date}>
        {dateTimeFormatter(date, { includeTimeZone: false })}
      </time>
    </Text>
  )

  return (
    <Tooltip
      content={
        <p className={styles.tooltipContent}>
          {channelTimeZone && <span>Channel Local Time:</span>}
          {dateTimeFormatter(
            date,
            channelTimeZone
              ? {
                  timeZone: channelTimeZone,
                }
              : {},
          )}
        </p>
      }
    >
      {dateTime}
    </Tooltip>
  )
}
