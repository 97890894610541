import React from 'react'
import { useQuery } from '@apollo/client'

import { graphql } from '~publish/gql'
import { useAppSelector } from '~publish/legacy/store'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import { Select } from '@bufferapp/ui'

type IdeaGroupSelectorProps = {
  selectedGroupId?: string
  onGroupSelect?: (groupId: string | undefined) => void
}

const getIdeaGroupsQuery = graphql(/* GraphQL */ `
  query GetIdeaGroups($organizationId: ID!) {
    ideaGroups(input: { organizationId: $organizationId }) {
      id
      name
      isLocked
    }
  }
`)

type GroupItem = {
  id: string
  title: string
  selected: boolean
}

const UNASSIGNED_ITEM_ID = 'unassigned'

// This is a temporary component to select idea group
export const IdeaGroupSelector = ({
  selectedGroupId,
  onGroupSelect,
}: IdeaGroupSelectorProps): JSX.Element | null => {
  const organizationId: string = useAppSelector(selectCurrentOrganizationId)
  // TODO: this loading should happen in parent component, this is a temporary solution
  const { data, loading } = useQuery(getIdeaGroupsQuery, {
    variables: { organizationId },
  })

  if (loading || !data) {
    return null
  }

  const handleGroupSelect = (groupItem: GroupItem): void => {
    onGroupSelect?.(groupItem.id)
  }

  const selectedGroup = data.ideaGroups.find(
    (group) => group.id === selectedGroupId,
  )

  return (
    <Select
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error No overload matches this call
      label={selectedGroup ? selectedGroup.name : 'Unassigned'}
      items={[
        {
          id: UNASSIGNED_ITEM_ID,
          title: 'Unassigned',
          selected: !selectedGroupId,
        },
        ...data.ideaGroups.map((group) => ({
          id: group.id,
          title: group.name,
          selected: selectedGroupId === group.id,
          disabled: !!group.isLocked,
        })),
      ]}
      onSelectClick={handleGroupSelect}
    />
  )
}
