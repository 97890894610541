import React, { useEffect } from 'react'
import { Text, Button } from '@bufferapp/ui'
import ArrowRightIcon from '@bufferapp/ui/Icon/Icons/ArrowRight'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '~publish/legacy/store'
import { AndroidIcon } from '../../icons/AndroidIcon'
import { AppleIcon } from '../../icons/AppleIcon'
import type { StepProps } from '../../types'
import { openLinkAppStore, openLinkGooglePlay } from '../../utils'
import {
  StepsContainer,
  StepDescription,
  StepHeadline,
  ButtonsArea,
  QRArea,
  AppDownloadButtons,
  Title,
  FixedWidthWrapper,
  MainArea,
} from '../styles'
import { useAccount } from '~publish/legacy/accountContext'
import { trackButtonClick, trackStepViewed } from '../utils'
import { selectModalPlacement } from '~publish/legacy/modals/selectors'

const StepOne = ({ handleNextStep }: StepProps): JSX.Element => {
  const { t } = useTranslation()
  const account = useAccount()
  const currentOrganization = account?.account?.currentOrganization

  const remindersSetUpWizardPlacement: string = useAppSelector((state) =>
    selectModalPlacement(state),
  )

  useEffect(() => {
    if (currentOrganization) {
      trackStepViewed(1, currentOrganization?.id)
    }
  }, [currentOrganization])

  const onAndroidButtonClick = (): void => {
    trackButtonClick(
      'get-android-app',
      currentOrganization?.id,
      remindersSetUpWizardPlacement,
    )
    openLinkGooglePlay()
  }

  const onIOsButtonClick = (): void => {
    trackButtonClick(
      'get-ios-app',
      currentOrganization?.id,
      remindersSetUpWizardPlacement,
    )
    openLinkAppStore()
  }

  const onNextButtonClick = (): void => {
    trackButtonClick(
      'next',
      currentOrganization?.id,
      remindersSetUpWizardPlacement,
    )
    handleNextStep()
  }

  return (
    <>
      <MainArea>
        {/* TILE AND DESCRIPTION */}
        <Title type="h2">{t('remindersSetupWizard.headline')}</Title>
        <StepsContainer>
          {/* STEP 1: LOGIN TO THE BUFFER APP */}
          <StepHeadline step={1} active done={false}>
            {t('remindersSetupWizard.stepOne.headline')}
          </StepHeadline>
          <StepDescription borderBlue>
            <Text type="p">
              {t('remindersSetupWizard.stepOne.description')}
            </Text>
            <FixedWidthWrapper>
              {/* APPS DOWNLOAD BUTTONS */}
              <AppDownloadButtons>
                {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: () => void; size: s... Remove this comment to see the full error message */}
                <Button
                  type="secondary"
                  onClick={onAndroidButtonClick}
                  size="small"
                  label={t('remindersSetupWizard.stepOne.ctaAndroid')}
                  aria-label="Get Buffer for Android"
                  icon={<AndroidIcon />}
                />
                {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: () => void; size: s... Remove this comment to see the full error message */}
                <Button
                  type="secondary"
                  onClick={onIOsButtonClick}
                  size="small"
                  label={t('remindersSetupWizard.stepOne.ctaiOS')}
                  aria-label="Get Buffer for iOS"
                  icon={<AppleIcon />}
                />
              </AppDownloadButtons>
              {/* QR IMAGE */}
              <QRArea>
                <img
                  data-testid="reminders-wizard-qr-image"
                  src="https://buffer-publish.s3.amazonaws.com/images/qr-download.png"
                  alt="QR to download the Buffer app"
                />
              </QRArea>
            </FixedWidthWrapper>
          </StepDescription>
          {/* STEP 2: ENABLE NOTIFICATIONS */}
          <StepHeadline step={2} active={false} done={false}>
            {t('remindersSetupWizard.stepTwo.headline')}
          </StepHeadline>
        </StepsContainer>
      </MainArea>
      <ButtonsArea>
        {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: () => void; label: ... Remove this comment to see the full error message */}
        <Button
          type="primary"
          onClick={onNextButtonClick}
          label={t('remindersSetupWizard.stepOne.buttonLabel')}
          aria-label="Go to Next Step"
          icon={<ArrowRightIcon color="white" />}
          iconEnd
        />
      </ButtonsArea>
    </>
  )
}

export default StepOne
