import React from 'react'
import {
  ArrowUpIcon,
  Text,
  TrendingUpIcon,
  type IconProps,
  ImpressionsIcon,
  MessageSquareIcon,
  ThumbsUpIcon,
  ViewIcon,
  SmileIcon,
  RepostIcon,
  VisuallyHidden,
  Link,
  CommentSquareQuoteIcon,
  StarIcon,
  Share2Icon,
  MousePointerClickIcon,
  BookmarkIcon,
  ActivityIcon,
} from '@buffer-mono/popcorn'

import type { PostMetricType, PostMetricUnit } from '~publish/gql/graphql'

import { getChannelEngageUrl } from '../helpers'

import styles from './PostMetric.module.css'

const formatMetricNumber = (
  value: number,
  unit: PostMetricUnit,
): string | null => {
  const roundedValue = Math.round(value)

  if (unit === 'percentage') {
    return `${roundedValue}%`
  }

  if (value === 0) {
    return null
  }

  return roundedValue.toLocaleString('en-US')
}

const iconByType: Partial<
  Record<PostMetricType, React.ComponentType<IconProps>>
> = {
  engagementRate: ActivityIcon,
  favorites: StarIcon,
  impressions: ImpressionsIcon,
  likes: ThumbsUpIcon,
  reach: ArrowUpIcon,
  shares: Share2Icon,
  views: ViewIcon,
  reactions: SmileIcon,
  replies: MessageSquareIcon,
  comments: CommentSquareQuoteIcon,
  reblogs: RepostIcon,
  repins: RepostIcon,
  retweets: RepostIcon,
  link_clicks: MousePointerClickIcon,
  clicks: MousePointerClickIcon,
  saves: BookmarkIcon,
}

type PostMetricProps = {
  name: string
  type: PostMetricType
  value: number
  unit: PostMetricUnit
  channel: string
}

const PostMetric = ({
  name,
  type,
  value,
  unit,
  channel,
}: PostMetricProps): JSX.Element => {
  const Icon = iconByType[type] ?? TrendingUpIcon
  const linkToEngage =
    (type === 'replies' || type === 'comments') &&
    value > 0 &&
    getChannelEngageUrl(channel)

  const displayValue = formatMetricNumber(value, unit) ?? (
    <>
      <VisuallyHidden>0</VisuallyHidden>
      <span aria-hidden>-</span>
    </>
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        <Icon className={styles.icon} aria-hidden={true} color="subtle" />
        <Text size="sm" weight="medium">
          {name}
        </Text>
      </div>
      <Text className={styles.metric} weight="bold" size="md">
        {linkToEngage ? (
          <Link external href={linkToEngage}>
            {displayValue}
          </Link>
        ) : (
          displayValue
        )}
      </Text>
    </div>
  )
}

PostMetric.displayName = 'PostMetric'

export { PostMetric }
