import React from 'react'

import { Text, Button as LegacyButton } from '@bufferapp/ui'
import { Button, Dialog, Textarea, VisuallyHidden } from '@buffer-mono/popcorn'
import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'

import { Row } from '~publish/legacy/shared-components'
import { useAccount, useOrganizationId } from '~publish/legacy/accountContext'

import styles from './NewPublishExperience.module.css'
import { usePublishRevamp } from '~publish/hooks/usePublishRevamp'

const OptOutDialog = ({
  children,
  onOptedOut,
}: {
  children: React.ReactNode
  onOptedOut: () => void
}): JSX.Element => {
  const organizationId = useOrganizationId()
  const { account } = useAccount()
  const [feedback, setFeedback] = React.useState<string>('')

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()

    BufferTracker.feedbackFormSubmitted({
      clientName: 'publishWeb',
      organizationId: organizationId ?? '',
      userId: account.id ?? '',
      widgetId: 'single-channel-opt-out-1',
      source: 'publish',
      text: feedback ?? '(no feedback provided)',
    })

    setFeedback('')
    onOptedOut()
  }

  return (
    <Dialog>
      <Dialog.Trigger>{children}</Dialog.Trigger>
      <Dialog.Content asChild>
        <form className={styles.form} onSubmit={handleSubmit}>
          <Dialog.Header>
            <Dialog.Title>Sorry to see you go 😞</Dialog.Title>
            <Dialog.Description>
              Let us know why you&apos;ve disabled this new experience. You can
              always enable it again in the future.
            </Dialog.Description>
          </Dialog.Header>

          <Dialog.Body>
            <div>
              <VisuallyHidden as="label" htmlFor="feedback">
                Feedback
              </VisuallyHidden>
              <Textarea
                id="feedback"
                rows={3}
                resize="none"
                placeholder="Share your feedback"
                maxLength={500}
                value={feedback}
                onChange={(event): void => setFeedback(event.target.value)}
              />
            </div>
          </Dialog.Body>
          <Dialog.Footer>
            <Dialog.Close>
              <Button variant="secondary">Keep Enabled</Button>
            </Dialog.Close>
            <Button type="submit" variant="primary">
              Disable New Experience
            </Button>
          </Dialog.Footer>
        </form>
      </Dialog.Content>
    </Dialog>
  )
}

export const NewPublishExperience = (): JSX.Element => {
  const [isNewPublishEnabled, { setPreference }] = usePublishRevamp()

  const handleNewPublishOptOut = (): void => {
    setPreference('disabled')
  }

  const handleNewPublishOptIn = (): void => {
    setPreference('enabled')
  }

  return (
    <Row>
      <div>
        <Text type="h3">New Publish Experience</Text>
        <div
          style={{
            marginTop: '0.5rem',
          }}
        >
          <Text type="p">
            Temporarily enable/disable the new Publish experience. This
            experience <strong>will be permanently enabled soon.</strong>
          </Text>
        </div>
      </div>
      {isNewPublishEnabled ? (
        <div style={{ alignSelf: 'center' }}>
          <OptOutDialog onOptedOut={handleNewPublishOptOut}>
            {/* @ts-expect-error: Button is not a valid prop */}
            <LegacyButton label="Disable New Experience" type="secondary" />
          </OptOutDialog>
        </div>
      ) : (
        <div style={{ alignSelf: 'center' }}>
          {/* @ts-expect-error: Button is not a valid prop */}
          <LegacyButton
            label="Enable New Experience"
            type="primary"
            onClick={handleNewPublishOptIn}
          />
        </div>
      )}
    </Row>
  )
}
