import React from 'react'
import { useHasVisibleNotices } from '../RegisteredNotice'

export interface RegisteredBanner {
  id: string
  canRender: boolean
}

interface RegisteredBannersContextType {
  banners: RegisteredBanner[]
  activeBanner: React.ReactNode
  registerBanner: (newBanner: RegisteredBanner) => void
}

/**
 * RegisteredBannersContext provides a context for managing and rendering banners based on their registration
 * and rendering logic.
 *
 * This context allows components to register banners, track the active banner, and update banners dynamically.
 */
export const RegisteredBannersContext = React.createContext<
  RegisteredBannersContextType | undefined
>(undefined)

export function RegisteredBannersProvider({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  const [activeBanner, setActiveBanner] = React.useState<React.ReactNode>(null)
  const [banners, setBanners] = React.useState<RegisteredBanner[]>([])
  // Hooks into Notices context to check if there are visible notices
  // If there are visible notices, the banners will not be rendered
  // If the notice context isn't present, it will default to false
  const hasVisibleNotices = useHasVisibleNotices()

  // Register a banner
  const registerBanner = React.useCallback(
    (newBanner: RegisteredBanner): void => {
      const existingBanner = banners.find(
        (banner) => banner.id === newBanner.id,
      )
      // If the banner is already registered, check if it needs to be updated
      if (existingBanner) {
        if (existingBanner.canRender === newBanner.canRender) {
          return
        }
        setBanners((currentBanners) =>
          currentBanners.map((currentBanner) =>
            currentBanner.id === newBanner.id ? newBanner : currentBanner,
          ),
        )
        return
      }

      // If the banner is not registered, add it to the list
      setBanners((prev) => [...prev, newBanner])
    },
    [banners],
  )

  React.useLayoutEffect(() => {
    if (hasVisibleNotices) {
      setActiveBanner(null)
      return
    }

    // Check which banner should be active based on the logic
    for (const banner of banners) {
      if (banner.canRender) {
        setActiveBanner(banner.id)
        return
      }
    }
    // If no banner is eligible, set activeBanner to null
    setActiveBanner(null)
  }, [banners, hasVisibleNotices])

  return (
    <RegisteredBannersContext.Provider
      value={{ banners, activeBanner, registerBanner }}
    >
      {children}
    </RegisteredBannersContext.Provider>
  )
}

export function useRegisteredBannersContext(): RegisteredBannersContextType {
  const context = React.useContext(RegisteredBannersContext)
  if (context === undefined) {
    throw new Error(
      'useRegisteredBannersContext must be used within a RegisteredBannersProvider',
    )
  }
  return context
}

/**
 * RegisteredBanner is a React component that registers a banner with a given ID and rendering condition
 * and renders its children only when the banner is the currently active one.
 *
 * This ensures that only the active banner will be rendered while other registered banners will be ignored.
 *
 * The first banner that can render will become the active banner,
 * meaning the order in which banners are registered dictates their priority.
 *
 * @param {string} id - Unique identifier for the banner to register.
 * @param {boolean} canRender - A boolean value indicating whether the banner is allowed to render.
 * @param {React.ReactNode} children - The content (children) to render when the banner is active.
 *
 * @example
 * const isFeatureEnabled = useSplit('some-feature')
 * const banner = useDismissableBanner('new-banner')
 * const hasTwitterChannel = useHasChannelConnected('twitter')
 * const canRender = isFeatureEnabled && banner.isActive && !hasTwitterChannel
 *
 * <RegisteredBanner id="my-banner" canRender={canRender}>
 *  <MyBannerComponent />
 * </RegisteredBanner>
 */
export const RegisteredBanner = ({
  id,
  canRender,
  children,
}: {
  id: string
  canRender: boolean
  children: React.ReactNode
}): JSX.Element | null => {
  const { activeBanner, registerBanner } = useRegisteredBannersContext()

  React.useLayoutEffect(() => {
    registerBanner({ id, canRender })
  }, [id, canRender, registerBanner])

  if (activeBanner !== id) return null

  return <React.Fragment key={id}>{children}</React.Fragment>
}
