import { useQuery } from '@apollo/client'
import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

import {
  BetaBadge,
  Button,
  ChannelAvatar,
  CoachMark,
  CriticalIcon,
  EmptyState,
  Flex,
  GlobeIcon,
  IconButton,
  MessageCircleHeartIcon,
  PlusIcon,
  SettingsIcon,
  ShopgridIcon,
  Skeleton,
  SkeletonText,
  Text,
} from '@buffer-mono/popcorn'
import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'
import { useSplitEnabled } from '@buffer-mono/features'

import { FeedbackWidget } from '~publish/components/FeedbackWidget'
import { FilterByTag } from '~publish/components/FilterByTag'
import { NewPostComposerTrigger } from '~publish/components/NewPostComposerTrigger'
import { PostViewToggle } from '~publish/components/PostViewToggle/PostViewToggle'
import { graphql } from '~publish/gql'
import { formatTimeZone } from '~publish/helpers/dateFormatters'
import { sanitizeNullableArray } from '~publish/helpers/typeGuards'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import { TimezoneProvider } from '~publish/hooks/useTimezone'
import {
  useAccountId,
  useCurrentOrganization,
} from '~publish/legacy/accountContext'
import { useFullStory } from '~publish/legacy/thirdParty/hooks/useFullStory'
import type { GetChannelInfoQuery } from '~publish/gql/graphql'
import { PageLayout } from '~publish/components/PageLayout'
import {
  PostEmptyStateByTab,
  PostTabs,
  type PostTab,
} from '~publish/components/PostTabs'
import { RegisteredBannersProvider } from '~publish/components/RegisteredBanner'
import { RegisteredNoticesProvider } from '~publish/components/RegisteredNotice'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'

// TODO: fetching post counts and list should be extracted to common folders
import { PostList, PostListSkeleton } from '../AllChannels/PostList'
import { usePostCounts } from '../AllChannels/PostList/usePostCounts'
import { ChannelTour } from './ChannelTour'
import { QueueLimitNotice } from './QueueLimitNotice'
import { QueueList } from './QueueList'
import { QueuePausedNotice } from './QueuePausedNotice'
import { ChannelProvider } from './ChannelContext'
import { BlueskyPromotionalBanner } from './PromotionalBanners/BlueskyPromotionalBanner'
import { MetaThreadsPromotionalBanner } from './PromotionalBanners/MetaThreadsPromotionalBanner'

// TODO: replace serverUrl with metadata.serverUrl, at the moment doesn't seem to work
/*
metadata {
    __typename
    ... on MastodonMetadata {
      serverUrl
    }
  }
*/
export const ChannelPage_Channel = graphql(/* GraphQL */ `
  fragment ChannelPage_Channel on Channel {
    ...QueueList_Channel
    accessLevel
    id
    avatar
    isQueuePaused
    name
    locationData {
      location
    }
    serverUrl
    service
    timezone
    type
  }
`)
export const GetChannelInfo = graphql(/* GraphQL */ `
  query GetChannelInfo(
    $channelId: ChannelId!
    $organizationId: OrganizationId!
  ) {
    channel(input: { id: $channelId }) {
      ...QueueList_Channel
      ...ChannelPage_Channel
      id
      avatar
      isQueuePaused
      name
      locationData {
        location
      }
      serverUrl
      service
      timezone
    }

    tags(input: { organizationId: $organizationId }) {
      id
      ...FilterByTag_Tag
    }
  }
`)

/**
 * TODO: this should be removed on Monday, September 2
 * Meant as a temporary educational tool to show users where the Calendar is now located
 * while in beta
 */
const TemporaryCalendarCoachMark = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const banner = useDismissableBanner('calendar-button-coachmark')

  if (!banner.isActive) {
    return <>{children}</>
  }

  return (
    <CoachMark
      open={banner.isActive}
      dismissTimeout={null}
      onDismiss={banner.dismiss}
    >
      <CoachMark.Overlay>
        {children}
        <CoachMark.Spotlight />
      </CoachMark.Overlay>
      <CoachMark.Content>
        <CoachMark.Title>Calendar has a new home ✨</CoachMark.Title>
        <CoachMark.Description>
          We integrated calendar as part of the new channel view, switch back
          and forth seamlessly and keep your filters applied.
        </CoachMark.Description>
        <CoachMark.Footer>
          <CoachMark.Dismiss>
            <Button>Got it</Button>
          </CoachMark.Dismiss>
        </CoachMark.Footer>
      </CoachMark.Content>
    </CoachMark>
  )
}

export const ChannelPage = (): JSX.Element => {
  const id = useParams<{ id: string }>().id
  const [tab = 'queue', setTab] = useQueryParam<PostTab>('tab')
  const [tagsQueryParam = [], setTagFilter] = useQueryParam<string[]>('tags')

  const { initFullStory } = useFullStory()

  const { isEnabled: isChannelsTourFeatureEnabled } =
    useSplitEnabled('channels-tour')
  const { isEnabled: isSecondaryButtonTreatmentEnabled } = useSplitEnabled(
    'geid-secondary-button-treatment-with-global-action',
  )

  const organizationId = useCurrentOrganization()?.id

  const { data, error } = useQuery(GetChannelInfo, {
    variables: { organizationId, channelId: id },
    fetchPolicy: 'cache-and-network',
  })
  const { counts } = usePostCounts({
    organizationId,
    channelIds: [id],
    tagIds: tagsQueryParam,
  })

  const tags = sanitizeNullableArray(data?.tags)
  const channel = data?.channel

  // tracking
  useEffect(() => {
    initFullStory()
  }, [initFullStory])

  const accountId = useAccountId()

  useEffect(() => {
    BufferTracker.singleChannelOpened({
      tab,
      organizationId,
      accountId: accountId || '',
      channelSelected: id,
      tagsSelected: tagsQueryParam.length === 0 ? undefined : tagsQueryParam,
    })
  }, [organizationId, id, tagsQueryParam, tab, accountId])

  if (error) {
    return (
      <EmptyState size="xlarge" variant="critical">
        <EmptyState.Icon>
          <CriticalIcon />
        </EmptyState.Icon>
        <EmptyState.Heading>Failed to load</EmptyState.Heading>
        <EmptyState.Description>
          Error happened, please let our team know about it.{' '}
          <Text color="critical">{error.message}</Text>
        </EmptyState.Description>
      </EmptyState>
    )
  }

  if (!channel) {
    return (
      <PageLayout>
        <PageLayout.Header>
          <PageLayout.HeaderRow>
            <Flex direction="row" align="center" gap="space-200">
              <Skeleton rounded width={48} height={48} />
              <Skeleton rounded width={100} height={20} />
            </Flex>
            <PageLayout.Actions>
              <Skeleton width={90} height={40} />
            </PageLayout.Actions>
          </PageLayout.HeaderRow>
          <PageLayout.HeaderRow>
            <Flex direction="row" align="center" gap="space-200">
              <SkeletonText lines={1} width={80} />
              <SkeletonText lines={1} width={80} />
              <SkeletonText lines={1} width={80} />
              <SkeletonText lines={1} width={80} />
            </Flex>
            <PageLayout.Actions>
              <SkeletonText lines={1} width={80} />
              <SkeletonText lines={1} width={80} />
            </PageLayout.Actions>
          </PageLayout.HeaderRow>
        </PageLayout.Header>
        <PageLayout.Container size="narrow">
          <PostListSkeleton />
        </PageLayout.Container>
      </PageLayout>
    )
  }

  const tabs: { id: PostTab; count: number | null | undefined }[] = [
    { id: 'queue', count: counts?.queue },
    { id: 'drafts', count: counts?.drafts },
    { id: 'approvals', count: counts?.approvals },
    { id: 'sent', count: counts?.sent },
  ]

  const filterApplied = tagsQueryParam.length > 0

  return (
    <ChannelProvider channel={channel}>
      <TimezoneProvider timeZone={channel.timezone}>
        <RegisteredNoticesProvider
          key={`registered-notice-provider-${channel.name}`}
        >
          <RegisteredBannersProvider
            key={`registered-banner-provider-${channel.name}`}
          >
            {isChannelsTourFeatureEnabled && <ChannelTour />}
            <PostTabs
              asChild
              value={tab}
              // TODO: this should not be necessary once we remnove shop grid from tabs
              onChange={(selectedTab): void => setTab(selectedTab as PostTab)}
            >
              <PageLayout>
                <PageLayout.NotificationsContainer>
                  <ChannelPageNotifications />
                </PageLayout.NotificationsContainer>
                <PageLayout.Header>
                  <PageLayout.HeaderRow>
                    <ChannelPageTitle channel={channel} />
                    <PageLayout.Actions>
                      <FeedbackWidget id="single-channel-1" source="publish">
                        <Button variant="tertiary" size="large">
                          <MessageCircleHeartIcon />
                          Share Feedback
                        </Button>
                      </FeedbackWidget>
                      <TemporaryCalendarCoachMark>
                        <PostViewToggle />
                      </TemporaryCalendarCoachMark>
                      <NewPostComposerTrigger
                        cta="publish-singleChannel-header-newPost-1"
                        channels={[id]}
                      >
                        <Button
                          size="large"
                          variant={
                            isSecondaryButtonTreatmentEnabled
                              ? 'secondary'
                              : 'primary'
                          }
                        >
                          <PlusIcon /> New Post
                        </Button>
                      </NewPostComposerTrigger>
                    </PageLayout.Actions>
                  </PageLayout.HeaderRow>
                  <PageLayout.HeaderRow>
                    <PostTabs.TabList>
                      {tabs.map((tab) => (
                        <PostTabs.Tab
                          key={tab.id}
                          value={tab.id}
                          count={tab.count}
                        />
                      ))}
                    </PostTabs.TabList>
                    <PageLayout.Actions>
                      <div id="tags-filter">
                        <FilterByTag
                          tags={tags}
                          value={tagsQueryParam}
                          onSelect={setTagFilter}
                        />
                      </div>
                      <Button
                        variant="tertiary"
                        size="large"
                        as={Link}
                        to={`/channels/${id}/settings?tab=posting-schedule`}
                      >
                        <GlobeIcon aria-label="Timezone" />
                        {formatTimeZone(channel.timezone)}
                      </Button>
                    </PageLayout.Actions>
                  </PageLayout.HeaderRow>
                </PageLayout.Header>

                <PageLayout.Container size="narrow" as="main">
                  <PostTabs.Panel value="queue" asChild>
                    <QueueList
                      key={`${id}-queue`}
                      channel={channel}
                      tagIds={
                        tagsQueryParam.length ? tagsQueryParam : undefined
                      }
                    />
                  </PostTabs.Panel>

                  {(['drafts', 'approvals', 'sent'] as const).map((tab) => (
                    <PostTabs.Panel key={`${id}-${tab}`} value={tab} asChild>
                      <PostList
                        key={`${id}-${tab}`}
                        status={tab}
                        channelIds={[id]}
                        tagIds={
                          tagsQueryParam.length ? tagsQueryParam : undefined
                        }
                        emptyState={
                          !filterApplied && (
                            <PostEmptyStateByTab tab={tab}>
                              <NewPostComposerTrigger
                                cta="publish-singleChannel-emptyState-newPost-1"
                                channels={[id]}
                              >
                                <Button size="large">
                                  <PlusIcon /> New Post
                                </Button>
                              </NewPostComposerTrigger>
                            </PostEmptyStateByTab>
                          )
                        }
                      />
                    </PostTabs.Panel>
                  ))}
                </PageLayout.Container>
              </PageLayout>
            </PostTabs>
          </RegisteredBannersProvider>
        </RegisteredNoticesProvider>
      </TimezoneProvider>
    </ChannelProvider>
  )
}

export function ChannelPageNotifications(): JSX.Element {
  return (
    <>
      {/* Notices */}
      <QueueLimitNotice />
      <QueuePausedNotice />

      {/* Banners */}
      {/* We don't have support for checking missing credential scopes */}
      {/* so we can't accurately implement LinkedIn Analytics banner yet */}
      {/* TODO: Add scope check the Channel type to render LinkedIn Analytics banner */}
      {/* <RefreshLinkedInForAnalyticsPromotionalBanner /> */}
      <BlueskyPromotionalBanner />
      <MetaThreadsPromotionalBanner />

      {/* We don't support `hasPushNotifications` on the Channel type */}
      {/* so we can't accurately implement Instagram reminders banner yet */}
      {/* TODO: Add push notifications status to Channel type to allow showing Instagram reminders banner */}
      {/* <SetupInstagramRemindersPromotionalBanner /> */}
    </>
  )
}

export function ChannelPageTitle({
  channel,
}: {
  channel?: GetChannelInfoQuery['channel'] | null
}): JSX.Element {
  if (!channel) return <></>

  const secondaryChannelText =
    channel.serverUrl || channel.locationData?.location

  return (
    <Flex direction="row" align="center" gap="space-200">
      <ChannelAvatar
        src={channel.avatar}
        alt={channel.service}
        channel={channel.service}
        size="large"
      />
      <div>
        <Flex direction="row" align="center" gap="space-100">
          <PageLayout.Title>{channel.name}</PageLayout.Title>
          {channel.service === 'instagram' && (
            <IconButton
              id="shop-grid"
              variant="tertiary"
              label="Shop Grid"
              tooltip="Open Shop Grid"
              size="small"
              as={Link}
              to={`/channels/${channel.id}/shop-grid`}
            >
              <ShopgridIcon />
            </IconButton>
          )}
          <IconButton
            id="settings-menu"
            variant="tertiary"
            label="Channel settings"
            tooltip="Channel Settings"
            size="small"
            as={Link}
            to={`/channels/${channel.id}/settings`}
          >
            <SettingsIcon />
          </IconButton>

          <BetaBadge size="small" />
        </Flex>
        {secondaryChannelText && (
          <Text color="subtle">{secondaryChannelText}</Text>
        )}
      </div>
    </Flex>
  )
}
