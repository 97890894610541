import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import ComposerPopover from '~publish/legacy/composer-popover'
import {
  BufferLoading,
  ComposerInput,
  QueueItems,
} from '~publish/legacy/shared-components'

import { selectApprovalEntitlement } from '~publish/legacy/campaign/selectors'
import { SEGMENT_NAMES } from '~publish/legacy/constants'
import { BannerTypes } from '~publish/legacy/profile-page/hooks/useDismissBanner'
import ProfilesDisconnectedBanner from '~publish/legacy/profiles-disconnected-banner'
import {
  CollaborationUpgradePathBanner,
  useBannersInQueue,
} from '~publish/legacy/queue/components/Banners'
import QueueLimitBar from '~publish/legacy/queue/components/QueueLimitBar'
import ApprovalsUpsell from '~publish/legacy/upgrade-paths/components/ApprovalsUpsell'
import getErrorBoundary from '~publish/legacy/web/components/ErrorBoundary'
import Empty from '../Empty'

const ErrorBoundary = getErrorBoundary(true)

const composerStyle = {
  marginBottom: '1.5rem',
  flexGrow: '1',
}

const topBarContainerStyle = {
  display: 'flex',
}

const loadingContainerStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
  textAlign: 'center',
  paddingTop: '5rem',
}

const bannerStyle = {
  marginBottom: '1rem',
}

export const showPaywall = (tabId: string): boolean => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hasApprovalEntitlement = useSelector(selectApprovalEntitlement)
  const showPaywallApproval = !hasApprovalEntitlement && tabId === 'approvals'

  return showPaywallApproval
}

const DraftList = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'loading' implicitly has an 'any' ... Remove this comment to see the full error message
  loading,
  // @ts-expect-error TS(7031) FIXME: Binding element 'postLists' implicitly has an 'any... Remove this comment to see the full error message
  postLists,
  // @ts-expect-error TS(7031) FIXME: Binding element 'manager' implicitly has an 'any' ... Remove this comment to see the full error message
  manager,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onApproveClick' implicitly has an... Remove this comment to see the full error message
  onApproveClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onDeleteConfirmClick' implicitly ... Remove this comment to see the full error message
  onDeleteConfirmClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onEditClick' implicitly has an 'a... Remove this comment to see the full error message
  onEditClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onRevertApprovalClick' implicitly... Remove this comment to see the full error message
  onRevertApprovalClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onRequestApprovalClick' implicitl... Remove this comment to see the full error message
  onRequestApprovalClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onRescheduleClick' implicitly has... Remove this comment to see the full error message
  onRescheduleClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onComposerPlaceholderClick' impli... Remove this comment to see the full error message
  onComposerPlaceholderClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'tabId' implicitly has an 'any' ty... Remove this comment to see the full error message
  tabId,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isDisconnectedProfile' implicitly... Remove this comment to see the full error message
  isDisconnectedProfile,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hasFirstCommentFlip' implicitly h... Remove this comment to see the full error message
  hasFirstCommentFlip,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hasCampaignsFeature' implicitly h... Remove this comment to see the full error message
  hasCampaignsFeature,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onConfirmDismissComposer' implici... Remove this comment to see the full error message
  onConfirmDismissComposer,
  // @ts-expect-error TS(7031) FIXME: Binding element 'shouldResetComposerData' implicit... Remove this comment to see the full error message
  shouldResetComposerData,
  // @ts-expect-error TS(7031) FIXME: Binding element 'fetchDrafts' implicitly has an 'a... Remove this comment to see the full error message
  fetchDrafts,
  // @ts-expect-error TS(7031) FIXME: Binding element 'profileId' implicitly has an 'any... Remove this comment to see the full error message
  profileId,
}): JSX.Element => {
  const bannersToDisplay = [BannerTypes.collaborationUpgradePath]
  const { showBanners } = useBannersInQueue(bannersToDisplay, false)
  const [showCollaborationUpgradePath] = showBanners

  // Fetch the Data
  useEffect(() => {
    const needsApproval = tabId !== 'drafts'
    fetchDrafts({ needsApproval })
  }, [tabId, profileId])

  if (showPaywall(tabId)) {
    return <ApprovalsUpsell feature="queueTab" />
  }

  if (loading) {
    return (
      <div style={loadingContainerStyle}>
        <BufferLoading size={64} />
      </div>
    )
  }

  return (
    <ErrorBoundary>
      {isDisconnectedProfile && <ProfilesDisconnectedBanner />}
      <QueueLimitBar cta={SEGMENT_NAMES.DRAFTS_LIMIT_BANNER_UPGRADE} />
      <div style={topBarContainerStyle}>
        {tabId === 'drafts' && (
          <div style={composerStyle}>
            {showCollaborationUpgradePath && postLists.length > 0 && (
              <div style={bannerStyle}>
                <CollaborationUpgradePathBanner />
              </div>
            )}
            <ComposerInput
              placeholder="Create a new draft..."
              onPlaceholderClick={onComposerPlaceholderClick}
            />
          </div>
        )}
      </div>
      {postLists.length > 0 ? (
        <QueueItems
          items={postLists}
          onApproveClick={onApproveClick}
          onDeleteConfirmClick={onDeleteConfirmClick}
          onEditClick={onEditClick}
          onRevertApprovalClick={onRevertApprovalClick}
          onRequestApprovalClick={onRequestApprovalClick}
          onRescheduleClick={onRescheduleClick}
          draggable={false}
          type="drafts"
          hasFirstCommentFlip={hasFirstCommentFlip}
          hasCampaignsFeature={hasCampaignsFeature}
        />
      ) : (
        !isDisconnectedProfile && <Empty isManager={manager} view={tabId} />
      )}
    </ErrorBoundary>
  )
}

DraftList.propTypes = {
  loading: PropTypes.bool,
  postLists: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
    }),
  ),
  manager: PropTypes.bool.isRequired,
  onApproveClick: PropTypes.func.isRequired,
  onDeleteConfirmClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onRevertApprovalClick: PropTypes.func.isRequired,
  onRequestApprovalClick: PropTypes.func.isRequired,
  onRescheduleClick: PropTypes.func.isRequired,
  onComposerPlaceholderClick: PropTypes.func.isRequired,
  tabId: PropTypes.oneOf(['approvals', 'drafts']),
  isDisconnectedProfile: PropTypes.bool,
  hasFirstCommentFlip: PropTypes.bool,
  hasCampaignsFeature: PropTypes.bool,
  onConfirmDismissComposer: PropTypes.func.isRequired,
  shouldResetComposerData: PropTypes.bool,
  fetchDrafts: PropTypes.func.isRequired,
  profileId: PropTypes.string.isRequired,
}

DraftList.defaultProps = {
  shouldResetComposerData: true,
  loading: true,
  postLists: [],
  showComposer: false,
  editMode: false,
  tabId: null,
  isDisconnectedProfile: false,
  hasFirstCommentFlip: false,
}

export default DraftList
