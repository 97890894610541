import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { push } from 'redux-first-history'

import { Divider } from '@bufferapp/components'
import { Tooltip } from '@bufferapp/ui'
import { Gear, Tag } from '@bufferapp/ui/Icon'
import { useAccount } from '~publish/legacy/accountContext'
import { useAppDispatch } from '~publish/legacy/store'
import UserEntity from '~publish/legacy/user/UserEntity'
import AddChannelButton from '../AddChannelButton'
import {
  BottomSectionStyle,
  ButtonDividerStyle,
  ButtonStyled,
  ButtonWrapper,
  ManageSocialAccountsStyle,
  SocialButtonsWrapperStyle,
} from './style'

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean
  wrapper: (children: React.ReactNode) => JSX.Element
  children: React.ReactNode
}): JSX.Element => (condition ? wrapper(children) : <>{children}</>)

interface ManageSocialAccountsProps {
  canManageSocialAccounts?: boolean
  reachedChannelLimit?: boolean
  manageChannelsURL: string
  ownerEmail?: string
  hasProfiles: boolean
  shouldShowAddChannels?: boolean
}

const ManageSocialAccounts = ({
  canManageSocialAccounts,
  reachedChannelLimit,
  manageChannelsURL,
  ownerEmail,
  hasProfiles,
  shouldShowAddChannels,
}: ManageSocialAccountsProps): JSX.Element => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const account = useAccount()
  const isAdmin = UserEntity.isAdmin(account)

  return (
    <ManageSocialAccountsStyle>
      <SocialButtonsWrapperStyle>
        {canManageSocialAccounts && shouldShowAddChannels && (
          <AddChannelButton
            reachedChannelLimit={reachedChannelLimit}
            label={t('profile-sidebar.addChannels')}
          />
        )}
        <BottomSectionStyle>
          <ButtonDividerStyle>
            <Divider color="grayLight" marginTop="1rem" />
          </ButtonDividerStyle>
          <>
            <ButtonWrapper>
              <ButtonStyled
                label={`${isAdmin ? 'Manage' : 'View'} Tags`}
                type="secondary"
                icon={<Tag />}
                fullWidth
                onClick={(): void => {
                  dispatch(push('/tags'))
                }}
                active={pathname === '/tags'}
              />
            </ButtonWrapper>
            <ConditionalWrapper
              condition={!canManageSocialAccounts}
              wrapper={(children): JSX.Element => (
                // @ts-expect-error TS(2322) FIXME: Type '{ children: any; label: string; position: st... Remove this comment to see the full error message
                <Tooltip
                  label={t('profile-sidebar.permissionSubtitle', {
                    email: ownerEmail,
                  })}
                  position="top"
                >
                  {children}
                </Tooltip>
              )}
            >
              <ButtonStyled
                label={t(
                  `profile-sidebar.${
                    hasProfiles ? 'connectButton' : 'addChannels'
                  }`,
                )}
                data-testid="profile-sidebar-channels-action"
                type="secondary"
                icon={<Gear />}
                fullWidth
                disabled={!canManageSocialAccounts}
                onClick={(): void => {
                  window.location.assign(manageChannelsURL)
                }}
              />
            </ConditionalWrapper>
          </>
        </BottomSectionStyle>
      </SocialButtonsWrapperStyle>
    </ManageSocialAccountsStyle>
  )
}

ManageSocialAccounts.defaultProps = {
  canManageSocialAccounts: true,
  reachedChannelLimit: false,
  ownerEmail: 'the owner',
  shouldShowAddChannels: true,
}

export default ManageSocialAccounts
