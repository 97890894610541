import React from 'react'
import { Button, CoachMark, Image, NewBadge } from '@buffer-mono/popcorn'

import styles from './ChannelTour.module.css'

type StepPropTypes = {
  index: number
  open?: boolean
  step: {
    title?: React.ReactNode
    content?: React.ReactNode
    data?: {
      image: string
    }
    callback?: () => void
    placement?: string
  }
  size: number
  isLastStep: boolean
  closeProps: {
    onClick: (event: React.MouseEvent<HTMLElement>) => void
  }
  primaryProps: {
    onClick: (event: React.MouseEvent<HTMLElement>) => void
  }
  handleResumeTour: () => void
  isPaused?: boolean
}

export function StepCoachmark({
  index,
  open,
  step,
  size,
  isLastStep,
  closeProps,
  primaryProps,
  handleResumeTour,
  isPaused = false,
}: StepPropTypes): JSX.Element {
  const currentStepForDisplay = index + 1
  const totalStepsForDisplay = size

  const handleCloseGuide = (event: React.MouseEvent<HTMLElement>): void => {
    closeProps.onClick(event)

    if (step.callback) {
      step.callback()
    }
  }

  const handleNextStep = (event: React.MouseEvent<HTMLElement>): void => {
    primaryProps.onClick(event)
    if (isPaused) {
      handleResumeTour()
    }
  }

  const spotlightTop = index === 1 ? '-54px' : index === 2 ? '-39px' : '-45px'
  const spotlightLeft = index === 1 ? '-18px' : '0'

  return (
    <CoachMark
      open={open}
      onDismiss={(): void =>
        handleCloseGuide({} as React.MouseEvent<HTMLElement>)
      }
    >
      <CoachMark.Overlay>
        <CoachMark.Spotlight
          style={{
            '--spotlight-top': spotlightTop,
            '--spotlight-left': spotlightLeft,
          }}
        />
      </CoachMark.Overlay>

      <CoachMark.Content className={styles.coachmark} showCloseIcon={false}>
        {step.data?.image && (
          <CoachMark.Description>
            {index === 1 ? (
              <div className={styles.scrollingImageWrapper}>
                <div className={styles.scrollingImage}>
                  <Image src={step.data.image} alt="" />
                </div>
              </div>
            ) : (
              <Image src={step.data.image} alt="" />
            )}
          </CoachMark.Description>
        )}

        <CoachMark.Title>
          {index !== 2 && <NewBadge />} {step.title}
        </CoachMark.Title>
        <CoachMark.Description>{step.content}</CoachMark.Description>
        <CoachMark.Footer>
          {!isLastStep && (
            <Button variant="tertiary" onClick={handleCloseGuide}>
              Close
            </Button>
          )}
          <CoachMark.Dismiss>
            <Button onClick={handleNextStep}>
              {isLastStep
                ? 'Close'
                : `Next (${currentStepForDisplay}/${totalStepsForDisplay})`}
            </Button>
          </CoachMark.Dismiss>
        </CoachMark.Footer>
      </CoachMark.Content>
    </CoachMark>
  )
}
