import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { BufferTracker, Client } from '@bufferapp/buffer-tracking-browser-ts'
import { Text, Modal, Button } from '@bufferapp/ui'
import { blue, grayDarker, black } from '@bufferapp/ui/style/colors'
import { Cross, Checkmark } from '@bufferapp/ui/Icon'
import { SEGMENT_NAMES } from '~publish/legacy/constants'
import { actions as modalActions } from '~publish/legacy/modals/reducer'

import {
  HeadingText,
  TextStyled,
  CloseWrapper,
  BodyModal,
  TextWrapper,
  IconWrapper,
  Check,
  CTAWrapper,
} from './styles'

import { useAccount } from '../../../accountContext'

const onUpsellClick = ({
  dispatch,
  canStartTrial,
}: {
  dispatch: (action: unknown) => void
  canStartTrial: boolean
}): void => {
  const upgradePath = canStartTrial ? 'trial' : 'upgrade'
  const { MODALS, actions } = window?.appshell || {}
  actions.openModal(MODALS.planSelector, {
    cta: SEGMENT_NAMES.COMPOSER_REQUEST_APPROVAL_UPSELL,
    upgradePathName: `approvals-${upgradePath}`,
  })

  dispatch(modalActions.hideTrialCollaborationModal())
}

const TrialCollaborationModal: React.FC = () => {
  const dispatch = useDispatch()
  const user = useAccount()
  const canStartTrial =
    user?.account?.currentOrganization?.billing?.canStartTrial
  const headingText = canStartTrial
    ? 'Try our collaboration features for free!'
    : 'Try collaboration features on the Team plan'

  const commonTrackingProps =
    user?.account?.currentOrganization?.commonTrackingProperties || null

  useEffect(() => {
    BufferTracker.pageViewed({
      name: 'Draft Paywall Modal',
      product: 'publish',
      cta: 'Drafts Request Approval',
    })

    const upgradePath = canStartTrial ? 'trial' : 'upgrade'

    BufferTracker.upgradePathViewed({
      organizationId: user?.account?.currentOrganization?.id || '',
      upgradePathName: `approvals-${upgradePath}`,
      product: 'publish',
      clientName: Client.PublishWeb,
    })
  }, [user, commonTrackingProps, canStartTrial])

  const handleStartStrialClick = (): void => {
    const { MODALS, actions } = window?.appshell || {}
    dispatch(modalActions.hideTrialCollaborationModal())
    actions.openModal(MODALS.startTrial, {
      ctaView: 'trialCollaborationModal',
      ctaLocation: 'draft',
      cta: 'startTrial',
      ctaButton: 'startTrial',
      upgradePathName: 'approvals-trial',
    })
  }

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; action: {}; width: st... Remove this comment to see the full error message
    <Modal action={{}} width="wide" dismissible={false}>
      <CloseWrapper>
        <IconWrapper>
          <Cross
            onClick={() => dispatch(modalActions.hideTrialCollaborationModal())}
          />
        </IconWrapper>
      </CloseWrapper>

      <BodyModal background="url('https://buffer-publish.s3.amazonaws.com/images/celebrate%402x.svg') no-repeat">
        <TextWrapper>
          <HeadingText type="h1" color={black}>
            {headingText}
          </HeadingText>
          <Text type="p" color={grayDarker}>
            Send drafts for approval or review other&apos;s drafts plus...
          </Text>
          <Check>
            <Checkmark color={blue} />
            <TextStyled type="p" color={grayDarker}>
              Unlocked publishing, analytics and engagement
            </TextStyled>
          </Check>
          <Check>
            <Checkmark color={blue} />
            <TextStyled type="p" color={grayDarker}>
              Unlimited seats for your team
            </TextStyled>
          </Check>
          <Check>
            <Checkmark color={blue} />
            <TextStyled type="p" color={grayDarker}>
              Easy, clear exportable reports
            </TextStyled>
          </Check>
        </TextWrapper>
        {canStartTrial && (
          <CTAWrapper>
            {/* @ts-expect-error TS(2740) FIXME: Type '{ id: string; type: string; label: string; o... Remove this comment to see the full error message */}
            <Button
              id="collab-start-trial-cta"
              type="primary"
              label="Start a 14-day Free Trial"
              onClick={(): void => {
                handleStartStrialClick()
              }}
            />
            {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
            <Button
              type="secondary"
              label="See Paid Plans"
              onClick={(): void => {
                onUpsellClick({ dispatch, canStartTrial })
              }}
            />
          </CTAWrapper>
        )}
        {!canStartTrial && (
          // @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message
          <Button
            type="primary"
            label="Discover the Team plan"
            onClick={(): void => {
              onUpsellClick({ dispatch, canStartTrial })
            }}
          />
        )}
      </BodyModal>
    </Modal>
  )
}

export default TrialCollaborationModal
