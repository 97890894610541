import { Button, Flex, Textarea, VisuallyHidden } from '@buffer-mono/popcorn'
import useId from '@buffer-mono/popcorn/src/helpers/useId'
import React from 'react'
import styles from '../NotesWidget.module.css'

type Props = {
  value: string
  onSubmit: (text: string) => void
  onCancel: () => void
}

export const EditNoteForm = ({
  value,
  onSubmit,
  onCancel,
}: Props): JSX.Element => {
  const textareaId = useId()
  const formRef = React.useRef<HTMLFormElement>(null)
  const textareaRef = React.useRef<HTMLTextAreaElement>(null)

  React.useEffect(() => {
    // Focus the textarea and set the cursor to the end of the text
    textareaRef.current?.focus()
    textareaRef.current?.setSelectionRange(
      textareaRef.current.value.length,
      textareaRef.current.value.length,
    )
  }, [])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event?.preventDefault()
    const updatedText = textareaRef.current?.value.trim()

    if (!!updatedText && updatedText !== value) {
      onSubmit(updatedText)
      return
    }
    onCancel()
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (event.key === 'Enter' && (event.metaKey || event.ctrlKey)) {
      formRef.current?.requestSubmit()
    }

    if (event.key === 'Escape') {
      event.preventDefault()
      event.stopPropagation()
      onCancel()
    }
  }

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit}
      autoComplete="off"
      className={styles.form}
    >
      <VisuallyHidden as="label" htmlFor={textareaId}>
        Edit note
      </VisuallyHidden>
      <Textarea
        id={textareaId}
        ref={textareaRef}
        className={styles.editTextarea}
        name="editNote"
        defaultValue={value}
        maxLength={500}
        rows={2}
        onKeyDown={onKeyDown}
      />
      <Flex className={styles.editFormActions} gap="space-50">
        <Button
          variant="secondary"
          type="reset"
          size="small"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button variant="primary" type="submit" size="small">
          Save
        </Button>
      </Flex>
    </form>
  )
}
