import styled from 'styled-components'
import { white, grayDarker } from '@bufferapp/ui/style/colors'

export const ComposerRoot = styled.div`
  height: calc(100vh - 112px);
  max-height: 888px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  color: ${grayDarker};
  -webkit-font-smoothing: antialiased;
`

export const App = styled.div<{
  $fullHeight?: boolean
  $sidebarRightVisible?: boolean
  $sidebarLeftVisible?: boolean
}>`
  position: relative;
  z-index: 2;
  width: 720px;
  display: flex;
  flex-direction: column;
  /* margin: 100px auto 50px; */
  margin: 0px auto;
  transform: translate(0%, 0%);
  /* added to fix ie11 issue with react-tooltip */
  border: 1px solid transparent;
  border-radius: 8px;
  background: ${white};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.28);
  padding: 0;
  box-sizing: border-box;

  transition: all 350ms ease-in-out;
  margin-left: ${(props): string =>
    props.$sidebarLeftVisible ? '374px !important' : '0px'};
  margin-right: ${(props): string =>
    props.$sidebarRightVisible ? '374px !important' : '0px'};

  height: ${(props): string => (props.$fullHeight ? '100%' : 'auto')};
`
