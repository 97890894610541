import * as Dialog from '@radix-ui/react-dialog'
import React, { useEffect, useRef } from 'react'
import {
  type RootState,
  useAppDispatch,
  useAppSelector,
} from '~publish/legacy/store'
import { useTranslation } from 'react-i18next'
import { useDeleteIdea } from '~publish/pages/Create/hooks/useDeleteIdea'
import { useFilterIdeasByTag } from '~publish/legacy/campaign/hooks/useFilterIdeasByTag'
import { useIdeasEditor } from '~publish/pages/Create/hooks/useIdeasEditor'

import { createPostFromIdea } from '~publish/pages/Create/state/thunks/createPostFromIdea'
import { selectProfiles } from '~publish/legacy/profile-sidebar/selectors'
import { selectUserIdAndEmail } from '~publish/legacy/user/selectors'

import { IdeaEditor } from '~publish/pages/Create/components/IdeaEditor'
import { EmptyState } from '~publish/legacy/shared-components'
import { EmptyCampaignWrapper } from '../EmptyState'
import type { Idea as IdeaType, NewIdea } from '~publish/pages/Create/types'
import { IdeasList } from '../IdeasList'
import { selectTagById } from '~publish/legacy/campaign/slice'
import { PlusIcon, toast } from '@buffer-mono/popcorn'

export const IdeasListContent = ({
  campaignId,
}: {
  campaignId: string
}): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const ideaListRef = useRef(undefined)

  const { ideas, loadMore, resetIdeasAndFetch } =
    useFilterIdeasByTag(campaignId)

  const deleteIdea = useDeleteIdea()
  const {
    isOpen,
    currentIdea,
    errorMessage,
    errorCode,
    onOpenChange,
    openEditor,
    saveIdea,
    getCurrentContent,
    closeEditor,
    plateEditor,
    tags,
    setSelectedTags,
    title,
    setTitle,
    groupId,
    setGroupId,
  } = useIdeasEditor()
  const tag = useAppSelector((state: RootState) =>
    selectTagById(state, campaignId),
  )

  const profiles = useAppSelector(selectProfiles)
  const user = useAppSelector(selectUserIdAndEmail)

  const onDelete = async (idea: IdeaType): Promise<void> => {
    try {
      await deleteIdea(idea)
      resetIdeasAndFetch()
    } catch (error) {
      throw new Error('The idea could not be deleted. Please try again!')
    }
  }

  const onCreatePost = (): void => {
    const idea = getCurrentContent()
    if (idea) {
      dispatch(createPostFromIdea({ profiles, idea, user }))
      resetIdeasAndFetch()
      closeEditor()
    }
  }

  const onSaveIdea = (): void => {
    saveIdea()
    resetIdeasAndFetch()
  }

  const getNewIdeaWithTag = (): NewIdea => {
    return {
      content: {
        tags: tag
          ? [
              {
                id: tag.id,
                name: tag.name,
                color: tag.color,
              },
            ]
          : [],
      },
    }
  }

  useEffect(() => {
    loadMore(campaignId)
  }, [campaignId, loadMore])

  useEffect(() => {
    if (errorCode) {
      toast.error(`Operation failed: ${errorCode}`)
    }
  }, [errorCode])

  return (
    <Dialog.Root
      open={isOpen}
      modal={false}
      onOpenChange={(open: boolean): void => onOpenChange(open)}
    >
      {ideas.length === 0 && (
        <EmptyCampaignWrapper>
          <EmptyState
            height="100%"
            title={t('campaigns.viewCampaign.emptyIdeas.title')}
            subtitle={t('campaigns.viewCampaign.emptyIdeas.subtitle')}
            heroImg="https://buffer-publish.s3.amazonaws.com/images/empty-ideas-tag.png"
            primaryAction={{
              label: t('campaigns.viewCampaign.emptyIdeas.createIdea'),
              icon: <PlusIcon />,
              onClick: () => openEditor(getNewIdeaWithTag()),
            }}
          />
        </EmptyCampaignWrapper>
      )}
      <Dialog.Portal container={ideaListRef.current}>
        <IdeaEditor
          onSaveIdea={onSaveIdea}
          errorMessage={errorMessage}
          containerRef={ideaListRef.current}
          onCreatePost={onCreatePost}
          onOpenChange={onOpenChange}
          currentIdea={currentIdea}
          plateEditor={plateEditor}
          onSelectTags={setSelectedTags}
          preSelectedTags={tags}
          title={title}
          setTitle={setTitle}
          groupId={groupId}
          setGroupId={setGroupId}
        />
      </Dialog.Portal>
      {ideas.length > 0 && (
        <IdeasList ideas={ideas} onDelete={onDelete} openEditor={openEditor} />
      )}
    </Dialog.Root>
  )
}
