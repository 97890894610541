// TODO: Move to CSS custom properties instead
export const CARD_PADDING = 24
export const GRID_GAP = 16
export const LINE_HEIGHT = 24
export const IDEAS_UPLOADER_ID = 'ideas'

export const CARD_TYPE = {
  TEXT: 'text',
  MEDIA: 'media',
  MIXED: 'mixed',
}
export const TAG_MAX_CHARACTERS = 24
