import React from 'react'
import PropTypes from 'prop-types'
import HashtagGroupCreator from '../HashtagGroupCreator'
import HashtagGroupManager from '../HashtagGroupManager'

const boxStyle = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '28px 0 24px',
  overflow: 'hidden',
  boxSizing: 'border-box',
}

const CREATE_MODE = 'createHashtag'
const MANAGE_MODE = 'manageHashtag'
const EDIT_MODE = 'editHashtag'

/*
 * Wrapper to make sure to display group creator or manager
 */
class HashtagGroupWrapper extends React.Component {
  constructor() {
    // @ts-expect-error TS(2554) FIXME: Expected 1-2 arguments, but got 0.
    super()

    this.state = {
      viewMode: CREATE_MODE,
    }

    this.onSwitchMode = this.onSwitchMode.bind(this)
    this.onDeleteHashtagGroupClick = this.onDeleteHashtagGroupClick.bind(this)
    this.onEditHashtagGroupClick = this.onEditHashtagGroupClick.bind(this)
  }

  componentDidMount() {
    // @ts-expect-error TS(2339) FIXME: Property 'viewMode' does not exist on type 'Readon... Remove this comment to see the full error message
    const { viewMode } = this.props
    this.onSwitchMode(viewMode)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'viewMode' implicitly has an 'any' type.
  onSwitchMode(viewMode) {
    this.setState({ viewMode })
    // @ts-expect-error TS(2339) FIXME: Property 'onCancelHashtagGroup' does not exist on ... Remove this comment to see the full error message
    const { onCancelHashtagGroup } = this.props
    onCancelHashtagGroup()
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
  onDeleteHashtagGroupClick(name, text, snippetId) {
    // @ts-expect-error TS(2339) FIXME: Property 'onDeleteHashtagGroup' does not exist on ... Remove this comment to see the full error message
    const { onDeleteHashtagGroup } = this.props
    onDeleteHashtagGroup(name, text, snippetId)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
  onEditHashtagGroupClick(name, text, snippetId) {
    this.onSwitchMode(EDIT_MODE)
    // @ts-expect-error TS(2339) FIXME: Property 'onEditHashtagGroup' does not exist on ty... Remove this comment to see the full error message
    const { onEditHashtagGroup } = this.props
    onEditHashtagGroup(name, text, snippetId)
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'viewMode' does not exist on type 'Readon... Remove this comment to see the full error message
    const { viewMode } = this.state
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'hashtagGroups' does not exist on type 'R... Remove this comment to see the full error message
      hashtagGroups,
      // @ts-expect-error TS(2339) FIXME: Property 'onHandleInsertHashtagGroupClick' does no... Remove this comment to see the full error message
      onHandleInsertHashtagGroupClick,
      // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      name,
      // @ts-expect-error TS(2339) FIXME: Property 'text' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      text,
      // @ts-expect-error TS(2339) FIXME: Property 'snippetId' does not exist on type 'Reado... Remove this comment to see the full error message
      snippetId,
      // @ts-expect-error TS(2339) FIXME: Property 'onChangeGroupName' does not exist on typ... Remove this comment to see the full error message
      onChangeGroupName,
      // @ts-expect-error TS(2339) FIXME: Property 'onChangeGroupText' does not exist on typ... Remove this comment to see the full error message
      onChangeGroupText,
      // @ts-expect-error TS(2339) FIXME: Property 'onSaveHashtagGroup' does not exist on ty... Remove this comment to see the full error message
      onSaveHashtagGroup,
      // @ts-expect-error TS(2339) FIXME: Property 'onUpdateHashtagGroup' does not exist on ... Remove this comment to see the full error message
      onUpdateHashtagGroup,
    } = this.props

    return (
      <div style={boxStyle} data-testid="hash-tag-manager">
        {viewMode === CREATE_MODE && (
          <HashtagGroupCreator
            // @ts-expect-error TS(2322) FIXME: Type '{ name: any; text: any; isEditing: boolean; ... Remove this comment to see the full error message
            name={name}
            text={text}
            isEditing={false}
            onChangeGroupName={onChangeGroupName}
            onChangeGroupText={onChangeGroupText}
            onSaveHashtagGroup={onSaveHashtagGroup}
            onUpdateHashtagGroup={onUpdateHashtagGroup}
            onCancelHashtagGroup={() => this.onSwitchMode(MANAGE_MODE)}
          />
        )}

        {viewMode === EDIT_MODE && (
          <HashtagGroupCreator
            // @ts-expect-error TS(2322) FIXME: Type '{ name: any; text: any; snippetId: any; isEd... Remove this comment to see the full error message
            name={name}
            text={text}
            snippetId={snippetId}
            isEditing
            onChangeGroupName={onChangeGroupName}
            onChangeGroupText={onChangeGroupText}
            onSaveHashtagGroup={onSaveHashtagGroup}
            onUpdateHashtagGroup={onUpdateHashtagGroup}
            onCancelHashtagGroup={() => this.onSwitchMode(MANAGE_MODE)}
          />
        )}

        {viewMode === MANAGE_MODE && (
          <HashtagGroupManager
            hashtagGroups={hashtagGroups}
            onCreateHashtagGroup={() => this.onSwitchMode(CREATE_MODE)}
            onInsertHashtagGroupClick={onHandleInsertHashtagGroupClick}
            onEditHashtagGroupClick={this.onEditHashtagGroupClick}
            onDeleteHashtagGroupClick={this.onDeleteHashtagGroupClick}
          />
        )}
      </div>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
HashtagGroupWrapper.propTypes = {
  viewMode: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  snippetId: PropTypes.string,
  onHandleInsertHashtagGroupClick: PropTypes.func,
  onDeleteHashtagGroup: PropTypes.func,
  onEditHashtagGroup: PropTypes.func,
  onSaveHashtagGroup: PropTypes.func,
  onUpdateHashtagGroup: PropTypes.func,
  onChangeGroupName: PropTypes.func,
  onChangeGroupText: PropTypes.func,
  onCancelHashtagGroup: PropTypes.func,
  hashtagGroups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      id: PropTypes.string,
    }),
  ).isRequired,
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
HashtagGroupWrapper.defaultProps = {
  viewMode: 'createHashtag',
  name: null,
  text: null,
  snippetId: null,
  onHandleInsertHashtagGroupClick: () => {},
  onDeleteHashtagGroup: () => {},
  onEditHashtagGroup: () => {},
  onSaveHashtagGroup: () => {},
  onUpdateHashtagGroup: () => {},
  onChangeGroupName: () => {},
  onChangeGroupText: () => {},
  onCancelHashtagGroup: () => {},
}

export default HashtagGroupWrapper
