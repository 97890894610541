import { useMutation } from '@apollo/client'
import { DISMISS_BANNER } from '../graphql/dismissBanner'

export const BannerTypes = {
  emailVerification: 'emailVerification',
  learnBuffer: 'learnBuffer',
  instagramStoryAspectRatio: 'instagramStoryAspectRatio',
  instagramStoriesPromo: 'instagramStoriesPromo',
  remindersSetup: 'remindersSetup',
  pinterestPersonalProfile: 'pinterestPersonalProfile',
  youtubeSentPostAnalyticsNotice: 'youtubeSentPostAnalyticsNotice',
  twitterThreadsPromo: 'twitterThreadsPromo',
  collaborationUpgradePath: 'collaborationUpgradePath',
  contentPlanningBoardViewCoachMark: 'contentPlanningBoardViewCoachMark',
  threadsByMetaPromo: 'threadsByMetaPromo',
  blueskyPromo: 'blueskyPromo',
  profileEducationPopover: 'profileEducationPopover',
  twitterPremium: 'twitterPremium',
} as const

export const useDismissBanner = ({
  banner,
}: {
  banner: keyof typeof BannerTypes
}): { onDismissBanner: () => void } => {
  const [dismissBanner] = useMutation(DISMISS_BANNER)

  const onDismissBanner = (): void => {
    if (!banner || !Object.values(BannerTypes).includes(banner)) {
      return
    }

    dismissBanner({ variables: { banner } })
  }

  return { onDismissBanner }
}

export default useDismissBanner
