import React, { useId } from 'react'
import {
  Button,
  Flex,
  Heading,
  HoverCard,
  IconButton,
  LockIcon,
  Paragraph,
  UpgradeBadge,
  VisuallyHidden,
} from '@buffer-mono/popcorn'

import { IdeaBoardGroupLimitPaywall } from './IdeaBoardGroupLimitPaywall'

import styles from './LockedColumnMarker.module.css'

export function LockedColumnMarker(): JSX.Element {
  const descriptionId = useId()

  return (
    <>
      {/* Hover cards are not accessible for assistive technology,
      so we are adding accessible description for screen readers */}
      <VisuallyHidden id={descriptionId}>
        This group is locked, you&apos;ve hit the limit of 3 free groups.
        Upgrade to unlock this group
      </VisuallyHidden>

      <HoverCard>
        <IdeaBoardGroupLimitPaywall
          cta={{ location: 'groupHeader', button: 'lock' }}
        >
          <HoverCard.Trigger>
            <IconButton
              variant="secondary"
              label="Upgrade to unlock this group"
              aria-describedby={descriptionId}
            >
              <LockIcon />
            </IconButton>
          </HoverCard.Trigger>
        </IdeaBoardGroupLimitPaywall>
        <HoverCard.Content className={styles.card}>
          <UpgradeBadge className={styles.badge} />
          <Flex direction="column" gap="space-100">
            <Heading size="small">Upgrade to unlock this group</Heading>
            <Paragraph>
              You&apos;ve hit your limit of 3 groups on the free plan. Upgrade
              to unlock unlimited groups.
            </Paragraph>
            <IdeaBoardGroupLimitPaywall
              cta={{ location: 'groupHeader', button: 'upgrade' }}
            >
              <Button className={styles.button}>Upgrade now</Button>
            </IdeaBoardGroupLimitPaywall>
          </Flex>
        </HoverCard.Content>
      </HoverCard>
    </>
  )
}
