import { useMemo } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'

import { graphql } from '~publish/gql'
import { useInfiniteScrollPagination } from '~publish/hooks/useInfiniteScrollPagination'
import type { PostStatus, PostSortInput } from '~publish/gql/graphql'
import { useRealTimePusherUpdates } from './useRealTimePusherUpdates'
import { useOrganizationId } from '~publish/legacy/accountContext'
import { debugLog } from '~publish/helpers/debugLog'

export const POSTS_PER_PAGE = 20

export const GetPostList = graphql(/* GraphQL */ `
  query GetPostList(
    $first: Int!
    $after: String
    $organizationId: OrganizationId!
    $channelIds: [ChannelId!]
    $tagIds: [TagId!]
    $status: [PostStatus!]
    $sort: [PostSortInput!]
  ) {
    posts(
      input: {
        organizationId: $organizationId
        filter: { channelIds: $channelIds, tagIds: $tagIds, status: $status }
        sort: $sort
      }
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          ...PostCard_Post
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`)

type SortPreset = 'upcomingFirst' | 'mostRecentlyPostedFirst'

export type UsePaginatedPostListOptions = {
  status: PostStatus[]
  channelIds?: string[]
  tagIds?: string[]
  sortPreset?: SortPreset
}

const mapSortPresetToSortOrder = (sortPreset: SortPreset): PostSortInput[] => {
  switch (sortPreset) {
    case 'upcomingFirst':
      return [
        { field: 'dueAt', direction: 'asc' },
        { field: 'createdAt', direction: 'desc' },
      ]
    case 'mostRecentlyPostedFirst':
      return [
        { field: 'dueAt', direction: 'desc' },
        { field: 'createdAt', direction: 'desc' },
      ]
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const usePaginatedPostList = ({
  status,
  channelIds,
  tagIds,
  sortPreset = 'upcomingFirst',
}: UsePaginatedPostListOptions) => {
  const organizationId = useOrganizationId()
  const { data, refetch, fetchMore, error, networkStatus } = useQuery(
    GetPostList,
    {
      variables: {
        first: POSTS_PER_PAGE,
        after: null,
        organizationId,
        status,
        channelIds,
        tagIds,
        sort: mapSortPresetToSortOrder(sortPreset),
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  )

  const loading = networkStatus === NetworkStatus.loading
  const fetchingMore = networkStatus === NetworkStatus.fetchMore

  if (networkStatus === NetworkStatus.refetch) {
    debugLog('🔄 refetching posts...')
  }

  useRealTimePusherUpdates({
    filters: {
      status,
      tagIds,
      channelIds,
    },
    refetch: async (options): Promise<void> => {
      debugLog('⚡ posts refetch triggered', options?.mode)

      await refetch({
        first:
          // HACK: fetch one more post than current in case in insert mode
          // this works up to a limit, but it helps resolving issue of new posts not showing up
          data?.posts?.edges?.length
            ? data.posts.edges.length + (options?.mode === 'insert' ? 1 : 0)
            : POSTS_PER_PAGE,
      })
    },
    isPostVisible: (id: string): boolean => {
      return data?.posts?.edges
        ? data.posts.edges.some((edge) => {
            return edge.node.id === id
          })
        : false
    },
  })

  const [lastElementRef] = useInfiniteScrollPagination({
    loading: fetchingMore,
    hasNextPage: data?.posts.pageInfo.hasNextPage,
    fetchMore: () =>
      fetchMore({
        variables: {
          after: data?.posts.pageInfo.endCursor,
        },
      }),
  })

  return useMemo(
    () => ({
      data,
      loading,
      fetchingMore,
      error,
      lastElementRef,
    }),
    [data, loading, fetchingMore, error, lastElementRef],
  )
}
