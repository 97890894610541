import React from 'react'

import { useLoadCalendarPostCard } from '../hooks/useLoadCalendarPostCard'
import { PostCardProvider } from '~publish/components/PostCard'
import PostPreview, {
  type PostPreviewProps,
} from '~publish/legacy/calendar/components/PostPreview'
import styles from './PostCardHoverCard.module.css'

type CalendarPostHoveredCardProps = {
  postId: string
} & PostPreviewProps

export const CalendarPostHoveredCard = (
  props: CalendarPostHoveredCardProps,
): JSX.Element => {
  const { postId, ...legacyProps } = props
  const { data } = useLoadCalendarPostCard(postId)
  const post = data?.post
  if (!post) return <div className={styles.loading} />
  return (
    <PostCardProvider post={post}>
      <PostPreview {...legacyProps} />
    </PostCardProvider>
  )
}
