import React, { useState } from 'react'
import GearIcon from '@bufferapp/ui/Icon/Icons/Gear'
import HeartOutlineIcon from '@bufferapp/ui/Icon/Icons/HeartOutline'
import RefreshIcon from '@bufferapp/ui/Icon/Icons/Refresh'

import { useAppSelector, useAppDispatch, history } from '~publish/legacy/store'
import { selectGenerateContentLimits } from '~publish/legacy/ai/state/aiLimits/slice'
import {
  selectGeneratedIdeas,
  clearIdeas,
} from '~publish/legacy/ai/state/contentIdeation/slice'
import { generateIdeas } from '~publish/legacy/ai/state/contentIdeation/thunks'
import { getPrompt } from '~publish/legacy/ai/helpers/localStorage'
import { Credits } from '~publish/legacy/ai/components/GenerateIdeasButton/Credits'
import { ErrorMessage } from '~publish/legacy/ai/components/GenerateIdeasButton/ErrorMessage'
import { createNew } from '~publish/legacy/routes'

import * as Styled from './styles'

const LoadingState = (): JSX.Element => (
  <Styled.SkeletonBoxWrapper>
    <Styled.SkeletonBox style={{ width: '80%' }} />
    <Styled.SkeletonBox style={{ width: '90%' }} />
    <Styled.SkeletonBox style={{ width: '83%' }} />
    <Styled.SkeletonBox style={{ width: '50%' }} />
    <Styled.SkeletonBox style={{ width: '80%' }} />
  </Styled.SkeletonBoxWrapper>
)

/**
 *
 */
const GeneratedIdea = ({
  onUpgrade,
  source,
}: {
  onUpgrade: () => void
  source?: string
}): JSX.Element | null => {
  const [animateButton, setAnimateButton] = useState(false)

  const dispatch = useAppDispatch()

  const { canGenerateContent } = useAppSelector((state) =>
    selectGenerateContentLimits(state),
  )

  const { generatedIdeas, generateIdeasLoading, generateIdeasError } =
    useAppSelector((state) => selectGeneratedIdeas(state))

  const onTryAnother = (): void => {
    if (canGenerateContent) {
      dispatch(
        generateIdeas({
          prompt: getPrompt(),
          cta: 'publish-ideas-contentIdeationWidget-tryAnother-1',
        }),
      )
    } else {
      setAnimateButton(true)
      setTimeout(() => setAnimateButton(false), 500)
    }
  }

  const onUseIt = (): void => {
    const idea = generatedIdeas?.[0]
    if (!idea) return
    const params = new URLSearchParams(window.location.search)
    params.set('text', idea)
    if (source) {
      params.set('source', source)
    }
    history.push(`${createNew.route}?${params.toString()}`)
  }

  const onCustomize = (): void => {
    if (canGenerateContent) {
      // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0
      dispatch(clearIdeas())
    } else {
      onUpgrade()
    }
  }

  return generatedIdeas ? (
    <Styled.Wrapper>
      <Styled.CustomizeButton onClick={onCustomize}>
        <GearIcon size="smedium" />
        Customize
      </Styled.CustomizeButton>
      <Styled.IdeaWrapper>
        {generateIdeasLoading ? (
          <LoadingState />
        ) : (
          <Styled.Idea loading={generateIdeasLoading}>
            {generatedIdeas[0]}
          </Styled.Idea>
        )}
        <Styled.Buttons>
          {generateIdeasLoading ? (
            <Styled.LoadingMessage>
              <RefreshIcon />
              Generating another idea...
            </Styled.LoadingMessage>
          ) : (
            <>
              <Styled.Button
                onClick={onTryAnother}
                disabledStyles={!canGenerateContent}
              >
                <RefreshIcon />
                Try Another
              </Styled.Button>
              <Styled.Divider />
              <Styled.ButtonPrimary onClick={onUseIt}>
                <HeartOutlineIcon />
                Use it
              </Styled.ButtonPrimary>
            </>
          )}
        </Styled.Buttons>
      </Styled.IdeaWrapper>
      {generateIdeasError && <ErrorMessage message={generateIdeasError} />}
      <Styled.ErrorMessageContainer animate={animateButton}>
        <Credits />
      </Styled.ErrorMessageContainer>
    </Styled.Wrapper>
  ) : null
}

export { GeneratedIdea }
