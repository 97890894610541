import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Button, SimpleModal, Text } from '@bufferapp/ui'
import { blue, grayDark } from '@bufferapp/ui/style/colors'
import CheckmarkIcon from '@bufferapp/ui/Icon/Icons/Checkmark'
import { SEGMENT_NAMES } from '~publish/legacy/constants'
import { Trans, useTranslation } from 'react-i18next'
import {
  BufferTracker,
  Product,
  Client,
} from '@bufferapp/buffer-tracking-browser-ts'
import {
  useAccount,
  useOrganizationBilling,
} from '~publish/legacy/accountContext'
import { actions as modalActions } from '~publish/legacy/modals/reducer'
import { useDispatch } from 'react-redux'

export const Wrapper = styled.div`
  width: 720px;
  height: 397px;
  display: flex;

  h2 {
    margin: 0 0 16px;
  }

  p {
    margin: 0;
    line-height: 24px;
    color: ${grayDark};
  }
`

export const Content = styled.div`
  padding: 60px 50px;
`

export const Image = styled.img`
  height: 100%;
`

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 16px 0 32px;
`

export const Item = styled.li`
  display: flex;
  align-items: center;
  color: ${grayDark};
`

export const StyledCheckmark = styled(CheckmarkIcon)`
  color: ${blue};
  flex-shrink: 0;
  margin-right: 6px;
`

export const TagsUpgradeModal = (): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { account } = useAccount()
  const currentOrganization = account?.currentOrganization
  const billing = useOrganizationBilling()
  const canStartTrial = billing?.canStartTrial
  const cta = canStartTrial
    ? SEGMENT_NAMES.TAGS_LIST_LIMIT_TRIAL
    : SEGMENT_NAMES.TAGS_LIST_LIMIT_UPGRADE
  const upgradePathName = canStartTrial
    ? 'tagsLimit-trial'
    : 'tagsLimit-upgrade'

  const onCloseModal = (): void => {
    dispatch(modalActions.hideTagsLimitUpgradeModal())
  }

  useEffect(() => {
    const eventData = {
      cta,
      product: Product.Publish,
      upgradePathName,
      organizationId: currentOrganization?.id || '',
      clientName: Client.PublishWeb,
    }
    BufferTracker.upgradePathViewed(eventData)
  }, [currentOrganization])

  const handleUpgradeButtonClick = (): void => {
    onCloseModal()
    const { MODALS, actions } = window?.appshell || {}
    actions.openModal(MODALS.planSelector, {
      cta,
      upgradePathName,
    })
  }

  return (
    <SimpleModal closeAction={onCloseModal}>
      <Wrapper>
        <Content>
          <Text type="h2">{t('campaigns.upgradePlanModal.title')}</Text>
          <Text type="p">
            <Trans i18nKey="campaigns.upgradePlanModal.description" />
          </Text>
          <List>
            <Item>
              <StyledCheckmark />
              <Text type="p">{t('campaigns.upgradePlanModal.bullet1')}</Text>
            </Item>
            <Item>
              <StyledCheckmark />
              <Text type="p">{t('campaigns.upgradePlanModal.bullet2')}</Text>
            </Item>
            <Item>
              <StyledCheckmark />
              <Text type="p">{t('campaigns.upgradePlanModal.bullet3')}</Text>
            </Item>
          </List>
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: ()... Remove this comment to see the full error message */}
          <Button
            type="primary"
            label={t('campaigns.upgradePlanModal.button')}
            onClick={handleUpgradeButtonClick}
          />
        </Content>
        <Image
          src="https://buffer-publish.s3.amazonaws.com/images/tags-upgrade%402x.png"
          alt="upgrade to unlock more tags"
        />
      </Wrapper>
    </SimpleModal>
  )
}
