import query, { type ParsedQuery, type StringifyOptions } from 'query-string'
import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export type UseQueryParamOptions = {
  /**
   * If true, replaces the current URL without adding to the history stack.
   */
  replace?: boolean
}

const queryStringSerializingOptions: StringifyOptions = {
  arrayFormat: 'bracket',
}

export type QueryParamValue =
  | string
  | string[]
  | (string | string[] | null | undefined)[]
  | null
  | undefined
/**
 * This hook is used to manage query parameters in the URL.
 * It supports array with the same key.
 *
 * @param param - The query parameter name to manage
 * @example
 * ```tsx
 * const [tab = 'defaultTab', setTab] = useQueryParam('tab')
 * const [tags, setChannels] = useQueryParam<string[]>('tags')
 *```
 */
export function useQueryParam<T extends string | string[] = string>(
  param: string,
): [
  T | undefined,
  (newValue: T | undefined, options?: UseQueryParamOptions) => void,
] {
  const location = useLocation()
  const history = useHistory()

  const queryParams = useMemo(
    () => parseQueryParams(location.search),
    [location.search],
  )

  // Casting type is necessary here as the parsed type is unknown
  const currentValue = (queryParams[param] as T | undefined) ?? undefined

  const setValue = useCallback(
    (newValue?: T, options: UseQueryParamOptions = {}): void => {
      const newQueryParams = { ...queryParams }

      if (newValue === undefined) {
        delete newQueryParams[param]
      } else {
        newQueryParams[param] = newValue
      }

      const newSearch = serializeQueryParams(newQueryParams)
      const newLocation = `${location.pathname}?${newSearch}`

      if (options.replace) {
        history.replace(newLocation)
      } else {
        history.push(newLocation)
      }
    },
    [history, location.pathname, param, queryParams],
  )

  return [currentValue, setValue]
}

export function serializeQueryParams(params: {
  [x: string]: QueryParamValue
}): string {
  return query.stringify(params, queryStringSerializingOptions)
}

export function parseQueryParams(
  search: string,
): ParsedQuery<string | string[] | undefined> {
  return query.parse(search, queryStringSerializingOptions)
}
