import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

import {
  DocumentPreviewControls,
  LoadingSpinner,
} from './DocumentPreviewControls'

import styles from './DocumentPreview.module.css' // Import CSS module

if (process.env.NODE_ENV !== 'test') {
  // Set the worker source for pdfjs
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`
}

export const DocumentPreview = ({
  title,
  url,
}: {
  title: string
  url: string
}): JSX.Element => {
  const [pageCount, setPageCount] = useState<number>(1)
  const [pageNumber, setPageNumber] = useState<number>(1)

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setPageCount(numPages)
    setPageNumber(1)
  }

  function changePage(offset: number): void {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  const pagesToRender = Array.from(
    // we only render the page after the current one
    { length: Math.min(pageNumber + 1, pageCount) },
    // pages are 1-indexed
    (_, index) => index + 1,
  )

  // Calculate the offset for sliding animation
  const offset = -100 * (pageNumber - 1)

  return (
    <div className={styles.previewContainer}>
      <DocumentPreviewControls
        title={title}
        pageCount={pageCount}
        pageNumber={pageNumber}
        changePage={changePage}
      />
      <Document
        file={url}
        loading={<LoadingSpinner />}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <ul
          className={styles.previewViewport}
          style={{ transform: `translateX(${offset}%)` }}
        >
          {pagesToRender.map((pageNumber) => (
            <li key={pageNumber} className={styles.page}>
              <Page
                pageNumber={pageNumber}
                // width is .previewViewport minus minus .page padding
                width={290}
              />
            </li>
          ))}
        </ul>
      </Document>
    </div>
  )
}
