import { gql } from '@apollo/client'
import React, { useEffect, useState } from 'react'

import type { Service } from '~publish/gql/graphql'
import { initialAccountContextState } from './constants'
import type {
  Billing,
  CurrentOrganization,
  InitialAccountContext,
  Limits,
  Privileges,
} from './types'

export const AccountContext = React.createContext(initialAccountContextState)

export type planType = {
  id: string | null
  isAdmin: boolean
  isOneBufferOrganization: boolean
}

function getPlanId(account: InitialAccountContext): string {
  const currentOrganization = account?.account?.currentOrganization
  if (currentOrganization && currentOrganization.isOneBufferOrganization) {
    return currentOrganization.billing.subscription.plan.id || ''
  }

  return ''
}

export function usePlan(): planType {
  const account = React.useContext(AccountContext)
  if (account === undefined) {
    throw new Error('usePlan must be used within a AccountContext')
  }

  const [plan, setPlan] = useState({
    id: '',
    isAdmin: false,
    isOneBufferOrganization: true,
  })

  useEffect(() => {
    const currentOrganization = account?.account?.currentOrganization

    setPlan({
      id: getPlanId(account),
      isAdmin: currentOrganization?.role === 'admin',
      isOneBufferOrganization: currentOrganization?.isOneBufferOrganization,
    })
  }, [account])

  return plan
}

export const useAccount = (): InitialAccountContext => {
  const context = React.useContext(AccountContext)
  if (context === undefined) {
    throw new Error('useAccount must be used within a AccountContext')
  }
  return context
}

export const useCurrentOrganization = (): CurrentOrganization => {
  return useAccount()?.account?.currentOrganization
}

// Custom hook to keep track if the homepage has been initially loaded
export const usePageLoad = (): {
  initialPageLoad: boolean
  updateInitialPageLoad: () => void
} => {
  const [initialPageLoad, setInitialPageLoad] = useState(false)

  const updateInitialPageLoad = (): void => {
    setInitialPageLoad(true)
  }
  return { initialPageLoad, updateInitialPageLoad }
}

export const useDismissedBanners = (): string[] => {
  const account = React.useContext(AccountContext)
  if (account === undefined) {
    throw new Error('useAccount must be used within a AccountContext')
  }

  return account?.account?.dismissedBanners
}

export const useChannelsConnected = (): CurrentOrganization['channels'] => {
  return useCurrentOrganization()?.channels
}

export const useHasChannelConnected = (channel: Service): boolean => {
  return useCurrentOrganization()?.channels?.some(
    (currentChannel) => currentChannel.service === channel,
  )
}

export const useHasEntitlement = (entitlement: string): boolean => {
  return useCurrentOrganization()?.entitlements?.includes(entitlement)
}

export const useOrganizationId = (): string => {
  return useCurrentOrganization()?.id
}

export const useOrganizationLimits = (): Limits => {
  return useCurrentOrganization()?.limits
}

export const useOrganizationBilling = (): Billing => {
  return useCurrentOrganization()?.billing
}

export const useAccountPrivileges = (): Privileges | undefined => {
  return useCurrentOrganization()?.privileges
}

export const useOrganizationMembersTotal = (): number | undefined => {
  return useCurrentOrganization()?.members?.totalCount
}

export const useAccountId = (): string | null => {
  return useAccount().account.id
}

export const useCanStartTrial = (): boolean => {
  return useOrganizationBilling()?.canStartTrial
}

export const useIsLegacyPlan = (): boolean => {
  const { isOneBufferOrganization } = useCurrentOrganization()
  return !isOneBufferOrganization
}

export const GET_ACCOUNT = gql`
  query getAccount {
    account {
      id
      email
      featureFlips
      createdAt
      dismissedBanners
      settings {
        aiSettings {
          onboarded
        }
      }
      currentOrganization {
        id
        isOneBufferOrganization
        isBetaEnabled
        entitlements
        createdAt
        members {
          totalCount
        }
        privileges {
          canManageAllNotes
          canEdit
        }
        channels {
          id
          name
          service
          organizationId
          isLocked
        }
        commonTrackingProperties {
          atEventBillingState
          atEventBillingPlan
          atEventBillingCycle
          atEventBillingGateway
          atEventIsNewBuffer
          atEventChannelQty
        }
        dismissedObjects {
          id
        }
        name
        role
        billing {
          canAccessPublishing
          ... on OBBilling {
            id
            canStartTrial
            subscription {
              interval
              plan {
                id
              }
            }
            changePlanOptions {
              planId
              planInterval
              isRecommended
            }
            gateway {
              gatewayType
            }
            paymentDetails {
              hasPaymentDetails
            }
          }
        }
        limits {
          ideas
          channels
          scheduledPosts
          tags
        }
      }
    }
  }
`
