import styled from 'styled-components'
import { grayDarker } from '@bufferapp/ui/style/colors'
import { fontFamily } from '@bufferapp/ui/style/fonts'

export const StatLabel = styled.span`
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
  margin-right: 8px;
  font-weight: 500;
  font-size: 14px;
`

export const StatValue = styled.span`
  font-size: 18px;
  font-weight: 600;
  margin-left: 1px;
`

export const StatsWrapper = styled.ul`
  display: flex;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-left: 0px;
  text-align: left;
  font-family: ${fontFamily};
  color: ${grayDarker};
`

export const StatWrapper = styled.li`
  display: flex;
  flex-direction: column;
`
