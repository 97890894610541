import Button from '@bufferapp/ui/Button'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { actions as modalActions } from '~publish/legacy/modals/reducer'

import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'
import { useAccount } from '~publish/legacy/accountContext'
import AppActionCreators from '../../action-creators/AppActionCreators'
import {
  ButtonsQueuingTypesMap,
  PaywallButtonTypes,
  SaveButtonTypes,
} from '../../AppConstants'
import { UserDataContext } from '../../hooks/userDataContext'
import { profilesPropType } from '../ComposerPropTypes'
import DateTimeSlotPicker from '../DateTimeSlotPicker'

const ButtonStyled = styled(Button)`
  margin-right: 10px;
`

const ScheduleTimeWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  right: 100%;
  left: auto;
  margin-right: 10px;
  z-index: 20;
  padding: 5px 0;
  background: white;
  border: 1px solid #d5dce3;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-2);
`

const DraftSaveButtonWrapper = styled.div`
  position: relative;
`

const ScheduleTime: React.FC<{
  timezone: string
  draftMode: boolean
  buttonAction: keyof typeof SaveButtonTypes
  onClickOutside: () => void
}> = ({ timezone, draftMode, buttonAction, onClickOutside }) => {
  const scheduleTimeRef = useRef<HTMLDivElement>(null)
  const { uses24hTime, weekStartsMonday } = useContext(UserDataContext)

  // Used to close the date timer when user clicks anywhere else on their screen
  useEffect(() => {
    // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
    const handleClickOutside = (event): void => {
      if (
        scheduleTimeRef.current &&
        !scheduleTimeRef.current?.contains(event.target)
      ) {
        onClickOutside()
      }
    }
    document.addEventListener('mousedown', handleClickOutside, true)

    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside, true)
    }
  }, [])

  // @ts-expect-error TS(7006) FIXME: Parameter 'timestamp' implicitly has an 'any' type... Remove this comment to see the full error message
  const onDateTimeSlotPickerSubmit = (timestamp): void => {
    const queuingType = ButtonsQueuingTypesMap.get(buttonAction)

    AppActionCreators.saveDrafts(queuingType, {
      customScheduleTime: timestamp,
      shouldSkipEmptyTextAlert: false,
      saveDraftsFromQueue: draftMode,
      hasCampaignsToTagsFeature: true,
    })
    onClickOutside()
  }

  return (
    <ScheduleTimeWrapper
      ref={scheduleTimeRef}
      data-testid="schedule-time-container"
    >
      <DateTimeSlotPicker
        onSubmit={onDateTimeSlotPickerSubmit}
        shouldUse24hTime={!!uses24hTime}
        timezone={timezone}
        weekStartsMonday={!!weekStartsMonday}
        submitButtonCopy={draftMode ? 'Add Draft' : 'Schedule'}
        showDraftScheduleNotice={draftMode}
      />
    </ScheduleTimeWrapper>
  )
}

type DraftSaveItem = {
  id: string
  title: string
  icon?: JSX.Element
  iconEnd?: boolean
}

const DraftSaveButtons: React.FC<{
  isDisabled?: boolean
  selectedProfiles: unknown[]
  draftSaveItems: DraftSaveItem[]
  timezone: string
  showApprovalPaywall: boolean
}> = ({
  isDisabled = false,
  timezone,
  draftSaveItems,
  showApprovalPaywall,
}) => {
  const { account } = useAccount()
  const [showScheduleDraftTime, setShowScheduleDraftTime] = useState(false)
  const dispatch = useDispatch()
  const draftButtonRef = useRef<HTMLDivElement>(null)
  const [popoverPosition, setPopoverPosition] = useState<'top' | 'bottom'>(
    'bottom',
  )

  const handleScheduleDraftAction = (): void => {
    setShowScheduleDraftTime(true)
  }

  const commonTrackingProps =
    account?.currentOrganization?.commonTrackingProperties || null

  // @ts-expect-error TS(7006) FIXME: Parameter 'buttonType' implicitly has an 'any' typ... Remove this comment to see the full error message
  const handleDraftAction = (buttonType): void => {
    if (buttonType.id === SaveButtonTypes.SCHEDULE_DRAFT) {
      handleScheduleDraftAction()
    } else if (buttonType.id === PaywallButtonTypes.REQUEST_APPROVAL) {
      BufferTracker.ctaClicked({
        organizationId: account?.currentOrganization?.id || '',
        cta: 'publish-composer-requestApproval-upsell-1',
        upgradePathName: 'approvals-upgrade',
        product: 'publish',
        clientName: 'publishWeb',
        ...commonTrackingProps,
      })
      dispatch(modalActions.showTrialCollaborationModal())
    } else {
      const queuingType = ButtonsQueuingTypesMap.get(buttonType.id)

      AppActionCreators.saveDrafts(queuingType, {
        shouldSkipEmptyTextAlert: false,
        saveDraftsFromQueue: true,
        hasCampaignsToTagsFeature: true,
      })
    }
  }

  const handleOpenDropdown = (): void => {
    if (draftButtonRef.current) {
      const popover =
        draftButtonRef.current.parentElement?.querySelector('ul')?.parentElement // Find the popover element
      if (popover) {
        const buttonRect = draftButtonRef.current.getBoundingClientRect()
        const popoverRect = popover.getBoundingClientRect()

        const isOutsideWindow =
          buttonRect.bottom + popoverRect.height > window.innerHeight
        setPopoverPosition(isOutsideWindow ? 'top' : 'bottom')
      }
    }
    if (showApprovalPaywall) {
      BufferTracker.ctaViewed({
        organizationId: account?.currentOrganization?.id || null,
        cta: 'publish-composer-requestApproval-upsell-1',
        upgradePathName: 'approvals-upgrade',
        product: 'publish',
        ...commonTrackingProps,
      })
    }
  }

  const handleClickOutsideScheduleTime = (): void => {
    setShowScheduleDraftTime(false)
  }

  const [firstItem, ...restOfItems] = draftSaveItems

  return (
    <DraftSaveButtonWrapper>
      {showScheduleDraftTime && (
        <ScheduleTime
          timezone={timezone}
          draftMode
          buttonAction={SaveButtonTypes.SCHEDULE_DRAFT}
          onClickOutside={handleClickOutsideScheduleTime}
        />
      )}
      <ButtonStyled
        data-testid="draft-save-buttons"
        buttonRef={draftButtonRef}
        disabled={isDisabled}
        onSelectClick={handleDraftAction}
        onClick={(): void =>
          handleDraftAction({ id: SaveButtonTypes.ADD_TO_DRAFTS })
        }
        onOpen={handleOpenDropdown}
        type="secondary"
        isSplit={!!restOfItems.length}
        items={restOfItems}
        label={firstItem.title}
        selectPosition={popoverPosition}
      />
    </DraftSaveButtonWrapper>
  )
}

const commonPropTypes = {
  selectedProfiles: profilesPropType.isRequired,
  timezone: PropTypes.string.isRequired,
}

ScheduleTime.propTypes = {
  ...commonPropTypes,
  onClickOutside: PropTypes.func.isRequired,
  draftMode: PropTypes.bool.isRequired,
  buttonAction: PropTypes.oneOf<
    (typeof SaveButtonTypes)[keyof typeof SaveButtonTypes]
  >(Object.values(SaveButtonTypes)).isRequired,
}

DraftSaveButtons.propTypes = {
  ...commonPropTypes,
  isDisabled: PropTypes.bool,
}

DraftSaveButtons.defaultProps = {
  isDisabled: true,
}

export { DraftSaveButtons, ScheduleTime }
