// TODO: It's an established best practice to name the fragment after with an underscore,
// so disabling eslint errors for it for now. We need to figure out a better way to handle these more globally
/* eslint-disable camelcase, @typescript-eslint/naming-convention */

import {
  EyeIcon,
  Flex,
  Lightbox,
  type LightboxProps,
  Text,
  VisuallyHidden,
} from '@buffer-mono/popcorn'
import React from 'react'

import { usePostData } from '../PostCardContext'
import { MediaBadge } from './MediaBadge'
import {
  PostCardMediaAsset,
  PostCardMediaAsset_Asset,
} from './PostCardMediaAsset'

import { getFragmentData } from '~publish/gql'
import type { PostCardMediaAsset_AssetFragment } from '~publish/gql/graphql'
import { isNotNull, isOfType } from '~publish/helpers/typeGuards'
import styles from './PostCardMedia.module.css'
import { getAltText } from './helpers'

const MAX_ASSETS_DISPLAYED = 4

const assetsToLightboxSlide = (
  assets: readonly PostCardMediaAsset_AssetFragment[],
): LightboxProps['slides'] => {
  return assets
    .map((asset) => {
      if (isOfType(asset, 'VideoAsset')) {
        return {
          type: 'video' as const,
          poster: asset.thumbnail,
          width: asset.video.width,
          height: asset.video.height,
          sources: [{ src: asset.source, type: asset.mimeType }],
        }
      }

      if (isOfType(asset, 'ImageAsset')) {
        return {
          type: 'image' as const,
          src: asset.source ?? asset.thumbnail,
          alt: getAltText(asset),
          width: asset.image.width,
          height: asset.image.height,
        }
      }
      return null
    })
    .filter(isNotNull)
}

const PostCardMedia = (): JSX.Element | null => {
  const { assets: passedAssets } = usePostData()
  const assets = getFragmentData(PostCardMediaAsset_Asset, passedAssets)

  if (!assets.length) {
    return null
  }

  const displayedAssets = assets.slice(0, MAX_ASSETS_DISPLAYED)
  const moreCount = assets.length - MAX_ASSETS_DISPLAYED

  const slides = assetsToLightboxSlide(assets)

  return (
    <Lightbox slides={slides}>
      <Lightbox.Trigger asChild>
        <div
          className={styles.container}
          role="group"
          aria-label="Media attachments"
          data-media-count={displayedAssets.length}
        >
          {displayedAssets.map((asset, index) => (
            <PostCardMediaAsset
              key={index}
              asset={asset}
              className={styles.item}
            />
          ))}
          {moreCount > 0 && (
            <MediaBadge className={styles.moreCount}>
              <span aria-hidden>+{moreCount}</span>
              <VisuallyHidden>{moreCount} more assets</VisuallyHidden>
            </MediaBadge>
          )}
          <Flex
            direction="column"
            justify="center"
            align="center"
            className={styles.mediaContainerOverlay}
          >
            <EyeIcon color="inverted" size="medium" />
            <Text weight="medium" color="inverted">
              Show preview
            </Text>
          </Flex>
        </div>
      </Lightbox.Trigger>
    </Lightbox>
  )
}

PostCardMedia.displayName = 'PostCardMedia'

export { PostCardMedia }
