import React from 'react'

import {
  Badge,
  CommentSquare1StIcon,
  Flex,
  MapPinIcon,
  ThreadIcon,
  Tooltip,
  VisuallyHidden,
} from '@buffer-mono/popcorn'

import { isOfType } from '~publish/helpers/typeGuards'

import { usePostData } from './PostCardContext'
import styles from './PostCardAddons.module.css'

export const PostCardAddons = (): JSX.Element | null => {
  const { metadata } = usePostData()

  return (
    <Flex direction="row" gap="space-100">
      {(isOfType(metadata, 'TwitterPostMetadata') ||
        isOfType(metadata, 'ThreadsPostMetadata') ||
        isOfType(metadata, 'BlueskyPostMetadata')) &&
        metadata.threadCount > 1 &&
        metadata?.thread?.[1]?.text && (
          <Tooltip
            content={
              <span
                className={styles.tooltip}
              >{`Thread (${metadata.threadCount} posts) ${metadata.thread[1].text}`}</span>
            }
          >
            <Flex data-testid="addon-icons-x" gap="space-50">
              <ThreadIcon />
              <Badge size="xsmall">
                {metadata.threadCount}
                <VisuallyHidden>thread posts</VisuallyHidden>
              </Badge>
            </Flex>
          </Tooltip>
        )}
      {(isOfType(metadata, 'LinkedInPostMetadata') ||
        isOfType(metadata, 'InstagramPostMetadata')) &&
        metadata.firstComment && (
          <Tooltip content={`First comment: ${metadata.firstComment}`}>
            <CommentSquare1StIcon
              data-testid="first-comment-icon"
              aria-label={`First comment: ${metadata.firstComment}`}
            />
          </Tooltip>
        )}
      {isOfType(metadata, 'InstagramPostMetadata') && metadata.geolocation && (
        <Tooltip content={`Location: ${metadata.geolocation.text}`}>
          <MapPinIcon
            data-testid="map-icon"
            aria-label={`Location: ${metadata.geolocation.text}`}
          />
        </Tooltip>
      )}
    </Flex>
  )
}
