import React, { useEffect, useState } from 'react'
import { BufferTracker, Client } from '@bufferapp/buffer-tracking-browser-ts'
import {
  Button,
  CheckIcon,
  Heading,
  Image,
  UpgradeDialog,
  Paragraph,
  toast,
} from '@buffer-mono/popcorn'

import {
  useCurrentOrganization,
  useOrganizationBilling,
} from '~publish/legacy/accountContext'

import styles from './NotesPaywall.module.css'
import { useStartTrial } from '~publish/hooks/useStartTrial'

type CtaOptions = {
  product: string
  view: string
  location: string
  button: string
}

type NotesPaywallProps = {
  ctaProperties: CtaOptions
  children: React.ReactNode
}

export function NotesPaywall({
  children,
  ctaProperties,
}: NotesPaywallProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { canStartTrial, subscription } = useOrganizationBilling()
  const currentOrganization = useCurrentOrganization()
  const currentOrganizationId = currentOrganization.id
  const planId = subscription?.plan?.id
  const planInterval = subscription?.interval
  const [open, setOpen] = useState(false)
  const { product, view, location, button } = ctaProperties
  const cta = `${product}-${view}-${location}-${button}-1`

  const upgradePathName = canStartTrial ? 'notes-trial' : 'notes-upgrade'
  const ctaText = canStartTrial
    ? 'Start your 14-day Free Trial'
    : 'See Upgrade Options'
  const heading = canStartTrial
    ? 'Try Notes with a 14-day free trial'
    : 'Upgrade to a paid plan to use Notes and other premium features'

  const NOTES_PAYWALL_DATA = {
    heading,
    body: [
      'Schedule unlimited posts & ideas',
      'Organize content with unlimited tags',
      'View detailed post analytics',
      'Engage with comments on Facebook and Instagram ',
      'and more',
    ],
    ctaText,
  }

  const startTrial = useStartTrial()

  const handleStartTrial = async (): Promise<void> => {
    try {
      const dataCheckForTrial = currentOrganizationId && planId && planInterval
      if (dataCheckForTrial) {
        setIsLoading(true)
        await startTrial({
          currentOrganization,
          organizationId: currentOrganizationId,
          attribution: {
            cta,
            upgradePathName,
          },
        }).then(() => {
          setIsLoading(false)
          setOpen(false)
        })
      }
    } catch (error) {
      setOpen(false)
      setIsLoading(false)
      toast.error((error as Error).message)
    }
  }

  const onCTAClick = (): void => {
    if (canStartTrial) {
      handleStartTrial()
    } else {
      window?.appshell.actions.openModal(window?.appshell.MODALS.planSelector, {
        cta,
        upgradePathName,
      })
    }
  }

  useEffect(() => {
    BufferTracker.ctaViewed({
      product: 'publish',
      cta,
      upgradePathName,
      organizationId: currentOrganizationId ?? '',
    })
  }, [cta, currentOrganizationId, upgradePathName])

  const handleOpen = (isOpen: boolean): void => {
    if (isOpen) {
      BufferTracker.ctaClicked({
        clientName: Client.PublishWeb,
        product: 'publish',
        cta,
        upgradePathName,
        organizationId: currentOrganizationId ?? '',
      })
      BufferTracker.upgradePathViewed({
        clientName: Client.PublishWeb,
        product: 'publish',
        organizationId: currentOrganizationId ?? '',
        upgradePathName,
      })
    }
    setOpen(isOpen)
  }

  return (
    <UpgradeDialog open={open} onOpenChange={handleOpen}>
      <UpgradeDialog.Trigger>{children}</UpgradeDialog.Trigger>
      <UpgradeDialog.Content size="xlarge">
        <UpgradeDialog.TextContent>
          <Heading size="medium">{NOTES_PAYWALL_DATA.heading}</Heading>
          <ul className={styles.list}>
            {NOTES_PAYWALL_DATA.body.map((item, index) => {
              return (
                <li key={`notes-paywall-details-${index}`}>
                  <CheckIcon size="small" color="brand" aria-hidden />
                  <Paragraph>{item}</Paragraph>
                </li>
              )
            })}
          </ul>
          <div className={styles.buttonGroup}>
            <Button
              variant="primary"
              size="large"
              onClick={(): void => {
                onCTAClick()
              }}
              loading={isLoading}
            >
              {NOTES_PAYWALL_DATA.ctaText}
            </Button>
            <Button
              variant="tertiary"
              size="large"
              onClick={(): void => {
                setOpen(false)
              }}
            >
              Maybe later
            </Button>
          </div>
        </UpgradeDialog.TextContent>
        <UpgradeDialog.Image>
          <Image
            src="https://buffer-publish.s3.amazonaws.com/images/notes-paywall-x2.png"
            alt="upgrade to use notes illustration"
            placeholder="https://buffer-publish.s3.amazonaws.com/images/notes-paywall-x1.png"
          />
        </UpgradeDialog.Image>
      </UpgradeDialog.Content>
    </UpgradeDialog>
  )
}
