import React from 'react'
import {
  BufferIcon,
  ChannelIcon,
  type ChannelIconProps,
  Text,
  Tooltip,
} from '@buffer-mono/popcorn'

import { usePostData } from '../PostCardContext'
import { TextSeparator } from './TextSeparator'

import styles from './PostCardHeader.module.css'

const capitalize = (s: string): string =>
  s?.charAt(0)?.toUpperCase() + s.slice(1)

const hasIcon = (service: string): service is ChannelIconProps['type'] => {
  const availableIcons = [
    'facebook',
    'googlebusiness',
    'instagram',
    'linkedin',
    'mastodon',
    'pinterest',
    'shopify',
    'startPage',
    'tiktok',
    'twitter',
    'youtube',
    'x',
    'threads',
    'bluesky',
  ]

  return availableIcons.includes(service)
}

export const PostVia = (): JSX.Element | null => {
  const { channel, status, via } = usePostData()

  if (status !== 'sent') {
    return <></>
  }

  if (via === 'buffer' || via === 'api') {
    return (
      <>
        <TextSeparator />
        <Tooltip content="This post was posted on your behalf by Buffer">
          <Text className={styles.addon}>
            <BufferIcon size="xsmall" />
            via Buffer
          </Text>
        </Tooltip>
      </>
    )
  }

  const serviceName =
    channel.service === 'twitter' ? 'Twitter / X' : capitalize(channel.service)

  return (
    <>
      <TextSeparator />
      <Tooltip content="This post was posted natively without using Buffer">
        <Text className={styles.addon}>
          {hasIcon(channel.service) && (
            <ChannelIcon size="xsmall" color="subtle" type={channel.service} />
          )}
          via {serviceName}
        </Text>
      </Tooltip>
    </>
  )
}
