import React from 'react'
import PropTypes from 'prop-types'
import ProfileGroupItem from './ProfileGroupItem'
import A from './shared/A'
import styles from './css/ProfileGroups.module.css'
import { getBaseUrl } from '../utils/StringUtils'

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const ProfileGroups = (props) => {
  const { groups, selectedProfilesIds } = props

  const hasGroups = groups.length > 0

  // @ts-expect-error TS(7006) FIXME: Parameter 'group' implicitly has an 'any' type.
  const profileGroupsWithAllProfilesSelected = groups.filter((group) =>
    // @ts-expect-error TS(7006) FIXME: Parameter 'profileId' implicitly has an 'any' type... Remove this comment to see the full error message
    group.profileIds.every((profileId) =>
      selectedProfilesIds.includes(profileId),
    ),
  )

  const selectedProfileGroupsIds = profileGroupsWithAllProfilesSelected.map(
    // @ts-expect-error TS(7006) FIXME: Parameter 'group' implicitly has an 'any' type.
    (group) => group.id,
  )

  const createEditGroupButtonClassName = [styles.createEditGroupButton].join(
    ' ',
  )

  const getGroupLink = () => {
    const groupPage = hasGroups ? '/app/edit_groups' : '/app/create_group'

    return `${getBaseUrl()}${groupPage}?content_only=true`
  }

  return (
    <div className={styles.profileGroups}>
      {/* @ts-expect-error TS(7006) FIXME: Parameter 'group' implicitly has an 'any' type. */}
      {groups.map((group) => (
        <ProfileGroupItem
          group={group}
          selectedProfileGroupsIds={selectedProfileGroupsIds}
          key={group.id}
        />
      ))}
      <A
        href={getGroupLink()}
        target="_blank"
        rel="noreferrer noopener"
        className={createEditGroupButtonClassName}
      >
        <span className={styles.createEditGroupButtonCopy}>
          {hasGroups ? 'Edit Groups' : 'Create a Group'}
        </span>
      </A>
    </div>
  )
}

ProfileGroups.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      profileIds: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  selectedProfilesIds: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default ProfileGroups
