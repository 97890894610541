/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CloseIcon } from '@buffer-mono/popcorn'
import { grayDark } from '@bufferapp/ui/style/colors'
import { LinkifiedText } from '@bufferapp/components'
import { useIsIntegrationUploading } from '~publish/legacy/integrations-bar/state/useIsIntegrationUploading'
import { draftPropType } from './ComposerPropTypes'
import { EditorWrapper } from './EditorWrapper'
import MediaAttachment from './MediaAttachment'
import { ComposerIntegrationsBar } from '../../../integrations-bar/ComposerIntegrationsBar'
import ComposerStore from '../stores/ComposerStore'
import ComposerActionCreators from '../action-creators/ComposerActionCreators'
import SuggestedMediaBox from './SuggestedMediaBox'
import EditorNotices from './notices/EditorNotices'
import LinkAttachment from './LinkAttachment'
import RetweetAttachment from './RetweetAttachment'
import { AttachmentSwitch } from './attachment-switch/AttachmentSwitch'
import { parseTwitterLinks } from '../../../utils/channels/twitter/helpers'
import { parseThreadsLinks } from '../../../utils/channels/threads/helpers'
import type { EntityWithIndices } from 'twitter-text'

const UnfocusedEditor = styled.div`
  padding: 16px;
  position: relative;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
  }
`

const UnfocusedEditorOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 38%;
`

const UnfocusedEditorText = styled.div<{
  shouldShowMargin: boolean
}>`
  white-space: pre-line;
  line-height: 20px;
  color: ${grayDark};
  margin-bottom: ${(props): string =>
    props.shouldShowMargin ? '24px' : '0px'};
  padding-right: 64px;
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    color: #2c4bff !important;
  }
`

const UnfocusedEditorMediaContainer = styled.div`
  display: flex;
  overflow: hidden;
`

const UnfocusedEditorMedia = styled.img`
  width: 102px;
  height: 102px;
  margin-right: 8px;
  border-radius: 4px;
  object-fit: cover;
`

const RemoveTweet = styled.button`
  color: ${grayDark};
  background: none;
  border: none;
  position: absolute;
  right: 8px;
  top: 12px;
  z-index: 4003;

  &:hover {
    cursor: pointer;
  }
`

const StyledLinkAttachment = styled(LinkAttachment)`
  margin: 0;
`

const StyledRetweetAttachment = styled(RetweetAttachment)`
  margin: 0;
`

const Thread = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'draft' implicitly has an 'any' ty... Remove this comment to see the full error message
  draft,
  // @ts-expect-error TS(7031) FIXME: Binding element 'editorClassName' implicitly has a... Remove this comment to see the full error message
  editorClassName,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onEditorFocus' implicitly has an ... Remove this comment to see the full error message
  onEditorFocus,
  // @ts-expect-error TS(7031) FIXME: Binding element 'editorContainerClassName' implici... Remove this comment to see the full error message
  editorContainerClassName,
  // @ts-expect-error TS(7031) FIXME: Binding element 'suggestedMediaBoxClassName' impli... Remove this comment to see the full error message
  suggestedMediaBoxClassName,
  // @ts-expect-error TS(7031) FIXME: Binding element 'shouldAutoFocus' implicitly has a... Remove this comment to see the full error message
  shouldAutoFocus,
  // @ts-expect-error TS(7031) FIXME: Binding element 'emojiContainer' implicitly has an... Remove this comment to see the full error message
  emojiContainer,
  // @ts-expect-error TS(7031) FIXME: Binding element 'setEmojiContainer' implicitly has... Remove this comment to see the full error message
  setEmojiContainer,
}) => {
  const { isIntegrationUploading } = useIsIntegrationUploading()

  // @ts-expect-error TS(7006) FIXME: Parameter 'draftEntity' implicitly has an 'any' ty... Remove this comment to see the full error message
  const getEditor = (draftEntity, key, hasThread) => (
    <React.Fragment key={key}>
      <EditorNotices draft={draft} />
      <div className={editorContainerClassName}>
        <div className={editorClassName}>
          <EditorWrapper
            draft={draftEntity}
            // @ts-expect-error TS(2322) FIXME: Type '{ draft: any; onFocus: any; shouldAutoFocus:... Remove this comment to see the full error message
            onFocus={onEditorFocus}
            shouldAutoFocus={shouldAutoFocus}
            attachmentGlanceHasNoThumbnail
            setEmojiContainer={setEmojiContainer}
          />
          <MediaAttachment draft={draftEntity} />
        </div>
        <LinkAttachment draft={draft} />
        <RetweetAttachment draft={draft} />
        <AttachmentSwitch draft={draft} />
        <SuggestedMediaBox
          draft={draft}
          className={suggestedMediaBoxClassName}
        />
        <ComposerIntegrationsBar
          draft={draftEntity}
          emojiContainer={emojiContainer}
        />
        {hasThread && (
          <RemoveTweet
            type="button"
            onClick={(): void =>
              ComposerActionCreators.deleteThreadedDraft(draft.id)
            }
            aria-label={`Remove ${draft.service.nameOfPost}`}
          >
            <CloseIcon />
          </RemoveTweet>
        )}
      </div>
    </React.Fragment>
  )

  // @ts-expect-error TS(7006) FIXME: Parameter 'threadId' implicitly has an 'any' type.
  const changeEditor = (threadId) => {
    const areUploadsInProgress =
      draft.filesUploadProgress.size > 0 || isIntegrationUploading
    if (areUploadsInProgress) {
      return
    }
    ComposerActionCreators.switchActiveThreadEditor(draft.id, threadId)
  }

  if (!draft.hasThread()) {
    return getEditor(draft, draft.id, false)
  }

  const activeThreadId = ComposerStore.getActiveThreadId()

  // @ts-expect-error TS(7006) FIXME: Parameter 'threadedDraft' implicitly has an 'any' ... Remove this comment to see the full error message
  return draft.thread.map((threadedDraft, i) => {
    if (i === activeThreadId) {
      return getEditor(draft, activeThreadId, true)
    }

    const hasMedia =
      (Array.isArray(threadedDraft.images) &&
        threadedDraft.images.length > 0) ||
      threadedDraft.video ||
      threadedDraft.gif

    const hasLink = threadedDraft.link
    const hasRetweet = threadedDraft.retweet
    const isEmpty =
      threadedDraft.text.trim() === '' &&
      !hasRetweet &&
      (!threadedDraft.images || threadedDraft.images.length === 0) &&
      !threadedDraft.gif &&
      !threadedDraft.video

    const parseLinks = (text: string): (EntityWithIndices | null)[] => {
      if (draft.service?.isTwitter()) {
        return parseTwitterLinks(text)
      } else if (draft.service?.isThreads()) {
        return parseThreadsLinks(text)
      }
      return []
    }

    return (
      // TODO: generate unique id for Threaded Update (although it's not necessary for Threads v0, there's no usecase for it yet)
      <UnfocusedEditor
        onClick={() => changeEditor(i)}
        key={i}
        style={{
          borderTop: i === 0 ? 'none' : '1px solid #e6ebef',
          borderBottom: i === activeThreadId - 1 ? '1px solid #e6ebef' : 'none',
        }}
        data-testid={`threads-unfocused-editor-${i}`}
      >
        {isEmpty && (
          <UnfocusedEditorText shouldShowMargin={false}>
            Add a {draft.service.nameOfPost?.toLowerCase()}…
          </UnfocusedEditorText>
        )}
        {threadedDraft.text && (
          <UnfocusedEditorText
            shouldShowMargin={hasMedia || hasRetweet || hasLink}
          >
            {threadedDraft.text && (
              <LinkifiedText
                links={parseLinks(threadedDraft.text)}
                size="mini"
                whitespace="pre-wrap"
                newTab
                unstyled
              >
                {threadedDraft.text}
              </LinkifiedText>
            )}
          </UnfocusedEditorText>
        )}
        {hasMedia && (
          <UnfocusedEditorMediaContainer>
            {threadedDraft.images &&
              // @ts-expect-error TS(7006) FIXME: Parameter 'image' implicitly has an 'any' type.
              threadedDraft.images.map((image) => (
                <UnfocusedEditorMedia src={image.url} />
              ))}
            {threadedDraft.gif && (
              <UnfocusedEditorMedia src={threadedDraft.gif.url} />
            )}
            {threadedDraft.video && (
              <UnfocusedEditorMedia src={threadedDraft.video.thumbnail} />
            )}
          </UnfocusedEditorMediaContainer>
        )}

        {hasLink && <StyledLinkAttachment draft={threadedDraft} disabled />}

        {hasRetweet && (
          <StyledRetweetAttachment draft={threadedDraft} disabled />
        )}

        <UnfocusedEditorOverlay />
      </UnfocusedEditor>
    )
  })
}

Thread.propTypes = {
  draft: draftPropType.isRequired,
  editorClassName: PropTypes.string.isRequired,
  onEditorFocus: PropTypes.func.isRequired,
  shouldAutoFocus: PropTypes.bool,
  emojiContainer: PropTypes.elementType,
  setEmojiContainer: PropTypes.func,
}

Thread.defaultProps = {
  shouldAutoFocus: false,
  emojiContainer: null,
  setEmojiContainer: () => {},
}

export default Thread
