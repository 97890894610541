/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { graphql, getFragmentData } from '~publish/gql'
import type { FragmentType } from '~publish/gql'

import { format } from 'date-fns'
import { HoverCard } from '@buffer-mono/popcorn'
import { actions as composerPopoverActions } from '~publish/legacy/composer-popover'
import { ENTER_KEY_CODE } from '~publish/legacy/utils/contants'
import { selectWeekStartsMonday } from '~publish/legacy/user/selectors'
import { selectPostById } from '../../../../../post/slice'
import styles from './PostItem.module.css'
import { CalendarPostHoveredCard } from '~publish/pages/Calendar/CalendarPostHoverPreview/PostCardHoverCard'

import { isLastThirdDayOfWeek, isLastTwoDaysOfWeek } from './utils'

import PostContent from '../PostContent/PostContent'
import DraggablePostItem from '../DraggablePostItem/DraggablePostItem'
import { PostPreviewHoverWrapper, PostWrapper } from './styles'
import { usePostPermissions } from '../../../../../post/hooks'
import { PostEntity } from '../../../../../post/PostEntity'
import { filterPost } from '~publish/pages/Calendar/util/filterPost'
import { useActiveFilter } from '../../../TypeFilter'
import { selectIsManagerInChannel } from '~publish/legacy/profile-sidebar/selectors'
import { useAppSelector } from '~publish/legacy/store'
import { useIsPostHovered } from './useIsPostHovered'

export const CalendarPostCard_Post = graphql(/* GraphQL */ `
  fragment CalendarPostCard_Post on Post {
    id
    status
    tags {
      id
      color
    }
    dueAt
    schedulingType
    text
    channelId
    channelService
  }
`)

export type PostItemProps = {
  post: FragmentType<typeof CalendarPostCard_Post>
  small: boolean
  scheduledDay: string
  inLastTwoRows: boolean
  index: number
  isDraggingOver: boolean
  setOpenModal:
    | null
    | ((openModal: {
        open: boolean
        ctaString: string
        service: string
      }) => void)
}

export const PostItem = ({
  small,
  post: maskedPost,
  scheduledDay,
  inLastTwoRows,
  index,
  isDraggingOver,
  setOpenModal,
}: PostItemProps): JSX.Element => {
  const dispatch = useDispatch()
  const { isHovered, onHoverChange } = useIsPostHovered()
  const post = getFragmentData(CalendarPostCard_Post, maskedPost)
  const postId = post?.id
  const filter = useActiveFilter()
  const weekStartsOn = useSelector(selectWeekStartsMonday) ? 1 : 0
  const legacyPost = filterPost({
    // @ts-expect-error TS(2322) FIXME: Type 'PublishingPost | RpcUpdate | undefined' is n... Remove this comment to see the full error message
    post: useSelector((state) => selectPostById(state, postId)),
    filter,
  })
  const { hasEditPermission } = usePostPermissions(post)
  const isSent = PostEntity.isSent(post)
  const isManager = useAppSelector(selectIsManagerInChannel(post?.channelId))
  const canEdit = hasEditPermission && !isSent

  const hasError = post.status === 'error'
  const ariaLabel = useMemo(() => {
    if (!post || !post.dueAt) return ''
    const channelService = post.channelService
    const formattedTime = format(new Date(post.dueAt), 'HH:mm')
    const error = hasError ? ' with an error' : ''
    return `${channelService} post scheduled for ${scheduledDay}, ${formattedTime}${error}`
  }, [post, hasError, scheduledDay])

  // TODO: consider a post placeholder while loading
  if (!PostEntity.isPublishingPost(legacyPost)) return <></>

  const dueAt = post?.dueAt ? new Date(post?.dueAt) : null
  const shouldDisableDrag = hasError || !canEdit || !isManager

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.keyCode === ENTER_KEY_CODE && canEdit) {
      event.preventDefault()
      dispatch(composerPopoverActions.handleEditClick(post.id))
    }
  }

  const handleOnClick = (): void => {
    if (canEdit) dispatch(composerPopoverActions.handleEditClick(post.id))
  }

  return (
    <DraggablePostItem
      postId={post?.id}
      index={index}
      shouldDisableDrag={shouldDisableDrag}
      isDraggingOver={isDraggingOver}
    >
      <HoverCard open={isHovered} onOpenChange={onHoverChange}>
        <HoverCard.Trigger>
          <PostPreviewHoverWrapper canEdit={canEdit}>
            <PostWrapper
              small={small}
              campaignColor={legacyPost.campaign?.color?.value}
              tags={post?.tags}
              aria-label={ariaLabel}
              onKeyDown={handleKeyDown}
              onClick={handleOnClick}
              tabIndex={0}
              role={canEdit && 'button' ? 'button' : undefined}
              status={post?.status}
            >
              <PostContent post={post} small={small} legacyPost={legacyPost} />
            </PostWrapper>
          </PostPreviewHoverWrapper>
        </HoverCard.Trigger>
        <HoverCard.Content className={styles.hoverWrapper} side="left">
          {isHovered && (
            <CalendarPostHoveredCard
              postId={post?.id}
              post={legacyPost}
              inLastTwoRows={inLastTwoRows}
              isEndOfWeek={isLastTwoDaysOfWeek(dueAt, { weekStartsOn })}
              isLastThirdDayOfWeek={isLastThirdDayOfWeek(dueAt, {
                weekStartsOn,
              })}
              setOpenModal={setOpenModal}
            />
          )}
        </HoverCard.Content>
      </HoverCard>
    </DraggablePostItem>
  )
}
