import React, { useEffect, useState } from 'react'
import type { Draft } from '~publish/legacy/composer/composer/entities/Draft'
import AsyncSelect from 'react-select/async'
import type { SingleValue } from 'react-select'
import LocationFinder from './utils/LocationFinder'
import ComposerActionCreators from '../../action-creators/ComposerActionCreators'
import AppStore from '../../stores/AppStore'
import * as ReminderFieldsStyled from '~publish/legacy/reminders/components/new-reminders/components/forms/field/styles'
import type { ServiceLocation } from '~publish/legacy/composer/composer/components/location-bar/entities/ServiceLocation'
import { gray, grayLight } from '@bufferapp/ui/style/colors'
import { ChevronDownIcon } from '@buffer-mono/popcorn'

interface LocationOption {
  value: string
  label: string
  formattedAddress?: string
}

interface LocationComposerBarProps {
  draft: Draft
}

const LocationComposerBar: React.FC<LocationComposerBarProps> = ({ draft }) => {
  const locationId =
    draft?.channelData?.instagram?.service_geolocation_id ?? null
  const locationName =
    draft?.channelData?.instagram?.service_geolocation_name ?? ''
  const [selectedLocation, setSelectedLocation] =
    useState<LocationOption | null>(null)

  useEffect(() => {
    if (locationId && locationName) {
      setSelectedLocation({ value: locationId, label: locationName })
    }
  }, [locationId, locationName])

  const loadOptions = async (inputValue: string): Promise<LocationOption[]> => {
    if (!inputValue) return []
    const profileId = getSelectedInstagramProfileId()
    if (!profileId) return []
    try {
      const locations = await LocationFinder.findLocations(
        profileId,
        inputValue,
      )
      return locations.map((loc: ServiceLocation) => ({
        value: loc.id,
        label: `${loc.name} - ${loc.formattedAddress}`,
        formattedAddress: loc.formattedAddress,
      }))
    } catch (error) {
      console.error('Failed to load locations:', error)
      return []
    }
  }

  const handleChange = (newValue: SingleValue<LocationOption>): void => {
    setSelectedLocation(newValue)
    ComposerActionCreators.updateDraftInstagramData({
      ...draft.channelData?.instagram,
      service_geolocation_id: newValue?.value || null,
      service_geolocation_name: newValue?.label || '',
    })
  }

  const getSelectedInstagramProfileId = (): string | null => {
    const selectedProfiles = AppStore.getSelectedProfiles().filter(
      (profile) => profile.service.name === 'instagram',
    )
    return selectedProfiles.length > 0 ? selectedProfiles[0].id : null
  }

  if (!draft.service?.isInstagram() || draft.isStoryPost()) {
    return null
  }

  return (
    <ReminderFieldsStyled.Wrapper>
      <ReminderFieldsStyled.LabelWrapper>
        <ReminderFieldsStyled.Label>Location</ReminderFieldsStyled.Label>
      </ReminderFieldsStyled.LabelWrapper>
      <ReminderFieldsStyled.InputWrapper>
        <AsyncSelect
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              border: `1px solid ${grayLight}`,
              fontSize: '12px',
              minHeight: '32px',
              maxHeight: '32px',
              outline: 'none',
              borderColor: gray,
              boxShadow: 'none',
              transition: 'none',
              '&:hover': {},
              '&:focus-within': {
                outline: `2px solid var(--focus-color)`,
                outlineOffset: '1px',
              },
            }),
            option: (baseStyles) => ({
              ...baseStyles,
              fontSize: '12px',
            }),
            indicatorsContainer: (baseStyles) => ({
              ...baseStyles,
              height: '32px',
            }),
            dropdownIndicator: (baseStyles) => ({
              ...baseStyles,
              padding: '4px',
              marginTop: '-2px',
            }),
            placeholder: (baseStyles) => ({
              ...baseStyles,
              marginTop: '-2px',
              color: gray,
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              fontSize: '12px',
            }),
          }}
          components={{
            DownChevron: () => <ChevronDownIcon />,
          }}
          className="react-select-container"
          classNamePrefix="react-select"
          isClearable={true}
          isSearchable={true}
          name="location"
          value={selectedLocation}
          onChange={handleChange}
          loadOptions={loadOptions}
          defaultOptions
          placeholder="Type the location"
          noOptionsMessage={(): string =>
            'No locations found, try something different.'
          }
        />
      </ReminderFieldsStyled.InputWrapper>
    </ReminderFieldsStyled.Wrapper>
  )
}

export default LocationComposerBar
