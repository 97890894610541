import React, { useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'

import { useSplitEnabled } from '@buffer-mono/features'

import { Phone, Warning } from '@bufferapp/ui/Icon'
import { redDark } from '@bufferapp/ui/style/colors'

import { usePostPermissions } from '~publish/legacy/post/hooks'
import { ChannelIcon } from '~publish/legacy/shared-components'
import { isDraft as checkIsDraft } from '~publish/pages/Calendar/util/isDraft'
import MediaThumbnail from '../../../MediaThumbnail'

import { Tooltip } from '@bufferapp/ui'
import type {
  PublishingPost,
  CalendarPostCard_PostFragment,
} from '~publish/gql/graphql'
import { RemindersIcon } from '~publish/legacy/reminders/components/RemindersIcon'
import {
  ApprovalTag,
  DraftTag,
  EndContent,
  Error,
  ErrorCard,
  Icon,
  MiddleContent,
  PostContentWrapper,
  PostText,
  StartContent,
  TagContainer,
  Time,
  TooltipWrapper,
} from './styles'

const Reminder = ({
  small,
  text,
}: {
  small: boolean
  text: string
}): JSX.Element => {
  return (
    <Icon small={small}>
      <Phone color="white" role="img" title={text} />
    </Icon>
  )
}

const PostContent = ({
  small,
  legacyPost,
  post,
}: {
  small: boolean
  legacyPost: PublishingPost
  post: CalendarPostCard_PostFragment
}): JSX.Element => {
  const { t } = useTranslation()
  // TODO - delete all of these fields
  const { media, channelData } = legacyPost
  const { status, text, schedulingType, channelId, channelService, dueAt } =
    post
  const needsApproval = post.status === 'needs_approval'
  const isReminder = schedulingType === 'notification'
  const googleBusinessTitle =
    channelService === 'googlebusiness'
      ? channelData?.googlebusiness?.title
      : null
  const formattedTime = useMemo(() => {
    if (!dueAt) return ''
    return format(new Date(dueAt), 'HH:mm')
  }, [dueAt])
  const { isManager } = usePostPermissions(legacyPost)
  const hasMedia = (legacyPost?.media?.length ?? 0) > 0
  const hasError = status === 'error'
  const isDraft = checkIsDraft(status)
  const showMediaThumbnail = hasMedia && !(small && hasError)

  const renderDraftTag = isDraft && !needsApproval
  const renderApprovalTag = isDraft && needsApproval
  const renderTime = small && !isDraft
  const renderSmallError = hasError && small
  const renderError = hasError && !small

  const { isEnabled: isRemindersEnabled } = useSplitEnabled('CORE-reminders')

  return (
    <PostContentWrapper>
      {/* Social Channel and Reminder Icon */}
      <StartContent small={small}>
        <ChannelIcon
          network={channelService}
          circular
          aria-label={`${channelService} post`}
        />
        {/* Show reminder icon here if large item */}
        {!small &&
          isReminder &&
          (isRemindersEnabled ? (
            <TooltipWrapper>
              {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; label: string; }' is no... Remove this comment to see the full error message */}
              <Tooltip label={t('remindersGeneral.tooltip')}>
                <RemindersIcon />
              </Tooltip>
            </TooltipWrapper>
          ) : (
            <Reminder
              small={small}
              text="You'll receive a reminder in your Buffer app"
            />
          ))}
      </StartContent>
      {/* Tags, Time, Post Text */}
      <MiddleContent small={small}>
        {renderDraftTag && (
          <TagContainer>
            <DraftTag>Draft</DraftTag>
          </TagContainer>
        )}
        {renderApprovalTag && (
          <TagContainer>
            <ApprovalTag>
              {isManager ? 'Awaiting Approval' : 'Pending Approval'}
            </ApprovalTag>
          </TagContainer>
        )}
        {renderTime && <Time type="p">{formattedTime}</Time>}

        {/* GBP Post Title */}
        {!small && googleBusinessTitle && (
          <PostText type="p" isDraft={isDraft}>
            {googleBusinessTitle}
          </PostText>
        )}

        {/* Post Text */}
        {/* Do not show text when there is a GBP Post Title */}
        {!small && !googleBusinessTitle && (
          <PostText type="p" isDraft={isDraft}>
            {text}
          </PostText>
        )}
        {/* Show reminder icon here if small item */}
        {small &&
          isReminder &&
          (isRemindersEnabled ? (
            <TooltipWrapper>
              {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; label: string; }' is no... Remove this comment to see the full error message */}
              <Tooltip label={t('remindersGeneral.tooltip')}>
                <RemindersIcon />
              </Tooltip>
            </TooltipWrapper>
          ) : (
            <Reminder
              small={small}
              text="You'll receive a reminder in your Buffer app"
            />
          ))}
      </MiddleContent>

      {/* Media Thumbnail */}
      {showMediaThumbnail && (
        <EndContent>
          <MediaThumbnail
            media={media!}
            size={small ? 'extraSmall' : 'small'}
          />
        </EndContent>
      )}
      {/* Error flags */}
      {renderError && (
        <Error>
          <Warning color={redDark} />
        </Error>
      )}
      {renderSmallError && (
        <EndContent>
          <ErrorCard>
            <Warning color="white" />
          </ErrorCard>
        </EndContent>
      )}
    </PostContentWrapper>
  )
}

export default PostContent
