// TODO: It's an established best practice to name the fragment after with an underscore,
// so disabling eslint errors for it for now. We need to figure out a better way to handle these more globally
/* eslint-disable camelcase, @typescript-eslint/naming-convention */
import type { PostCardMediaAsset_AssetFragment } from '~publish/gql/graphql'
import { isOfType } from '~publish/helpers/typeGuards'

export const getAltText = (asset: PostCardMediaAsset_AssetFragment): string => {
  if (isOfType(asset, 'ImageAsset')) {
    return asset.image.altText ?? 'Image preview'
  }

  if (isOfType(asset, 'VideoAsset')) {
    return 'Video preview'
  }

  if (isOfType(asset, 'DocumentAsset')) {
    return 'Document preview'
  }

  return 'Media preview'
}
