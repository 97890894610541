import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
`

export const RemindersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`

export const WrapperOnlyReminders = styled.div`
  display: flex;
  padding: 12px 16px;
  float: right;
  align-items: right;
`

export const RemindersComposerBox = styled.div`
  border-top: 1px solid rgb(224, 224, 224);
`

export const StickersWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
