import {
  type AutoformatRule,
  createPlateEditor,
  type CreatePlateEditorOptions,
  createPluginFactory,
  createPlugins,
  createTEditor,
  type Decorate,
  type DecorateEntry,
  type DOMHandler,
  type EDescendant,
  type EElement,
  type EElementEntry,
  type EElementOrText,
  type ELEMENT_PARAGRAPH,
  type EMarks,
  type ENode,
  type ENodeEntry,
  type EText,
  type ETextEntry,
  getTEditor,
  type InjectComponent,
  type InjectProps,
  type KeyboardHandler,
  type NoInfer,
  type OnChange,
  type OverrideByKey,
  type PlateEditor,
  type PlateId,
  type PlatePlugin,
  type PlatePluginComponent,
  type PlatePluginInsertData,
  type PlatePluginProps,
  type PlateProps,
  type PluginOptions,
  type SerializeHtml,
  type TElement,
  type TNodeEntry,
  type TReactEditor,
  useEditorRef,
  useEditorState,
  usePlateActions,
  usePlateEditorRef,
  usePlateEditorState,
  usePlateSelectors,
  usePlateStates,
  type WithOverride,
} from '@udecode/plate'
import type { PlainText } from '../BufferEditor/types.plate'
import type { LinkElement } from '../plugins/link/nodes/LinkElement'

/**
 * Inline Elements
 */

export type IdeasInlineElement = LinkElement
export type IdeasInlineDescendant = IdeasInlineElement
export type IdeasInlineChildren = IdeasInlineDescendant[]

/**
 * Block props
 */

export interface IdeasBlockElement extends TElement {
  id?: PlateId
}

export interface IdeasParagraphElement extends IdeasBlockElement {
  type: typeof ELEMENT_PARAGRAPH
  children: IdeasInlineChildren | PlainText[]
}

export type IdeasNestableBlock = IdeasParagraphElement

export type IdeasBlock = Exclude<IdeasElement, IdeasInlineElement>
export type IdeasBlockEntry = TNodeEntry<IdeasBlock>

export type IdeasRootBlock = IdeasParagraphElement

export type IdeasValue = IdeasRootBlock[]

/**
 * Editor types
 */

export type IdeasEditor = PlateEditor<IdeasValue> & { isDragging?: boolean }
export type IdeasReactEditor = TReactEditor<IdeasValue>
export type IdeasNode = ENode<IdeasValue>
export type IdeasNodeEntry = ENodeEntry<IdeasValue>
export type IdeasElement = EElement<IdeasValue>
export type IdeasElementEntry = EElementEntry<IdeasValue>
export type IdeasText = EText<IdeasValue>
export type IdeasTextEntry = ETextEntry<IdeasValue>
export type IdeasElementOrText = EElementOrText<IdeasValue>
export type IdeasDescendant = EDescendant<IdeasValue>
export type IdeasMarks = EMarks<IdeasValue>
export type IdeasMark = keyof IdeasMarks

/**
 * Plate types
 */

export type IdeasDecorate<P = PluginOptions> = Decorate<
  P,
  IdeasValue,
  IdeasEditor
>
export type IdeasDecorateEntry = DecorateEntry<IdeasValue>
export type IdeasDOMHandler<P = PluginOptions> = DOMHandler<
  P,
  IdeasValue,
  IdeasEditor
>
export type IdeasInjectComponent = InjectComponent<IdeasValue>
export type IdeasInjectProps = InjectProps<IdeasValue>
export type IdeasKeyboardHandler<P = PluginOptions> = KeyboardHandler<
  P,
  IdeasValue,
  IdeasEditor
>
export type IdeasOnChange<P = PluginOptions> = OnChange<
  P,
  IdeasValue,
  IdeasEditor
>
export type IdeasOverrideByKey = OverrideByKey<IdeasValue, IdeasEditor>
export type IdeasPlatePlugin<P = PluginOptions> = PlatePlugin<
  P,
  IdeasValue,
  IdeasEditor
>
export type IdeasPlatePluginInsertData = PlatePluginInsertData<IdeasValue>
export type IdeasPlatePluginProps = PlatePluginProps<IdeasValue>
export type IdeasPlateProps = PlateProps<IdeasValue, IdeasEditor>
export type IdeasSerializeHtml = SerializeHtml<IdeasValue>
export type IdeasWithOverride<P = PluginOptions> = WithOverride<
  P,
  IdeasValue,
  IdeasEditor
>

/**
 * Plate store, Slate context
 */
export const IDEAS_EDITOR_ID = 'ideas'

export const getIdeasEditor = (editor: IdeasEditor) =>
  getTEditor<IdeasValue, IdeasEditor>(editor)
export const useIdeasEditorRef = () => useEditorRef<IdeasValue, IdeasEditor>()
export const useIdeasEditorState = () =>
  useEditorState<IdeasValue, IdeasEditor>()
export const useIdeasPlateEditorRef = () =>
  usePlateEditorRef<IdeasValue, IdeasEditor>(IDEAS_EDITOR_ID)
export const useIdeasPlateEditorState = () =>
  usePlateEditorState<IdeasValue, IdeasEditor>(IDEAS_EDITOR_ID)
export const useIdeasPlateSelectors = () =>
  usePlateSelectors<IdeasValue, IdeasEditor>(IDEAS_EDITOR_ID)
export const useIdeasPlateActions = () =>
  usePlateActions<IdeasValue, IdeasEditor>(IDEAS_EDITOR_ID)
export const useIdeasPlateStates = () =>
  usePlateStates<IdeasValue, IdeasEditor>(IDEAS_EDITOR_ID)

/**
 * Utils
 */
export const createIdeasEditor = () => createTEditor() as IdeasEditor
export const createIdeasPlateEditor = (
  options: CreatePlateEditorOptions<IdeasValue, IdeasEditor> = {},
) => createPlateEditor<IdeasValue, IdeasEditor>(options)
export const createIdeasPluginFactory = <P = PluginOptions>(
  defaultPlugin: PlatePlugin<NoInfer<P>, IdeasValue, IdeasEditor>,
) => createPluginFactory(defaultPlugin)
export const createIdeasPlugins = (
  plugins: IdeasPlatePlugin[],
  options?: {
    components?: Record<string, PlatePluginComponent>
    overrideByKey?: IdeasOverrideByKey
  },
) => createPlugins<IdeasValue, IdeasEditor>(plugins, options)

export type IdeasAutoformatRule = AutoformatRule<IdeasValue, IdeasEditor>
