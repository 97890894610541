import {
  type DateTimeFormatterOptions,
  formatDateTime,
  formatTime,
  formatDate,
} from '~publish/helpers/dateFormatters'
import { useTimeFormatPreference } from './useTimeFormatPreference'
import { useTimezone } from './useTimezone'

/**
 * Custom hook that returns a function to format date and time.
 * @returns {Function} A function that takes a date string and options, and returns a formatted date-time string.
 */
export const useDateTimeFormatter = (): ((
  date: string,
  options?: DateTimeFormatterOptions,
) => string) => {
  const timeZone = useTimezone()
  const timeFormat = useTimeFormatPreference()

  /**
   * Formats a date and time string according to the user's timezone and time format preference.
   * @param {string} date - The date string to format.
   * @param {DateTimeFormatterOptions} options - Additional formatting options.
   * @returns {string} The formatted date and time string.
   */
  return (date: string, options: DateTimeFormatterOptions = {}): string =>
    formatDateTime(date, {
      timeZone,
      timeFormat,
      ...options,
    })
}

/**
 * Custom hook that returns a function to format time.
 * @returns {Function} A function that takes a date string and options, and returns a formatted time string.
 */
export const useTimeFormatter = (): ((
  date: string,
  options?: Omit<DateTimeFormatterOptions, 'dateFormat'>,
) => string) => {
  const timeZone = useTimezone()
  const timeFormat = useTimeFormatPreference()

  /**
   * Formats a time string according to the user's timezone and time format preference.
   * @param {string} date - The date string to format.
   * @param {Omit<DateTimeFormatterOptions, 'dateFormat'>} options - Additional formatting options.
   * @returns {string} The formatted time string.
   */
  return (
    date: string,
    options: Omit<DateTimeFormatterOptions, 'dateFormat'> = {},
  ): string =>
    formatTime(date, {
      timeZone: options.timeZone ?? timeZone,
      timeFormat,
      ...options,
    })
}

/**
 * Custom hook that returns a function to format date.
 * @returns {Function} A function that takes a date string and options, and returns a formatted date string.
 */
export const useDateFormatter = (): ((
  date: string,
  options?: Omit<DateTimeFormatterOptions, 'timeFormat'>,
) => string) => {
  const timeZone = useTimezone()

  /**
   * Formats a date string according to the user's timezone.
   * @param {string} date - The date string to format.
   * @param {Omit<DateTimeFormatterOptions, 'timeFormat'>} options - Additional formatting options.
   * @returns {string} The formatted date string.
   */
  return (
    date: string,
    options: Omit<DateTimeFormatterOptions, 'timeFormat'> = {},
  ): string =>
    formatDate(date, {
      timeZone,
      ...options,
    })
}
