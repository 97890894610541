import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { actions as modalActions } from '~publish/legacy/modals/reducer'
import { Text, Button } from '@bufferapp/ui'
import {
  ButtonsArea,
  List as ListHowRemindersWork,
  ListItem,
  MainArea,
  Title,
} from '../styles'
import { useAccount } from '~publish/legacy/accountContext'
import { useAppSelector } from '~publish/legacy/store'
import { trackButtonClick, trackStepViewed } from '../utils'
import { selectModalPlacement } from '~publish/legacy/modals/selectors'

import { selectedProfileServiceName } from '~publish/legacy/profile-sidebar/selectors'

const StepThree = (): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const account = useAccount()
  const currentOrganization = account?.account?.currentOrganization

  useEffect(() => {
    if (currentOrganization) {
      trackStepViewed(3, currentOrganization?.id)
    }
  }, [currentOrganization])

  const remindersSetUpWizardPlacement: string = useAppSelector((state) =>
    selectModalPlacement(state),
  )

  const onCloseButtonClick = (): void => {
    trackButtonClick(
      'finish',
      currentOrganization?.id,
      remindersSetUpWizardPlacement,
    )
    dispatch(modalActions.hideRemindersWizardModal())
  }

  const serviceName = useAppSelector(selectedProfileServiceName)

  return (
    <>
      <MainArea>
        {/* TILE AND DESCRIPTION */}
        <Title type="h2">{t('remindersSetupWizard.stepThree.headline')}</Title>
        <Text type="p">{t('remindersSetupWizard.stepThree.description')}</Text>
        {/* HOW REMINDERS WORK */}
        <ListHowRemindersWork>
          <ListItem>{t('remindersSetupWizard.stepThree.itemListOne')}</ListItem>
          <ListItem>{t('remindersSetupWizard.stepThree.itemListTwo')}</ListItem>
          <ListItem>
            {t('remindersSetupWizard.stepThree.itemListThree', {
              serviceName,
            })}
          </ListItem>
        </ListHowRemindersWork>
      </MainArea>
      <ButtonsArea>
        {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: () => { type: any; ... Remove this comment to see the full error message */}
        <Button
          type="primary"
          onClick={onCloseButtonClick}
          label={t('remindersSetupWizard.stepThree.buttonLabel')}
          aria-label="Close Wizard"
        />
      </ButtonsArea>
    </>
  )
}

export default StepThree
