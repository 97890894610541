import {
  AlertDialog,
  Button,
  Flex,
  Label,
  Paragraph,
  toast,
} from '@buffer-mono/popcorn'
import React, { useState } from 'react'
import { cleanQueue } from '~publish/legacy/general-settings/thunks/cleanQueue'
import { selectCurrentProfile } from '~publish/legacy/profile-sidebar/selectors'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import commonStyles from '../../../GeneralSettings.module.css'

export const CleanQueueAction = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const profile = useAppSelector(selectCurrentProfile)
  const [isModalShowing, setModalShowing] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const hideModal = (): void => setModalShowing(false)
  const showModal = (): void => setModalShowing(true)

  // Do not show Clean Queue action if there is no profile loaded
  if (!profile) return <></>

  const onConfirmCleanQueue = async (): Promise<void> => {
    const profileId = profile.id
    setLoading(true)

    const result = await dispatch(cleanQueue({ profileId }))

    if (cleanQueue.fulfilled.match(result)) {
      toast.success(result.payload.message)
    } else {
      toast.error(
        `Sorry! Something went wrong while cleaning your queue: ${result.error.message}. Would you be up for trying again?`,
      )
    }

    setLoading(false)
    hideModal()
  }

  return (
    <>
      <Flex
        justify="between"
        className={commonStyles.fullWidth}
        gap="space-400"
      >
        <Flex direction="column">
          <Label>Clean Queue</Label>
          <Paragraph className={commonStyles.settingsParagraph}>
            Cleaning your queue will remove all failed updates within your{' '}
            {profile.serviceUsername} queue.
          </Paragraph>
        </Flex>
        <Flex className={commonStyles.actionsWrapper}>
          <Button
            data-testid="clean-queue-trigger"
            className={commonStyles.actionButton}
            size="large"
            variant="secondary"
            onClick={showModal}
          >
            Clean Queue
          </Button>
        </Flex>
      </Flex>
      <AlertDialog open={isModalShowing}>
        <AlertDialog.Content>
          <AlertDialog.Title>Clean Queue?</AlertDialog.Title>
          <AlertDialog.Description>
            All failed updates in your{' '}
            <strong>{profile.serviceUsername}</strong> queue will be removed
          </AlertDialog.Description>
          <AlertDialog.Separator />
          <AlertDialog.Actions>
            <AlertDialog.Cancel>
              <Button onClick={hideModal} variant="tertiary">
                Cancel
              </Button>
            </AlertDialog.Cancel>
            <AlertDialog.Action>
              <Button
                data-testid="clean-queue-confirm"
                onClick={onConfirmCleanQueue}
                loading={isLoading}
                variant="critical"
              >
                Clean Queue
              </Button>
            </AlertDialog.Action>
          </AlertDialog.Actions>
        </AlertDialog.Content>
      </AlertDialog>
    </>
  )
}
