import React from 'react'
import { Link } from '@bufferapp/components'
import { Text, Button } from '@bufferapp/ui'
import { HC_UTM_PARAMS } from '~publish/legacy/utils/contants'
import QrCode from './_qr-code'
import SetupAppDetails from './_setup-app-details'

type SetupAppParams = {
  transition: (arg0: string) => void
  qrCode: string
  initKey: string
  email: string
}

const SetupApp = ({
  transition,
  qrCode,
  initKey,
  email,
}: SetupAppParams): JSX.Element => (
  <>
    <Text type="h3">Set up your authenticator app</Text>
    <div>
      <Text type="p">
        Scan the QR code below in your authenticator app and you&apos;re all
        set!{' '}
      </Text>
      <Text type="p">
        (If you have not installed an authenticator app yet,{' '}
        <Link
          newTab
          href={`https://support.buffer.com/article/503-enabling-two-factor-authentication?${HC_UTM_PARAMS}`}
        >
          read here for more information.
        </Link>
        )
      </Text>
    </div>
    <div style={{ textAlign: 'center' }}>
      <QrCode data={qrCode} />
      <SetupAppDetails initKey={initKey} email={email} />
    </div>

    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
      <Button
        type="text"
        label="Back"
        onClick={(): void => transition('BACK')}
      />
      {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
      <Button
        type="primary"
        label="Next"
        onClick={(): void => transition('NEXT')}
      />
    </div>
  </>
)

export default SetupApp
