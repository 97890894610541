import { useCallbackRef } from '@buffer-mono/popcorn'
import { useEffect } from 'react'
import {
  useIntersectionObserver,
  type UseIntersectionObserverReturn,
} from './useIntersectionObserver'

type PaginationOptions = {
  loading: boolean
  hasNextPage?: boolean
  delayMs?: number
  fetchMore: () => void
  rootMargin?: string
}
const DELAY_IN_MS = 10

type Return = [UseIntersectionObserverReturn[0]]

/**
 * Hook to handle infinite scroll pagination, it will call fetchMore when
 * the element with ref becomes visible
 *
 * @example
 * ```tsx
 * const { data, loading, error, fetchMore, networkStatus } = useQuery(Query)
 * const [lastElementRef] = useInfiniteScrollPagination({
 *  loading: loading || networkStatus === NetworkStatus.fetchMore,
 *  hasNextPage: data?.items.pageInfo.hasNextPage,
 *  fetchMore: () => {
 *    fetchMore({ variables: { after: data?.items.pageInfo.endCursor }})
 *  }
 * })
 *
 * ...
 *
 * <Item ref={lastElementRef} />
 * ```
 */
export function useInfiniteScrollPagination({
  loading,
  hasNextPage,
  fetchMore,
  delayMs = DELAY_IN_MS,
  rootMargin = '100px',
}: PaginationOptions): Return {
  const fetchMoreRef = useCallbackRef(fetchMore)
  const [sentryRef, isSentryVisible] = useIntersectionObserver({
    rootMargin,
  })
  const shouldLoadMore = !loading && isSentryVisible && hasNextPage

  useEffect(() => {
    if (!shouldLoadMore) {
      return
    }

    const timer = setTimeout(() => {
      fetchMoreRef()
    }, delayMs)

    return () => clearTimeout(timer)
  }, [fetchMoreRef, shouldLoadMore, delayMs])

  return [sentryRef]
}
