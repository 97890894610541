import { actionTypes as dataFetchActionTypes } from '@bufferapp/async-data-fetch'
import keyWrapper from '~publish/helpers/keywrapper'

export const actionTypes = keyWrapper('CAMPAIGN_FORM', {
  CREATE_CAMPAIGN: 0,
  EDIT_CAMPAIGN: 0,
})

export const initialState = {
  campaign: {},
  isLoading: false,
  campaignId: null,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case `createCampaign_${dataFetchActionTypes.FETCH_START}`: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case `createCampaign_${dataFetchActionTypes.FETCH_SUCCESS}`:
    case `getCampaign_${dataFetchActionTypes.FETCH_SUCCESS}`: {
      return {
        ...state,
        campaign: action.result,
        campaignId: action.result?.id,
        isLoading: false,
      }
    }

    case `createCampaign_${dataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...state,
        isLoading: false,
      }
    case actionTypes.CREATE_CAMPAIGN:
    case actionTypes.EDIT_CAMPAIGN:
      return {
        ...state,
      }
    default:
      return state
  }
}

export const actions = {
  // @ts-expect-error TS(7031) FIXME: Binding element 'name' implicitly has an 'any' typ... Remove this comment to see the full error message
  handleCreateCampaignClick: ({ name, color }) => ({
    type: actionTypes.CREATE_CAMPAIGN,
    name,
    color,
  }),
  // @ts-expect-error TS(7031) FIXME: Binding element 'id' implicitly has an 'any' type.
  handleEditCampaignClick: ({ id, name, color, orgId }) => ({
    type: actionTypes.EDIT_CAMPAIGN,
    id,
    name,
    color,
    orgId,
  }),
}
