import type { UploadSource } from '../../legacy/uploads/values/UploadSource'

export enum MediaType {
  video = 'video',
  image = 'image',
  link = 'link',
  gif = 'gif',
}

export interface Media {
  id: string
  url: string
  thumbnailUrl?: string
  alt?: string
  type: MediaType
  size?: number
  source?: UploadSource
  location?: string
  transcodedLocation?: string
}

export interface ExtendedMedia extends Media {
  width: number
  height: number
  duration: number
  durationMs: number
  size: number
}

export type Tag = {
  __typename?: string
  id: string
  name: string
  color: string
}

// TODO: this types should be transitioned to GraphQL codegen
export type IdeaContent = {
  title?: string
  text?: string
  media?: Media[]
  tags?: Tag[]
  aiAssisted?: boolean
}

export interface Idea {
  id: string
  content: IdeaContent
  groupId?: string
  position?: number
  createdAt?: number
}

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>
export type NewIdea = Optional<Idea, 'id'>

export interface ExtendedIdea extends Idea {
  media?: ExtendedMedia[]
}

export type PostMediaValidity = {
  valid: boolean
  message: string | null
}

export enum IdeaEvents {
  IDEAS_DELETED = 'ideas_deleted',
  IDEA_UPDATED = 'idea_updated',
  IDEA_CREATED = 'idea_created',
}

export type IdeasDataEdges = {
  node: Idea
}

export type IdeasData = {
  ideas: {
    edges: IdeasDataEdges[]
    pageInfo: {
      hasNextPage: boolean
      endCursor: string
    }
  }
}
