import React, { type KeyboardEventHandler } from 'react'
import clsx from 'clsx'
import { useSortable } from '@dnd-kit/sortable'
import type { UniqueIdentifier } from '@dnd-kit/core'

import {
  UnstyledButton,
  VisuallyHidden,
  GripVerticalIcon,
} from '@buffer-mono/popcorn'

import useDraggingCursor from '~publish/hooks/useDraggingCursor'

import styles from './SortableItem.module.css'

interface ItemProps {
  id: UniqueIdentifier
  children: React.ReactNode
  style?: React.CSSProperties
  className?: string
  dragOverlay?: boolean
  disabled?: boolean
}

const SortableItem = ({
  children,
  id,
  style,
  disabled,
  className,
  dragOverlay = false,
}: ItemProps): JSX.Element => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
    disabled,
    data: {
      type: 'item',
    },
  })

  useDraggingCursor(dragOverlay)

  const { onKeyDown, ...mouseListeners } = listeners ?? {}

  return (
    <li
      ref={disabled ? null : setNodeRef}
      {...mouseListeners}
      className={clsx(
        styles.base,
        {
          [styles.dragging]: isDragging,
          [styles.dragOverlay]: dragOverlay,
        },
        className,
      )}
      style={{
        transition,
        '--translate-x': transform ? `${Math.round(transform.x)}px` : undefined,
        '--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
        '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
        '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
        ...style,
      }}
    >
      <UnstyledButton
        className={styles.dragHandle}
        {...attributes}
        onKeyDown={onKeyDown as KeyboardEventHandler}
        data-dnd
      >
        <VisuallyHidden>Drag to reorder</VisuallyHidden>
        <GripVerticalIcon />
      </UnstyledButton>
      {!dragOverlay && isDragging && (
        // Gray overlay to indicate where the item is being dragged
        <div className={styles.destination} />
      )}
      {children}
    </li>
  )
}

SortableItem.displayName = 'SortableItem'
export default SortableItem
