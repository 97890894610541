import {
  BufferTracker,
  Product,
  Client,
  notifyViolations,
} from '@bufferapp/buffer-tracking-browser-ts'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import { store } from '~publish/legacy/store'
import type { IntegrationsContext } from './types'
import { env } from '~publish/env'
import { getCurrentProfile } from '~publish/legacy/composer/composer/components/app/AppStateless'
import { SEGMENT_NAMES } from '~publish/legacy/constants'

export const BUTTON_CLICKED = 'integration-button-clicked'
export const MEDIA_ATTACHED = 'integration-media-attached'
export const QUERY_RUN = 'integration-query-run'

const IS_DEVELOPMENT = (): boolean => {
  return env.VITE_NODE_ENV === 'development' || env.VITE_NODE_ENV === 'test'
}

notifyViolations(BufferTracker, IS_DEVELOPMENT())

const getTrackingMetadata = (): {
  organizationId: string
  clientName: Client
  product: Product
} => {
  const organizationId = selectCurrentOrganizationId(store.getState())
  return {
    organizationId,
    clientName: Client.PublishWeb,
    product: Product.Publish,
  }
}

export const sendIntegrationsEvent = (
  event: string,
  context: IntegrationsContext,
  service: string,
  info: string | null = null,
): void => {
  service = service.toLowerCase()

  switch (event) {
    case BUTTON_CLICKED:
      {
        const evenButtonClickedData = {
          ...getTrackingMetadata(),
          source: context,
          integration: service,
        }
        BufferTracker.integrationButtonClicked(evenButtonClickedData)
      }
      break
    case MEDIA_ATTACHED:
      {
        const eventMediaAttachedData = {
          ...getTrackingMetadata(),
          destination: context,
          integration: service,
          mediaType: info || 'no-filled',
        }
        BufferTracker.integrationMediaAttached(eventMediaAttachedData)
      }
      break
    case QUERY_RUN:
      {
        const eventSearchData = {
          ...getTrackingMetadata(),
          integration: service,
          source: context,
          query: info || 'not-filled',
        }

        BufferTracker.integrationSearchRun(eventSearchData)
      }
      break
    default:
      break
  }
}

export const trackLinkShortenerToggleClick = (isShortened: boolean): void => {
  const channel = getCurrentProfile()

  if (!channel || !channel?.serviceName) return

  const properties = {
    ...getTrackingMetadata(),
    channel: channel.serviceName,
    channelId: channel.id,
    cta: isShortened
      ? SEGMENT_NAMES.LINK_SORTENED_CLICKED
      : SEGMENT_NAMES.LINK_UNSORTENED_CLICKED,
  }

  if (isShortened) {
    BufferTracker.linkShortenedClicked(properties)
  } else {
    BufferTracker.linkUnshortenedClicked(properties)
  }
}
