import {
  ELEMENT_PARAGRAPH,
  getEditorString,
  getNode,
  getNodeEntries,
  type GetNodeEntriesOptions,
  isEditor,
  isElement,
  isLastChild,
  isText,
  type TElement,
  type TNode,
  type TNodeEntry,
  type TText,
} from '@udecode/plate'
import type { NodeEntry } from 'slate'
import type { SlateEntityData } from '../helpers/EditorEntityData'
import type { IdeasEditor } from '../IdeasEditor/types.plate'
import {
  type FacebookMentionEntityDetails,
  FacebookMentionInputElement,
} from '../plugins/facebook-mention/nodes'
import { LinkElement, type LinkEntity } from '../plugins/link/nodes/LinkElement'
import {
  LinkedInAnnotationElement,
  type LinkedInEntityDetails,
} from '../plugins/linkedin-annotations/nodes/LinkedInAnnotationElement'
import type { SerializedNode } from './types'
import type { BufferEditor, BufferValue } from './types.plate'

/**
 * Paragraphs should add a line break at the end of the serlized text when
 *  - when the node being serialized is the last one in the paragraph
 *  - AND it's not the last paragraph in an editor
 */
const shouldAddLineBreak = (
  editor: BufferEditor,
  [, path]: TNodeEntry<TText>,
): boolean => {
  const [firstLevel] = path

  if (firstLevel === undefined) {
    // this is just the editor node
    return false
  }
  const firstLevelNode = getNode<TElement>(editor, [firstLevel])
  if (firstLevelNode && firstLevelNode?.type === ELEMENT_PARAGRAPH) {
    const isLastNodeInPararaph = isLastChild(
      [firstLevelNode, [firstLevel]],
      path,
    )
    const isLastParagraph = isLastChild([editor, []], [firstLevel])

    return isLastNodeInPararaph && !isLastParagraph
  }

  return false
}

export const serializeNode = (
  editor: BufferEditor,
  [node, path]: NodeEntry<TNode>,
): SerializedNode<
  LinkEntity | LinkedInEntityDetails | FacebookMentionEntityDetails | undefined
> => {
  if (isText(node)) {
    const text = shouldAddLineBreak(editor, [node, path])
      ? `${node.text}\n`
      : node.text

    return { text }
  }

  if (isEditor(node)) return { text: '' }

  if (LinkElement.is(node)) return LinkElement.serialize(node)
  if (LinkedInAnnotationElement.is(node))
    return LinkedInAnnotationElement.serialize(node)
  if (FacebookMentionInputElement.is(node))
    return FacebookMentionInputElement.serialize(node)

  return { text: '' }
}

export const serialize = (
  editor: BufferEditor | IdeasEditor,
  options: GetNodeEntriesOptions<BufferValue> = {},
): { text: string; data: SlateEntityData } => {
  const nodeEntries = getNodeEntries(editor, {
    at: [],
    ...options,
    // we have to go forward to avoid having to recalculate textBefore
    reverse: false,
  })

  const serializedValue = Array.from(nodeEntries).reduce(
    (result: { text: string; data: SlateEntityData }, [node, path]) => {
      const textBefore = result.text
      const { text, data } = serializeNode(editor as BufferEditor, [node, path])

      result.text += text

      if (isElement(node) && data) {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        if (!result.data[node.type]) {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          result.data[node.type] = []
        }

        const length = text.length || getEditorString(editor, path).length

        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        result.data[node.type] = [
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          ...result.data[node.type],
          { ...data, start: textBefore.length, length },
        ]
      }

      return result
    },
    { text: '', data: {} },
  )

  return serializedValue
}
