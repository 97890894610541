import React, { useEffect, useState } from 'react'

import StarOutlineIcon from '@bufferapp/ui/Icon/Icons/StarOutline'
import ShareArrowIcon from '@bufferapp/ui/Icon/Icons/ShareArrow'

import { LinkifiedText } from '~publish/legacy/shared-components/LinkifiedText'
import type {
  Image,
  Link,
  Video,
} from '~publish/legacy/composer/composer/entities/factories'
import { parseMastodonLinks } from '~publish/legacy/utils/channels/mastodon/helpers'
import { VideoPlayer } from '../../../Common/VideoPlayer'
import { DotsIcon } from '../../../Common/styles'
import { getMediaProperties, truncateMastodonText } from '../../../Common/utils'
import type { MastodonContent, MastodonProfile } from '../../../types'
import { ImagesGrid } from '../../../Common/ImagesGrid'
import useLinkPreviewEffect from '../../../Common/hooks/useLinkPreviewEffect'

import * as Styles from './styles'

const MastodonPost = ({
  content,
  profile,
}: {
  content: MastodonContent
  profile: MastodonProfile
}): JSX.Element => {
  const { avatar, displayName, handle } = profile
  const { text, images, gif, video, link: linkFromPost } = content
  const hasContentWarning = !!content.spoilerText
  const [showContent, setShowContent] = useState(true)

  useEffect(() => {
    setShowContent(!hasContentWarning)
  }, [hasContentWarning])

  const [linkPreview] = useLinkPreviewEffect({
    linkFromPost,
    text,
    checkValidUrl: true,
  })

  const { hasImages, hasVideo, hasLink } = getMediaProperties(
    content,
    linkPreview,
  )
  const textToDisplay = text ? truncateMastodonText(text, 500) : null
  const allImages = images?.length ? images : ([{ ...gif }] as Image[])

  return (
    <Styles.PostWrapper>
      <Styles.Header>
        <Styles.Avatar data-testid="avatar" src={avatar} />
        <Styles.UsernameWrapper>
          <Styles.Username>{displayName}</Styles.Username>
          <Styles.FormattedUsername>{handle}</Styles.FormattedUsername>
        </Styles.UsernameWrapper>
        <DotsIcon />
      </Styles.Header>
      <Styles.Body>
        <Styles.Content>
          {hasContentWarning && (
            <Styles.ContentWarning showContent={showContent}>
              <span>{content.spoilerText}</span>
              <Styles.ShowMore
                onClick={(): void => {
                  setShowContent(!showContent)
                }}
              >
                Show {showContent ? 'less' : 'more'}
              </Styles.ShowMore>
            </Styles.ContentWarning>
          )}
          {showContent && (
            <LinkifiedText
              links={parseMastodonLinks(textToDisplay)}
              size="mini"
              whitespace="pre-wrap"
              newTab
            >
              {textToDisplay}
            </LinkifiedText>
          )}
        </Styles.Content>
      </Styles.Body>
      {hasImages && showContent && (
        <ImagesGrid
          images={allImages}
          isRowFormat={false}
          maxHeight="200px"
          shouldShowGifTag
        />
      )}
      {hasVideo && <VideoPlayer video={video as Video} />}
      {hasLink && (
        <Styles.LinkPreview
          link={linkPreview as Link}
          titleOnTop
          imageCover
          showEmptyThumbnail
          clickable={false}
        />
      )}
      <Styles.Footer>
        <Styles.CommentIconStyled />
        <Styles.ReshareIcon />
        <StarOutlineIcon />
        <ShareArrowIcon />
      </Styles.Footer>
    </Styles.PostWrapper>
  )
}

export default MastodonPost
