import { ImageDimensions } from '../constants'

export const getSafeImageUrl = (
  url: string,
  width: number,
  height: number,
  fitImage = false,
): string => {
  let safeImageUrl = 'https://safeimage.buffer.com/'
  safeImageUrl += `${width}x${height}`
  if (fitImage) {
    safeImageUrl += ',fit'
  }
  safeImageUrl += `/${url}`
  return safeImageUrl
}

export const getLargeSafeImageUrl = (
  url: string,
  fitImage: boolean,
): string => {
  const { width, height } = ImageDimensions.large
  return getSafeImageUrl(url, width, height, fitImage)
}

const getSafeImageUrlWithWidth = (url: string, width: number): string => {
  let safeImageUrl = 'https://safeimage.buffer.com/'
  safeImageUrl += `${width}xfit/${url}`
  return safeImageUrl
}

export const getQueueSafeImageUrl = (url: string): string => {
  return getSafeImageUrlWithWidth(url, ImageDimensions.queueImage.width)
}
