import type { GetNodeEntriesOptions, TNode } from '@udecode/plate'
import type { NodeEntry } from 'slate'
import type { IdeasEditor } from '../IdeasEditor/types.plate'

import { serialize, serializeNode } from './serialize'
import type { BufferEditor, BufferValue } from './types.plate'

export const getNodePlainText = (
  editor: BufferEditor,
  [node, path]: NodeEntry<TNode>,
): string => {
  const { text } = serializeNode(editor, [node, path])

  return text
}

export const getPlainText = (
  editor: BufferEditor | IdeasEditor,
  options: GetNodeEntriesOptions<BufferValue> = {},
): string => {
  return serialize(editor, options).text || ''
}
