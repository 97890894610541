import React from 'react'

import { draftPropType } from '~publish/legacy/composer/composer/components/ComposerPropTypes'
import { PostTypeShort } from '~publish/legacy/post/constants'
import { ShortBar } from '~publish/legacy/composer/composer/components/youtube/ShortBar/ShortBar'
import { YoutubeComposerBarWrapper } from './styles'
import type Draft from '../../entities/Draft/Draft'
import type { YoutubeChannelData } from '../../entities/Draft/types'

function isShortPost(
  draft: Draft,
): draft is Draft & { channelData: { youtube: YoutubeChannelData } } {
  return draft.isShortPost()
}

const YoutubeComposerBar = ({
  draft,
}: {
  draft: Draft
}): JSX.Element | null => {
  if (!isShortPost(draft)) {
    return null
  }

  const data = draft.channelData.youtube

  return (
    <YoutubeComposerBarWrapper>
      {draft.updateType === PostTypeShort && <ShortBar data={data} />}
    </YoutubeComposerBarWrapper>
  )
}

YoutubeComposerBar.propTypes = {
  draft: draftPropType.isRequired,
}

export default YoutubeComposerBar
