import React from 'react'
import PropTypes from 'prop-types'
import AppActionCreators from '../action-creators/AppActionCreators'
import Button from './shared/Button'

import styles from './css/ProfileGroupItem.module.css'

class ProfileGroupItem extends React.Component {
  static propTypes = {
    group: PropTypes.object.isRequired,
    selectedProfileGroupsIds: PropTypes.array.isRequired,
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  onClick = (e) => {
    // @ts-expect-error TS(2339) FIXME: Property 'group' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { group, selectedProfileGroupsIds } = this.props
    const { id } = group

    if (this.isSelected(id))
      AppActionCreators.unselectGroupProfiles(id, selectedProfileGroupsIds)
    else AppActionCreators.selectGroupProfiles(id)

    e.preventDefault()
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  isSelected = (id) => this.props.selectedProfileGroupsIds.includes(id)

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'group' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { group } = this.props
    const profilesCount = group.profileIds.length

    const groupItemClassName = [
      this.isSelected(group.id)
        ? styles.selectedGroupBtn
        : styles.unselectedGroupBtn,
    ].join(' ')

    return (
      <Button className={groupItemClassName} onClick={this.onClick}>
        <span className={styles.groupName}>{group.name}</span>
        <span className={styles.profilesCount}>{profilesCount}</span>
      </Button>
    )
  }
}

export default ProfileGroupItem
