import { Lightbox } from '@buffer-mono/popcorn'
import { Text } from '@bufferapp/ui'
import ClockIcon from '@bufferapp/ui/Icon/Icons/Clock'
import Input from '@bufferapp/ui/Input'
import dayjs from 'dayjs'
import timezonePlugin from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

dayjs.extend(utc)
dayjs.extend(timezonePlugin)

const gridContainer = {
  display: 'flex',
  marginBottom: '20px',
  flexFlow: 'row wrap',
  position: 'relative',
}

const UrlInputWrapper = styled.div`
  padding: 15px 13px;
`

const UrlTextWrapper = styled.div`
  padding: 0px 13px;
  min-height: 48px;
`

// @ts-expect-error TS(7006) FIXME: Parameter 'index' implicitly has an 'any' type.
const itemStyle = (index) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: (index + 1) % 3 === 0 ? '0 0 4% 0' : '0px 1.41% 4% 0px',
  flex: '0 1 32.35%',
  border: '1px solid #B8B8B8',
  boxSizing: 'border-box',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.16)',
  borderRadius: '4px',
})

// @ts-expect-error TS(7006) FIXME: Parameter 'thumbnail' implicitly has an 'any' type... Remove this comment to see the full error message
const imgWrapperStyle = (thumbnail) => ({
  position: 'relative',
  width: '100%',
  height: '281.66px',
  background: 'no-repeat center',
  backgroundSize: 'cover',
  cursor: 'pointer',
  backgroundImage: `url(${thumbnail})`,
})

const scheduledIconStyle = {
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '48px',
  height: '40px',
  top: '15px',
  right: '0',
  backgroundColor: '#FFC6AB',
  color: '#662D12',
}

/*
 * Getting tooltip text
 * */
// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const getTooltipText = (post, timezone) => {
  const timestamp = post.scheduled ? post.due_at : post.sent_at
  let slotMoment = dayjs.unix(timestamp)
  if (timezone) slotMoment = slotMoment.tz(timezone)
  const humanReadableTime = slotMoment.format('MMM Do YYYY [at] h:mm a')
  const sentText = post.scheduled ? 'will be' : 'was'

  return `This post ${sentText} sent ${humanReadableTime}`
}

const GridListPost = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'post' implicitly has an 'any' typ... Remove this comment to see the full error message
  post,
  // @ts-expect-error TS(7031) FIXME: Binding element 'index' implicitly has an 'any' ty... Remove this comment to see the full error message
  index,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onChangePostUrl' implicitly has a... Remove this comment to see the full error message
  onChangePostUrl,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onSavePostUrl' implicitly has an ... Remove this comment to see the full error message
  onSavePostUrl,
  // @ts-expect-error TS(7031) FIXME: Binding element 'timezone' implicitly has an 'any'... Remove this comment to see the full error message
  timezone,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hasWriteAccess' implicitly has an... Remove this comment to see the full error message
  hasWriteAccess,
}) => {
  const isValidLink =
    typeof post.oldLink !== 'undefined' && post.oldLink !== post.link

  return (
    <div style={itemStyle(index)}>
      <Lightbox slides={[{ src: `${post.thumbnail}` }]}>
        <Lightbox.Trigger>
          <div
            style={imgWrapperStyle(post.thumbnail)}
            title={getTooltipText(post, timezone)}
          >
            {post.scheduled && (
              <div style={scheduledIconStyle}>
                <ClockIcon size="medium" />
              </div>
            )}
          </div>
        </Lightbox.Trigger>
      </Lightbox>

      {hasWriteAccess ? (
        <UrlInputWrapper>
          <Input
            // @ts-expect-error TS(2322) FIXME: Type '{ onChange: (e: any) => void; onBlur: (e: an... Remove this comment to see the full error message
            onChange={(e) => {
              onChangePostUrl(post, e.target.value)
            }}
            // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
            onBlur={(e) => {
              if (isValidLink) {
                onSavePostUrl(post, e.target.value)
              }
            }}
            // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
            onKeyUp={(e) => {
              if (e.key === 'Enter' && isValidLink) {
                onSavePostUrl(post, e.target.value)
              }
            }}
            size="small"
            name="postUrl"
            placeholder="Website or Product URL"
            value={post.link}
          />
        </UrlInputWrapper>
      ) : (
        <UrlTextWrapper>
          <Text type="p">{post.link}</Text>
        </UrlTextWrapper>
      )}
    </div>
  )
}

GridListPost.propTypes = {
  index: PropTypes.number,
  timezone: PropTypes.string,
  onChangePostUrl: PropTypes.func,
  onSavePostUrl: PropTypes.func,
  post: PropTypes.shape({
    text: PropTypes.string,
  }),
  hasWriteAccess: PropTypes.bool.isRequired,
}

GridListPost.defaultProps = {
  index: 0,
  timezone: '',
  onChangePostUrl: () => {},
  onSavePostUrl: () => {},
  post: {},
}

const GridList = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'gridPosts' implicitly has an 'any... Remove this comment to see the full error message
  gridPosts,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onChangePostUrl' implicitly has a... Remove this comment to see the full error message
  onChangePostUrl,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onSavePostUrl' implicitly has an ... Remove this comment to see the full error message
  onSavePostUrl,
  // @ts-expect-error TS(7031) FIXME: Binding element 'timezone' implicitly has an 'any'... Remove this comment to see the full error message
  timezone,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hasWriteAccess' implicitly has an... Remove this comment to see the full error message
  hasWriteAccess,
}) => {
  return (
    <div style={gridContainer}>
      {/* @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type. */}
      {gridPosts.map((post, index) => {
        return (
          <GridListPost
            key={`gridListPost-${post.id}`}
            index={index}
            post={post}
            timezone={timezone}
            hasWriteAccess={hasWriteAccess}
            onChangePostUrl={onChangePostUrl}
            onSavePostUrl={onSavePostUrl}
          />
        )
      })}
    </div>
  )
}

GridList.propTypes = {
  timezone: PropTypes.string,
  onChangePostUrl: PropTypes.func,
  onSavePostUrl: PropTypes.func,
  hasWriteAccess: PropTypes.bool,
  gridPosts: PropTypes.arrayOf(
    PropTypes.shape({
      posts: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
        }),
      ),
    }),
  ),
}

GridList.defaultProps = {
  timezone: '',
  hasWriteAccess: false,
  gridPosts: [],
  onChangePostUrl: () => {},
  onSavePostUrl: () => {},
}

export default GridList
