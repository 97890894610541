import {
  actions as dataFetchActions,
  actionTypes as dataFetchActionTypes,
} from '@bufferapp/async-data-fetch'

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    next(action)
    switch (action.type) {
      case `user_${dataFetchActionTypes.FETCH_SUCCESS}`:
        dispatch(
          dataFetchActions.fetch({
            name: 'enabledApplicationModes',
            args: {
              comprehensive: true,
            },
          }),
        )
        break
      case `profiles_${dataFetchActionTypes.FETCH_SUCCESS}`: {
        const profilesArray = action.result
        // Only makes request to check reminders if there are instagram profiles.
        const hasAtLeastOneIGProfile =
          profilesArray &&
          profilesArray.some(
            // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
            (profile) => profile.type && profile.type === 'instagram',
          )

        if (hasAtLeastOneIGProfile) {
          dispatch(
            dataFetchActions.fetch({
              name: 'checkRemindersStatus',
            }),
          )
        }

        break
      }
      default:
        break
    }
  }
