/* eslint-disable default-param-last */
import { actionTypes as dataFetchActionTypes } from '@bufferapp/async-data-fetch'
import { createNextState, type PayloadAction } from '@reduxjs/toolkit'
import {
  noteAdded,
  noteDeleted,
  type NotesPusherPayload,
  noteUpdated,
} from '~publish/legacy/post/slice'
import { actionTypes as profileSidebarActionTypes } from '~publish/legacy/profile-sidebar/reducer'
import { actionTypes as queueActionTypes } from '~publish/legacy/queue/actionTypes'
import { parseNotes } from '~publish/legacy/duplicate-server/parsers/postParser'
import type { RpcUpdate } from '~publish/legacy/post/types'
import type { Profile } from '~publish/legacy/profile-sidebar/types'

export const actionTypes = {
  FETCH_SENT_POSTS: 'SENT__FETCH_SENT_POSTS',
  VIEW_CAMPAIGN_PAGE: 'SENT__VIEW_CAMPAIGN_PAGE',
} as const

export interface SentPostsProfileState {
  loading: boolean
  loadingMore: boolean
  moreToLoad: boolean
  page: number
  posts: Record<string, RpcUpdate>
  total: number
  hasBitlyPosts?: boolean
}

export interface SentPostsState {
  byProfileId: Record<string, SentPostsProfileState>
  showComposer: boolean
  editMode: boolean
  editingPostId: string
  environment: string
  selectedProfileIds: string[]
  post?: RpcUpdate
}

export const initialState: SentPostsState = {
  byProfileId: {},
  showComposer: false,
  editMode: false,
  editingPostId: '',
  environment: 'production',
  selectedProfileIds: [],
  post: undefined,
}

export const profileInitialState: SentPostsProfileState = {
  loading: true,
  loadingMore: false,
  moreToLoad: false,
  page: 1,
  posts: {},
  total: 0,
  hasBitlyPosts: false,
}

const fetchStart = `sentPosts_${dataFetchActionTypes.FETCH_START}`
const fetchSuccess = `sentPosts_${dataFetchActionTypes.FETCH_SUCCESS}`
const fetchFail = `sentPosts_${dataFetchActionTypes.FETCH_FAIL}`

type AcceptedActionTypes =
  | typeof profileSidebarActionTypes.SELECT_PROFILE
  | typeof queueActionTypes.POST_SENT
  | typeof queueActionTypes.POST_UPDATED
  | typeof fetchStart
  | typeof fetchSuccess
  | typeof fetchFail

export interface CatchAllActionStructure {
  type: AcceptedActionTypes
  profileId?: string
  profile?: Profile
  updateId?: string
  post?: RpcUpdate
  args?: { profileId: string; updateId: string; isFetchingMore: boolean }
  result?: { updates: Record<string, RpcUpdate>; total: number }
}

const handlePosts = (
  action: CatchAllActionStructure,
  currentPosts: Record<string, RpcUpdate>,
): Record<string, RpcUpdate> => {
  let posts = action?.result?.updates || {}
  if (action?.args?.isFetchingMore) {
    posts = { ...currentPosts, ...posts }
  }
  return posts
}

const determineIfMoreToLoad = (
  action: CatchAllActionStructure,
  currentPosts: Record<string, RpcUpdate>,
): boolean => {
  if (!action?.result) return false
  const currentPostCount = Object.keys(currentPosts).length
  const resultUpdatesCount = Object.keys(action?.result?.updates).length
  return action.result.total > currentPostCount + resultUpdatesCount
}

const getProfileId = (action: CatchAllActionStructure): string | undefined => {
  if (action.profileId) {
    return action.profileId
  }
  if (action.args) {
    return action.args.profileId
  }
  if (action.profile) {
    return action.profile.id
  }
}

const getPostUpdateId = (
  action: CatchAllActionStructure,
): string | undefined => {
  if (action.updateId) {
    return action.updateId
  }
  if (action.args) {
    return action.args.updateId
  }
  if (action.post) {
    return action.post.id
  }
}

const postsReducer = (
  state: Record<string, RpcUpdate>,
  action: CatchAllActionStructure,
): Record<string, RpcUpdate> => {
  switch (action.type) {
    case queueActionTypes.POST_UPDATED:
    case queueActionTypes.POST_SENT: {
      const postId = getPostUpdateId(action)
      const newState =
        postId && action.post ? { ...state, [postId]: action.post } : state
      return newState
    }
    default:
      return state
  }
}

const hasBitlyPosts = (posts: Record<string, RpcUpdate>): boolean =>
  Object.values(posts).some(
    (post) =>
      post.text?.indexOf('https://buff.ly/') >= 0 ||
      post.text?.indexOf('https://bit.ly/') >= 0 ||
      post.text?.indexOf('https://j.mp/') >= 0,
  )

const profileReducer = (
  state: SentPostsProfileState = profileInitialState,
  action: CatchAllActionStructure,
): SentPostsProfileState => {
  switch (action.type) {
    case profileSidebarActionTypes.SELECT_PROFILE:
      return profileInitialState
    case `sentPosts_${dataFetchActionTypes.FETCH_START}`:
      return {
        ...state,
        loading: !action?.args?.isFetchingMore,
        loadingMore: !!action?.args?.isFetchingMore,
      }
    case `sentPosts_${dataFetchActionTypes.FETCH_SUCCESS}`: {
      const posts = handlePosts(action, state.posts)

      return {
        ...state,
        loading: false,
        loadingMore: false,
        moreToLoad: determineIfMoreToLoad(action, state.posts),
        page: state.page + 1,
        posts,
        total: action?.result?.total ?? 0,
        hasBitlyPosts: hasBitlyPosts(posts),
      }
    }
    case `sentPosts_${dataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...state,
        loading: false,
      }
    case queueActionTypes.POST_UPDATED:
    case queueActionTypes.POST_SENT: {
      const updateTotal = action.type === queueActionTypes.POST_SENT
      return {
        ...state,
        total: updateTotal ? state.total + 1 : state.total,
        posts: postsReducer(state.posts, action),
      }
    }
    default:
      return state
  }
}

export default (
  state: SentPostsState = initialState,
  action: PayloadAction<NotesPusherPayload> | CatchAllActionStructure,
): SentPostsState => {
  let profileId
  switch (action.type) {
    case profileSidebarActionTypes.SELECT_PROFILE:
    case `sentPosts_${dataFetchActionTypes.FETCH_START}`:
    case `sentPosts_${dataFetchActionTypes.FETCH_SUCCESS}`:
    case `sentPosts_${dataFetchActionTypes.FETCH_FAIL}`:
    case queueActionTypes.POST_SENT:
    case queueActionTypes.POST_UPDATED:
      profileId = getProfileId(action)
      if (profileId) {
        return {
          ...state,
          byProfileId: {
            ...state.byProfileId,
            [profileId]: profileReducer(state.byProfileId[profileId], action),
          },
        }
      }
      return state
    case noteAdded.type:
    case noteUpdated.type:
    case noteDeleted.type: {
      const { payload } = action as PayloadAction<NotesPusherPayload>
      const { postId, profileId: currentProfileId, notes } = payload

      return createNextState(state, (draftState) => {
        const post = draftState.byProfileId[currentProfileId]?.posts?.[postId]
        if (post) post.notes = parseNotes(notes)
        return draftState
      })
    }
    default:
      return state
  }
}

export const actions = {
  handleCampaignTagClick: ({
    campaignId,
  }: {
    campaignId: string
  }): {
    type: typeof queueActionTypes.VIEW_CAMPAIGN_PAGE
    campaignId: string
    isSent: boolean
  } => ({
    type: queueActionTypes.VIEW_CAMPAIGN_PAGE,
    campaignId,
    isSent: true,
  }),
  fetchSentPosts: ({
    profileId,
    includeReminders,
    includeDirect,
  }: {
    profileId: string
    includeReminders: boolean
    includeDirect: boolean
  }): {
    type: typeof actionTypes.FETCH_SENT_POSTS
    profileId: string
    includeReminders: boolean
    includeDirect: boolean
  } => ({
    type: actionTypes.FETCH_SENT_POSTS,
    profileId,
    includeReminders,
    includeDirect,
  }),
}
