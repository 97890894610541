import React, { useEffect } from 'react'
import { ChannelIcon } from '@buffer-mono/popcorn'

import { getAccountURL } from '~publish/legacy/utils/formatters/getURL'
import { useAccount } from '~publish/legacy/accountContext'
import { isFreeUser } from '~publish/helpers/user'
import { useCta } from '~publish/hooks/useCta'
import { useIsAtPlanLimit } from '~publish/hooks/useIsAtPlanLimit'

import { SideNavItem } from './SideNavItem'

import styles from './PageSidebar.module.css'
import UserEntity from '~publish/legacy/user/UserEntity'

interface ConnectableChannel {
  name: string
  service: string
}

interface ConnectableChannelsSuggestionsProps {
  connectableChannels: ConnectableChannel[]
}

export const ConnectableChannelsSuggestions: React.FC<
  ConnectableChannelsSuggestionsProps
> = ({ connectableChannels }) => {
  const { account } = useAccount()
  const isAdmin = UserEntity.isAdmin({ account })
  const isFree = isFreeUser(account)
  const isAtPlanLimit = useIsAtPlanLimit()
  const { viewed: ctaViewed, clicked: ctaClicked } = useCta(
    'publish-sidebar-channels-addChannelSuggestions-1',
    {
      upgradePathName: 'addChannel-upgrade',
    },
  )

  useEffect(() => {
    if (isAdmin && connectableChannels.length > 0 && isAtPlanLimit && isFree) {
      ctaViewed()
    }
  }, [connectableChannels, ctaViewed, isAdmin, isAtPlanLimit, isFree])

  if (!isAdmin) {
    return <></>
  }

  return (
    <>
      {connectableChannels.map(({ name, service }) => (
        <SideNavItem className={styles.suggestion} key={service}>
          <a
            href={`${getAccountURL()}/channels/connect?startConnectionService=${service}`}
            onClick={ctaClicked}
          >
            <div className={styles.icon}>
              <ChannelIcon
                type={
                  service as React.ComponentProps<typeof ChannelIcon>['type']
                }
              />
            </div>
            {name}
          </a>
        </SideNavItem>
      ))}
    </>
  )
}
