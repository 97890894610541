import React, { useContext, useEffect } from 'react'
import {
  ButtonWrapper,
  CloseDialogButton,
  Footer,
  IconWrapper,
  Main,
  ModalHeader,
  ModalWrapper,
  StyledOverlay,
} from './style'
import * as Dialog from '@radix-ui/react-dialog'
import { Button, Text } from '@bufferapp/ui'
import { useAppDispatch } from '~publish/legacy/store'
import { actions as modalActions } from '~publish/legacy/modals/reducer'
import { PostDiagram } from '../../icons/post-diagram'
import CrossIcon from '@bufferapp/ui/Icon/Icons/Cross'
import { AccountContext } from '~publish/legacy/accountContext'
import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'
import useDismissObject from '../hooks/useDismissObject'
import { SEGMENT_NAMES } from '~publish/legacy/constants'
import { actions as streaksActions } from '~publish/legacy/streaks/reducer'

export const FIRST_POST_CELEBRATION_MODAL = 'first_post_celebration_modal'

export interface FirstPostCelebrationModalProps {
  modalCopy: boolean
  onCreateNextPostClick: ({
    selectedProfileId,
    selectedProfileIds,
  }: {
    selectedProfileId?: string
    selectedProfileIds: string[]
  }) => void
  onAIAssistantClick: ({
    selectedProfileId,
    selectedProfileIds,
  }: {
    selectedProfileId?: string
    selectedProfileIds: string[]
  }) => void
}

export const FirstPostCelebrationModal = ({
  modalCopy,
  onCreateNextPostClick,
  onAIAssistantClick,
}: FirstPostCelebrationModalProps): JSX.Element => {
  const { account } = useContext(AccountContext)
  const currentOrganization = account?.currentOrganization
  const commonTrackingProperties = currentOrganization.commonTrackingProperties

  const { onDismissObject } = useDismissObject({
    organizationId: currentOrganization.id,
    dismissedObject: {
      id: FIRST_POST_CELEBRATION_MODAL,
    },
  })

  const dispatch = useAppDispatch()

  const handleCloseModal = (): void => {
    onDismissObject()
    dispatch(modalActions.hideFirstPostCelebrationModal())
    dispatch(streaksActions.clearTriggerStreaksModal())
  }

  const handlePrimaryCtaClick = (): void => {
    handleCloseModal()
    onCreateNextPostClick({
      selectedProfileIds: [],
    })
    if (currentOrganization.id) {
      BufferTracker.firstPostCelebrationCtaClicked({
        organizationId: currentOrganization.id,
        cta: SEGMENT_NAMES.STREAKS_FIRST_POST_CELEBRATION_CTA_CLICKED,
        ...commonTrackingProperties,
      })
    }
    dispatch(streaksActions.clearTriggerStreaksModal())
  }

  const handleSecondaryCtaClick = (): void => {
    handleCloseModal()
    onAIAssistantClick({
      selectedProfileIds: [],
    })
    if (currentOrganization.id) {
      BufferTracker.firstPostCelebrationCtaClicked({
        organizationId: currentOrganization.id,
        cta: SEGMENT_NAMES.STREAKS_FIRST_POST_CELEBRATION_AI_CTA_CLICKED,
        ...commonTrackingProperties,
      })
    }
    dispatch(streaksActions.clearTriggerStreaksModal())
  }

  useEffect(() => {
    if (currentOrganization.id) {
      BufferTracker.firstPostCelebrationModalViewed({
        organizationId: currentOrganization.id,
        ...commonTrackingProperties,
      })
    }
  }, [currentOrganization.id, commonTrackingProperties])

  return (
    <Dialog.Root onOpenChange={handleCloseModal} open>
      <Dialog.Portal>
        <StyledOverlay />
        <ModalWrapper>
          <Main id="main">
            <ModalHeader>
              <Dialog.Close asChild>
                <CloseDialogButton aria-label="Close" type="button">
                  <CrossIcon />
                </CloseDialogButton>
              </Dialog.Close>
            </ModalHeader>
            <IconWrapper>
              <PostDiagram />
            </IconWrapper>
            <Text type="h3">{modalCopy}</Text>
            <Text type="p">
              Keep the magic flowing! Hit the button below to create your next
              compelling post and maintain a streak of posting regularly.
            </Text>
            <ButtonWrapper>
              {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; size: string; label: any; on... Remove this comment to see the full error message */}
              <Button
                type="primary"
                size="large"
                label="Create your next post"
                onClick={handlePrimaryCtaClick}
              />
            </ButtonWrapper>
            <Footer>
              Need inspiration or a creative boost? Get Help from the{' '}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#" onClick={handleSecondaryCtaClick}>
                AI Assistant
              </a>
            </Footer>
          </Main>
        </ModalWrapper>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
