import clsx from 'clsx'
import React from 'react'

import { Heading, type HeadingProps } from '@buffer-mono/popcorn'

import styles from './PageLayout.module.css'

type PageLayoutProps = React.HTMLAttributes<HTMLDivElement>

const PageLayout = React.forwardRef<HTMLDivElement, PageLayoutProps>(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <div
        ref={forwardedRef}
        className={clsx(styles.wrapper, className)}
        {...props}
      >
        {children}
      </div>
    )
  },
)

PageLayout.displayName = 'PageLayout'

type PageLayoutHeaderProps = React.HTMLAttributes<HTMLDivElement>

const PageLayoutHeader = React.forwardRef<
  HTMLDivElement,
  PageLayoutHeaderProps
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <header
      ref={forwardedRef}
      className={clsx(styles.header, className)}
      {...props}
    >
      {children}
    </header>
  )
})

PageLayoutHeader.displayName = 'PageLayout.Header'

type PageLayoutHeaderRowProps = React.HTMLAttributes<HTMLDivElement> & {
  size?: 'narrow' | 'wide' | 'full'
}

const PageLayoutHeaderRow = React.forwardRef<
  HTMLDivElement,
  PageLayoutHeaderRowProps
>(({ children, className, size = 'wide', ...props }, forwardedRef) => {
  return (
    <div
      ref={forwardedRef}
      className={clsx(styles.headerRow, styles[size], className)}
      {...props}
    >
      {children}
    </div>
  )
})

PageLayoutHeaderRow.displayName = 'PageLayout.HeaderRow'

type PageLayoutTitleProps = React.HTMLAttributes<HTMLDivElement> &
  Omit<HeadingProps, 'size'>

const PageLayoutTitle = React.forwardRef<
  HTMLHeadingElement,
  PageLayoutTitleProps
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <Heading
      {...props}
      as="h1"
      ref={forwardedRef}
      size="medium"
      className={clsx(styles.title, className)}
    >
      {children}
    </Heading>
  )
})

PageLayoutTitle.displayName = 'PageLayout.Title'

type PageLayoutActionsProps = React.HTMLAttributes<HTMLDivElement>

const PageLayoutActions = React.forwardRef<
  HTMLDivElement,
  PageLayoutActionsProps
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <div
      ref={forwardedRef}
      className={clsx(styles.actions, className)}
      {...props}
    >
      {children}
    </div>
  )
})

PageLayoutActions.displayName = 'PageLayout.Actions'

type PageLayoutContainerProps = React.HTMLAttributes<HTMLDivElement> & {
  size?: 'narrow' | 'wide' | 'full'
  as?: 'div' | 'main' | 'section'
}

const PageLayoutContainer = React.forwardRef<
  HTMLDivElement,
  PageLayoutContainerProps
>(
  (
    { children, size = 'wide', as: As = 'div', className, ...props },
    forwardedRef,
  ) => {
    return (
      <As
        ref={forwardedRef}
        className={clsx(styles.content, styles[size], className)}
        {...props}
      >
        {children}
      </As>
    )
  },
)

PageLayoutContainer.displayName = 'PageLayout.Content'

type PageLayoutNotificationsContainerProps = PageLayoutContainerProps

const PageLayoutNotificationsContainer = React.forwardRef<
  HTMLDivElement,
  PageLayoutNotificationsContainerProps
>(
  (
    { children, size = 'wide', as: As = 'div', className, ...props },
    forwardedRef,
  ) => {
    return (
      <As
        ref={forwardedRef}
        className={clsx(
          styles.content,
          styles.notifications,
          styles[size],
          className,
        )}
        {...props}
      >
        {children}
      </As>
    )
  },
)
PageLayoutNotificationsContainer.displayName =
  'PageLayout.NotificationsContainer'

const PageLayoutObject = Object.assign(PageLayout, {
  Header: PageLayoutHeader,
  HeaderRow: PageLayoutHeaderRow,
  Title: PageLayoutTitle,
  Actions: PageLayoutActions,
  Container: PageLayoutContainer,
  NotificationsContainer: PageLayoutNotificationsContainer,
})

export {
  PageLayoutObject as PageLayout,
  PageLayoutProps,
  PageLayoutHeaderProps,
  PageLayoutHeaderRowProps,
  PageLayoutTitleProps,
  PageLayoutActionsProps,
}
