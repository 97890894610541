import React from 'react'

import {
  CloseIcon,
  CommentRoundFilledIcon,
  CommentRoundIcon,
  Flex,
  IconButton,
  Popover,
  toast,
  VisuallyHidden,
} from '@buffer-mono/popcorn'
import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'

import { NotesPaywall } from '~publish/components/NotesPaywall'
import { NotesWidget } from '~publish/components/NotesWidget'
import { graphql, getFragmentData } from '~publish/gql'
import { isFreeUser } from '~publish/helpers/user'
import { useSplitEnabled } from '@buffer-mono/features'
import {
  useAccount,
  useHasEntitlement,
  useIsLegacyPlan,
  useOrganizationId,
  useOrganizationMembersTotal,
} from '~publish/legacy/accountContext'

import { NOTES_LIMIT } from './PostCard'
import { usePostData } from './PostCardContext'
import { UpgradeToTeamPlan } from './UpgradeToTeamPlan'
import { useAddNoteToPost } from './useAddNoteToPost'
import { useDeleteNoteFromPost } from './useDeleteNoteFromPost'
import { useUpdateNoteOnPost } from './useUpdateNoteOnPost'

import styles from './PostCard.module.css'
import { NotesLegacyPaywall } from '../NotesPaywall/NotesLegacyPaywall'

export const PostCardNotes_Note = graphql(/* GraphQL */ `
  fragment PostCardNotes_Note on Note {
    __typename
    id
    text
    type
    createdAt
    updatedAt
    author {
      __typename
      id
      email
      avatar
      isDeleted
    }
    allowedActions
  }
`)

export const PostCardNotes = (): JSX.Element => {
  const [open, setOpen] = React.useState(false)
  const post = usePostData()
  const notes = getFragmentData(PostCardNotes_Note, post.notes)
  const { account } = useAccount()
  const organizationId = useOrganizationId()
  const canAddNote = post.allowedActions.includes('addPostNote')

  const onOpenNotes = (): void => {
    if (!post || !organizationId) return

    BufferTracker.noteFeedViewed({
      entityId: post.id,
      entityType: 'post',
      channel: post.channel.service,
      channelId: post.channel.id,
      channelType: post.channel.type,
      channelUsername: post.channel.displayName ?? '',
      clientName: 'publishWeb',
      entityStatus: post.status || null,
      entityText: post.text || '',
      organizationId,
    })
  }

  const totalMembers = useOrganizationMembersTotal()
  const hasAccessTeamPanel = useHasEntitlement('teamsPanel')
  const showUpgradePath = !hasAccessTeamPanel
  const notesLimitReached = notes.length === NOTES_LIMIT

  const isLegacyPlan = useIsLegacyPlan()

  const [addNoteToPost] = useAddNoteToPost()
  const [updateNoteOnPost] = useUpdateNoteOnPost()
  const [deleteNoteFromPost] = useDeleteNoteFromPost()

  const handleAddNote = React.useCallback(
    async (text: string): Promise<void> => {
      if (!text.trim() || !canAddNote) return

      const { success, error } = await addNoteToPost({
        variables: { postId: post.id, text },
      })

      if (!success) {
        toast.error(`Failed to add new note: ${error.message}`)
      }
    },
    [canAddNote, addNoteToPost, post.id],
  )

  const handleEditNote = React.useCallback(
    async (noteId: string, text: string): Promise<void> => {
      const { success, error } = await updateNoteOnPost({
        variables: { postId: post.id, noteId, text },
      })

      if (!success) {
        toast.error(`Failed to update note: ${error.message}`)
      }
    },
    [updateNoteOnPost, post.id],
  )

  const handleDeleteNote = React.useCallback(
    async (noteId: string): Promise<void> => {
      const { success, error } = await deleteNoteFromPost({
        variables: { postId: post.id, noteId },
      })

      if (!success) {
        toast.error(`Failed to delete note: ${error.message}`)
      }
    },
    [deleteNoteFromPost, post.id],
  )

  // Notes paywall
  const { isEnabled: isNotesPaywallEnabled } =
    useSplitEnabled('geid-notes-paywall')
  const isOnFreePlan = isFreeUser(account)
  const shouldShowPaywall = isOnFreePlan && isNotesPaywallEnabled

  if (shouldShowPaywall) {
    return (
      <NotesPaywall
        ctaProperties={{
          product: 'publish',
          view: 'allChannels',
          location: 'postCard',
          button: 'addNote',
        }}
      >
        <IconButton className={styles.notesTrigger} label="Notes" size="large">
          <CommentRoundIcon />
        </IconButton>
      </NotesPaywall>
    )
  }

  if (isLegacyPlan) {
    return (
      <NotesLegacyPaywall
        ctaProperties={{
          location: 'postCard',
          button: 'openNotes',
        }}
      >
        <IconButton className={styles.notesTrigger} label="Notes" size="large">
          <CommentRoundIcon />
        </IconButton>
      </NotesLegacyPaywall>
    )
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <Popover.Trigger>
        <IconButton
          className={styles.notesTrigger}
          label="Notes"
          size="large"
          onClick={onOpenNotes}
        >
          {!notes.length ? (
            <CommentRoundIcon />
          ) : (
            <CommentRoundFilledIcon color="subtle" />
          )}
        </IconButton>
      </Popover.Trigger>
      <Popover.Content
        side="right"
        align="start"
        className={styles.notesPopoverContent}
        sideOffset={-40}
        alignOffset={-8}
        aria-labelledby="notes-widget-label"
      >
        <VisuallyHidden id="notes-widget-label">Notes widget</VisuallyHidden>
        <NotesWidget
          key={`notes-widget-${post.id}`}
          isVisibleToTeamMembers={!!totalMembers && totalMembers > 1}
          className={styles.notesWidget}
        >
          <NotesWidget.NoteList>
            {notes.map((note) => (
              <NotesWidget.Note
                key={note.id}
                note={note}
                isAuthor={account.id === note.author.id}
                onDelete={handleDeleteNote}
                onEdit={handleEditNote}
              />
            ))}
          </NotesWidget.NoteList>
          {canAddNote && (
            <NotesWidget.NewNoteForm
              notesLimitReached={notesLimitReached}
              onSubmit={handleAddNote}
            />
          )}
          <Flex gap="space-50" className={styles.notesWidgetActions}>
            <IconButton
              label="Close"
              variant="tertiary"
              onClick={(): void => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </Flex>
        </NotesWidget>
        {showUpgradePath && <UpgradeToTeamPlan />}
      </Popover.Content>
    </Popover>
  )
}
