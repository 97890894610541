import {
  Combobox,
  EmptyState,
  TagIcon,
  Tag,
  SearchIcon,
} from '@buffer-mono/popcorn'
import React from 'react'

import type { FilterByTag_TagFragment } from '~publish/gql/graphql'
import { type FragmentType, graphql, getFragmentData } from '~publish/gql'

import { FilterButton } from '../FilterButton'

export const FilterByTag_Tag = graphql(/* GraphQL */ `
  fragment FilterByTag_Tag on Tag {
    id
    name
    color
  }
`)

/**
 * Masked type provided by parent query result.
 * Use `useFilterByTagFragment` to get the unmasked type.
 */
export type MaskedFilterByTagFragment = readonly FragmentType<
  typeof FilterByTag_Tag
>[]

/**
 * Unmasked type extracted from parent query result.
 */
export type FilterByTagFragment = FilterByTag_TagFragment
/**
 * List of unmasked tag filter fragments extracted from parent query result.
 */
export type FilterByTagFragments = readonly FilterByTag_TagFragment[]

/**
 * Unmask tag filters from the parent query result into typed
 *  fragments.
 * @param tags masked tag filter fragments from the parent query result
 * @returns
 */
export const useFilterByTagFragment = (
  tags: MaskedFilterByTagFragment,
): FilterByTagFragments => getFragmentData(FilterByTag_Tag, tags)

type FilterByTagProps = {
  tags: MaskedFilterByTagFragment
  /**
   * Selected tag ids
   */
  value: string[]
  onSelect: (tagIds: string[]) => void
}

/**
 * Component to enable filtering by tags, using Combobox
 * It exposes fragment to fetch tags, tags array and selected tags
 */
export const FilterByTag = ({
  tags: passedTags,
  value,
  onSelect,
}: FilterByTagProps): JSX.Element => {
  const tags = getFragmentData(FilterByTag_Tag, passedTags)

  return (
    <Combobox multiple value={value} onChange={onSelect}>
      <Combobox.Trigger>
        <FilterButton
          icon={<TagIcon />}
          count={value.length}
          variant="tertiary"
          size="medium"
        >
          Tags
        </FilterButton>
      </Combobox.Trigger>
      <Combobox.Content>
        <Combobox.Input placeholder="Search tags" />
        <Combobox.List>
          <Combobox.Empty>
            {tags.length === 0 ? (
              <EmptyState size="small">
                <EmptyState.Icon variant="primary">
                  <TagIcon />
                </EmptyState.Icon>
                <EmptyState.Heading>No tags</EmptyState.Heading>
                <EmptyState.Description>
                  Use tags to organise your content
                </EmptyState.Description>
              </EmptyState>
            ) : (
              <EmptyState size="small">
                <EmptyState.Icon>
                  <SearchIcon />
                </EmptyState.Icon>
                <EmptyState.Description>No tags found</EmptyState.Description>
              </EmptyState>
            )}
          </Combobox.Empty>
          {tags.map(({ id, name, color }) => (
            <Combobox.Item key={id} value={id} keywords={[name]}>
              <Tag color={color}>{name}</Tag>
            </Combobox.Item>
          ))}
        </Combobox.List>
      </Combobox.Content>
    </Combobox>
  )
}
