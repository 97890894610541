import {
  type Idea,
  type IdeaContent,
  MediaType,
  type NewIdea,
  type PostMediaValidity,
} from '~publish/pages/Create/types'

export const hasMultipleVideos = (idea: Idea): boolean => {
  let videoCount = 0
  idea.content.media?.forEach((media) => {
    if (media.type === MediaType.video) {
      videoCount += 1
    }
  })
  return videoCount > 1
}

export const hasMixedMediaTypes = (idea: Idea): boolean => {
  let hasMixedMedia = false
  let lastMediaType: MediaType | null = null
  idea.content.media?.forEach((media) => {
    if (lastMediaType && media.type !== lastMediaType) {
      hasMixedMedia = true
      return
    }
    lastMediaType = media.type
  })
  return hasMixedMedia
}

export const isMediaConvertibleToPost = (idea: Idea): PostMediaValidity => {
  if (hasMultipleVideos(idea)) {
    return {
      valid: false,
      message: 'Multiple videos not supported',
    }
  }

  if (hasMixedMediaTypes(idea)) {
    return {
      valid: false,
      message: 'Different media types not supported',
    }
  }
  return {
    valid: true,
    message: null,
  }
}

type UpdateIdeaFunction = (idea: Idea, options?: { source?: string }) => void
type CreateIdeaFunction = (
  idea: { content: IdeaContent; groupId?: string },
  options: { source?: string },
) => void

type CreateOrUpdateIdeaArgs = {
  idea: Idea | NewIdea | null
  updateIdea: UpdateIdeaFunction
  createIdea: CreateIdeaFunction
  source?: string | undefined
}

export const createOrUpdateIdea = (args: CreateOrUpdateIdeaArgs): void => {
  if (!args.idea) return

  const { idea, updateIdea, createIdea, source } = args
  if (idea.id) {
    updateIdea(idea as Idea, { source })
  } else {
    createIdea(idea, { source })
  }
}
