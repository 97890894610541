import React from 'react'
import PropTypes from 'prop-types'
import { ColorPicker } from '~publish/legacy/shared-components'
import { Button } from '@bufferapp/ui'
import styled from 'styled-components'
import InfoIcon from '@bufferapp/ui/Icon/Icons/Info'
import Tooltip from '@bufferapp/ui/Tooltip'
import { gray, grayDark } from '@bufferapp/ui/style/colors'
import { fontFamily, fontWeightBold } from '@bufferapp/ui/style/fonts'

const DEFAULT_COLOR = '#000000'

const MyLinksHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
`

const MyLinksTitle = styled.div`
  display: flex;
  align-items: center;
`

const MyLinksIcon = styled.span`
  padding: 6px 0 0 6px;
`

const MyLinksTitleText = styled.span`
  color: ${grayDark};
  font-family: ${fontFamily};
  font-size: 18px;
  font-weight: ${fontWeightBold};
`

const LinkColorSection = styled.div`
  display: flex;
  padding: 0 8px;
`

const AddLinkSection = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 0 8px;
`

const LinksHeader = ({
  title = 'Top Links',
  popupText = 'Add up to 3 custom links to the top of your Shop grid page.',
  onAddLinkClick = () => {},
  buttonText = 'Add Link',
  pickerText = 'Link Color',
  // @ts-expect-error TS(7031) FIXME: Binding element 'setColorButton' implicitly has an... Remove this comment to see the full error message
  setColorButton,
  // @ts-expect-error TS(7031) FIXME: Binding element 'setTextColor' implicitly has an '... Remove this comment to see the full error message
  setTextColor,
  // @ts-expect-error TS(7031) FIXME: Binding element 'colorButtons' implicitly has an '... Remove this comment to see the full error message
  colorButtons,
  // @ts-expect-error TS(7031) FIXME: Binding element 'textColor' implicitly has an 'any... Remove this comment to see the full error message
  textColor,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onUpdateCustomLinksColor' implici... Remove this comment to see the full error message
  onUpdateCustomLinksColor,
  addLinkDisabled = false,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hasWriteAccess' implicitly has an... Remove this comment to see the full error message
  hasWriteAccess,
}) => {
  return (
    <MyLinksHeader>
      <MyLinksTitle>
        <MyLinksTitleText>{title}</MyLinksTitleText>
        <MyLinksIcon>
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; label: string; position... Remove this comment to see the full error message */}
          <Tooltip label={popupText} position="right">
            <InfoIcon color={gray} />
          </Tooltip>
        </MyLinksIcon>
      </MyLinksTitle>
      {hasWriteAccess && (
        <AddLinkSection>
          <LinkColorSection>
            <ColorPicker
              label={pickerText}
              defaultColor={colorButtons || DEFAULT_COLOR}
              // @ts-expect-error TS(2322) FIXME: Type '{ label: string; defaultColor: any; setColor... Remove this comment to see the full error message
              setColorButton={setColorButton}
              setTextColor={setTextColor}
              onChange={(color, contrastColor) => {
                setColorButton(color)
                setTextColor(contrastColor)
              }}
              onBlur={(color, contrastColor) => {
                setColorButton(color)
                setTextColor(contrastColor)
                onUpdateCustomLinksColor({
                  customLinkColor: colorButtons,
                  customLinkContrastColor: textColor,
                })
              }}
            />
          </LinkColorSection>
          {/* @ts-expect-error TS(2740) FIXME: Type '{ label: string; type: string; onClick: () =... Remove this comment to see the full error message */}
          <Button
            label={buttonText}
            type="primary"
            onClick={onAddLinkClick}
            disabled={addLinkDisabled}
          />
        </AddLinkSection>
      )}
    </MyLinksHeader>
  )
}

LinksHeader.propTypes = {
  customLinksDetails: PropTypes.shape({
    customLinks: PropTypes.array,
    maxCustomLinks: PropTypes.number,
    buttonColor: PropTypes.string,
    buttonContrastColor: PropTypes.string,
  }),
  title: PropTypes.string,
  popupText: PropTypes.string,
  colorButtons: PropTypes.string,
  buttonText: PropTypes.string,
  pickerText: PropTypes.string,
  textColor: PropTypes.string,
  maxCustomLinks: PropTypes.number,
  onAddLinkClick: PropTypes.func,
  setColorButton: PropTypes.func,
  setTextColor: PropTypes.func,
  onUpdateCustomLinksColor: PropTypes.func,
  addLinkDisabled: PropTypes.bool,
  hasWriteAccess: PropTypes.bool.isRequired,
}

LinksHeader.defaultProps = {
  customLinksDetails: {
    customLinks: [],
    maxCustomLinks: 0,
    buttonColor: null,
    buttonContrastColor: null,
  },
  title: 'Top Links',
  popupText: 'Add up to 3 custom links to the top of your Shop grid page.',
  buttonText: 'Add Link',
  pickerText: 'Link Color',
  colorButtons: '',
  textColor: '',
  maxCustomLinks: 3,
  onAddLinkClick: () => {},
  setColorButton: () => {},
  setTextColor: () => {},
  onUpdateCustomLinksColor: () => {},
}

export default LinksHeader
