import { connect } from 'react-redux'
import { actions as modalsActions } from '~publish/legacy/modals'
import { actions as queueActions } from '~publish/legacy/queue'
import { actions as draftActions } from '~publish/legacy/drafts'

import { campaignTab } from '~publish/legacy/routes'
import { getURL } from '~publish/legacy/utils/formatters'
import {
  type Campaign,
  CampaignTab,
  type LeanTag,
} from '~publish/legacy/campaign/types'
import { movePostToDrafts } from '~publish/legacy/post/thunks'
import { actions } from './reducer'
import ViewCampaign from './components/ViewCampaign'
import type { RpcUpdate } from '../post/types'

export default connect(
  (state, ownProps) => {
    // @ts-expect-error TS(2339) FIXME: Property 'campaign' does not exist on type 'Defaul... Remove this comment to see the full error message
    const { campaignPosts } = state.campaign
    return {
      // @ts-expect-error TS(2339) FIXME: Property 'campaign' does not exist on type 'Defaul... Remove this comment to see the full error message
      campaign: state.campaign.campaign,
      campaignPosts: campaignPosts || [],
      // @ts-expect-error TS(2339) FIXME: Property 'campaign' does not exist on type 'Defaul... Remove this comment to see the full error message
      editMode: state.campaign.editMode,
      // @ts-expect-error TS(2339) FIXME: Property 'organizations' does not exist on type 'D... Remove this comment to see the full error message
      hideAnalyzeReport: !state.organizations.selected?.canSeeCampaignsReport,
      // @ts-expect-error TS(2339) FIXME: Property 'organizations' does not exist on type 'D... Remove this comment to see the full error message
      showCampaignActions: state.organizations.selected?.canModifyCampaigns,
      // @ts-expect-error TS(2339) FIXME: Property 'campaign' does not exist on type 'Defaul... Remove this comment to see the full error message
      isLoading: state.campaign.isLoading,
      // @ts-expect-error TS(2339) FIXME: Property 'campaign' does not exist on type 'Defaul... Remove this comment to see the full error message
      hideSkeletonHeader: state.campaign.hideSkeletonHeader,
      // @ts-expect-error TS(2339) FIXME: Property 'match' does not exist on type '{}'.
      campaignId: ownProps.match?.params?.id || state.campaign?.campaignId,
      // @ts-expect-error TS(2339) FIXME: Property 'organizations' does not exist on type 'D... Remove this comment to see the full error message
      hasAnalyticsOnPosts: state.organizations.selected?.hasAnalyticsOnPosts,
    }
  },

  (dispatch, ownProps) => ({
    actions: {
      onDeleteCampaignClick: (campaign: Campaign): void => {
        dispatch(modalsActions.showDeleteCampaignModal(campaign))
      },
      goToAnalyzeReport: (campaign: Campaign): void => {
        dispatch(actions.goToAnalyzeReport(campaign))
      },
      onEditCampaignClick: (tag: LeanTag): void => {
        dispatch(modalsActions.openTagFormModal({ editMode: true, tag }))
      },
    },
    postActions: {
      onSetRemindersClick: ({ post }: { post: RpcUpdate }): void => {
        const nextUrl = campaignTab.getRoute({
          campaignId: post.campaignDetails?.id || '',
          tab: CampaignTab.Scheduled,
        })
        const reminderUrl = getURL.getRemindersURL({
          profileId: post.profileId,
          nextUrl,
        })
        window.location.assign(reminderUrl)
      },
      openRemindersWizard: (): void => {
        dispatch(
          modalsActions.showRemindersWizardModal({ placement: 'campaign' }),
        )
      },
      onDeleteConfirmClick: ({ post }: { post: RpcUpdate }): void => {
        if (!post.isDraft) {
          dispatch(
            queueActions.handleDeleteConfirmClick({
              post,
              profileId: post.profileId,
            }),
          )
        } else {
          dispatch(
            draftActions.handleDeleteConfirmClick({
              draft: post,
              profileId: post.profileId,
            }),
          )
        }
        dispatch(
          actions.handleDeleteConfirmClick({
            post,
          }),
        )
      },
      onApproveClick: ({ draft }: { draft: RpcUpdate }): void => {
        dispatch(
          draftActions.handleApproveClick({
            draft,
            // @ts-expect-error TS(2345) FIXME: Argument of type '{ draft: any; profileId: any; }'... Remove this comment to see the full error message
            profileId: ownProps.profileId,
          }),
        )
      },
      onRequestApprovalClick: ({ draft }: { draft: RpcUpdate }): void => {
        dispatch(
          draftActions.handleRequestApprovalClick({
            draft,
            // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
            profileId: ownProps.profileId,
            needsApproval: true,
          }),
        )
      },
      onRevertApprovalClick: ({ draft }: { draft: RpcUpdate }): void => {
        dispatch(
          draftActions.handleRequestApprovalClick({
            draft,
            // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
            profileId: ownProps.profileId,
            needsApproval: false,
          }),
        )
      },
      onMovePostToDraftsClick: ({ post }: { post: RpcUpdate }): void => {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'AsyncThunkAction<unknown, { post... Remove this comment to see the full error message
        dispatch(movePostToDrafts({ postId: post.id }))
      },
      onRequeueClick: ({ post }: { post: RpcUpdate }): void => {
        dispatch(
          queueActions.handleRequeue({
            post,
            profileId: post.profileId,
          }),
        )
      },
      onShareNowClick: ({ post }: { post: RpcUpdate }): void => {
        dispatch(
          queueActions.handleShareNowClick({
            post,
            profileId: post.profileId,
          }),
        )
        dispatch(
          actions.handleShareNowClick({
            post,
          }),
        )
      },
    },
  }),
  // @ts-expect-error TS(2345) FIXME: Argument of type '{ ({ campaign, campaignPosts, is... Remove this comment to see the full error message
)(ViewCampaign)

export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
