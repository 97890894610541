import callRpc from '~publish/legacy/utils/call-rpc'
import type { UserS3UploadSignature } from '~publish/legacy/user/types'
import { uploadsLogger } from '../loggers'

const debug = uploadsLogger.extend('getS3Signature')

type S3SignatureResponse = {
  success: boolean
  signature: UserS3UploadSignature
  error?: string
}

type SignatureCache = {
  s3Signature: UserS3UploadSignature | null
  expiresAt: number
}

const TTL_IN_MS = 5 * 60 * 1000
const cache: SignatureCache = {
  s3Signature: null,
  expiresAt: 0,
}

export async function getS3Signature(): Promise<UserS3UploadSignature> {
  // use cached signature if it's less than 5 minutes old
  if (cache.s3Signature && Date.now() < cache.expiresAt) {
    debug('Using cached signature')
    return cache.s3Signature
  }

  debug('Fetching new signature')
  const response: S3SignatureResponse = await callRpc('composerApiProxy', {
    url: `/1/user/s3_signature.json`,
    HTTPMethod: 'GET',
    args: {},
  })

  if (!response.success) {
    throw new Error(
      `Failed to fetch S3 signature${
        response.error ? `: ${response.error}` : ''
      }`,
    )
  }

  if (!response.signature) {
    throw new Error('Failed to fetch S3 signature: missing s3Signature')
  }

  cache.s3Signature = response.signature
  cache.expiresAt = Date.now() + TTL_IN_MS

  debug('Fetched new signature')
  return response.signature
}
