import { AttachmentTypes } from '~publish/legacy/composer/composer/AppConstants'
import { gbpChannelDataValidation } from '~publish/legacy/composer/composer/lib/validation/utils/gbpChannelDataValidation'
import {
  PostValidationError,
  Services,
  SERVICES_WITHOUT_SHARE_AGAIN_ACTION,
} from '~publish/legacy/constants'
import {
  getDateString,
  isInThePast,
} from '~publish/legacy/duplicate-server/formatters'
import type { RpcUpdate } from '../types'
import type { PublishingPost } from '~publish/gql/graphql'

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const getId = (post) => post?.id

/* eslint-disable camelcase */
// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const isDraft = (post) => {
  // RPC post logic
  if (post?.isDraft) return true

  // GraphQL post logic
  if (post?.type === 'draft') return true
  return false
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const isSent = (post) => {
  // RPC post logic
  if (post?.isSent) return true

  // GraphQL post logic
  if (
    post?.status === 'sent' ||
    post?.status === 'pending_tiktok' ||
    post?.status === 'service'
  )
    return true

  return false
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const isPastReminder = (post) => {
  // RPC post logic
  if (post?.isPastReminder) return true

  // GraphQL post logic
  if (post.status === 'notified') return true

  return false
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const isPendingTiktok = (post) => {
  // GraphQL post logic
  if (post?.status === 'pending_tiktok') return true

  return false
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const isRetweet = (post) => {
  // RPC post logic
  if (post?.postDetails) return post.postDetails.isRetweet

  // GraphQL post logic
  return post.retweet !== undefined
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const isPastDue = (post) => {
  // RPC post logic
  if (post?.isPastDue !== undefined) return post.isPastDue

  // GraphQL post logic
  if (post?.dueAt) return isInThePast(post?.dueAt)
  return false
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const getScheduledAt = (post) => {
  return post?.scheduledAt || post?.scheduled_at
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const getDueAt = (post) => {
  return post?.due_at || post?.dueAt
}

// It's common to think that the concept of a post
// being scheduled depends on it having a scheduled at
// date, whereas it actually depends on the due at date.
// This helper aims to help abstract that confusion.
// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const isScheduled = (post) => {
  return !!getDueAt(post)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const getFirstComment = (post) => {
  // RPC post logic
  if (post?.channel_data?.instagram?.comment_text)
    return post?.channel_data?.instagram?.comment_text
  if (post?.channel_data?.linkedin?.comment_text)
    return post?.channel_data?.linkedin?.comment_text

  // GraphQL post logic
  const graphFirstComment = post?.attachments?.find(
    // @ts-expect-error TS(7006) FIXME: Parameter 'attachment' implicitly has an 'any' typ... Remove this comment to see the full error message
    (attachment) => attachment?.__typename === 'PublishingPostFirstComment',
  )
  if (graphFirstComment) return graphFirstComment?.text
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const needsApproval = (post) => {
  if (isDraft(post) && post.needsApproval) return true
  return false
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const getLocation = (post) => {
  // RPC post logic
  if (post?.channel_data?.instagram?.service_geolocation_name) {
    return post?.channel_data?.instagram?.service_geolocation_name
  }

  // GraphQL post logic
  if (post?.channelData?.instagram?.service_geolocation_name) {
    return post?.channelData?.instagram?.service_geolocation_name
  }

  return ''
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const userIsPostOwner = (post, userId) => {
  if (!post || !userId) return false

  // RPC post logic
  if (post?.user?.id === userId) return true

  // GraphQL post logic
  if (post?.createdBy === userId) return true

  return false
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const hasText = (post) => {
  return !!post?.text
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const hasImage = (post) => {
  // RPC post logic
  if (post?.media?.picture) return true

  // GraphQL post logic
  if (
    Array.isArray(post?.media) &&
    // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
    post?.media?.some((item) => item?.type === 'image')
  )
    return true

  return false
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const hasVideo = (post) => {
  // RPC post logic
  if (post?.media?.video?.id) return true

  // GraphQL post logic
  if (
    Array.isArray(post?.media) &&
    // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
    post?.media?.some((item) => item.type === 'video')
  )
    return true

  return false
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const hasSourceUrl = (post) => {
  // eslint-disable-next-line camelcase
  return !!post?.source_url || !!post?.sourceUrl
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const getChannelId = (post) => {
  // RPC || GraphQL
  return post?.profileId || post?.channel?.id
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const getChannelName = (post) => {
  // RPC || GraphQL
  return post?.profile_service || post?.channel?.service
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const getChannel = (post) => {
  const channelName = getChannelName(post)
  if (channelName) return Services.get(channelName)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const validatePost = (post) => {
  const channel = getChannel(post)

  if (
    !hasImage(post) &&
    !hasVideo(post) &&
    channel?.requiredAttachmentType === AttachmentTypes.MEDIA
  ) {
    return PostValidationError.MISSING_IMAGE_OR_VIDEO
  }

  if (!hasSourceUrl(post) && !hasVideo(post) && channel?.requiresSourceUrl) {
    return PostValidationError.MISSING_SOURCE_URL
  }

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  if (!hasText(post) && channel.requiresText) {
    return PostValidationError.MISSING_TEXT
  }

  if (
    (channel?.name === 'googlebusiness' ||
      // @ts-expect-error TS(2339) FIXME: Property 'service' does not exist on type 'Service... Remove this comment to see the full error message
      channel?.service === 'googlebusiness') &&
    /**
     * TODO: It's a hotfix for GBP missing channelData when created through Zapier.
     * A proper fix is to add this on save for the API endpoint (on the backend)
     */
    post.channelData
  ) {
    const validationErrors = gbpChannelDataValidation.validateGBPChannelData(
      post.updateType,
      post.channelData?.googlebusiness,
    )
    if (validationErrors.length > 0) {
      return validationErrors[0].message
    }
  }

  return null
}

const isPublishingPost = (
  post?: RpcUpdate | PublishingPost,
): post is PublishingPost => {
  return !!post && '__typename' in post && post?.__typename === 'PublishingPost'
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const hasCampaign = (post) => {
  return !!(post?.campaign || post?.campaignDetails)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const getCampaign = (post) => {
  return post?.campaignDetails || post?.campaign
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const isCustomScheduled = (post) => {
  // RPC post logic
  if (post?.postDetails) return post.postDetails.isCustomScheduled

  // GraphQL post logic
  return post?.isCustomScheduled || !!post.scheduled_at
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const isInstagramReminder = (post) => {
  // RPC post logic
  if (post?.postDetails) return post?.postDetails?.isInstagramReminder

  // GraphQL post logic
  return (
    post?.isInstagramReminder ||
    (post.profile_service === 'instagram' && !post.can_send_direct)
  )
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const isReminder = (post) => {
  // RPC post logic
  return post?.isReminder
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const getFormatedCreatedAt = (post, { twentyFourHourTime, timezone }) => {
  const createdAt = post?.createdAt
  return getDateString(createdAt, timezone, {
    createdAt,
    twentyFourHourTime,
  })
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const getError = (post) => {
  if (post?.postDetails) {
    return post.postDetails.error
  }

  const status = post?.status
  if (status !== 'error') {
    return null
  }

  const error = post?.error
  const isObject = typeof error === 'object' && error !== null
  return isObject ? error.text || '' : error || ''
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const hasError = (post) => !!getError(post)

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const getErrorLink = (post) => {
  // RPC post logic
  if (post?.postDetails) return post.postDetails.errorLink

  // GraphQL post logic
  return post.status === 'error' && post.error && post.error.link
    ? post.error.link
    : null
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const getErrorLabel = (post) => {
  // RPC post logic
  if (post?.postDetails)
    return post.postDetails.errorLabel
      ? post.postDetails.errorLabel
      : 'Learn More'

  // GraphQL post logic
  return post.status === 'error' && post.error && post.error.link_text
    ? post.error.link_text
    : 'Learn More'
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const isFetchedPostFromService = (post, service: string): boolean => {
  if (!service || !post) {
    return false
  }
  return (
    getChannelName(post) === service &&
    // via is campitalized in postParser, but not when coming from gateway.
    post.via?.toLowerCase() === service
  )
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const isShareAgainActionSupported = (args: {
  channelName: string | undefined
  post: any
}): boolean => {
  const { channelName, post } = args
  if (!channelName) return false

  if (
    SERVICES_WITHOUT_SHARE_AGAIN_ACTION.includes(channelName) &&
    PostEntity.isFetchedPostFromService(post, channelName)
  ) {
    return false
  }

  return true
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const isPostChannelSupportedNewPostAnalytics = (args: {
  post: any
}): boolean => {
  const { post } = args
  const channelName = getChannelName(post)

  return SERVICES_WITHOUT_SHARE_AGAIN_ACTION.includes(channelName)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const hasServiceLink = (post: any): boolean => {
  return !!post?.serviceLink
}

const getPostAuthor = (post: RpcUpdate | PublishingPost): string => {
  let user = ''
  // RPC post logic
  if ('user' in post) user = post?.user?.name || ''
  // GraphQL post logic
  else if (post?.createdBy) user = post?.createdBy || ''
  return user
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const isRepin = (post): boolean => {
  // RPC check
  if (post.channel_data?.pinterest?.is_repin) {
    return post.channel_data?.pinterest?.is_repin
  }

  // Graphql check
  if (post.channelData?.pinterest?.is_repin) {
    return post.channelData?.pinterest?.is_repin
  }

  return false
}

export const PostEntity = {
  getId,
  isDraft,
  isSent,
  isPastReminder,
  isReminder,
  isPastDue,
  isPendingTiktok,
  isRetweet,
  isRepin,
  getScheduledAt,
  getDueAt,
  isScheduled,
  getFirstComment,
  needsApproval,
  userIsPostOwner,
  hasText,
  hasImage,
  hasVideo,
  hasSourceUrl,
  getChannelId,
  getChannelName,
  getChannel,
  validatePost,
  isPublishingPost,
  hasCampaign,
  getCampaign,
  isCustomScheduled,
  isInstagramReminder,
  getFormatedCreatedAt,
  getError,
  hasError,
  getErrorLink,
  getErrorLabel,
  isFetchedPostFromService,
  isShareAgainActionSupported,
  isPostChannelSupportedNewPostAnalytics,
  hasServiceLink,
  getPostAuthor,
  getLocation,
}
