import { format, toZonedTime } from 'date-fns-tz'

/**
 * Gets the current time zone of the system.
 * @returns {string} The current time zone.
 */
export const getCurrentTimeZone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

/**
 * Formats a time zone string by replacing underscores with spaces.
 * @param {string} timeZone - The time zone string to format.
 * @returns {string} The formatted time zone string.
 */
export const formatTimeZone = (timeZone: string): string => {
  return timeZone.replace('_', ' ')
}

/**
 * Options for formatting date and time.
 */
export type DateTimeFormatterOptions = {
  /** The time zone to use for formatting. */
  timeZone?: string
  /** The time format to use (12 or 24 hour). */
  timeFormat?: '24' | '12'
  /** The date format to use. */
  dateFormat?: string
  /** Whether to include the time zone in the output. */
  includeTimeZone?: boolean
}

/**
 * Default options for date and time formatting.
 */
const defaultOptions = {
  timeZone: getCurrentTimeZone(),
  timeFormat: '24',
  dateFormat: 'MMM d,',
  includeTimeZone: true,
  includeDate: true,
  includeTime: true,
}

/**
 * Formats a date and time string according to the provided options.
 * @param {string} date - The date string to format.
 * @param {DateTimeFormatterOptions & { includeDate?: boolean, includeTime?: boolean }} options - Formatting options.
 * @returns {string} The formatted date and time string.
 */
export const formatDateTime = (
  date: string,
  options: DateTimeFormatterOptions & {
    includeDate?: boolean
    includeTime?: boolean
  } = {},
): string => {
  const {
    timeZone,
    timeFormat,
    dateFormat,
    includeDate,
    includeTime,
    includeTimeZone,
  } = { ...defaultOptions, ...options }
  const _timeFormat = timeFormat === '24' ? 'HH:mm' : 'hh:mm a'
  let dateTimeFormat = `${dateFormat} ${_timeFormat}`
  if (!includeDate) {
    dateTimeFormat = _timeFormat
  }
  if (!includeTime) {
    dateTimeFormat = dateFormat
  }
  const processedDate = timeZone ? toZonedTime(date, timeZone) : date
  const formattedDate = format(processedDate, dateTimeFormat, {
    timeZone: timeZone ?? undefined,
  })
  if (!includeTimeZone) {
    return formattedDate
  }
  return `${formattedDate} (${formatTimeZone(timeZone)})`
}

/**
 * Formats a date string to show only the time.
 * @param {string} date - The date string to format.
 * @param {DateTimeFormatterOptions} options - Formatting options.
 * @returns {string} The formatted time string.
 */
export const formatTime = (
  date: string,
  options: DateTimeFormatterOptions = {},
): string => {
  return formatDateTime(date, {
    includeTimeZone: false,
    ...options,
    includeDate: false,
  })
}

/**
 * Formats a date string to show only the date.
 * @param {string} date - The date string to format.
 * @param {DateTimeFormatterOptions} options - Formatting options.
 * @returns {string} The formatted date string.
 */
export const formatDate = (
  date: string,
  options: DateTimeFormatterOptions = {},
): string => {
  return formatDateTime(date, { ...options, includeTime: false })
}
