import styled from 'styled-components'
import { grayDarker, grayLight, white } from '@bufferapp/ui/style/colors'

export const MediaThumbnailWrapper = styled.div<{
  size: 'extraSmall' | 'small' | 'medium' | 'large'
}>`
  position: relative;
  overflow: hidden;
  justify-self: flex-end;
  ${(props): string | false =>
    props.size !== 'extraSmall' &&
    `
      border: 1px solid ${grayLight};
      border-radius: 2px;
    `}
  ${(props): string | false =>
    props.size === 'small' &&
    `
      width: 38px;
      height: 38px;
    `}
  ${(props): string | false =>
    props.size === 'medium' &&
    `
      width: 78px;
      height: 78px;
    `}
  ${(props): string | false =>
    props.size === 'large' &&
    `
      width: 98px;
      height: 98px;
    `}
  ${(props): string | false =>
    props.size === 'extraSmall' &&
    `
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      width: 30px;
      height: 30px;
    `};
`

export const Media = styled.img<{
  size: 'extraSmall' | 'small' | 'medium' | 'large'
  hasMediaThumbnail: boolean
}>`
  filter: ${(props): string =>
    props.hasMediaThumbnail ? 'brightness(0.75)' : 'none'};
  object-fit: cover;
  ${(props): string | false =>
    props.size === 'extraSmall' &&
    `
      max-width: 30px;
    `}
  ${(props): string | false =>
    props.size === 'small' &&
    `
      max-width: 38px;
    `}
  ${(props): string | false =>
    props.size === 'medium' &&
    `
      max-width: 80px;
    `}
`

export const MediaIconWrapper = styled.div<{
  size: 'extraSmall' | 'small' | 'medium' | 'large'
}>`
  position: absolute;
  height: 16px;
  bottom: 4px;
  left: 4px;
  ${(props): string | false =>
    props.size === 'extraSmall' &&
    `
      svg {
        height: 12px;
        width: 12px;
      }
    `}
  ${(props): string | false =>
    props.size === 'small' &&
    `
      svg {
        height: 14px;
        width: 14px;
      }
    `}
  ${(props): string | false =>
    props.size === 'large' &&
    `
      bottom: 6px;
      left: 6px;
      border-radius: 50%;
      background-color: ${grayDarker};
      fill: ${white};
      background-color: rgba(99, 99, 99, 0.62);
      display: block;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 12px;
        width: 12px;
      }
    `}
`

export const TagWrapper = styled.div`
  position: absolute;
  left: 5px;
  bottom: 5px;
  font-size: 14px;
  height: 16px;
  border-radius: 5px;
  background-color: ${grayDarker};
  color: ${white};
  display: block;
  height: 18px;
  width: 56px;
  line-height: 18px;
  text-align: center;
`
