import clsx from 'clsx'
import React from 'react'
import {
  NavLink,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom'
import {
  AllChannelsIcon,
  IconButton,
  PlusIcon,
  VisuallyHidden,
} from '@buffer-mono/popcorn'
import { useSplitEnabled } from '@buffer-mono/features'

import { useOrganizationId } from '~publish/legacy/accountContext'
import { formatCompactNumber } from '~publish/helpers/numberFormatters'
import { usePostCounts } from '~publish/pages/AllChannels/PostList/usePostCounts'
import {
  allChannels,
  newCalendarAllChannels,
  channel,
} from '~publish/legacy/routes'
import { AllChannelsCoachmark } from '~publish/pages/AllChannels/AllChannelsCoachmark'
import {
  parseQueryParams,
  type QueryParamValue,
  serializeQueryParams,
} from '~publish/hooks/useQueryParam'

import { NewPostComposerTrigger } from '../NewPostComposerTrigger'
import { useChannels } from './useChannels'
import { SideNavItem } from './SideNavItem'
import { SidebarEducationPopover } from './SidebarEducationPopover'

import styles from './PageSidebar.module.css'

export function AllChannelsItem(): JSX.Element | null {
  const organizationId = useOrganizationId() ?? ''
  const { channels } = useChannels()
  const postCounts = usePostCounts({
    organizationId,
  })
  const isChannelPage = !!useRouteMatch(channel.route)
  const { isEnabled: isChannelsTourFeatureEnabled } =
    useSplitEnabled('channels-tour')
  const url = useAllChannelsPageRoute({ keepSearch: true })

  if (channels.length < 1) {
    return null
  }

  const postQueueCounts = postCounts.counts?.queue

  return (
    <SidebarEducationPopover>
      <SideNavItem className={styles.allChannelsItem}>
        <NavLink
          to={url}
          className={(isActive: boolean): string =>
            clsx(isActive && styles.selected)
          }
        >
          {isChannelsTourFeatureEnabled && !isChannelPage && (
            <AllChannelsCoachmark />
          )}
          <div className={styles.icon}>
            <AllChannelsIcon size="small" />
          </div>
          All Channels
          <div className={styles.itemCount}>
            {formatCompactNumber(postQueueCounts)}
            <VisuallyHidden> posts</VisuallyHidden>
          </div>
          <NewPostComposerTrigger
            channels={channels.map((channel) => channel.id)}
          >
            <IconButton
              label="New Post"
              variant="secondary"
              tooltip="New post"
              size="small"
              className={styles.newPostButton}
            >
              <PlusIcon />
            </IconButton>
          </NewPostComposerTrigger>
        </NavLink>
      </SideNavItem>
    </SidebarEducationPopover>
  )
}

function useAllChannelsPageRoute({ keepSearch = false } = {}): string {
  const { granularity } = useParams<{ granularity: 'week' | 'month' }>()
  const currentSearch = parseQueryParams(useLocation().search)
  const currentRoute = granularity ? newCalendarAllChannels : allChannels

  const search: Record<string, QueryParamValue> = {}
  for (const param of currentRoute.queryParams) {
    const value = currentSearch[param]
    if (value) {
      search[param] = value
    }
  }

  const newRoute =
    currentRoute.buildPathname({ granularity }) +
    (keepSearch ? `?${serializeQueryParams(search)}` : '')

  return newRoute
}
