import ComposerActionCreators from '~publish/legacy/composer/composer/action-creators/ComposerActionCreators'
import type { PostFields } from '@buffer-mono/reminders-config'
import type { Draft } from '~publish/legacy/composer/composer/entities/Draft'
import {
  getFieldsToDisplay,
  getStickersToDisplay,
} from '~publish/legacy/reminders/components/new-reminders/components/channel-fields/helpers'
import { BufferTracker, Client } from '@bufferapp/buffer-tracking-browser-ts'
import { useAppSelector } from '~publish/legacy/store'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'

const useStickers = (
  draft: Draft,
): {
  stickersToDisplay: PostFields[]
  fieldsToDisplay: PostFields[]
  handleStickerChange: (stickerName: PostFields, isChecked: boolean) => void
} => {
  const organizationId = useAppSelector((state) =>
    selectCurrentOrganizationId(state),
  )

  const handleStickerChange = (
    stickerName: PostFields,
    isChecked: boolean,
  ): void => {
    const currentStickers = [...draft.selectedStickers]
    ComposerActionCreators.updateDraftSelectedStickers(
      draft.id,
      isChecked
        ? [
            stickerName,
            ...currentStickers.filter((sticker) => sticker !== stickerName),
          ]
        : [...currentStickers.filter((sticker) => sticker !== stickerName)],
    )

    const commonTrackingProperties = {
      organizationId,
      channel: draft.service.name,
      clientName: Client.PublishWeb,
      sticker: stickerName,
      updateType: draft.updateType ?? 'post',
    }

    if (isChecked) {
      ComposerActionCreators.updateDraftIsReminder(draft.id, true)
      BufferTracker.stickerSelected(commonTrackingProperties)
    } else {
      BufferTracker.stickerDeselected(commonTrackingProperties)
    }
  }

  const stickersToDisplay = getStickersToDisplay({
    service: draft.id,
    updateType: draft.updateType ?? 'post',
  })
  const fieldsToDisplay = getFieldsToDisplay({
    draft,
  })

  return {
    stickersToDisplay,
    fieldsToDisplay,
    handleStickerChange,
  }
}

export default useStickers
