import { useCallback } from 'react'
import { type MutationResult, useMutation } from '@apollo/client'
import { useAccount } from '~publish/legacy/accountContext'
import type { IdeaContent } from '~publish/pages/Create/types'
import { QUERY_IDEAS_COUNT } from './useIdeasCount'
import { UNASSIGNED_GROUP_ID } from '../components/Board/helpers/helpers'
import type { CreateIdeaInput, CreateIdeaMutation } from '~publish/gql/graphql'
import { graphql } from '~publish/gql'

const CREATE_IDEA = graphql(/* GraphQL */ `
  mutation CreateIdea($input: CreateIdeaInput!) {
    createIdea(input: $input) {
      __typename
      ... on Idea {
        id
        content {
          title
          text
          aiAssisted
          media {
            id
            url
            alt
            thumbnailUrl
            type
            size
            source {
              name
              id
            }
          }
          tags {
            id
            name
            color
          }
        }
        organizationId
        groupId
        createdAt
      }
      ... on MutationError {
        message
      }
    }
  }
`)

type CreateIdeaInputArgs = {
  content: IdeaContent
  groupId?: string
}

type CreateIdeaOptions = {
  source?: string
  group?: {
    groupId: string | null
    placeAfterId?: string
  }
}

export function useCreateIdea(): {
  createIdea: (idea: CreateIdeaInputArgs, options: CreateIdeaOptions) => void
  createIdeaData: MutationResult<CreateIdeaMutation>['data']
  createIdeaError: MutationResult<CreateIdeaMutation>['error']
  createIdeaLoading: MutationResult<CreateIdeaMutation>['loading']
} {
  // TODO: Disable this lint rule until we type the Account domain
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const accountContext = useAccount() as any
  const account = accountContext?.account
  const organizationId = account?.currentOrganization?.id

  const [
    executeCreateIdea,
    {
      data: createIdeaData,
      loading: createIdeaLoading,
      error: createIdeaError,
    },
  ] = useMutation(CREATE_IDEA, {
    refetchQueries: [QUERY_IDEAS_COUNT, 'GetIdeasBoard', 'GetIdeasGallery'],
  })

  const createIdea = useCallback(
    (idea: CreateIdeaInputArgs, { group, source }: CreateIdeaOptions) => {
      if (organizationId) {
        executeCreateIdea({
          variables: {
            input: {
              organizationId,
              group: group && {
                groupId:
                  group?.groupId === UNASSIGNED_GROUP_ID
                    ? null
                    : group?.groupId,
                placeAfterId: group?.placeAfterId,
              },
              content: idea.content as CreateIdeaInput['content'],
              cta: source ?? 'create-webapp-createIdea-default-1',
            },
          },
        })
      }
    },
    [executeCreateIdea, organizationId],
  )

  return { createIdea, createIdeaData, createIdeaError, createIdeaLoading }
}
