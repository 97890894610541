import {
  Button,
  CheckIcon,
  Heading,
  Image,
  Paragraph,
  Text,
  UpgradeDialog,
} from '@buffer-mono/popcorn'
import { BufferTracker, Client } from '@bufferapp/buffer-tracking-browser-ts'
import React, { useEffect, useState } from 'react'

import { useOrganizationId } from '~publish/legacy/accountContext'

import { navigateToMigrationHub } from '~publish/legacy/upgrade-paths/helpersUpgradePaths'
import { useNotesCtaView } from '../NotesWidget'
import styles from './NotesPaywall.module.css'

type CtaOptions = {
  location: string
  button: string
}

type NotesPaywallProps = {
  ctaProperties: CtaOptions
  children: React.ReactNode
}

export function NotesLegacyPaywall({
  children,
  ctaProperties,
}: NotesPaywallProps): JSX.Element {
  const currentOrganizationId = useOrganizationId()
  const [open, setOpen] = useState(false)

  const view = useNotesCtaView()

  const { location, button } = ctaProperties
  const cta = `publish-${view}-${location}-${button}-1` as const

  const upgradePathName = 'notes-migrate'

  const NOTES_PAYWALL_DATA = {
    heading: 'Unlock Notes',
    body: "You're on a legacy Buffer plan. Switch over to the latest version of Buffer to unlock Notes to collaborate with your team, plus lots more!",
    bulletPoints: [
      "No charge for channels you don't use",
      'Unlimited team members (on our team plan)',
      'Free access to Analytics on all plans',
      'Access to channels like YouTube Shorts, Bluesky and Threads',
      'Access to all new features, guaranteed',
    ],
    ctaText: 'Upgrade now',
  }

  const onCTAClick = (): void => {
    navigateToMigrationHub('notes-legacy-paywall')
  }

  useEffect(() => {
    BufferTracker.ctaViewed({
      product: 'publish',
      cta,
      upgradePathName,
      organizationId: currentOrganizationId ?? '',
    })
  }, [cta, currentOrganizationId, upgradePathName])

  const handleOpen = (isOpen: boolean): void => {
    if (isOpen) {
      BufferTracker.ctaClicked({
        clientName: Client.PublishWeb,
        product: 'publish',
        cta,
        upgradePathName,
        organizationId: currentOrganizationId ?? '',
      })
      BufferTracker.upgradePathViewed({
        clientName: Client.PublishWeb,
        product: 'publish',
        organizationId: currentOrganizationId ?? '',
        upgradePathName,
      })
    }
    setOpen(isOpen)
  }

  return (
    <UpgradeDialog open={open} onOpenChange={handleOpen}>
      <UpgradeDialog.Trigger>{children}</UpgradeDialog.Trigger>
      <UpgradeDialog.Content size="xlarge">
        <UpgradeDialog.TextContent>
          <Heading size="medium">Unlock Notes</Heading>
          <Text>{NOTES_PAYWALL_DATA.body}</Text>
          <ul className={styles.list}>
            {NOTES_PAYWALL_DATA.bulletPoints.map((item, index) => {
              return (
                <li key={`notes-paywall-details-${index}`}>
                  <CheckIcon size="small" color="brand" aria-hidden />
                  <Paragraph>{item}</Paragraph>
                </li>
              )
            })}
          </ul>
          <Button variant="primary" size="large" onClick={onCTAClick}>
            {NOTES_PAYWALL_DATA.ctaText}
          </Button>
        </UpgradeDialog.TextContent>
        <UpgradeDialog.Image>
          <Image
            src="https://buffer-publish.s3.amazonaws.com/images/notes-paywall-x2.png"
            alt="upgrade to use notes illustration"
            placeholder="https://buffer-publish.s3.amazonaws.com/images/notes-paywall-x1.png"
          />
        </UpgradeDialog.Image>
      </UpgradeDialog.Content>
    </UpgradeDialog>
  )
}
