import styled from 'styled-components'
import { fontWeight, fontFamily } from '@bufferapp/ui/style/fonts'

const Input = styled.input`
  padding: 8px;
  margin-left: 4px;
  border-radius: 4px;
  border: 1px solid #e6ebef;
  box-sizing: border-box;
  font-weight: ${fontWeight};
  font-family: ${fontFamily};
  font-size: 12px;
  width: 100%;
  outline: none;
`

export default Input
