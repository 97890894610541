import React from 'react'

interface RegisteredNoticesContextType {
  notices: string[]
  registerNotice: (newNotice: string) => void
  unregisterNotice: (notice: string) => void
}

/**
 * RegisteredNoticesContext provides a context for managing and rendering notices based on their registration
 * and rendering logic.
 *
 * This context allows components to register and track active notices.
 * Every notice that is registered is visible.
 */
export const RegisteredNoticesContext = React.createContext<
  RegisteredNoticesContextType | undefined
>(undefined)

export function RegisteredNoticesProvider({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  const [notices, setNotices] = React.useState<string[]>([])

  // Register a new notice
  const registerNotice = React.useCallback((newNotice: string): void => {
    setNotices((currentNotices) => {
      // If the notice is not registered, add it to the list
      if (!currentNotices.includes(newNotice)) {
        return [...currentNotices, newNotice]
      }
      return currentNotices
    })
  }, [])

  const unregisterNotice = React.useCallback((notice: string): void => {
    setNotices((currentNotices) => {
      return currentNotices.filter((currentNotice) => currentNotice !== notice)
    })
  }, [])

  return (
    <RegisteredNoticesContext.Provider
      value={{ notices, registerNotice, unregisterNotice }}
    >
      {children}
    </RegisteredNoticesContext.Provider>
  )
}

export function useRegisteredNoticesContext(): RegisteredNoticesContextType {
  const context = React.useContext(RegisteredNoticesContext)
  if (context === undefined) {
    throw new Error(
      'useRegisteredNoticesContext must be used within a RegisteredNoticesProvider',
    )
  }
  return context
}

export const useHasVisibleNotices = (): boolean => {
  const context = React.useContext(RegisteredNoticesContext)
  if (!context) return false

  return context.notices.length > 0
}

/**
 * RegisteredNotice is a React component that registers a notice with a given ID if the rendering condition is met.
 * This notice is then considered 'active' in the context and can be used to inform other components of its presence.
 *
 * @param {string} id - Unique identifier for the notice to register.
 * @param {boolean} canRender - A boolean value indicating whether the notice is allowed to render.
 * @param {React.ReactNode} children - The content (children) to render when the notice is active.
 *
 * @example
 * const isFeatureEnabled = useSplit('some-feature')
 * const notice = useDismissableBanner('new-notice')
 * const hasTwitterChannel = useHasChannelConnected('twitter')
 * const canRender = isFeatureEnabled && banner.isActive && !hasTwitterChannel
 *
 * <RegisteredNotice id="my-notice" canRender={canRender}>
 *  <MyNoticeComponent />
 * </RegisteredNotice>
 */
export const RegisteredNotice = ({
  id,
  canRender,
  children,
}: {
  id: string
  canRender: boolean
  children: React.ReactNode
}): JSX.Element | null => {
  const { registerNotice, unregisterNotice } = useRegisteredNoticesContext()

  React.useLayoutEffect(() => {
    if (canRender) {
      // If notice is not registered and can render, register it
      registerNotice(id)
    } else {
      unregisterNotice(id)
    }
  }, [id, canRender, registerNotice, unregisterNotice])

  if (!canRender) return null

  return <React.Fragment key={id}>{children}</React.Fragment>
}
