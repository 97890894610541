import {
  useAccount,
  useDismissedBanners,
  useHasEntitlement,
} from '~publish/legacy/accountContext'
import { BannerTypes } from '~publish/legacy/profile-page/hooks/useDismissBanner'

type ProfileData = {
  service: string
  service_type: string
  hasPushNotifications: boolean
  isTwitterPremium?: boolean
}

/*
 * This hook is used to determine which banners should be shown in the queue
 * It takes in an array of banners and returns an array of booleans
 * The booleans are used to determine whether or not to show the banner
 * The order of the booleans in the array corresponds to the order of the banners in the array
 */
export const useBannersInQueue = (
  banners: string[],
  isProfileDisconnected: boolean,
  profileData?: ProfileData,
): {
  showBanners: boolean[]
} => {
  const account = useAccount()
  const dismissedBanners = useDismissedBanners()

  /* IS ONE BUFFER */
  const isOneBufferOrg =
    account?.account?.currentOrganization?.isOneBufferOrganization

  const isOneBufferWithoutTwitterPremium =
    isOneBufferOrg && !profileData?.isTwitterPremium

  const hasAccessTeamPanel = useHasEntitlement('teamsPanel')

  const profileServiceName = profileData?.service

  const shouldShowRemindersSetupBanner = (): boolean =>
    profileServiceName === 'instagram' &&
    !isProfileDisconnected &&
    !profileData?.hasPushNotifications

  const shouldShowTwitterThreadsPromoBanner = (): boolean =>
    profileServiceName === 'twitter' && !isProfileDisconnected

  const shouldShowCollaborationUpgradePathBanner = (): boolean =>
    isOneBufferOrg && !hasAccessTeamPanel

  const shouldShowThreadsByMetaPromoBanner = (): boolean =>
    profileServiceName === 'twitter' ||
    profileServiceName === 'instagram' ||
    profileServiceName === 'facebook' ||
    profileServiceName === 'mastodon'

  const shouldShowBlueskyPromoBanner = (): boolean =>
    profileServiceName === 'twitter' ||
    profileServiceName === 'linkedin' ||
    profileServiceName === 'threads' ||
    profileServiceName === 'mastodon'

  const shouldShowTwitterPremiumBanner = (): boolean =>
    profileServiceName === 'twitter' &&
    (!isOneBufferOrg || isOneBufferWithoutTwitterPremium)

  const showBanners = banners.map((banner) => {
    let showBanner = false

    /* BANNER DISMISSED */
    const alreadyDismissed =
      dismissedBanners && dismissedBanners.includes(banner)

    /* CRITERIA TO SHOW THE BANNER */
    switch (banner) {
      case BannerTypes.remindersSetup:
        showBanner = shouldShowRemindersSetupBanner()
        break
      case BannerTypes.twitterThreadsPromo:
        showBanner = shouldShowTwitterThreadsPromoBanner()
        break
      case BannerTypes.collaborationUpgradePath:
        showBanner = shouldShowCollaborationUpgradePathBanner()
        break
      case BannerTypes.threadsByMetaPromo:
        showBanner = shouldShowThreadsByMetaPromoBanner()
        break
      case BannerTypes.blueskyPromo:
        showBanner = shouldShowBlueskyPromoBanner()
        break
      case BannerTypes.twitterPremium:
        showBanner = shouldShowTwitterPremiumBanner()
    }

    return !alreadyDismissed && showBanner
  })

  return {
    showBanners,
  }
}
