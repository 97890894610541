import RPCClient from '~publish/legacy/composer/composer/utils/RPCClient'
import type { Callback, TestNotificationResult } from './types'

export const openLinkGooglePlay = () => {
  window.open(
    'https://play.google.com/store/apps/details?id=org.buffer.android',
    '_blank',
  )
}

export const openLinkAppStore = () => {
  window.open('https://apps.apple.com/app/apple-store/id490474324', '_blank')
}

export const sendTestNotification = (
  setIsLoading: Callback,
  setIsSent: Callback,
): Promise<TestNotificationResult> => {
  setIsLoading(true)
  setIsSent(false)

  return new Promise((resolve) => {
    RPCClient.call('composerApiProxy', {
      url: '/1/user/send_test_notification.json',
      args: {},
    })
      // @ts-expect-error TS(7006) FIXME: Parameter 'res' implicitly has an 'any' type.
      .then((res) => {
        setIsLoading(false)
        setIsSent(true)
        resolve(res)
      })
      .catch(() => {
        setIsLoading(false)
        setIsSent(true)
        resolve({ success: false })
      })
  })
}

export const getSendProcessLabel = (
  originalLabel: string,
  isLoading: boolean,
  sent: boolean,
  t: (key: string) => string,
): string | undefined => {
  if (isLoading) {
    return t('remindersSetupWizard.stepTwo.buttonLabelSending')
  }
  if (sent) {
    return t('remindersSetupWizard.stepTwo.buttonLabelSent')
  }
  return originalLabel
}
