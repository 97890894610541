import { connect } from 'react-redux'

import { getEnvironment } from '~publish/legacy/store/selectors'
import { actions as dataFetchActions } from '@bufferapp/async-data-fetch'
import { SEGMENT_NAMES } from '~publish/legacy/constants'
import { getURL } from '~publish/legacy/utils/formatters'
import type { RootState } from '~publish/legacy/store'

import { actions } from './reducer'
import { actions as composerPopoverActions } from '~publish/legacy/composer-popover'
import DraftList from './components/DraftList'
import { formatPostLists } from './helpers'

export default connect(
  (state: RootState, ownProps) => {
    // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
    const { profileId, tabId } = ownProps
    // @ts-expect-error TS(2339) FIXME: Property 'drafts' does not exist on type 'DefaultR... Remove this comment to see the full error message
    const currentProfile = state.drafts.byProfileId[profileId]
    if (currentProfile) {
      const { isDisconnected, isManager } =
        state.profileSidebar.selectedProfile ?? {}
      const {
        hasFirstCommentFeature,
        showStartBusinessTrialCta,
        hasApprovalFeature,
        hasCampaignsFeature,
      } = state.organizations.selected || {}
      return {
        // @ts-expect-error TS(2339) FIXME: Property 'drafts' does not exist on type 'DefaultR... Remove this comment to see the full error message
        shouldResetComposerData: state.drafts.shouldResetComposerData,
        manager: isManager,
        drafts: currentProfile.drafts,
        postLists: formatPostLists(
          state.profileSidebar.selectedProfile,
          currentProfile.drafts,
          state.user,
          tabId,
          hasApprovalFeature,
        ),
        loading: currentProfile.loading,
        loadingMore: currentProfile.loadingMore,
        moreToLoad: currentProfile.moreToLoad,
        page: currentProfile.page,
        total: currentProfile.total,
        editMode: state.composerPopover.editMode,
        environment: getEnvironment(state),
        isDisconnectedProfile: isDisconnected,
        showStartBusinessTrialCta,
        hasFirstCommentFlip: hasFirstCommentFeature,
        hasCampaignsFeature,
        // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
        profileId: ownProps.profileId,
        trackingCta:
          tabId === 'drafts'
            ? SEGMENT_NAMES.DRAFTS_PAYWALL_UPGRADE
            : SEGMENT_NAMES.AWAITING_APPROVAL_PAYWALL_UPGRADE,
      }
    }
    return {}
  },
  (dispatch, ownProps) => ({
    // @ts-expect-error TS(7031) FIXME: Binding element 'needsApproval' implicitly has an ... Remove this comment to see the full error message
    fetchDrafts: ({ needsApproval }) => {
      dispatch(
        dataFetchActions.fetch({
          name: 'draftPosts',
          args: {
            // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
            profileId: ownProps.profileId,
            isFetchingMore: false,
            needsApproval,
            clear: true,
          },
        }),
      )
    },
    // @ts-expect-error TS(7006) FIXME: Parameter 'draft' implicitly has an 'any' type.
    onApproveClick: (draft) => {
      const draftApproved = draft.draft
      dispatch(
        actions.handleApproveClick({
          draft: draftApproved,
        }),
      )
    },
    // @ts-expect-error TS(7006) FIXME: Parameter 'draft' implicitly has an 'any' type.
    onRequestApprovalClick: (draft) => {
      dispatch(
        actions.handleRequestApprovalClick({
          draft: draft.draft,
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
          profileId: ownProps.profileId,
          needsApproval: true,
        }),
      )
    },
    // @ts-expect-error TS(7006) FIXME: Parameter 'draft' implicitly has an 'any' type.
    onRevertApprovalClick: (draft) => {
      dispatch(
        actions.handleRequestApprovalClick({
          draft: draft.draft,
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
          profileId: ownProps.profileId,
          needsApproval: false,
        }),
      )
    },
    onRescheduleClick: ({ postId }: { postId: string }): void => {
      dispatch(
        // @ts-expect-error TS(2345) FIXME: Argument of type '(dispatch: Dispatch) => Promise<... Remove this comment to see the full error message
        composerPopoverActions.handleEditClick(postId, { draftMode: true }),
      )
    },
    onEditClick: ({ postId }: { postId: string }): void => {
      dispatch(
        // @ts-expect-error TS(2345) FIXME: Argument of type '(dispatch: Dispatch) => Promise<... Remove this comment to see the full error message
        composerPopoverActions.handleEditClick(postId, { draftMode: true }),
      )
    },
    // @ts-expect-error TS(7031) FIXME: Binding element 'post' implicitly has an 'any' typ... Remove this comment to see the full error message
    onDeleteConfirmClick: ({ post }) => {
      dispatch(
        actions.handleDeleteConfirmClick({
          draft: post,
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
          profileId: ownProps.profileId,
        }),
      )
    },
    onComposerPlaceholderClick: () => {
      dispatch(
        composerPopoverActions.openComposer({
          cta: SEGMENT_NAMES.CREATE_A_DRAFT,
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
          selectedProfileId: ownProps.profileId,
          draftMode: true,
        }),
      )
    },
    onStartTrialClick: () => {
      window.location.assign(
        `${getURL.getStartTrialURL({
          trialType: 'small',
          cta: SEGMENT_NAMES.DRAFTS_SBP_TRIAL,
          nextUrl: 'https://publish.buffer.com',
        })}`,
      )
    },
  }),
)(DraftList)

// export reducer, actions and action types
export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
