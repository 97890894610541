/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'
import { toast } from '@buffer-mono/popcorn'

import { useAccount } from '~publish/legacy/accountContext'
import { SlateJsProxy } from '~publish/legacy/composer/composer/entities/EditorStateProxy'
import type { Idea, IdeaContent, NewIdea } from '~publish/pages/Create/types'
import { isExistingIdea } from '~publish/pages/Create/helpers'
import { getPlainText } from '~publish/legacy/editor/BufferEditor'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { selectIncludedMedia } from '~publish/legacy/uploads/state/selectors'
import {
  selectIdeaById,
  setCurrentIdeaId,
  resetCurrentUnsavedAiStatus,
} from '~publish/pages/Create/state/ideasSlice'
import { createIdeasEditor } from '~publish/legacy/editor/IdeasEditor'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import { preloadMedia } from '~publish/legacy/uploads/state/thunks/preloadMedia'
import { IDEAS_UPLOADER_ID } from '../components/config'
import { useCreateIdea } from './useCreateIdea'
import { useEditIdea } from './useEditIdea'
import type { SelectedTag } from '~publish/legacy/campaign/types'
import type { MutationResult } from '@apollo/client'
import type {
  CreateIdeaMutation,
  UpdateIdeaMutation,
} from '~publish/gql/graphql'
import { logError } from '~publish/legacy/utils/logError'

const DEFAULT_ERROR_MESSAGE =
  'We run into an error saving your idea. Please try again.'

const emptyIdea: NewIdea = {
  content: {
    text: '',
  },
}

export type OpenEditorOptions =
  | string
  | {
      cta?: string
      source?: string
      placeAfterId?: string
    }

type useIdeaEditorReturn = {
  isOpen: boolean
  isEditMode: boolean
  onOpenChange: (open: boolean) => void
  openEditor: (idea?: NewIdea, opt?: OpenEditorOptions) => void
  closeEditor: () => void
  currentIdea?: NewIdea
  saveIdea: (source?: string) => void
  createIdeaData: MutationResult<CreateIdeaMutation>['data']
  updateIdeaData: MutationResult<UpdateIdeaMutation>['data']
  getCurrentContent: () => Idea | NewIdea | undefined
  errorCode:
    | ''
    | 'InvalidInputError'
    | 'UnauthorizedError'
    | 'UnexpectedError'
    | 'LimitReachedError'
    | 'unknown'
  errorMessage: string | null
  plateEditor: BufferEditor
  setSelectedTags: (tags: SelectedTag[]) => void
  tags: SelectedTag[]
  title: string | undefined
  groupId: string | undefined
  setTitle: (title: string | undefined) => void
  setGroupId: (groupId: string | undefined) => void
}

/**
 * Hook responsible rendering and managing external state of Ideas editor.
 * Includes:
 * - open state
 * - storing the current idea
 * - setting the intitial state of the editor
 * - saving ideas by serializing internal editor values to content
 */
export const useIdeasEditor = (): useIdeaEditorReturn => {
  const [isOpen, setIsOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [currentIdea, setCurrentIdea] = useState<NewIdea | undefined>(undefined)
  const [tags, setTags] = useState<SelectedTag[]>([])
  const [groupId, _setGroupId] = useState<string | undefined>(undefined)
  const [placeAfterId, setPlaceAfterId] = useState<string | undefined>(
    undefined,
  )
  const [title, setTitle] = useState<string | undefined>('')
  const dispatch = useAppDispatch()
  const media = useAppSelector((state) =>
    selectIncludedMedia(state, IDEAS_UPLOADER_ID),
  )
  const [source, setSource] = useState<string | undefined>(undefined)

  const setGroupId = useCallback(
    (groupId: string | undefined) => {
      // we want to unset the placeAfterId if the groupId changes
      _setGroupId(groupId)
      setPlaceAfterId(undefined)
    },
    [_setGroupId, setPlaceAfterId],
  )

  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [errorCode, setErrorCode] =
    useState<useIdeaEditorReturn['errorCode']>('')

  // Idea hooks
  const { createIdea, createIdeaData, createIdeaError, createIdeaLoading } =
    useCreateIdea()
  const { updateIdea, updateIdeaData, updateIdeaError, updateIdeaLoading } =
    useEditIdea()

  // Plate hooks
  const plateEditor = useMemo(() => {
    return SlateJsProxy.createStateFromText(
      createIdeasEditor(),
      currentIdea?.content?.text || '',
    )
  }, [currentIdea])

  // Get organizationID for Tracking purposes
  const accountContext = useAccount()
  const account = accountContext?.account
  const organizationId = account?.currentOrganization?.id

  const openEditor = useCallback(
    (idea: NewIdea = emptyIdea, opts: OpenEditorOptions = {}) => {
      if (typeof opts === 'string') {
        opts = { source: opts }
      } else if (!opts?.source) {
        opts = {
          ...opts,
          source: 'create-webapp-openEditor-default-1',
        }
      }
      setIsOpen(true)
      setCurrentIdea(idea)
      setSource(opts?.source)
      // Used to store/track whether the current idea was
      // AI assisted. We set the current idea id to know the exact
      // idea to target if a user inserts AI generated content.
      dispatch(setCurrentIdeaId({ id: idea.id }))
      setTags(idea.content?.tags || [])
      _setGroupId(idea.groupId)
      setPlaceAfterId(opts?.placeAfterId)
      setTitle(idea.content?.title)
      dispatch(
        preloadMedia({
          media: idea.content?.media || [],
          uploaderId: IDEAS_UPLOADER_ID,
        }),
      )

      if (organizationId) {
        BufferTracker.ideaComposerOpened({
          clientName: 'publishWeb',
          organizationId,
          ideaId: idea.id,
          source: opts?.source,
          cta: opts?.cta || opts?.source,
        })
      }
      if (idea.id) {
        setIsEditMode(true)
      } else {
        setIsEditMode(false)
      }
    },
    [],
  )

  const resetErrorsOnClose = (): void => {
    setErrorMessage(null)
    setErrorCode('')
  }

  const closeEditor = useCallback(() => {
    setErrorMessage(null)
    setIsOpen(false)
    setCurrentIdea(undefined)
    resetErrorsOnClose()
    dispatch(resetCurrentUnsavedAiStatus({}))
  }, [])

  // This is meant specifically for the Radix Dialog Root component
  const onOpenChange = useCallback(
    (open: boolean) => {
      if (open)
        openEditor(undefined, {
          source: 'create-webapp-onOpenChange-default-1',
        })
      else closeEditor()
    },
    [openEditor, closeEditor],
  )

  const resetErrorMessage = useCallback(() => {
    setTimeout(() => {
      setErrorMessage(null)
      setErrorCode('')
    }, 5000)
  }, [])

  // Update error message if create is unsuccessful or else close the editor
  useEffect(() => {
    if (createIdeaError) {
      logError(createIdeaError, { metaData: { organizationId } })
      setErrorMessage(DEFAULT_ERROR_MESSAGE)
      resetErrorMessage()
    } else if (
      createIdeaData &&
      createIdeaData?.createIdea.__typename !== 'Idea' &&
      createIdeaData?.createIdea.__typename !== 'IdeaResponse'
    ) {
      setErrorMessage(createIdeaData.createIdea?.message)
      setErrorCode(createIdeaData?.createIdea.__typename || 'unknown')
      resetErrorMessage()
    } else if (createIdeaData) {
      setErrorMessage(null)
      closeEditor()
      setSource(undefined)
      toast.success('Idea created successfully')
    }
  }, [createIdeaLoading])

  // Update error Message if Update is unsuccessful or else close the editor
  useEffect(() => {
    if (updateIdeaError) {
      logError(updateIdeaError, { metaData: { organizationId } })
      setErrorMessage(DEFAULT_ERROR_MESSAGE)
      resetErrorMessage()
    } else if (
      updateIdeaData?.updateIdea.__typename !== 'IdeaResponse' &&
      updateIdeaData?.updateIdea?.message !== undefined
    ) {
      setErrorMessage(updateIdeaData?.updateIdea?.message)
      resetErrorMessage()
    } else if (updateIdeaData) {
      setErrorMessage(null)
      closeEditor()
      toast.success('Idea updated successfully')
    }
  }, [updateIdeaLoading])

  // Idea loaded from database
  const previouslyAiAssisted = currentIdea?.content?.aiAssisted

  // "Live" idea from store that might have unsaved pending changes
  // for example: user adds AI content to initial idea that was not
  // originally ai assisted.
  const ideaBeingEdited = useAppSelector((state) =>
    selectIdeaById(state, currentIdea?.id || ''),
  )

  // Check whether:
  // 1. user added AI content just now while editing (ideaBeingEdited)
  // 2. idea from database was ai assisted (previouslyAiAssisted)
  // 3. if idea that hasn't been saved yet is ai assisted (currentUnsavedContentIsAiAssisted)
  const currentUnsavedContentIsAiAssisted = useAppSelector(
    (state) => state.ideas.currentUnsavedContentIsAiAssisted,
  )
  const aiAssisted =
    ideaBeingEdited?.content?.aiAssisted ||
    previouslyAiAssisted ||
    currentUnsavedContentIsAiAssisted

  const saveIdea = useCallback(
    (_source?: string) => {
      // Serialize the editor's state into plain text
      const text = getPlainText(plateEditor)
      const content: IdeaContent = { text, title, media, tags, aiAssisted }

      // If the current idea is an existing idea, update it
      // otherwise create a new idea
      if (currentIdea && isExistingIdea(currentIdea)) {
        updateIdea(
          { id: currentIdea.id, groupId, content },
          { source: _source ?? source },
        )
      } else {
        const group = groupId ? { groupId, placeAfterId } : undefined
        createIdea({ content }, { source: _source ?? source, group })
        setSource(undefined)
      }
    },
    [plateEditor, currentIdea, media, tags, aiAssisted, groupId, title, source],
  )

  const getCurrentContent = useCallback(() => {
    const text = getPlainText(plateEditor)
    const content: IdeaContent = { text, media, title, tags, aiAssisted }

    const idea: NewIdea = { content, groupId }
    if (currentIdea && isExistingIdea(currentIdea)) {
      idea.id = currentIdea.id
      return idea as Idea
    }
    return idea
  }, [plateEditor, currentIdea, media, groupId, title, tags, aiAssisted])

  const setSelectedTags = useCallback((tagsData: SelectedTag[]): void => {
    setTags(tagsData)
  }, [])

  return useMemo(
    () => ({
      isOpen,
      isEditMode,
      onOpenChange,
      openEditor,
      closeEditor,
      currentIdea,
      saveIdea,
      createIdeaData,
      updateIdeaData,
      getCurrentContent,
      errorMessage,
      errorCode,
      plateEditor,
      setSelectedTags,
      tags,
      title,
      groupId,
      setTitle,
      setGroupId,
    }),
    [
      isOpen,
      isEditMode,
      onOpenChange,
      openEditor,
      closeEditor,
      currentIdea,
      saveIdea,
      createIdeaData,
      updateIdeaData,
      getCurrentContent,
      errorMessage,
      errorCode,
      plateEditor,
      tags,
      setSelectedTags,
      title,
      groupId,
      setTitle,
      setGroupId,
    ],
  )
}
