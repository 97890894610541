import styled from 'styled-components'

import { grayDarker } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  display: flex;
  flex: 0 1 115px;
  color: ${grayDarker};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
`
