import { LOCATION_CHANGE } from 'redux-first-history'
import { actionTypes as dataFetchActionTypes } from '@bufferapp/async-data-fetch'
import { getProfilesParams } from '~publish/legacy/routes'
import generateProfileTabs from './utils'
import keyWrapper from '~publish/helpers/keywrapper'

export const actionTypes = keyWrapper('PROFILE_NAV', {
  GENERATE_PROFILE_TABS: 0,
})

export type ProfileNavAction = {
  type: string
  profile?: any
  organization?: any
  payload?: any
  result?: any
  // This is of type QueueAction TODO: add a new action in the Queue reducer
  counts?: {
    pending: number
    updatesScheduled: number
    draftsScheduled: number
  }
}

export type ProfileNavState = {
  profileNavTabs: string[]
  draftsNeedApprovalCount: number
  draftsCount: number
  draftsScheduledCount: number
  pendingCount: number
  selectedTabId: string | undefined
}

export const initialState: ProfileNavState = {
  profileNavTabs: [],
  draftsNeedApprovalCount: 0,
  draftsCount: 0,
  draftsScheduledCount: 0,
  pendingCount: 0,
  selectedTabId: undefined,
}

export default (
  state = initialState,
  action: ProfileNavAction,
): ProfileNavState => {
  switch (action.type) {
    case actionTypes.GENERATE_PROFILE_TABS: {
      const { profile, organization } = action
      return {
        ...state,
        profileNavTabs: generateProfileTabs({ profile, organization }),
      }
    }

    case LOCATION_CHANGE: {
      const { pathname } = action.payload.location || {}
      const { tabId } = getProfilesParams({ pathname }) || {}
      return {
        ...state,
        selectedTabId: tabId,
      }
    }

    case `getCounts_${dataFetchActionTypes.FETCH_SUCCESS}`: {
      return {
        ...state,
        draftsNeedApprovalCount:
          action.result.counts.drafts_needs_approval_true,
        draftsCount:
          action.result.counts.drafts_needs_approval_false ||
          action.result.counts.drafts,
        draftsScheduledCount: action.result.counts.drafts_scheduled,
        pendingCount: action.result.counts.pending,
      }
    }
    default:
      return state
  }
}

export const actions = {
  // @ts-expect-error TS(7031) FIXME: Binding element 'profile' implicitly has an 'any' ... Remove this comment to see the full error message
  generateProfileTabs: ({ profile, organization }) => ({
    type: actionTypes.GENERATE_PROFILE_TABS,
    profile,
    organization,
  }),
}
