import { actions as dataFetchActions } from '@bufferapp/async-data-fetch'

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    next(action)
    switch (action.type) {
      case 'INIT_USER':
        dispatch(
          dataFetchActions.fetch({
            name: 'user',
          }),
        )
        break
      default:
        break
    }
  }
