import { actionTypes as dataFetchActionTypes } from '@bufferapp/async-data-fetch'
import keyWrapper from '~publish/helpers/keywrapper'

export const actionTypes = keyWrapper('HASHTAG_GROUP_MANAGER', {
  CANCEL_HASHTAG_GROUP: 0,
  SAVE_HASHTAG_GROUP: 0,
  DELETE_HASHTAG_GROUP: 0,
  INSERT_HASHTAG_GROUP: 0,
  EDIT_HASHTAG_GROUP: 0,
  UPDATE_HASHTAG_GROUP: 0,
  ON_CHANGE_HASHTAG_GROUP_NAME: 0,
  ON_CHANGE_HASHTAG_GROUP_TEXT: 0,
})

export const initialState = {
  name: '',
  text: '',
  snippetId: '',
  groups: [],
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case `getHashtagGroups_${dataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        groups: action.result.data.snippets,
      }
    case actionTypes.ON_CHANGE_HASHTAG_GROUP_NAME:
      return {
        ...state,
        name: action.name,
      }
    case actionTypes.SAVE_HASHTAG_GROUP: {
      const newGroups = [
        ...(state.groups || []),
        { _id: 'temp', name: state.name, text: state.text },
      ].sort((a, b) => (a.name < b.name ? -1 : 1))

      return {
        ...state,
        groups: newGroups,
      }
    }
    case actionTypes.UPDATE_HASHTAG_GROUP: {
      let newGroups = state.groups.filter(
        // @ts-expect-error TS(2339) FIXME: Property '_id' does not exist on type 'never'.
        (group) => group._id !== state.snippetId,
      )

      // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
      newGroups.push({
        _id: state.snippetId,
        name: state.name,
        text: state.text,
      })
      // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'never'.
      newGroups = newGroups.sort((a, b) => (a.name < b.name ? -1 : 1))

      return {
        ...state,
        groups: newGroups,
      }
    }
    case `createHashtagGroup_${dataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        groups: action.result.data.snippets,
        name: '',
        text: '',
      }
    case `updateHashtagGroup_${dataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        groups: action.result.data.snippets,
        name: '',
        text: '',
        snippetId: null,
      }
    case actionTypes.ON_CHANGE_HASHTAG_GROUP_TEXT:
      return {
        ...state,
        text: action.text,
      }
    case actionTypes.CANCEL_HASHTAG_GROUP:
      return {
        ...state,
        name: '',
        text: '',
        snippetId: null,
      }
    case actionTypes.EDIT_HASHTAG_GROUP:
      return {
        ...state,
        name: action.name,
        text: action.text,
        snippetId: action.snippetId,
      }
    case actionTypes.DELETE_HASHTAG_GROUP:
      return {
        ...state,
        // @ts-expect-error TS(2339) FIXME: Property '_id' does not exist on type 'never'.
        groups: state.groups.filter((group) => group._id !== action.snippetId),
      }
    default:
      return state
  }
}

export const actions = {
  handleCancelHashtagGroupClick: () => ({
    type: actionTypes.CANCEL_HASHTAG_GROUP,
  }),
  handleSaveHashtagGroupClick: () => ({
    type: actionTypes.SAVE_HASHTAG_GROUP,
  }),
  handleUpdateHashtagGroupClick: () => ({
    type: actionTypes.UPDATE_HASHTAG_GROUP,
  }),
  // @ts-expect-error TS(7031) FIXME: Binding element 'name' implicitly has an 'any' typ... Remove this comment to see the full error message
  handleInsertHashtagGroupClick: ({ name, text }) => ({
    type: actionTypes.INSERT_HASHTAG_GROUP,
    name,
    text,
  }),
  // @ts-expect-error TS(7031) FIXME: Binding element 'name' implicitly has an 'any' typ... Remove this comment to see the full error message
  handleDeleteHashtagGroup: ({ name, text, snippetId }) => ({
    type: actionTypes.DELETE_HASHTAG_GROUP,
    name,
    text,
    snippetId,
  }),
  // @ts-expect-error TS(7031) FIXME: Binding element 'name' implicitly has an 'any' typ... Remove this comment to see the full error message
  handleEditHashtagGroup: ({ name, text, snippetId }) => ({
    type: actionTypes.EDIT_HASHTAG_GROUP,
    name,
    text,
    snippetId,
  }),
  // @ts-expect-error TS(7031) FIXME: Binding element 'name' implicitly has an 'any' typ... Remove this comment to see the full error message
  handleChangeGroupName: ({ name }) => ({
    type: actionTypes.ON_CHANGE_HASHTAG_GROUP_NAME,
    name,
  }),
  // @ts-expect-error TS(7031) FIXME: Binding element 'text' implicitly has an 'any' typ... Remove this comment to see the full error message
  handleChangeGroupText: ({ text }) => ({
    type: actionTypes.ON_CHANGE_HASHTAG_GROUP_TEXT,
    text,
  }),
}
