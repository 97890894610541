import React, { useCallback, useState } from 'react'
import clsx from 'clsx'
import { DndContext, type DragEndEvent } from '@dnd-kit/core'
import { SortableContext } from '@dnd-kit/sortable'

import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { useCustomSensors } from '~publish/hooks/useCustomSensors'

import {
  selectCompletedUploads,
  selectPendingCount,
  selectPendingProgress,
} from '../state/selectors'
import { reorderUploads } from '../state/slice'
import { useUploader } from '../hooks/useUploader'
import { Thumbnail } from './Thumbnail'
import { UploaderDropzone } from './UploaderDropzone'
import type { Upload } from '../entities/Upload'
import { MediaPreviewer } from './MediaPreviewer'
import { SortableThumbnail } from './SortableThumbnail'

import styles from './MediaManager.module.css'

export const MAX_THUMBNAILS = 10

export const MediaManager = ({
  id,
  containerRef,
}: {
  id: string
  containerRef: HTMLElement | null | undefined
}): JSX.Element => {
  const dispatch = useAppDispatch()
  const [uploadInPreview, setUploadInPreview] = useState<Upload | null>(null)
  const sensors = useCustomSensors()
  const { uploader } = useUploader({
    id,
    onUnmount: 'reset',
  })

  const completedUploads = useAppSelector((state) =>
    selectCompletedUploads(state, id),
  )
  const pendingCount = useAppSelector((state) => selectPendingCount(state, id))
  const pendingProgress = useAppSelector((state) =>
    selectPendingProgress(state, id),
  )

  const handleDragEnd = useCallback(
    (event: DragEndEvent): void => {
      const { active, over } = event

      if (over && active.id !== over?.id) {
        dispatch(
          reorderUploads({
            activeId: active.id,
            targetId: over?.id,
          }),
        )
      }
    },
    [dispatch],
  )
  const isUploading = pendingCount > 0

  return (
    <div
      className={clsx(styles.mediaManager)}
      id="media-manager"
      data-testid="media-manager"
    >
      <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
        <SortableContext items={completedUploads}>
          {completedUploads.map((up) => (
            <SortableThumbnail key={up.id} id={up.id}>
              <Thumbnail upload={up} openPreview={setUploadInPreview} />
            </SortableThumbnail>
          ))}
        </SortableContext>
      </DndContext>
      {completedUploads.length < MAX_THUMBNAILS && (
        // TODO: Replace with UploadDropzone component - https://buffer.atlassian.net/browse/CT-501
        <UploaderDropzone
          uploader={uploader}
          isUploading={isUploading}
          uploadProgress={pendingProgress}
          activeUploadsCount={pendingCount}
          disabled={isUploading}
          multiple
          onlyAllowsVerticalVideo={false}
          withBorder
        />
      )}
      <MediaPreviewer
        upload={uploadInPreview}
        containerRef={containerRef}
        onClose={(): void => setUploadInPreview(null)}
      />
    </div>
  )
}
