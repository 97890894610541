import { CHANNEL_SUGGESTIONS } from './constants'

const SUGGEST_CHANNELS_THRESHOLD = 5

export function getConnectableChannels(channels: { service: string }[]): {
  name: string
  service: string
}[] {
  if (channels.length === 0) return CHANNEL_SUGGESTIONS
  return CHANNEL_SUGGESTIONS.filter((suggestedChannel) => {
    return !channels.some(
      (channel) => channel.service === suggestedChannel.service,
    )
  }).slice(0, SUGGEST_CHANNELS_THRESHOLD - channels.length)
}
