import React, { useState, useEffect } from 'react'
import {
  Popover,
  Flex,
  Heading,
  Paragraph,
  Button,
  NewBadge,
  Image,
  CloseIcon,
  IconButton,
} from '@buffer-mono/popcorn'

import styles from './ProfileEducationPopover.module.css'

import { BannerTypes } from '~publish/legacy/profile-page/hooks/useDismissBanner'
import { usePostComposer } from '~publish/hooks/usePostComposer'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'

type ProfileEducationPopoverProps = {
  children: React.ReactNode
}

export const ProfileEducationPopover = ({
  children,
}: ProfileEducationPopoverProps): JSX.Element | null => {
  const { isOpen: isOpenComposer } = usePostComposer()

  const [isOpen, setIsOpen] = useState(true)

  const banner = useDismissableBanner(BannerTypes.profileEducationPopover)

  const dismiss = (): void => {
    setIsOpen(false)
    banner.dismiss()
  }

  /* we close the popover if the composer is open */
  useEffect(() => {
    if (isOpenComposer) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
  }, [isOpenComposer])

  if (!banner.isActive) return <>{children}</>

  return (
    <Popover
      open={isOpen}
      aria-labelledby="Popover-title"
      aria-describedby="Popover-description"
      modal={false}
    >
      <Popover.Trigger>{children}</Popover.Trigger>
      <Popover.Content
        arrow
        side="right"
        align="start"
        className={styles.content}
      >
        <Flex direction="column" gap="space-200" align="stretch">
          <Image
            aria-hidden="true"
            alt="A new quick way to create posts"
            loading="eager"
            placeholder="https://placehold.co/300x200?text=A+new+quick+way+to+create+posts"
            src="https://s3.amazonaws.com/buffer-publish/images/sidebar-new-post-education-popover.gif"
            className={styles.image}
          />
          <Flex direction="column" gap="space-100" align="start">
            <Heading id="Popover-title" size="small">
              <NewBadge
                size="small"
                aria-hidden="true"
                className={styles.badge}
              />
              A new quick way to create posts
            </Heading>
            <Paragraph id="Popover-description">
              Create new posts for any of your channels faster by hovering and
              clicking on the "+" button.
            </Paragraph>
          </Flex>
          <Flex direction="row" gap="space-100" justify="end">
            <Button variant="primary" onClick={dismiss} arial-label="Dismiss">
              Got it
            </Button>
          </Flex>
        </Flex>
        {/* It is important to have the close button at the end of the content to avoid it gets the focus first */}
        <IconButton
          size="small"
          variant="tertiary"
          label="Close"
          className={styles.closeButton}
          onClick={dismiss}
        >
          <CloseIcon />
        </IconButton>
      </Popover.Content>
    </Popover>
  )
}
