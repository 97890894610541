import twitterText from 'twitter-text'
import { MastodonParser } from '~publish/legacy/composer/composer/lib/parsers/MastodonParser'
import {
  AttachmentTypes,
  COMPOSER_UPLOADER_PREFIX,
  type SERVICE_GOOGLEBUSINESS,
  type SERVICE_INSTAGRAM,
  SERVICE_LINKEDIN,
  type SERVICE_STARTPAGE,
  type SERVICE_YOUTUBE,
} from '~publish/legacy/constants'
import { EditorStateProxy } from '../EditorStateProxy/EditorStateProxy'
import type { Draft } from './Draft'
import type {
  ChannelDataProperties,
  GoogleBusinessChannelData,
  InstagramChannelData,
  StartPageChannelData,
  YoutubeChannelData,
} from './types'
import { UploaderRestrictions } from '~publish/legacy/uploads/values/UploaderRestrictions'
import { FileUploadConfig } from '~publish/legacy/uploads/constants'
import { getMixedMediaError } from '~publish/legacy/uploads/lib/validation/validationUtils/getMixedMediaError'
import { Document } from '../Document'
import { PostTypeCarousel } from '~publish/legacy/post/constants'

function getChannelDataForChannel(
  draft: Draft,
  channel: typeof SERVICE_GOOGLEBUSINESS,
): GoogleBusinessChannelData | undefined
function getChannelDataForChannel(
  draft: Draft,
  channel: typeof SERVICE_INSTAGRAM,
): InstagramChannelData | undefined
function getChannelDataForChannel(
  draft: Draft,
  channel: typeof SERVICE_STARTPAGE,
): StartPageChannelData | undefined
function getChannelDataForChannel(
  draft: Draft,
  channel: typeof SERVICE_YOUTUBE,
): YoutubeChannelData | undefined
function getChannelDataForChannel(
  draft: Draft,
  channel:
    | typeof SERVICE_GOOGLEBUSINESS
    | typeof SERVICE_INSTAGRAM
    | typeof SERVICE_STARTPAGE
    | typeof SERVICE_YOUTUBE,
): ChannelDataProperties | undefined {
  return draft.channelData?.[channel]
}

export const DraftMethods = {
  hasLinkAttachmentEnabled(draft: Draft): boolean {
    return draft.enabledAttachmentType === AttachmentTypes.LINK
  },

  hasMediaAttachmentEnabled(draft: Draft): boolean {
    return draft.enabledAttachmentType === AttachmentTypes.MEDIA
  },

  hasRetweetAttachmentEnabled(draft: Draft): boolean {
    return draft.enabledAttachmentType === AttachmentTypes.RETWEET
  },

  hasLinkAttachment(draft: Draft): boolean {
    return DraftMethods.hasLinkAttachmentEnabled(draft) && draft.link !== null
  },

  hasLinkAttachmentWithThumbnail(draft: Draft): boolean {
    return (
      DraftMethods.hasLinkAttachmentEnabled(draft) &&
      draft.link?.thumbnail !== null
    )
  },

  hasMediaAttachment(draft: Draft): boolean {
    return (
      (DraftMethods.hasMediaAttachmentEnabled(draft) &&
        draft.images?.length > 0) ||
      draft.video !== null ||
      draft.gif !== null
    )
  },

  hasRetweetAttachment(draft: Draft): boolean {
    return (
      DraftMethods.hasRetweetAttachmentEnabled(draft) && draft.retweet !== null
    )
  },

  getDocumentAttachment(draft: Draft): Document | null {
    return Document.isDocument(draft.document) ? draft.document : null
  },

  getDocumentAttachmentTitle(draft: Draft): string {
    return draft.document?.title ?? ''
  },

  hasDocumentAttachment(draft: Draft): boolean {
    return !!DraftMethods.getDocumentAttachment(draft)
  },

  isLinkedInDocument(draft: Draft): boolean {
    return draft.service.isLinkedin() && draft.updateType === PostTypeCarousel
  },

  shouldShowSuggestedMedia(draft: Draft): boolean {
    return (
      !draft.isVerticalVideoOnlySupported() &&
      !DraftMethods.isLinkedInDocument(draft)
    )
  },

  hasAttachment(draft: Draft): boolean {
    return (
      DraftMethods.hasLinkAttachment(draft) ||
      DraftMethods.hasMediaAttachment(draft) ||
      DraftMethods.hasRetweetAttachment(draft) ||
      DraftMethods.hasDocumentAttachment(draft)
    )
  },

  hasNoMediaAttached(draft: Draft): boolean {
    return (
      draft.images.length === 0 &&
      draft.video === null &&
      draft.gif === null &&
      draft.document === null
    )
  },

  hasVideoAttachment(draft: Draft): boolean {
    return DraftMethods.hasMediaAttachmentEnabled(draft) && draft.video !== null
  },

  hasGifAttachment(draft: Draft): boolean {
    return DraftMethods.hasMediaAttachmentEnabled(draft) && draft.gif !== null
  },

  hasImagesAttachment(draft: Draft): boolean {
    return (
      DraftMethods.hasMediaAttachmentEnabled(draft) && draft.images.length !== 0
    )
  },

  hasText(draft: Draft): boolean {
    return (
      draft.text !== null &&
      draft.text !== undefined &&
      draft.text.trim() !== ''
    )
  },

  hasUnshortenedUrls(draft: Draft): boolean {
    // draftjs clenaup: draft.unshortenedUrls is only used for DraftJS
    const hasUnshortenedUrlsInArray = draft.unshortenedUrls.length > 0

    const hasUnshortenedUrlsInEditorState =
      !!draft.editorState &&
      EditorStateProxy.hasUnshortenedLinks(draft.editorState)

    return hasUnshortenedUrlsInArray || hasUnshortenedUrlsInEditorState
  },

  getChannelDataForChannel,

  getImageCount(draft: Draft): number {
    return draft.images.length
  },

  getMediaCount(draft: Draft): number {
    return (
      draft.images.length +
      (draft.video !== null ? 1 : 0) +
      (draft.gif !== null ? 1 : 0)
    )
  },

  exceedsMaxImageCount(draft: Draft): boolean {
    return (
      this.getImageCount(draft) > draft.service.maxAttachableImagesCount(draft)
    )
  },

  /**
   * Returns the additional amount of characters that need to be
   * added to the character count under specific conditions.
   *
   * Some services require that the character count be adjusted
   * under specific conditions, eg: A post has an image or video attachment.
   *
   * These characters all relate to things outside of the text.
   */
  getAdditionalCharacterCount(draft: Draft, text: string): number {
    if (draft.service.isTwitter()) {
      const mockAttachmentLink = 'https://pbs.twimg.com/media/XXXXXX'
      const mockAttachmentText = ` ${mockAttachmentLink}`

      if (
        draft.enabledAttachmentType === AttachmentTypes.MEDIA &&
        draft.video !== null &&
        draft.service.nativeVideoSizeLimit &&
        draft.service.nativeVideoDurationLimit
      ) {
        const videoSizeLessThanNativeLimit =
          draft.video.size < draft.service.nativeVideoSizeLimit
        const videoDurationLessThanNativeLimit =
          draft.video.durationMs < draft.service.nativeVideoDurationLimit * 1000

        const willVideoBeNativeOnTwitter =
          videoSizeLessThanNativeLimit && videoDurationLessThanNativeLimit
        const willVideoLinkBeAddedToText = !willVideoBeNativeOnTwitter

        if (willVideoLinkBeAddedToText) {
          return twitterText.getTweetLength(mockAttachmentText)
        }
      }
    }

    if (draft.service.isLinkedin()) {
      let charCount = 0

      const urls = twitterText.extractUrls(text)
      const hasLinkAttached =
        draft.enabledAttachmentType === AttachmentTypes.LINK &&
        draft.link !== null
      const isAttachedLinkInText =
        draft?.link && hasLinkAttached && urls.includes(draft.link.url)

      // Only add 24 chars for link attachment if the
      // attached link is not also found in the text
      if (hasLinkAttached && !isAttachedLinkInText) charCount += 24

      // Add 24 chars for images and videos
      if (draft.enabledAttachmentType === AttachmentTypes.MEDIA) {
        if (draft.images?.length > 0) charCount += 24
        if (draft.video !== null) charCount += 24
      }

      return charCount
    }

    return 0
  },

  /**
   * Returns the character count limit for the service
   */
  getCharLimit(draft: Draft): number | null {
    if (typeof draft.service.charLimit === 'function') {
      return draft.service.charLimit(draft)
    }
    return draft.service.charLimit
  },

  /**
   * Returns the character count for the given text.
   */
  getTextCharacterCount(draft: Draft, text: string): number {
    if (draft.service.isTwitter()) {
      return twitterText.getTweetLength(text)
    }

    if (draft.service.isInstagram()) {
      const charCount = (text && text.length) || 0
      let lineBreakLength = 0

      const lineBreakMatch = text?.match(/\n/g)
      if (text && lineBreakMatch) {
        lineBreakLength = lineBreakMatch.length
      }
      return charCount + lineBreakLength
    }

    if (draft.service.isLinkedin()) {
      let charCount = (text && text.length) || 0

      const urls = twitterText.extractUrls(text)

      // If there are urls in the text, subtract the url length
      // from the charCount and add back 24 chars for each url
      if (urls.length > 0) {
        let totalUrlLength = 0

        urls.forEach((url) => {
          totalUrlLength += url.length
        })

        const additionalCharCount = 24
        charCount -= totalUrlLength
        charCount += urls.length * additionalCharCount
      }

      return charCount
    }

    if (draft.service.isMastodon()) {
      return MastodonParser.getCharCount(text)
    }

    return (text && text.length) || 0
  },

  /**
   * Returns the text character count plus any additional characters
   * that need to be added under specific conditions;
   * eg: A post has an image or video attachment.
   */
  getFullCharacterCount(draft: Draft, text: string): number {
    return (
      DraftMethods.getTextCharacterCount(draft, text) +
      DraftMethods.getAdditionalCharacterCount(draft, text)
    )
  },

  getJoinedThreadsText(draft: Draft): string | undefined {
    return draft?.thread
      ?.reduce<string[]>((threadedDraftsText, threadedDraft) => {
        threadedDraftsText.push(threadedDraft.text)
        if (
          threadedDraft?.retweet?.text &&
          threadedDraft.retweet.text.trim() !== ''
        ) {
          threadedDraftsText.push(threadedDraft.retweet.text)
        }
        return threadedDraftsText
      }, [])
      .join('\n')
  },

  getFileAttachmentCount(draft: Draft): number {
    if (
      DraftMethods.hasVideoAttachment(draft) ||
      DraftMethods.hasGifAttachment(draft)
    )
      return 1

    return DraftMethods.hasImagesAttachment(draft) ? draft.images.length : 0
  },

  getUploaderId(draft: Draft): string {
    const { id, updateType } = draft

    const servicesIgnoringUpdateType = [
      // the toggle for carousel is not exposed to the user
      // we need the same uploder id for both posts and carousels
      SERVICE_LINKEDIN,
    ]

    const shouldUseUpdateType =
      !!updateType && !servicesIgnoringUpdateType.includes(id)

    return `${COMPOSER_UPLOADER_PREFIX}-${id}${
      shouldUseUpdateType ? `-${updateType}` : ''
    }`
  },

  getUploaderRestrictions(
    draft: Draft,
    features?: {
      isLinkedInCarouselEnabled?: boolean
    },
  ): UploaderRestrictions {
    // we use MEDIA by default
    let uploadConfig = new Map(FileUploadConfig.MEDIA)

    if (draft.service.isOmni) {
      uploadConfig = new Map(FileUploadConfig.OMNI)
    }

    if (draft.service.isLinkedin() && features?.isLinkedInCarouselEnabled) {
      uploadConfig = new Map(FileUploadConfig.LINKEDIN)
    }

    if (draft.isReelsPost()) {
      uploadConfig = new Map(FileUploadConfig.REELS)
    }

    if (draft.isFacebookReelPost()) {
      uploadConfig = new Map(FileUploadConfig.FACEBOOK_REELS)
    }

    if (draft.isFacebookStoryPost()) {
      uploadConfig = new Map(FileUploadConfig.FACEBOOK_STORIES)
    }

    if (draft.isStoryPost()) {
      uploadConfig = new Map(FileUploadConfig.STORIES)
    }

    if (draft.isShortPost()) {
      uploadConfig = new Map(FileUploadConfig.SHORTS)
    }

    draft.service.unavailableMediaAttachmentTypes.forEach((mediaType) => {
      uploadConfig.delete(mediaType)
    })

    return UploaderRestrictions.new({
      maxNumberOfFiles: draft.service.maxAttachableImagesCount(draft) || 10,
      mixedMediaError: getMixedMediaError(draft),
      uploadConfig,
    })
  },

  setUpdateType(draft: Draft, updateType: string): void {
    if (!draft.service.allowedPostTypes.includes(updateType)) {
      throw new Error(`Unsupported post type: ${updateType}`)
    }

    draft.updateType = updateType
  },
}
