/* eslint-disable camelcase */
import React, { useContext } from 'react'
import {
  addDays,
  format,
  isSameDay,
  isSameMonth,
  isPast,
  differenceInCalendarDays,
  setHours,
  startOfDay,
  endOfDay,
  type Day as DayType,
} from 'date-fns'
import { Droppable } from 'react-beautiful-dnd'
import getWeekIndexFromDay from '~publish/pages/Calendar/util/getWeekIndexFromDay'
import type { PostLimit } from '~publish/pages/Calendar/hooks/usePostLimit'
import createCalendarPostContext from '../hooks/createCalendarPostContext'
import AddButton from '../AddButton'
import { PostItem } from '../PostItem'
import { Day, PostsWrapper, ShowMoreButton, DayContent } from './style'
import type { GetCalendarAndPostListQuery } from '~publish/gql/graphql'
import { asPostsFragment } from '~publish/pages/Calendar/hooks/useCalendarAndPostsList'
import { filterPostsWithInterval } from '~publish/pages/Calendar/util/generatePostIntervals'

type DaysProps = {
  startDate: Date
  endDate: Date
  monthStart: Date
  weekStartsOn: DayType
  data: GetCalendarAndPostListQuery | null | undefined
  postLimit: PostLimit
  setOpenModal:
    | null
    | ((openModal: {
        open: boolean
        ctaString: string
        service: string
      }) => void)
  selectedChannelIds: string[] | void
}

const Days = ({
  startDate,
  endDate,
  monthStart,
  weekStartsOn,
  data,
  postLimit,
  setOpenModal,
  selectedChannelIds,
}: DaysProps): JSX.Element[] => {
  const { hasPermissions } = useContext(createCalendarPostContext)

  const dateFormat = 'd'

  const dayCells = (): JSX.Element[] => {
    const days: JSX.Element[] = []
    const numberDaysToDisplay =
      Math.abs(differenceInCalendarDays(startDate, endDate)) + 1

    // Generates number of days between start and end date
    ;[...Array(numberDaysToDisplay)].forEach((_, i) => {
      // Posts data
      const day = addDays(startDate, i)
      const middayHour = setHours(day, 12)
      const middayTimestamp = middayHour.getTime() / 1000
      const currentTimestamp = new Date().getTime() / 1000
      const defaultTimeslot =
        currentTimestamp > middayTimestamp ? currentTimestamp : middayTimestamp
      const rowIndex = getWeekIndexFromDay({
        currentDate: monthStart,
        day,
        weekStartsOn,
      })
      const limit = postLimit?.postsLimit?.[rowIndex]
      const isCurrentDay = isSameDay(new Date(), day)
      const isPastDay = isPast(day)
      const showAddButton = (!isPastDay || isCurrentDay) && hasPermissions
      const inLastTwoRows = i >= numberDaysToDisplay - 14
      const allPosts = asPostsFragment(data)
      const filterInterval = {
        startTimestamp: startOfDay(day).toISOString(),
        endTimestamp: endOfDay(day).toISOString(),
      }
      const posts = filterPostsWithInterval(filterInterval, allPosts)

      days.push(
        <Droppable
          key={day.getTime().toString()}
          droppableId={day.getTime().toString()}
          isDropDisabled={isPastDay && !isCurrentDay}
        >
          {(provided, snapshot): JSX.Element => (
            <Day
              data-testid={`monthly-day-${day}`}
              dayNumber={format(day, dateFormat)}
              isPastMonth={!isSameMonth(day, monthStart)}
              isPast={isPastDay && !isCurrentDay}
              isActive={isCurrentDay}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {showAddButton && !snapshot.isDraggingOver && (
                <AddButton
                  cta="publish-calendar-day-addPost-1"
                  data-testid={`post-button-${day}`}
                  labelDate={format(day, 'eeee, d MMMM y')}
                  defaultTimeslot={defaultTimeslot}
                  selectedChannelIds={selectedChannelIds}
                />
              )}
              <DayContent ref={provided.innerRef} {...provided.droppableProps}>
                {posts.length > 0 && (
                  <>
                    <PostsWrapper>
                      {posts.map((post, index) => {
                        const legacyPostProps = {
                          small: true,
                          index,
                          inLastTwoRows,
                          isDraggingOver: snapshot.isDraggingOver,
                          scheduledDay: format(day, 'd MMMM EEEE yyyy'),
                          setOpenModal,
                        }
                        if (index >= limit) {
                          return <></>
                        }
                        return (
                          <PostItem
                            key={post.id}
                            post={post}
                            {...legacyPostProps}
                          />
                        )
                      })}
                    </PostsWrapper>
                    {posts.length > limit && !snapshot.isDraggingOver && (
                      <ShowMoreButton
                        type="text"
                        label="+ More"
                        onClick={(): void => postLimit?.showMorePosts(rowIndex)}
                      />
                    )}
                  </>
                )}
                {provided.placeholder}
              </DayContent>
            </Day>
          )}
        </Droppable>,
      )
    })

    return days
  }

  return dayCells()
}

export default Days
