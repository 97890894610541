import BaseValidator from '~publish/legacy/composer/composer/lib/validation/BaseValidator'
import ValidationFail from '~publish/legacy/validation/ValidationFail'
import type ValidationResult from '~publish/legacy/validation/ValidationResult'
import ValidationSuccess from '~publish/legacy/validation/ValidationSuccess'
import { VALIDATION_CODE } from '~publish/legacy/validation/constants'
import {
  YT_FORBIDDEN_CHARACTERS,
  YT_MAX_CHAR_TITLE,
  YT_MISSING_CATEGORY,
  YT_MISSING_LICENSE,
  YT_MISSING_PRIVACY_STATUS,
  YT_MISSING_TITLE,
} from '~publish/legacy/composer/composer/lib/validation/utils/validationErrorMessages'
import {
  YOUTUBE_CATEGORIES_US_REGION,
  YOUTUBE_LICENSE_MAP,
  YOUTUBE_PRIVACY_STATUS_MAP,
} from '~publish/legacy/composer/composer/components/youtube/constants'

export default class YoutubeValidator extends BaseValidator {
  protected validateTitle(): ValidationResult {
    const youtubeData = this.draft.channelData?.youtube

    if (!youtubeData?.title) {
      return new ValidationFail(YT_MISSING_TITLE, VALIDATION_CODE.MISSING_VALUE)
    }

    if (youtubeData?.title.length > 100) {
      return new ValidationFail(
        YT_MAX_CHAR_TITLE,
        VALIDATION_CODE.MAX_LIMIT_REACHED,
      )
    }

    const contentText = this.draft.text

    if (
      youtubeData?.title.includes('<') ||
      youtubeData?.title.includes('>') ||
      contentText.includes('<') ||
      contentText.includes('>')
    ) {
      return new ValidationFail(
        YT_FORBIDDEN_CHARACTERS,
        VALIDATION_CODE.INVALID_VALUE,
      )
    }
    return new ValidationSuccess()
  }

  protected validateLicense(): ValidationResult {
    const youtubeData = this.draft.channelData?.youtube

    if (
      !youtubeData?.license ||
      !this.isValidYoutubeLicenseValue(youtubeData.license)
    ) {
      return new ValidationFail(
        YT_MISSING_LICENSE,
        VALIDATION_CODE.MISSING_VALUE,
      )
    }

    return new ValidationSuccess()
  }

  private isValidYoutubeLicenseValue(value: unknown): value is string {
    return (
      typeof value === 'string' &&
      Object.keys(YOUTUBE_LICENSE_MAP).includes(value)
    )
  }

  protected validatePrivacyStatus(): ValidationResult {
    const youtubeData = this.draft.channelData?.youtube

    if (
      !youtubeData?.privacyStatus ||
      !this.isValidYoutubePrivacyStatusValue(youtubeData.privacyStatus)
    ) {
      return new ValidationFail(
        YT_MISSING_PRIVACY_STATUS,
        VALIDATION_CODE.MISSING_VALUE,
      )
    }

    return new ValidationSuccess()
  }

  private isValidYoutubePrivacyStatusValue(value: unknown): value is string {
    return (
      typeof value === 'string' &&
      Object.keys(YOUTUBE_PRIVACY_STATUS_MAP).includes(value)
    )
  }

  protected validateCategory(): ValidationResult {
    const youtubeData = this.draft.channelData?.youtube

    if (
      !youtubeData?.category ||
      !this.isValidYoutubeCategory(youtubeData.category)
    ) {
      return new ValidationFail(
        YT_MISSING_CATEGORY,
        VALIDATION_CODE.MISSING_VALUE,
      )
    }

    return new ValidationSuccess()
  }

  private isValidYoutubeCategory(
    value: unknown,
  ): value is { id: string; title: string } {
    return (
      typeof value === 'object' &&
      value !== null &&
      YOUTUBE_CATEGORIES_US_REGION.some(
        (category) =>
          category.id === (value as { id: string; title: string }).id &&
          category.title === (value as { id: string; title: string }).title,
      )
    )
  }

  protected validateVideo(): ValidationResult {
    if (this.draft.isReminder) {
      return new ValidationSuccess()
    }

    return super.validateVideo()
  }

  protected validateForChannel(): ValidationResult[] {
    if (this.draft.isReminder) {
      return []
    }

    return [
      this.validateTitle(),
      this.validateLicense(),
      this.validateCategory(),
      this.validatePrivacyStatus(),
    ]
  }
}
