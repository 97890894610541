import {
  type ComboboxOnSelectItem,
  getPlugin,
  insertText,
  moveSelection,
  type PlateEditor,
  removeNodes,
  select,
  withoutMergingHistory,
  withoutNormalizing,
  comboboxActions,
  comboboxSelectors,
} from '@udecode/plate'
import { EmojiElement, EmojiSearchElement } from '../nodes'
import type { EmojiPluginOptions, EmojiSearchData } from '../types'

export const createEmojiOnSelectItem =
  (key = EmojiElement.type): ComboboxOnSelectItem<EmojiSearchData> =>
  (editor, item) => {
    const targetRange = comboboxSelectors.targetRange()
    if (!targetRange) return

    const {
      options: { insertSpaceAfterEmoji },
    } = getPlugin<EmojiPluginOptions>(
      // We cast editor here due to how ComboboxOnSelectItem
      // and getPlugin are typed internally.
      // - Argument of type 'PlateEditor<V>' is not assignable to parameter of type 'PlateEditor<Value>'.
      editor as PlateEditor,
      key,
    )

    withoutNormalizing(editor, () => {
      // Selectors are sensitive to operations, it's better to create everything
      // before the editor state is changed. For example, asking for text after
      // removeNodes below will return null.
      select(editor, targetRange)

      withoutMergingHistory(editor, () =>
        removeNodes(editor, {
          match: (node) => EmojiSearchElement.is(node),
        }),
      )

      const emoji = item?.data?.skins[0].native
      if (emoji) {
        // Insert the emoji as text
        insertText(editor, emoji)

        // move the selection after the element
        moveSelection(editor, { unit: 'offset' })

        if (insertSpaceAfterEmoji) {
          insertText(editor, ' ')
        }
      }
    })

    return comboboxActions.reset()
  }
