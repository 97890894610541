import React, { useCallback } from 'react'
import { endOfISOWeek, format, startOfISOWeek } from 'date-fns'

import {
  Button,
  IconButton,
  ChevronLeftIcon,
  ChevronRightIcon,
  Flex,
  Text,
  Combobox,
  VisuallyHidden,
  ChevronDownIcon,
  Heading,
} from '@buffer-mono/popcorn'

import { useOrganizationId } from '~publish/legacy/accountContext'

import { useCalendarState } from '../hooks/useCalendarState'
import { useCalendarAnalytics } from '../hooks/useCalendarAnalytics'

import styles from './CalendarControls.module.css'

export function CalendarControls(): JSX.Element {
  const calendarState = useCalendarState()
  const {
    filters: { channelsFilter },
    navigation,
  } = calendarState
  const { granularity, navigateGranularity } = navigation
  const isWeekly = granularity === 'week'

  const organizationId = useOrganizationId() ?? ''
  const { onWeekClick, onMonthClick, onCalendarChangeTrack } =
    useCalendarAnalytics({
      granularity: navigation.granularity,
      channelsCount: channelsFilter.sanitized.length,
      organizationId,
    })
  const startDate = navigation.currentDate
  let dateString = format(startDate, 'MMMM yyyy')
  if (granularity === 'week') {
    const endOfWeek = endOfISOWeek(startDate)
    const startOfWeek = startOfISOWeek(startDate)
    if (endOfWeek.getMonth() !== startOfWeek.getMonth()) {
      dateString = `${format(startOfWeek, 'MMM')}-${format(
        endOfWeek,
        'MMM',
      )} ${format(startDate, 'yyyy')}`
    }
  }
  const handleWeekMonthToggle = useCallback(
    (value: string): void => {
      if (value !== 'week' && value !== 'month') return

      if (value === 'week') onWeekClick(isWeekly)
      else if (value === 'month') onMonthClick(isWeekly)
      navigateGranularity(value)
    },
    [isWeekly, onWeekClick, onMonthClick, navigateGranularity],
  )

  const handleForwardsBackwardsClick = useCallback(
    (direction: 'forwards' | 'backwards' | 'today'): void => {
      const modifier =
        {
          forwards: 'next',
          backwards: 'previous',
          today: 'today',
        }[direction] === 'forwards'
          ? 'next'
          : 'previous'
      onCalendarChangeTrack(granularity, `${modifier}_${granularity}`)
      if (direction === 'today') {
        navigation.setCurrentDate(new Date())
      } else {
        navigation.onNavigate(direction, granularity)
      }
    },
    [navigation, onCalendarChangeTrack, granularity],
  )

  return (
    <Flex gap="space-200" align="center">
      <Flex align="center">
        <IconButton
          label={granularity === 'week' ? 'Previous Week' : 'Previous Month'}
          variant="tertiary"
          onClick={(): void => handleForwardsBackwardsClick('backwards')}
        >
          <ChevronLeftIcon />
        </IconButton>
        <IconButton
          label={granularity === 'week' ? 'Next Week' : 'Next Month'}
          variant="tertiary"
          onClick={(): void => handleForwardsBackwardsClick('forwards')}
        >
          <ChevronRightIcon />
        </IconButton>
        <Heading as="h2" size="small" className={styles.dateHeader}>
          {dateString}
        </Heading>
      </Flex>
      <Button
        variant="secondary"
        onClick={(): void => handleForwardsBackwardsClick('today')}
      >
        Today
      </Button>
      <Combobox
        multiple={false}
        value={granularity}
        onChange={(value: string): void =>
          handleWeekMonthToggle(value as 'week' | 'month')
        }
      >
        <Combobox.Trigger>
          <Button variant="tertiary">
            <VisuallyHidden>Filter by</VisuallyHidden>
            {granularity === 'week' ? 'Week' : 'Month'}
            <ChevronDownIcon />
          </Button>
        </Combobox.Trigger>
        <Combobox.Content>
          <Combobox.List>
            <Combobox.Item value={'week'}>
              <Text weight="medium">Week</Text>
            </Combobox.Item>
            <Combobox.Item value={'month'}>
              <Text weight="medium">Month</Text>
            </Combobox.Item>
          </Combobox.List>
        </Combobox.Content>
      </Combobox>
    </Flex>
  )
}
