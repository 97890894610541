import { connect } from 'react-redux'
import { SEGMENT_NAMES } from '../constants'
import { FirstPostCelebrationModal } from './components/FirstPostCelebrationModal'
import { actions as composerPopoverActions } from '~publish/legacy/composer-popover'

export default connect(
  (state) => {
    return {
      // @ts-expect-error TS(2339) FIXME: Property 'streaksContainer' does not exist on type 'Defaul... Remove this comment to see the full error message
      modalCopy: state.streaksContainer.modalCopy,
    }
  },
  (dispatch) => ({
    onCreateNextPostClick: ({
      selectedProfileId,
      selectedProfileIds,
    }: {
      selectedProfileId?: string
      selectedProfileIds: string[]
    }): void => {
      dispatch(
        composerPopoverActions.handleCreatePostClick({
          selectedProfileId,
          selectedProfileIds,
          cta: SEGMENT_NAMES.STREAKS_FIRST_POST_CELEBRATION_CTA_CLICKED,
        }),
      )
    },
    onAIAssistantClick: ({
      selectedProfileId,
      selectedProfileIds,
    }: {
      selectedProfileId?: string
      selectedProfileIds: string[]
    }): void => {
      dispatch(
        composerPopoverActions.handleCreatePostClick({
          selectedProfileId,
          selectedProfileIds,
          cta: SEGMENT_NAMES.STREAKS_FIRST_POST_CELEBRATION_AI_CTA_CLICKED,
          withAIPanelOpen: true,
        }),
      )
    },
  }),
)(FirstPostCelebrationModal)
