// TODO: indication if channel supports engage should come from API
// See task in Jira: https://buffer.atlassian.net/browse/CT-718
const servicesSupportingEngage = ['instagram', 'facebook']
export const getChannelEngageUrl = (service: string): string | null => {
  if (!servicesSupportingEngage.includes(service)) {
    return null
  }
  const [, ...domain] = window.location.hostname.split('.')
  return `https://engage.${domain.join('.')}`
}
