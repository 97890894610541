import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@bufferapp/components'
import { Warning } from '@bufferapp/ui/Icon'
import { white } from '@bufferapp/ui/style/colors'
import { IconWrapper, PostPendingBannerWrapper } from './style'

// @ts-expect-error TS(7031) FIXME: Binding element 'serviceName' implicitly has an 'a... Remove this comment to see the full error message
const PostPendingBanner = ({ serviceName }) => (
  <PostPendingBannerWrapper>
    <IconWrapper>
      <Warning color={white} />
    </IconWrapper>
    <Text size="mini" weight="medium" color="white">
      {serviceName} is processing this post
    </Text>
  </PostPendingBannerWrapper>
)

PostPendingBanner.propTypes = {
  serviceName: PropTypes.string.isRequired,
}

export default PostPendingBanner
