import React from 'react'
import PostTypeBar from './PostTypeBar'
import {
  PostTypePost,
  PostTypeReel,
  PostTypeStory,
} from '../../../../post/constants'
import { draftPropType } from '../ComposerPropTypes'
import type { Draft } from '../../entities/Draft'
import ComposerActionCreators from '../../action-creators/ComposerActionCreators'
import { PostFields } from '@buffer-mono/reminders-config'
import { EditorStateProxy } from '~publish/legacy/composer/composer/entities/EditorStateProxy'

const InstagramPostTypeBar = ({
  draft,
}: {
  draft: Draft
}): JSX.Element | null => {
  const maxImagesForStories = 10
  const hasVideoAttachment = draft.hasVideoAttachment()

  const disabledMessage = draft.hasImagesAttachment()
    ? 'You have to delete images in order to create a Reel'
    : 'You have to delete gif in order to create a Reel'

  const postTypes = [
    {
      value: PostTypePost,
      name: 'Post',
      disabledCallback: (): boolean => hasVideoAttachment,
      disabledMessage: 'Only Reels can be published if videos are attached',
    },
    {
      value: PostTypeReel,
      name: 'Reel',
      disabledCallback: (): boolean => draft.hasImagesAttachment(),
      disabledMessage,
    },
    {
      value: PostTypeStory,
      name: 'Story',
      disabledCallback: (): boolean =>
        draft.images.length > maxImagesForStories,
      disabledMessage: `You can post maximum ${maxImagesForStories} images per Story`,
    },
  ]

  const onPostTypeChangeCallback = (
    newValue: string,
    oldValue: string,
  ): void => {
    ComposerActionCreators.updateInstagramDraftsFeedback()

    /**
     * If we switch from Post to Story in Reminder-mode,
     * we want to enable Text sticker and preserve text there
     */
    if (
      newValue === PostTypeStory &&
      oldValue === PostTypePost &&
      draft.isReminder &&
      draft.channelData &&
      draft.channelData.instagram &&
      draft.text.trim().length > 0
    ) {
      ComposerActionCreators.updateDraftInstagramData({
        ...draft?.channelData?.instagram,
        text: draft.text,
      })
      ComposerActionCreators.updateDraftSelectedStickers(draft.id, [
        ...new Set([...draft.selectedStickers, PostFields.TEXT]),
      ])
    }

    /**
     * If we switch from Story to Post in Reminder-mode,
     * we want to copy text sticker into editor
     */
    if (
      newValue === PostTypePost &&
      oldValue === PostTypeStory &&
      draft.isReminder &&
      draft.channelData &&
      draft.channelData.instagram
    ) {
      ComposerActionCreators.updateDraftEditorState(
        draft.id,
        EditorStateProxy.createStateFromText(
          draft.editorState,
          draft?.channelData?.instagram?.text ?? draft.text ?? '',
          {
            annotations: [],
            facebookMentionEntities: [],
          },
        ),
      )
    }

    if (newValue === PostTypeStory && (draft?.images?.length ?? 0) > 1) {
      ComposerActionCreators.updateDraftIsReminder(draft.id, true)
    }
  }

  if (!draft.service.isInstagram()) {
    return null
  }

  return (
    <PostTypeBar
      postTypes={postTypes}
      defaultPostType={hasVideoAttachment ? PostTypeReel : PostTypePost}
      draft={draft}
      onPostTypeChangeCallback={onPostTypeChangeCallback}
    />
  )
}

InstagramPostTypeBar.propTypes = {
  draft: draftPropType.isRequired,
}

export default InstagramPostTypeBar
