/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import * as Popover from '@radix-ui/react-popover'
import CrossIcon from '@bufferapp/ui/Icon/Icons/Cross'
import MagicWandIcon from '@bufferapp/ui/Icon/Icons/MagicWand'

import { useAppSelector, useAppDispatch } from '~publish/legacy/store'
import { queryLimits } from '~publish/legacy/ai/state/aiLimits/thunks'
import { selectGenerateContentLimits } from '~publish/legacy/ai/state/aiLimits/slice'
import { selectGeneratedIdeas } from '~publish/legacy/ai/state/contentIdeation/slice'
import { Questions } from './Questions'
import { GeneratedIdea } from './GeneratedIdea'
import { UpgradeOptions } from './UpgradeOptions'

import * as Styled from './styles'

/**
 *
 */
const GenerateIdeasButton = ({ source }: { source?: string }): JSX.Element => {
  const [currentIdea, setCurrentIdea] = React.useState<string | null>(null)
  const [showUpgradeOptions, setShowUpgradeOptions] =
    React.useState<boolean>(false)

  const dispatch = useAppDispatch()

  const { generatedIdeas } = useAppSelector((state) =>
    selectGeneratedIdeas(state),
  )

  const { canGenerateContent, limitsLoaded } = useAppSelector((state) =>
    selectGenerateContentLimits(state),
  )

  React.useEffect(() => {
    if (!limitsLoaded || canGenerateContent) return
    setShowUpgradeOptions(true)
  }, [limitsLoaded])

  React.useEffect(() => {
    const idea = generatedIdeas.length ? generatedIdeas[0] : null
    setCurrentIdea(idea)
    dispatch(queryLimits())
  }, [generatedIdeas])

  const onUpgrade = (): void => {
    setShowUpgradeOptions(true)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const onClose = (event): void => {
    if (showUpgradeOptions && currentIdea) {
      event.preventDefault()
      event.stopPropagation()
      setShowUpgradeOptions(false)
    }
  }

  const renderContent = (): JSX.Element => {
    if (showUpgradeOptions) {
      return <UpgradeOptions />
    }

    return currentIdea ? (
      <GeneratedIdea onUpgrade={onUpgrade} source={source} />
    ) : (
      <Questions />
    )
  }

  return (
    <Popover.Root>
      <Styled.PopoverTrigger asChild>
        <Styled.TriggerButton id="generate-ideas-button">
          <MagicWandIcon />
          Generate Ideas
        </Styled.TriggerButton>
      </Styled.PopoverTrigger>
      <Popover.Portal>
        <Styled.PopoverContent sideOffset={5}>
          <Styled.Background />
          {renderContent()}
          <Styled.PopoverClose aria-label="Close" onClick={onClose}>
            <CrossIcon />
          </Styled.PopoverClose>
          <Styled.PopoverArrow />
        </Styled.PopoverContent>
      </Popover.Portal>
    </Popover.Root>
  )
}

export { GenerateIdeasButton }
