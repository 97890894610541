import isEmpty from '../isEmpty'

// @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
const isObject = (value) => {
  return typeof value === 'object' && value?.constructor === Object
}

// @ts-expect-error TS(7023) FIXME: 'removeEmptyValuesFromObject' implicitly has retur... Remove this comment to see the full error message
const removeEmptyValuesFromObject = (obj, recursive = true) => {
  return Object.entries(obj).reduce((result, [key, value]) => {
    const recurseChildObject = recursive && isObject(value)

    // @ts-expect-error TS(7022) FIXME: 'updatedValue' implicitly has type 'any' because i... Remove this comment to see the full error message
    const updatedValue = recurseChildObject
      ? removeEmptyValuesFromObject(value)
      : value

    if (isEmpty(updatedValue)) return result
    return { ...result, [key]: updatedValue }
  }, {})
}

export default removeEmptyValuesFromObject
