import React from 'react'
import { Button, CoachMark, Image, NewBadge } from '@buffer-mono/popcorn'

import styles from './AllChannelsPage.module.css'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'

export function AllChannelsCoachmark({
  spotlightTop = '0',
}: {
  spotlightTop?: string
}): JSX.Element {
  const banner = useDismissableBanner('all-channels-coachmark')

  if (!banner.isActive) {
    return <></>
  }

  return (
    <CoachMark defaultOpen onDismiss={banner.dismiss}>
      <CoachMark.Overlay>
        <CoachMark.Spotlight
          style={{
            '--spotlight-top': spotlightTop,
            '--spotlight-left': '100px',
          }}
        />
      </CoachMark.Overlay>

      <CoachMark.Content
        className={styles.coachmark}
        showCloseIcon={false}
        side="right"
      >
        <CoachMark.Description>
          <Image
            src="https://buffer-publish.s3.amazonaws.com/images/channels-tour-sidebar.png"
            alt=""
          />
        </CoachMark.Description>

        <CoachMark.Title>
          <NewBadge /> All Your Posts in One Place 🚀
        </CoachMark.Title>
        <CoachMark.Description>
          We brought posts from all your channels to one page.
        </CoachMark.Description>
        <CoachMark.Footer>
          <CoachMark.Dismiss>
            <Button>Got it</Button>
          </CoachMark.Dismiss>
        </CoachMark.Footer>
      </CoachMark.Content>
    </CoachMark>
  )
}
