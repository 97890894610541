const compactFomatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
  notation: 'compact',
  compactDisplay: 'short',
  style: 'decimal',
})

/**
 * Formats number in compact format with casual suffix (e.g. 1M, 200K)
 */
export function formatCompactNumber(
  num: number | undefined | null,
  limit = Infinity,
): string {
  if (num === undefined || num === null) return ''
  const beyondLimitSuffix = num > limit ? '+' : ''
  return `${compactFomatter.format(Math.min(num, limit))}${beyondLimitSuffix}`
}
