import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const FieldWrapper = styled.div`
  padding: 3px 0;
  display: flex;
  width: 100%;

  :last-child {
    padding-bottom: 11px;
  }
`

const InputWrapper = styled.span`
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: var(--space-100);
`

const Label = styled.label`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  color: #343e47;
  width: 96px;
  padding-right: 10px;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'ariaLabel' implicitly has an 'any... Remove this comment to see the full error message
const FooterField = ({ ariaLabel, label, fieldContent }) => (
  <FieldWrapper>
    <Label id={ariaLabel}>{label}</Label>
    <InputWrapper>{fieldContent}</InputWrapper>
  </FieldWrapper>
)

FooterField.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  fieldContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default FooterField
