/**
 * Simple time picker using selects. Works with 12/24h formats.
 *
 * This component is controlled through the time prop, and uses the onChange
 * callback in props to let its parent know of any change.
 */

import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advanced from 'dayjs/plugin/advancedFormat'
import styled from 'styled-components'
import Select from './shared/Select'
import { grayDarker } from '@bufferapp/ui/style/colors'
import { ChevronDownIcon } from '@buffer-mono/popcorn'

dayjs.extend(advanced)
dayjs.extend(utc)
dayjs.extend(timezone)

export const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  &:focus-visible,
  &:focus {
    outline: none;
  }
`

export const PickerSelect = styled(Select)`
  display: inline-block;
  flex: 1;
  padding: calc(
    var(--space-150) - 1px
  ); // Decrease padding to accommodate border
  border: 1px solid var(--color-border-neutral);
  border-radius: var(--border-radius);
  cursor: pointer;
  color: ${grayDarker};
  line-height: normal;
  font-weight: var(--font-weight-medium);
  outline: none;
  appearance: none;

  &:disabled {
    cursor: not-allowed;
  }
`

export const SelectChevron = styled(ChevronDownIcon)`
  position: absolute;
  right: var(--space-100);
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`

const SlotTimePicker = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--space-100);
`

const TimeDigitSelector = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'onChange' implicitly has an 'any'... Remove this comment to see the full error message
  onChange,
  // @ts-expect-error TS(7031) FIXME: Binding element 'getOptions' implicitly has an 'an... Remove this comment to see the full error message
  getOptions,
  // @ts-expect-error TS(7031) FIXME: Binding element 'currentOption' implicitly has an ... Remove this comment to see the full error message
  currentOption,
  // @ts-expect-error TS(7031) FIXME: Binding element 'formatter' implicitly has an 'any... Remove this comment to see the full error message
  formatter,
}) => (
  <SelectWrapper>
    <PickerSelect onChange={onChange} value={currentOption}>
      {/* @ts-expect-error TS(7006) FIXME: Parameter 'd' implicitly has an 'any' type. */}
      {getOptions().map((d) => (
        <option value={d} key={d}>
          {formatter(d)}
        </option>
      ))}
    </PickerSelect>
    <SelectChevron />
  </SelectWrapper>
)

TimeDigitSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  getOptions: PropTypes.func.isRequired,
  currentOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  formatter: PropTypes.func.isRequired,
}

class TimePicker extends React.Component {
  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  onHourSelectChange = (e) => {
    this.onChange({ hour: e.target.value })
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  onMinSelectChange = (e) => {
    this.onChange({ min: e.target.value })
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  onPeriodSelectChange = (e) => {
    this.onChange({ dayPeriod: e.target.value })
  }

  /**
   * Moment exposes methods to update a time's hours and minutes, but not day period,
   * so we're using the string parsing method instead
   */
  // @ts-expect-error TS(7006) FIXME: Parameter 'newTimeUnits' implicitly has an 'any' t... Remove this comment to see the full error message
  onChange = (newTimeUnits) => {
    const timeFormats = this.getTimeFormats()
    const timeUnits = this.getTimeUnits()

    const dateFormat = 'YYYY/M/D'
    // @ts-expect-error TS(2339) FIXME: Property 'time' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const formattedDate = this.props.time.format(dateFormat)
    const updatedTimeUnits = {
      ...timeUnits,
      ...newTimeUnits,
    }

    const momentParams = [
      `${formattedDate} ${updatedTimeUnits.hour}:${updatedTimeUnits.min} ${updatedTimeUnits.dayPeriod}`,
      `${dateFormat} ${timeFormats.hour}:${timeFormats.min} ${timeFormats.dayPeriod}`,
    ]

    // @ts-expect-error TS(2339) FIXME: Property 'timezone' does not exist on type 'Readon... Remove this comment to see the full error message
    const time = this.props.timezone
      ? // @ts-expect-error TS(2339) FIXME: Property 'timezone' does not exist on type 'Readon... Remove this comment to see the full error message
        dayjs(...momentParams).tz(this.props.timezone, true)
      : dayjs(...momentParams)

    // @ts-expect-error TS(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
    this.props.onChange(time)
  }

  getHoursInDay = () =>
    // @ts-expect-error TS(2339) FIXME: Property 'shouldUse24hTime' does not exist on type... Remove this comment to see the full error message
    this.props.shouldUse24hTime
      ? [...Array(24).keys()] // [0..23]
      : [...Array(12 + 1).keys()].slice(1) // [1..12]

  getMinutesInHour = () => [...Array(60).keys()] // [0..59]

  getTimeFormats = () => ({
    // @ts-expect-error TS(2339) FIXME: Property 'shouldUse24hTime' does not exist on type... Remove this comment to see the full error message
    hour: this.props.shouldUse24hTime ? 'H' : 'h',
    min: 'm',
    // @ts-expect-error TS(2339) FIXME: Property 'shouldUse24hTime' does not exist on type... Remove this comment to see the full error message
    dayPeriod: this.props.shouldUse24hTime ? ' ' : 'a',
  })

  getTimeUnits = () => {
    const timeFormats = this.getTimeFormats()

    return {
      // @ts-expect-error TS(2339) FIXME: Property 'time' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      hour: this.props.time.format(timeFormats.hour),
      // @ts-expect-error TS(2339) FIXME: Property 'time' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      min: this.props.time.format(timeFormats.min),
      // @ts-expect-error TS(2339) FIXME: Property 'time' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      dayPeriod: this.props.time.format(timeFormats.dayPeriod),
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'timeUnit' implicitly has an 'any' type.
  leftPadTimeUnit = (timeUnit) => (timeUnit < 10 ? `0${timeUnit}` : timeUnit)

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'shouldUse24hTime' does not exist on type... Remove this comment to see the full error message
    const { shouldUse24hTime } = this.props
    const shouldDisplayPeriodSelect = !shouldUse24hTime

    const { hour, min, dayPeriod } = this.getTimeUnits()

    return (
      <SlotTimePicker>
        <TimeDigitSelector
          onChange={this.onHourSelectChange}
          currentOption={hour}
          getOptions={this.getHoursInDay}
          formatter={this.leftPadTimeUnit}
        />
        <TimeDigitSelector
          onChange={this.onMinSelectChange}
          currentOption={min}
          getOptions={this.getMinutesInHour}
          formatter={this.leftPadTimeUnit}
        />
        {shouldDisplayPeriodSelect && (
          <TimeDigitSelector
            onChange={this.onPeriodSelectChange}
            currentOption={dayPeriod}
            getOptions={() => ['am', 'pm']}
            formatter={(v) => v.toUpperCase()}
          />
        )}
      </SlotTimePicker>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
TimePicker.propTypes = {
  shouldUse24hTime: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  // @ts-expect-error TS(2345) FIXME: Argument of type 'typeof import("/Users/mayauribe/... Remove this comment to see the full error message
  time: PropTypes.instanceOf(dayjs),
  timezone: PropTypes.string,
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
TimePicker.defaultProps = {
  time: dayjs(),
}

export default TimePicker
