import React from 'react'
import {
  Button,
  Dialog,
  Flex,
  Text,
  Heading,
  Image,
} from '@buffer-mono/popcorn'

import styles from './ChannelTour.module.css'

export const DialogTourIntro = ({
  open,
  onDialogClose,
  onTourStart,
}: {
  open: boolean
  onDialogClose: () => void
  onTourStart: () => void
}): JSX.Element => {
  const handleTourStart = (): void => {
    onTourStart()
  }

  const handleInteractOutside = (event: Event): void => {
    event.preventDefault()
  }

  return (
    <Dialog open={open} onOpenChange={onDialogClose}>
      <Dialog.Content
        size="medium"
        className={styles.dialogContent}
        onInteractOutside={handleInteractOutside}
      >
        <Dialog.Body className={styles.dialogBody}>
          <Flex direction="column" align="center" gap="space-200">
            <Image
              src="https://buffer-publish.s3.amazonaws.com/images/channels-tour-intro.png"
              alt="New look"
            />
            <Heading as="h2">This page has a new look! ✨</Heading>
            <Text align="center">
              Enjoy redesigned navigation and post cards in all single channel
              pages!
            </Text>
            <Flex direction="column" align="center" gap="space-100">
              <Button variant="primary" size="large" onClick={handleTourStart}>
                What else has changed?
              </Button>
              <Button variant="tertiary" onClick={onDialogClose}>
                Close
              </Button>
            </Flex>
          </Flex>
        </Dialog.Body>
        <Dialog.CloseButton className={styles.dialogCloseButton} />
      </Dialog.Content>
    </Dialog>
  )
}
