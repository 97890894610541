import styled from 'styled-components'
import { grayDark, white } from '@bufferapp/ui/style/colors'
import RetweetIcon from '@bufferapp/ui/Icon/Icons/Retweet'
import CommentIcon from '@bufferapp/ui/Icon/Icons/CommentRoundOutline'

import { LinkPreview as Link } from '../../../Common/LinkPreview'

import {
  PostWrapper as CommonPostWrapper,
  previewBlack,
  previewGrayDark,
  previewGrayLight,
  previewGrayMedium,
} from '../../../Common/styles'

const mastodonDark = '#4d4dc3'
const mastodonDarker = '#3f3f95'

export const PostWrapper = styled(CommonPostWrapper)`
  &:first-child:not(:only-child) {
    border-radius: 4px 4px 0 0;
  }

  & + & {
    border-top: none;
    border-radius: 0;
  }

  &:last-child:not(:only-child) {
    border-radius: 0 0 4px 4px;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 16px;
`

export const Avatar = styled.img`
  width: 46px;
  height: 46px;
  z-index: 1;
  border-radius: 12px;
`

export const UsernameWrapper = styled.div`
  display: flex;
  flex-direction column;
  align-items: flex-start;
  margin-left: 12px;
`

export const Username = styled.span`
  display: inline-block;
  color: ${previewBlack};
  max-width: 185px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
`

export const FormattedUsername = styled.span`
  display: inline-block;
  color: ${grayDark};
  font-weight: 400;
  max-width: 185px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const Body = styled.div`
  margin: 0 16px;
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px;
`

export const Content = styled.div`
  img {
    width: 100%;
    border-radius: 12px;
  }

  span {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: ${previewBlack} !important;
    white-space: pre-wrap !important;
    overflow-wrap: break-word !important;
    hyphens: manual !important;
  }

  p {
    margin-top: 0;
  }

  .hashtag {
    color: ${previewGrayMedium} !important;
    cursor: default !important;
    &:hover {
      color: ${previewGrayDark} !important;
    }
  }

  a {
    color: ${mastodonDark} !important;
    cursor: default !important;
    &:hover {
      color: ${mastodonDarker} !important;
    }
  }
`

export const ContentWarning = styled.div`
  margin-bottom: ${(props: { showContent: boolean }): string =>
    props.showContent ? '22px' : '0'};
`

export const ShowMore = styled.button`
  background: #d9e1e8;
  border-radius: 2px;
  border: none;
  font-size: 11px;
  font-weight: bold;
  margin-left: 4px;
  padding: 0 6px;
  text-transform: uppercase;
  vertical-align: text-bottom;

  &:hover {
    background: #ccd7e0;
  }
`

export const ReshareIcon = styled(RetweetIcon)`
  rotate: 90deg;
`

export const CommentIconStyled = styled(CommentIcon)`
  width: 18px;
  height: 18px;
`

export const LinkPreview = styled(Link)`
  border-radius: 12px;
  border: 0.5px solid ${previewGrayLight};
  margin: 12px 16px 0 16px;

  .content {
    background-color: ${white};
  }

  .title {
    font-weight: 400;
    font-size: 14px;
    margin: 0 0 4px 0;
  }
`
