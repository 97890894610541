import React from 'react'
import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'
import HelpIcon from '@bufferapp/ui/Icon/Icons/Help'
import SparklesIcon from '@bufferapp/ui/Icon/Icons/Sparkles'

import { useAppSelector } from '~publish/legacy/store'
import {
  selectContentGenerationStatus,
  selectContentGenerationConfig,
  selectContentGenerationSelection,
} from '~publish/legacy/ai/state/slice'

import * as Styled from './Footer.styles'

/**
 *
 */
const TIPS = {
  withPrompt: {
    Icon: SparklesIcon,
    highlight: 'Pro Tip:',
    text: 'Select parts of your existing copy to refine using the AI Assistant!',
    link: {
      label: 'Learn more',
      url: 'https://support.buffer.com/article/583-using-buffers-ai-assistant?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more#h_01GS8G1RP30E19WYDGFG2SGHVT',
    },
  },
  emptyPrompt: {
    Icon: HelpIcon,
    highlight: null,
    text: 'Need tips on writing better prompts?',
    link: {
      label: 'See tips.',
      url: 'https://support.buffer.com/article/583-using-buffers-ai-assistant?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more#h_01H6BYVHYR8QS9NX1GBT6NC1KE',
    },
  },
  withResults: {
    Icon: WarningIcon,
    highlight: null,
    text: 'AI responses can be inaccurate or misleading. Always review before using.',
    link: {
      label: 'Learn more.',
      url: 'https://support.buffer.com/article/583-using-buffers-ai-assistant?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more#h_01GS8G3FKDMZZC5C0N9TM5716T',
    },
  },
}

/**
 *
 */
function Footer(): JSX.Element | null {
  const {
    contentGenerationInProgress,
    contentGenerationError,
    contentGenerationResults,
  } = useAppSelector((state) => selectContentGenerationStatus(state))

  const { prompt } = useAppSelector((state) =>
    selectContentGenerationConfig(state),
  )

  const contentGenerationSelection = useAppSelector((state) =>
    selectContentGenerationSelection(state),
  )

  let tip = prompt ? 'withPrompt' : 'emptyPrompt'
  if (contentGenerationResults.length > 0) {
    tip = 'withResults'
  }

  const tipConfig = tip ? TIPS[tip as keyof typeof TIPS] : null
  const { Icon, highlight, text, link } = tipConfig || {}
  const { label, url } = link || {}

  const showTip =
    !contentGenerationInProgress &&
    !contentGenerationError &&
    !contentGenerationSelection

  return showTip ? (
    <Styled.Wrapper>
      {Icon && <Icon />}
      <Styled.Tip>
        {highlight && <span>{highlight}</span>}
        {text}
        {label && url && (
          <a
            href={url || ''}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="ai-assistant-footer-tip-link"
          >
            {label}
          </a>
        )}
      </Styled.Tip>
    </Styled.Wrapper>
  ) : null
}

export { Footer }
