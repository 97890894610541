import type Uppy from '@uppy/core'
import { nanoid } from '@reduxjs/toolkit'
import { serializeError } from 'serialize-error'
import { logError } from '~publish/legacy/utils/logError'
import { WebEventEmitter } from '../../utils/WebEventEmitter'
import { uploadsLogger } from '../loggers'
import { type RemoteFile, fileFromUrl } from './remoteFile'

import { createUppy } from '../uppy/createUppy'

import type { BufferUpload, S3Upload, Upload } from '../entities/Upload'
import type { UploaderRestrictions } from '../values/UploaderRestrictions'
import {
  type FileRejection,
  validateFilesForUploads,
} from './validation/validateFilesForUpload'
import { UploadMetadata } from '../values/UploadMetadata'
import { hasMixedMediaTypes } from './validation/validationUtils/hasMixedMediaTypes'
import type { UploadSource } from '../values/UploadSource'
import { LocalFile } from '../values/LocalFile'
import { UppyFile, type UppyFileDescriptor } from '../uppy/UppyFile'
import { assertUppyMetadata } from '../uppy/assertUppyMetadata'
import { BufferUploadsApi } from '../clients/BufferUploadsApi'
import { UploadProgress } from '../values/UploadProgress'
import { generateThumbnail } from './generateThumbnail'

export type UploadEventCallback = (upload: Upload) => void
export type UploadFinishedCallback = (upload: BufferUpload) => void
type EmmittedError = Error & { __emmitted?: boolean }

export type UploaderEvents = {
  'file-fetch-failed': (file: RemoteFile, error: Error) => void
  'file-upload-attempted': (files: LocalFile[]) => void
  'file-validation-failed': (file: LocalFile, error: Error) => void
  'thumbnail-generated': (event: Pick<Upload, 'id' | 'thumbnailUrl'>) => void
  'upload-prevented': (error: Error, files?: LocalFile[]) => void
  'upload-started': (upload: Upload) => void
  'upload-progress': (
    event: Pick<Upload, 'id'>,
    progress: UploadProgress,
  ) => void
  'upload-stalled': (event: Pick<Upload, 'id'>) => void
  'upload-to-s3-finished': (upload: S3Upload) => void
  'upload-finished': UploadFinishedCallback
  'upload-removed': (event: Pick<Upload, 'id'>) => void
  // @ts-expect-error TS(7006) FIXME: Parameter 'error' implicitly has an 'any' type.
  'upload-failed': (event: Upload, error) => void
  'uploader-reset': (uploaderId: string) => void
  'uploader-closed': (uploaderId: string) => void
}
export class Uploader extends WebEventEmitter<UploaderEvents> {
  private metadatMap: WeakMap<File, UploadMetadata> = new WeakMap()
  private readonly getDefaultCount: () => number = () =>
    this.uppyInstance.getFiles().length

  constructor(
    private readonly id: string,
    private readonly userId: string,
    private readonly organizationId: string,
    private readonly uppyInstance: Uppy,
    private readonly fileRestrictions:
      | UploaderRestrictions
      | (() => UploaderRestrictions),
    private readonly getCustomCount?: () => number,
  ) {
    super()

    this.mapUppyEventsToUploaderEvents()
  }

  getId(): string {
    return this.id
  }

  getOrganizationId(): string {
    return this.organizationId
  }

  // TODO UPLOADS: rename to upload and return a promise of BufferUploads
  async addFiles(
    files: File[],
    meta: Pick<UploadMetadata, 'source'>,
  ): Promise<BufferUpload[]> {
    return this.doAddFiles(files, meta)
      .then(() => this.generateThumbnails())
      .then(() => this.doUploadTos3())
      .then((uploads) => this.createUpdateDocument(uploads))
  }

  private async doAddFiles(
    files: File[],
    meta: Pick<UploadMetadata, 'source'>,
  ): Promise<void> {
    this.generateMetadata(files, meta)

    this.emitFileUploadAttempted(files)

    const fileRestrictions = this.getUploaderRetrictions()

    if (fileRestrictions.mixedMediaError && hasMixedMediaTypes(files)) {
      const error = new Error(fileRestrictions.mixedMediaError)
      this.emitUploadPrevented(error, files)

      return
    }

    const { validFiles, invalidFiles } = await validateFilesForUploads(files, {
      fileRestrictions,
      currentFileCount: this.getUploadsCount(),
    })

    this.emitFileValidationFailed(invalidFiles)

    const descriptors = validFiles.map((file) => this.getUppyDescriptor(file))

    this.uppyInstance.addFiles(descriptors)
  }

  private getUploaderRetrictions(): UploaderRestrictions {
    return typeof this.fileRestrictions === 'function'
      ? this.fileRestrictions()
      : this.fileRestrictions
  }

  /**
   * addFilesFromUrls
   *
   * When urls are provided instead files, we can use this method to get
   * the content of the files and add files to the uploader finally.
   */
  async addFilesFromUrls(
    remoteFiles: RemoteFile | RemoteFile[],
    source: UploadSource,
  ): Promise<void> {
    if (!Array.isArray(remoteFiles)) {
      remoteFiles = [remoteFiles]
    }

    const filesToDownload: Promise<File | void>[] = remoteFiles.map(
      async (remoteFile) => {
        uploadsLogger('Fetching file from url: %s', remoteFile.remoteUrl)
        return fileFromUrl(remoteFile).catch((error) => {
          this.emit('file-fetch-failed', remoteFile, error)
        })
      },
    )

    Promise.allSettled(filesToDownload).then((downloadResults) => {
      const filesDownloaded: File[] = downloadResults
        .filter(
          (result): result is PromiseFulfilledResult<File> =>
            result.status === 'fulfilled',
        )
        .map((result) => result.value)

      if (filesDownloaded?.length) {
        this.addFiles(filesDownloaded, {
          source,
        })
      }
    })
  }

  getFileData(id: string): File | Blob {
    return this.uppyInstance.getFile(id).data
  }

  reset(): void {
    this.uppyInstance.cancelAll()
    this.metadatMap = new WeakMap()
    this.emit('uploader-reset', this.id)
  }

  close(cleanup: () => void): void {
    this.reset()
    this.uppyInstance.close()
    this.emit('uploader-closed', this.id)
    this.offAll()
    cleanup()
  }

  async uploadDirect(file: File): Promise<S3Upload> {
    // @ts-expect-error TS(7034) FIXME: Variable 'uploadError' implicitly has type 'any' i... Remove this comment to see the full error message
    let uploadError
    uploadsLogger('Uploading file directly to S3: %s', file.name)

    const id = `${this.id}-child-${nanoid()}`
    const childUppy = createUppy({
      id,
      userId: this.userId,
    })

    childUppy.on('complete', () => childUppy.close())
    childUppy.on('upload-error', (_, error) => {
      uploadError = error
    })

    const fileDescriptor = this.getUppyDescriptor(file)

    childUppy.addFile(fileDescriptor)
    const [upload] = await childUppy
      .upload<UploadMetadata>()
      .then((response) => {
        // @ts-expect-error TS(7005) FIXME: Variable 'uploadError' implicitly has an 'any' typ... Remove this comment to see the full error message
        if (uploadError) throw uploadError

        if (response.failed.length) {
          throw new Error(`${response.failed[0].error}`)
        }

        return response.successful
      })
      .then((files) => UppyFile.mapToS3Upload(files))

    uploadsLogger('File %s uploaded directly to S3 %s', file.name, upload.url)

    return upload
  }

  removeUpload(upload: Upload): void {
    this.uppyInstance.removeFile(upload.id)
  }

  reportError<T extends object>(error: Error, file: T): void {
    const shouldDebug = 'cause' in error || 'isNetworkError' in error
    logError(error, {
      context: `uploader.${this.getId()}`,
      metaData: {
        file,
        debugInfo: shouldDebug && serializeError(error),
      },
    })
  }

  private getUploadsCount(): number {
    return this.getCustomCount?.() ?? this.getDefaultCount()
  }

  private emitFileValidationFailed(invalidFiles: FileRejection[]): void {
    invalidFiles.forEach(({ file, message }) => {
      this.emit(
        'file-validation-failed',
        this.getLocalFile(file),
        new Error(message),
      )
    })
  }

  private emitFileUploadAttempted(files: File[]): void {
    this.emit(
      'file-upload-attempted',
      files.map((file) => this.getLocalFile(file)),
    )
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'error' implicitly has an 'any' type.
  private emitUploadPrevented(error, files: File[]): void {
    this.emit(
      'upload-prevented',
      error,
      files.map((file) => this.getLocalFile(file)),
    )
  }

  private async generateThumbnails(): Promise<void[]> {
    const files = this.uppyInstance.getFiles<UploadMetadata>()

    const promises = files
      .filter(UppyFile.shouldGenerateThumbnail)
      .map(async (file) => {
        return generateThumbnail(file)
          .then((thumbnail) => {
            return thumbnail
          })
          .then((thumbnail) => this.uploadDirect(thumbnail))
          .then(({ url }) => {
            this.uppyInstance.setFileState(file.id, { preview: url })
            this.emit('thumbnail-generated', {
              id: file.id,
              thumbnailUrl: url,
            })
          })
          .catch((reason) => {
            reason.message = `Thumbnail generation failed for ${file.name}. ${reason.message}`
            this.emit(
              'upload-failed',
              UppyFile.toFailedUpload(file, reason.message),
              reason,
            )
            // do not try to upload if thumbnail failed
            this.uppyInstance.removeFile(file.id)
          })
      })

    return Promise.all(promises)
  }

  private async doUploadTos3(): Promise<S3Upload[]> {
    return this.uppyInstance
      .upload<UploadMetadata>()
      .then((response) => response?.successful ?? [])
      .then((files) => UppyFile.mapToS3Upload(files))
  }

  private async createUpdateDocument(
    uploads: S3Upload[],
  ): Promise<BufferUpload[]> {
    const promises = uploads.map((upload) => {
      return BufferUploadsApi.create(upload)
        .then((bufferUpload) => {
          this.emit('upload-finished', bufferUpload)
          return bufferUpload
        })
        .catch((error) => {
          this.emitUploadFailed(upload, error)
          return null
        })
    })

    return (await Promise.all(promises)).filter(
      (result): result is BufferUpload => result !== null,
    )
  }

  private generateMetadata(
    files: File[],
    meta: Pick<UploadMetadata, 'source'> = {},
  ): void {
    files.forEach((file) => {
      if (this.metadatMap.has(file)) {
        // we should only generate metadata once
        uploadsLogger(
          'Warning: Metadata already exists for file: %s',
          file.name,
        )
        return
      }

      uploadsLogger('Generating metadata for file: %s', file.name)
      this.metadatMap.set(
        file,
        UploadMetadata.new({
          ...meta,
          organizationId: this.organizationId,
          trackingId: nanoid(),
          uploaderId: this.id,
        }),
      )
    })
  }

  private getMetadata(file: File): UploadMetadata {
    const metadata = this.metadatMap.get(file)

    if (!metadata) throw new Error('Metadata not found for file')

    // Spreading into a new object because Uppy mutates the metadata in some cases
    return { ...metadata }
  }

  /**
   * Uppy sometimes emits the same error multiple times. This method ensures
   */
  private emitUploadFailed(upload: Upload, error: EmmittedError): void {
    if (!error.__emmitted) {
      error.message = `Upload failed for ${upload.name}. ${error.message}`
      this.emit('upload-failed', upload, error)
      error.__emmitted = true
    }
  }

  /**
   * Translates uppy events into uploader events.
   *
   * This hides implementation details (i.e. uppy dependency) and allows for
   * emitting directly from the uploader instance
   */
  private mapUppyEventsToUploaderEvents(): void {
    this.uppyInstance.on('restriction-failed', (file, error) => {
      uploadsLogger('Restriction failed for %s: %s', file?.name, error.message)

      if (!file) {
        this.emitUploadPrevented(error, [])

        return
      }

      const fileData: File = file.data as File

      const localFile = this.getLocalFile(fileData)

      this.emit('file-validation-failed', localFile, error)
    })

    this.uppyInstance.on('upload-error', (file, error) => {
      assertUppyMetadata(file)
      const upload = UppyFile.toUpload(file)
      this.emitUploadFailed(upload, error)
    })

    this.uppyInstance.on('file-removed', (file) => {
      this.emit('upload-removed', { id: file.id })
    })

    this.uppyInstance.on('file-added', (file) => {
      assertUppyMetadata(file)
      const upload = UppyFile.toUpload(file)
      this.emit('upload-started', upload)
    })

    this.uppyInstance.on('upload-progress', (file, uppyProgress) => {
      const progress = UploadProgress.new(
        uppyProgress.bytesTotal,
        uppyProgress.bytesUploaded,
      )
      this.emit('upload-progress', { id: file.id }, progress)
    })
  }

  private getLocalFile(file: File): LocalFile {
    if (!this.metadatMap.has(file)) {
      this.generateMetadata([file])
    }

    return LocalFile.new(file, this.getMetadata(file))
  }

  private getUppyDescriptor(file: File): UppyFileDescriptor {
    const { meta, ...fileDetails } = this.getLocalFile(file)

    return {
      ...fileDetails,
      meta: {
        ...meta,
        // we reuse the trackingId which is unique as a relativePath
        // so that Uppy does not complain about duplicate files
        relativePath: meta.trackingId,
      },
    }
  }

  /**
   * @deprecated
   *
   * We should hide uppy as an implementation detail.
   * Uppy events can be translated into our own uploader events.
   * This method could still be useful for testing
   */
  getUppyInstance(): Uppy {
    return this.uppyInstance
  }
}
