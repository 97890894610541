import { actions as dataFetchActions } from '@bufferapp/async-data-fetch'
import { actions as modalActions } from '~publish/legacy/modals/reducer'
import { getURL } from '~publish/legacy/utils/formatters'
import { SEGMENT_NAMES } from '~publish/legacy/constants'
import { actionTypes } from './reducer'

// @ts-expect-error TS(7031) FIXME: Binding element 'getState' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ getState, dispatch }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    // eslint-disable-line
    next(action)

    switch (action.type) {
      case actionTypes.CANCEL_TRIAL: {
        dispatch(dataFetchActions.fetch({ name: 'cancelTrial' }))
        dispatch(modalActions.hideTrialCompleteModal())
        break
      }
      case actionTypes.COMPLETE_UPGRADE_TRIAL: {
        const {
          hasExpiredProTrial,
          hasExpiredBusinessTrial,
          isPremiumBusiness,
        } = getState().trialCompleteModal
        const ctaName = () => {
          if (hasExpiredProTrial) return SEGMENT_NAMES.EXPIRED_TRIAL_PRO_UPGRADE
          if (hasExpiredBusinessTrial) {
            if (isPremiumBusiness) {
              return SEGMENT_NAMES.EXPIRED_TRIAL_PREMIUM_UPGRADE
            }
            return SEGMENT_NAMES.EXPIRED_TRIAL_BUSINESS_UPGRADE
          }
        }

        /** For business trials send them to the billing page */
        if (hasExpiredBusinessTrial) {
          window.location.assign(
            `${getURL.getBillingURL({
              cta: ctaName(),
            })}`,
          )
          return
        }

        /** For pro trials show the upgrade/switch plan modal */
        if (hasExpiredProTrial) {
          dispatch(modalActions.hideTrialCompleteModal())
        }
        break
      }
      default:
        break
    }
  }
