import React, { useMemo, useState } from 'react'
import {
  DndContext,
  DragOverlay,
  type DragStartEvent,
  type DragEndEvent,
  KeyboardSensor,
  useSensor,
  useSensors,
  PointerSensor,
} from '@dnd-kit/core'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'

import { ChannelItem } from './ChannelItem'
import { useChannels } from './useChannels'

interface DraggableChannelListProps {
  filteredChannels: ReturnType<typeof useChannels>['channels']
  onReorder: (dragIndex: number, hoverIndex: number) => void
}

export function DraggableChannelList({
  filteredChannels,
  onReorder,
}: DraggableChannelListProps): JSX.Element {
  const { channels } = useChannels()
  const [draggedChannel, setDraggedChannel] = useState<
    ReturnType<typeof useChannels>['channels'][0] | undefined
  >(undefined)

  const handleDragStart = (event: DragStartEvent): void => {
    const { active } = event
    if (!active.id) return
    setDraggedChannel(channels.find((channel) => channel.id === active.id))
  }

  const handleDragEnd = ({ active, over }: DragEndEvent): void => {
    if (!active.id || !over?.id) return
    const dragIndex = channels.findIndex((channel) => channel.id === active.id)
    const hoverIndex = channels.findIndex((channel) => channel.id === over.id)

    onReorder(dragIndex, hoverIndex)
  }

  const disableDragAndDrop = useMemo(() => {
    return filteredChannels.length !== channels.length || channels.length === 0
  }, [channels, filteredChannels])

  const sensors = useSensors(
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(PointerSensor),
  )
  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      sensors={sensors}
      modifiers={[restrictToFirstScrollableAncestor]}
    >
      <SortableContext
        items={channels}
        strategy={verticalListSortingStrategy}
        disabled={disableDragAndDrop}
      >
        {filteredChannels.map((channel) => (
          <ChannelItem
            channel={channel}
            key={channel.id}
            dragDisabled={disableDragAndDrop}
          />
        ))}
      </SortableContext>
      <DragOverlay>
        {draggedChannel && <ChannelItem channel={draggedChannel} dragged />}
      </DragOverlay>
    </DndContext>
  )
}
