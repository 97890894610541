import React from 'react'

export const PostDiagram = (): JSX.Element => {
  return (
    <svg
      width="358"
      height="130"
      viewBox="0 0 358 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0074 65.7883H296.507"
        stroke="#E0E0E0"
        strokeWidth="4"
        strokeDasharray="2 9"
      />
      <g filter="url(#filter0_d_360_775)">
        <rect
          x="130.113"
          y="14.7949"
          width="93.3637"
          height="93.3637"
          rx="5.70588"
          fill="white"
        />
        <rect
          x="130.613"
          y="15.2949"
          width="92.3637"
          height="92.3637"
          rx="5.20588"
          stroke="#F1F1F1"
        />
      </g>
      <rect
        x="139.085"
        y="25.6197"
        width="75.7735"
        height="39.8784"
        rx="6"
        fill="#DEDEDE"
      />
      <rect
        x="139.085"
        y="70.8596"
        width="75.7735"
        height="10.8248"
        rx="5.41239"
        fill="#DEDEDE"
      />
      <rect
        x="139.085"
        y="87.0972"
        width="45.77"
        height="10.8248"
        rx="5.41239"
        fill="#DEDEDE"
      />
      <rect
        x="203.18"
        y="3.29381"
        width="34.4124"
        height="34.4124"
        rx="17.2062"
        fill="#ABD464"
        stroke="white"
        strokeWidth="5.41239"
      />
      <path
        d="M214.106 20.4334C213.501 21.0353 213.501 22.0113 214.106 22.6133L218.486 26.9731L229.436 16.0735C230.04 15.4716 230.04 14.4956 229.436 13.8936C228.831 13.2916 227.85 13.2916 227.246 13.8936L218.486 22.6133L216.296 20.4334C215.691 19.8314 214.71 19.8314 214.106 20.4334Z"
        fill="white"
      />
      <g filter="url(#filter1_d_360_775)">
        <rect
          x="264.628"
          y="21.4175"
          width="78.7412"
          height="78.7412"
          rx="5.70588"
          fill="white"
        />
        <rect
          x="265.128"
          y="21.9175"
          width="77.7412"
          height="77.7412"
          rx="5.20588"
          stroke="#F2F2F2"
        />
      </g>
      <rect
        x="272.616"
        y="30.5469"
        width="63.9059"
        height="32.5076"
        rx="6"
        fill="#E3E3E3"
      />
      <rect
        x="272.616"
        y="67.6411"
        width="63.9059"
        height="9.12941"
        rx="4.56471"
        fill="#E3E3E3"
      />
      <rect
        x="272.616"
        y="81.3354"
        width="38.6796"
        height="9.12941"
        rx="4.56471"
        fill="#E3E3E3"
      />
      <rect
        x="325.073"
        y="11.8915"
        width="27.4583"
        height="27.4583"
        rx="13.7291"
        fill="#CFE7A6"
        stroke="white"
        strokeWidth="4.31865"
      />
      <path
        d="M333.79 25.5675C333.308 26.0478 333.308 26.8266 333.79 27.3069L337.285 30.7857L346.023 22.0887C346.505 21.6084 346.505 20.8297 346.023 20.3493C345.54 19.869 344.758 19.869 344.275 20.3493L337.285 27.3069L335.538 25.5675C335.055 25.0872 334.273 25.0872 333.79 25.5675Z"
        fill="white"
      />
      <g filter="url(#filter2_d_360_775)">
        <rect
          x="14.6281"
          y="21.4175"
          width="78.7412"
          height="78.7412"
          rx="5.70588"
          fill="white"
        />
        <rect
          x="15.1281"
          y="21.9175"
          width="77.7412"
          height="77.7412"
          rx="5.20588"
          stroke="#F2F2F2"
        />
      </g>
      <rect
        x="22.6163"
        y="30.5469"
        width="63.9059"
        height="32.5076"
        rx="6"
        fill="#E3E3E3"
      />
      <rect
        x="22.6163"
        y="67.6411"
        width="63.9059"
        height="9.12941"
        rx="4.56471"
        fill="#E3E3E3"
      />
      <rect
        x="22.6163"
        y="81.3354"
        width="38.68"
        height="9.12941"
        rx="4.56471"
        fill="#E3E3E3"
      />
      <rect
        x="75.073"
        y="11.8915"
        width="27.4583"
        height="27.4583"
        rx="13.7291"
        fill="#CFE7A6"
        stroke="white"
        strokeWidth="4.31865"
      />
      <path
        d="M83.7904 25.5675C83.3079 26.0478 83.3079 26.8266 83.7904 27.3069L87.2853 30.7857L96.0225 22.0887C96.5051 21.6084 96.5051 20.8297 96.0225 20.3493C95.54 19.869 94.7576 19.869 94.2751 20.3493L87.2853 27.3069L85.5379 25.5675C85.0553 25.0872 84.273 25.0872 83.7904 25.5675Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_360_775"
          x="114.103"
          y="4.19733"
          width="125.384"
          height="125.384"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5.41239" />
          <feGaussianBlur stdDeviation="8.005" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.85098 0 0 0 0 0.85098 0 0 0 0 0.85098 0 0 0 0.49 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_360_775"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_360_775"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_360_775"
          x="250.618"
          y="12.8199"
          width="106.761"
          height="106.761"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5.41239" />
          <feGaussianBlur stdDeviation="7.005" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.85098 0 0 0 0 0.85098 0 0 0 0 0.85098 0 0 0 0.39 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_360_775"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_360_775"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_360_775"
          x="0.618052"
          y="12.8199"
          width="106.761"
          height="106.761"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5.41239" />
          <feGaussianBlur stdDeviation="7.005" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.85098 0 0 0 0 0.85098 0 0 0 0 0.85098 0 0 0 0.39 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_360_775"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_360_775"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
