import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import ProfileDragWrapper from '../ProfileDragWrapper'
import type {
  DropProfileProps,
  Profile,
} from '~publish/legacy/profile-sidebar/types'
import { usePublishRevamp } from '~publish/hooks/usePublishRevamp'

type ProfileListProps = {
  profiles: Profile[]
  selectedProfileId: string
  onProfileClick: (
    profile: Profile,
    options?: { shouldRedirectToChannelPage?: boolean },
  ) => void
  onDropProfile: (args: DropProfileProps) => void
  profileLimit: number
}

const ProfileList = ({
  profiles,
  selectedProfileId,
  onProfileClick,
  onDropProfile,
  profileLimit,
}: ProfileListProps): JSX.Element => {
  const [shouldRedirectToChannelPage] = usePublishRevamp()

  const profileClickCallback = useCallback(
    (profileId: string): void => {
      const profile = profiles.find(({ id }) => id === profileId)
      if (profile) {
        onProfileClick(profile, { shouldRedirectToChannelPage })
      }
    },
    [profiles, onProfileClick, shouldRedirectToChannelPage],
  )

  return (
    <>
      {profiles.map((profile, index) => (
        <ProfileDragWrapper
          avatarUrl={profile.avatarUrl}
          type={profile.type}
          handle={profile.displayName ?? profile.handle}
          pendingCount={profile.pendingCount}
          sentCount={profile.sentCount}
          selected={profile.id === selectedProfileId}
          locked={profile.disabled}
          disconnected={profile.isDisconnected}
          onClick={profileClickCallback}
          profileLimit={profileLimit}
          onDropProfile={onDropProfile}
          organizationId={profile.organizationId}
          id={profile.id}
          index={index}
          key={profile.id}
          location={profile.location}
          additionalInfo={profile.locationData ?? profile.serverUrl}
        />
      ))}
    </>
  )
}

ProfileList.propTypes = {
  onProfileClick: PropTypes.func.isRequired,
  onDropProfile: PropTypes.func.isRequired,
  selectedProfileId: PropTypes.string,
  profileLimit: PropTypes.number.isRequired,
}

ProfileList.defaultProps = {
  profiles: [],
  selectedProfileId: undefined,
}

export default ProfileList
