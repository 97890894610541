import { useMemo, useEffect } from 'react'
import { getUnixTime } from 'date-fns'
import { useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { graphql, getFragmentData } from '~publish/gql'
import { actions as calendarActions } from '~publish/legacy/calendar/reducer'
import { useSplitEnabled } from '@buffer-mono/features'

import type {
  GetCalendarAndPostListQueryVariables,
  GetCalendarAndPostListQuery,
  CalendarView_PostFragment,
} from '~publish/gql/graphql'
import { getTimezoneFromBrowser } from '~publish/legacy/utils/timezone'
import { useOrganizationId } from '~publish/legacy/accountContext'
import { useOnUpdatePostsList, CalendarView_Post } from './usePostFragment'
import { useWeeklyDates } from '~publish/pages/Calendar/hooks/useWeeklyDates'
import { useMonthlyDates } from '~publish/pages/Calendar/hooks/useMonthlyDates'

import {
  usePostIntervals,
  type IntervalsResponse,
} from '../util/generatePostIntervals'

export type QueryVariables = GetCalendarAndPostListQueryVariables
export type Data = GetCalendarAndPostListQuery

export const GetCalendarAndPostList = graphql(/* GraphQL */ `
  query GetCalendarAndPostList(
    $startDate: DateTime!
    $endDate: DateTime!
    $startDateLegacy: Int!
    $endDateLegacy: Int!
    $seed: Int!
    $channelIdsLegacy: [ID!]
    $channelIds: [ChannelId!]
    $timezone: String!
    $use24HourTime: Boolean!
    $includeDrafts: Boolean
    $organizationId: OrganizationId!
    $postsLimit: Int!
    $tagIds: [TagId!]
  ) {
    posts(
      first: $postsLimit
      input: {
        organizationId: $organizationId
        filter: {
          channelIds: $channelIds
          dueAt: { start: $startDate, end: $endDate }
          tagIds: $tagIds
        }
        sort: [
          { field: dueAt, direction: asc }
          { field: createdAt, direction: asc }
        ]
      }
    ) {
      edges {
        node {
          id
          dueAt
          ...CalendarPostCard_Post
        }
      }
    }
    calendar(
      input: {
        startDate: $startDateLegacy
        endDate: $endDateLegacy
        timezone: $timezone
        use24HourTime: $use24HourTime
        channelIds: $channelIdsLegacy
        _seed: $seed
        intervalSize: oneHour
        includeDrafts: $includeDrafts
      }
    ) {
      ... on Calendar {
        posts {
          ...CalendarView_Post
        }
      }
    }
  }
`)

export type PostForCalendarHookResponse = {
  data: GetCalendarAndPostListQuery | undefined
  intervals: IntervalsResponse
  loading: boolean
  refetch: () => void
  variables: GetCalendarAndPostListQueryVariables
}

type PostForCalendarHookProps = {
  currentDate: Date
  calendarMode: 'week' | 'month'
  channelsSelected: string[] | void
  tagIds: string[] | void
  hasTwentyFourHourTimeFormat: boolean
}

export type SuccessCalendar = GetCalendarAndPostListQuery['calendar'] & {
  __typename: 'Calendar'
}
export const asCalendar = (
  data: GetCalendarAndPostListQuery | undefined | null,
): SuccessCalendar | undefined => {
  const calendar = data?.calendar
  if (calendar === null || calendar === undefined) return undefined
  if (calendar.__typename !== 'Calendar') return undefined

  return calendar as SuccessCalendar
}
export const useCalendar = asCalendar

export const getLegacyPostFragment = (
  data: GetCalendarAndPostListQuery | null | undefined,
): readonly CalendarView_PostFragment[] | undefined | null => {
  const calendar = asCalendar(data)
  return getFragmentData(CalendarView_Post, calendar?.posts)
}

// TODO replace with fragment
type NewPostType = NonNullable<
  GetCalendarAndPostListQuery['posts']['edges']
>[number]['node']

export const asPostsFragment = (
  data: GetCalendarAndPostListQuery | null | undefined,
): (NewPostType | undefined | null)[] => {
  return data?.posts.edges?.map((edge) => edge.node) ?? []
}

export const usePostsFragment = asPostsFragment

export const useCalendarAndPostsList = (
  props: PostForCalendarHookProps,
): PostForCalendarHookResponse => {
  const { isEnabled: extendCalendarPostList } = useSplitEnabled(
    'extend-calendar-post-list',
  )
  const {
    currentDate,
    channelsSelected,
    hasTwentyFourHourTimeFormat,
    calendarMode,
    tagIds,
  } = props
  const weekDates = useWeeklyDates(currentDate)
  const monthDates = useMonthlyDates(currentDate)

  const [startDate, endDate] =
    calendarMode === 'week'
      ? [weekDates.weekStart, weekDates.weekEnd]
      : [monthDates.calendarStart, monthDates.calendarEnd]
  const organizationId = useOrganizationId() ?? ''
  const timezone = getTimezoneFromBrowser()
  const variables = useMemo(
    () =>
      ({
        postsLimit: extendCalendarPostList ? 1_000 : 200,
        startDateLegacy: getUnixTime(startDate),
        endDateLegacy: getUnixTime(endDate),
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        organizationId,
        seed: 10,
        channelIds: channelsSelected,
        channelIdsLegacy: channelsSelected,
        timezone,
        use24HourTime: hasTwentyFourHourTimeFormat,
        includeDrafts: true,
        tagIds,
      } as QueryVariables),
    [
      channelsSelected,
      hasTwentyFourHourTimeFormat,
      startDate,
      endDate,
      timezone,
      organizationId,
      tagIds,
    ],
  )
  const updatePosts = useOnUpdatePostsList()
  const { data, loading, refetch } = useQuery<
    GetCalendarAndPostListQuery,
    QueryVariables
  >(GetCalendarAndPostList, {
    variables,
    onCompleted: updatePosts,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })

  const intervals = usePostIntervals(
    startDate.toISOString(),
    endDate.toISOString(),
    timezone,
  )

  const dispatch = useDispatch()

  // TODO: type here is coerced. Ideally we delete this as we move off of redux
  const shouldRefetch = useSelector(
    (state: { calendar: { shouldRefetch: boolean } }) =>
      state.calendar.shouldRefetch,
  )
  useEffect(() => {
    if (shouldRefetch) {
      refetch()
      dispatch(calendarActions.clearShouldRefetch())
    }
  }, [shouldRefetch, dispatch, refetch])

  return useMemo(
    () => ({
      data,
      intervals,
      loading,
      refetch,
      variables,
    }),
    [data, intervals, loading, refetch, variables],
  )
}
