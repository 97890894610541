import events from './Events'

const AppHooks = {
  handleAppLoaded: () => {
    events.emit('loaded')
  },

  // @ts-expect-error TS(2525) FIXME: Initializer provides no value for this binding ele... Remove this comment to see the full error message
  handleSavedDrafts: ({ message, fromIdea } = {}) => {
    events.emit('saved-drafts', { message, fromIdea })
  },

  handleBackdropClicked: () => {
    events.emit('backdrop-clicked')
  },

  // after a user starts a trial, send message with updated organizationsData
  // @ts-expect-error TS(7031) FIXME: Binding element 'message' implicitly has an 'any' ... Remove this comment to see the full error message
  handleStartTrial: ({ message, removeScope }) => {
    events.emit('start-trial', { message, removeScope })
  },

  handleActionTaken: (message = {}) => {
    events.emit('action-taken', message)
  },
}

export default AppHooks
