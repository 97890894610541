import React from 'react'

import { MediaAttachmentTypes } from '~publish/legacy/constants'
import type { Image } from '~publish/legacy/composer/composer/entities/factories'

import * as Styles from './styles'

const formatImagesInRows = (
  images: Image[],
): {
  firstRowCol: Image[]
  secondRowCol: Image[]
} => {
  const total = images.length
  let firstRowCol = [] as Image[]
  let secondRowCol = [] as Image[]

  if (total === 0) {
    return { firstRowCol, secondRowCol }
  }

  if (total <= 3) {
    firstRowCol = images.slice(0, 1)
    secondRowCol = images.slice(1)
  }

  if (total >= 4) {
    firstRowCol = images.slice(0, 2)
    secondRowCol = images.slice(2, Math.min(images.length, 5))
  }

  return { firstRowCol, secondRowCol }
}

const ImagesRowCol = ({
  type,
  images,
  fullSize,
  isRowFormat,
  totalImages,
  shouldShowMore,
  shouldShowGifTag,
  roundedCorners,
}: {
  type: string
  images: Image[]
  fullSize: boolean
  isRowFormat: boolean
  totalImages: number
  shouldShowMore: boolean
  shouldShowGifTag: boolean
  roundedCorners?: boolean
}): JSX.Element => {
  const extraImages = totalImages - 4
  const content = images.map((imageData, index) => {
    const { url, altText } = imageData
    const lastImageIndex = images.length - 1
    return (
      // eslint-disable-next-line react/no-array-index-key
      <Styles.ImageWrapper key={`image-${index}`}>
        <Styles.Image
          src={url}
          alt={altText || ''}
          roundedCorners={roundedCorners}
        />
        {imageData.mediaType === MediaAttachmentTypes.GIF &&
          shouldShowGifTag && <Styles.Tag>GIF</Styles.Tag>}
        {index === lastImageIndex && shouldShowMore && (
          <Styles.ShowMore className="show-more">
            +{extraImages}
          </Styles.ShowMore>
        )}
      </Styles.ImageWrapper>
    )
  })

  const singleChild = images.length === 1
  if (isRowFormat) {
    return <Styles.RowWrapper key={type}>{content}</Styles.RowWrapper>
  }

  return (
    <Styles.ColWrapper
      key={type}
      fullSize={fullSize}
      singleChild={singleChild}
      totalImagesInCol={images.length}
    >
      {content}
    </Styles.ColWrapper>
  )
}

const RowsCols = ({
  firstRowCol,
  secondRowCol,
  fullSize,
  isRowFormat,
  totalImages,
  shouldShowGifTag,
  roundedCorners,
}: {
  firstRowCol: Image[]
  secondRowCol: Image[]
  fullSize: boolean
  isRowFormat: boolean
  totalImages: number
  shouldShowGifTag: boolean
  roundedCorners?: boolean
}): JSX.Element => (
  <>
    {firstRowCol?.length > 0 && (
      <ImagesRowCol
        images={firstRowCol}
        type="first-images"
        fullSize={fullSize}
        isRowFormat={isRowFormat}
        totalImages={totalImages}
        shouldShowMore={false}
        shouldShowGifTag={shouldShowGifTag}
        roundedCorners={roundedCorners}
      />
    )}
    {secondRowCol?.length > 0 && (
      <ImagesRowCol
        images={secondRowCol}
        type="second-images"
        fullSize={fullSize}
        isRowFormat={isRowFormat}
        totalImages={totalImages}
        shouldShowMore={totalImages > 5}
        shouldShowGifTag={shouldShowGifTag}
        roundedCorners={roundedCorners}
      />
    )}
  </>
)

export const ImagesGrid = ({
  images,
  isRowFormat = false,
  formatImages = formatImagesInRows,
  maxHeight = '300px',
  shouldShowGifTag = false,
  className,
  roundedCorners,
}: {
  images: Image[]
  isRowFormat?: boolean
  shouldShowGifTag?: boolean
  formatImages?: (images: Image[]) => {
    firstRowCol: Image[]
    secondRowCol: Image[]
  }
  maxHeight?: string
  className?: string
  roundedCorners?: boolean
}): JSX.Element => {
  const { firstRowCol, secondRowCol } = formatImages(images)

  const double = firstRowCol?.length > 0 && secondRowCol?.length > 0
  const fullSize = !double

  return (
    <Styles.Wrapper
      data-testid="preview-images"
      fullHeight={fullSize}
      isRowLayout={isRowFormat}
      reverse={!isRowFormat && images.length === 3}
      maxHeight={maxHeight}
      className={className}
    >
      <RowsCols
        firstRowCol={firstRowCol}
        secondRowCol={secondRowCol}
        fullSize={fullSize}
        isRowFormat={isRowFormat}
        totalImages={images.length}
        shouldShowGifTag={shouldShowGifTag}
        roundedCorners={roundedCorners}
      />
    </Styles.Wrapper>
  )
}
