import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Button } from '@bufferapp/components'

class AutoSelectText extends Component {
  constructor() {
    // @ts-expect-error TS(2554) FIXME: Expected 1-2 arguments, but got 0.
    super()
    // @ts-expect-error TS(2339) FIXME: Property 'textRef' does not exist on type 'AutoSel... Remove this comment to see the full error message
    this.textRef = React.createRef()
  }

  handleClick() {
    // select all text
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    window.getSelection().selectAllChildren(this.textRef.current)
    // copy the text to the clipboard
    // @ts-expect-error TS(2339) FIXME: Property 'copyToClipboard' does not exist on type ... Remove this comment to see the full error message
    if (this.props.copyToClipboard) {
      document.execCommand('copy')
    }
    // trigger a function to indicate text has been selected
    // @ts-expect-error TS(2339) FIXME: Property 'onSelect' does not exist on type 'Readon... Remove this comment to see the full error message
    if (this.props.onSelect) {
      // @ts-expect-error TS(2339) FIXME: Property 'onSelect' does not exist on type 'Readon... Remove this comment to see the full error message
      this.props.onSelect(this.textRef.current.innerText)
    }
  }

  render() {
    return (
      <Button onClick={() => this.handleClick()} noStyle fillContainer>
        <Card color={'off-white'} noPadding>
          <div
            style={{
              padding: '1rem',
            }}
          >
            <code
              style={{
                fontSize: '16px',
                userSelect: 'all',
                fontWeight: 'bold',
              }}
              // @ts-expect-error TS(2339) FIXME: Property 'textRef' does not exist on type 'AutoSel... Remove this comment to see the full error message
              ref={this.textRef}
            >
              {/* @ts-expect-error TS(2339) FIXME: Property 'children' does not exist on type 'Re... Remove this comment to see the full error message */}
              {this.props.children}
            </code>
          </div>
        </Card>
      </Button>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
AutoSelectText.propTypes = {
  children: PropTypes.string,
  copyToClipboard: PropTypes.bool,
  onSelect: PropTypes.func,
}

export default AutoSelectText
