import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import { useSplitEnabled } from '@buffer-mono/features'
import { SidebarListItem } from '@bufferapp/ui'
import { Locked, Warning } from '@bufferapp/ui/Icon'
import Tag from '@bufferapp/ui/Tag'

import {
  grayDarker,
  grayLight,
  red,
  transparent,
} from '@bufferapp/ui/style/colors'
import { borderRadius } from '@bufferapp/ui/style/borders'
import {
  ChannelAvatar,
  IconButton,
  LockIcon,
  NewBadge,
  PlusIcon,
  Text,
  WarningIcon,
} from '@buffer-mono/popcorn'

import { actions as composerPopoverActions } from '~publish/legacy/composer-popover'

import * as Styled from './styles'
import type { Service } from '~publish/gql/graphql'
import { SEGMENT_NAMES } from '~publish/legacy/constants'
import ProfileEducationPopover from '../ProfileEducationPopover'
import { useAccount } from '~publish/legacy/accountContext'

const ListItemContainer = styled.div<{ locked: boolean }>`
  padding: 0;
  margin: 0 0 8px;
  border-radius: ${borderRadius};
  background-color: ${(props): string =>
    props.locked ? grayLight : transparent};
`

interface GetCountProps {
  pendingCount?: number
}
const getCount = ({ pendingCount = 0 }: GetCountProps): number => {
  if (pendingCount < 0 || pendingCount === 0 || !pendingCount) {
    return 0
  }

  return pendingCount
}

interface HandleClickProps {
  onClick: () => void
}

const handleClick = ({ onClick }: HandleClickProps): void => {
  onClick()
  /* move focus to tabs after selecting profile */
  const queueTab = document.querySelector('#tabs a')
  if (queueTab) (queueTab as HTMLElement).focus()
}

const NEW_CHANNELS: string[] = ['bluesky']

function renderUnhoveredEndItem({
  service,
  count,
  sentCount,
  textColor,
  isLocked,
  isDisconnected,
}: {
  service: Service
  count: number
  sentCount: number
  textColor: 'brand' | 'subtle'
  isLocked: boolean
  isDisconnected: boolean
}): JSX.Element {
  const isNewChannel = NEW_CHANNELS.includes(service)

  if (isLocked) {
    return <LockIcon size="small" className="status-view-item" />
  }

  if (isDisconnected) {
    return (
      <WarningIcon size="small" color="critical" className="status-view-item" />
    )
  }

  if (isNewChannel && sentCount === 0) {
    return <NewBadge size="medium" className="status-view-item" />
  }

  return (
    <Text
      size="xs"
      color={textColor}
      weight="medium"
      className="default-view-item"
    >
      {count}
    </Text>
  )
}

export interface ProfileListItemProps {
  avatarUrl?: string
  type: Service
  pendingCount?: number
  sentCount?: number
  handle: string
  locked?: boolean
  disconnected?: boolean
  selected?: boolean
  location?: string
  onClick?: (id: string) => void
  additionalInfo?: string | null
  id: string
  index: number
}

const ProfileListItem = ({
  avatarUrl = '',
  type,
  pendingCount = 0,
  sentCount = 0,
  handle,
  locked = false,
  disconnected = false,
  selected = false,
  onClick = (): void => undefined,
  location = '',
  additionalInfo = '',
  id,
  index,
}: ProfileListItemProps): JSX.Element => {
  const dispatch = useDispatch()
  const { account } = useAccount()

  const { isEnabled: isNewPostEnabled } = useSplitEnabled(
    'geid-new-post-sidebar',
  )

  const { isEnabled: isEducationPopoverEnabled } = useSplitEnabled(
    'geid-profiles-sidebar-popover',
    { email: account.email },
  )

  const user = {
    id: '',
    name: handle,
    profileImageUrl: avatarUrl,
    fallbackUrl: 'https://s3.amazonaws.com/buffer-ui/Default+Avatar.png',
    network: type,
    handle: additionalInfo,
  }

  const fallbackURL = 'https://s3.amazonaws.com/buffer-ui/Default+Avatar.png'
  const title = location ? `${handle} ${location} ` : `${handle}`
  const service: Service = type
  const count = getCount({ pendingCount })
  const textColor = selected ? 'brand' : 'subtle'
  const serviceId = id

  const shouldShowNewPostButton = !locked && !disconnected
  /* the education popover should only be shown only on the first channel */
  const shouldShowEducationPopover = index === 0 && isEducationPopoverEnabled

  const onCreatePost = (): void => {
    dispatch(
      composerPopoverActions.handleCreatePostClick({
        selectedProfileIds: [serviceId],
        cta: SEGMENT_NAMES.PROFILE_SUGGESTION_SIDEBAR_NEW_POST,
      }),
    )
  }

  const ChannelContainerWithNewPostButton = () => {
    const channelContainer = (
      <Styled.ChannelContainer
        isLocked={locked}
        isSelected={selected}
        onClick={(): void => handleClick({ onClick: onClick.bind(null, id) })}
      >
        <ChannelAvatar
          alt="Channel avatar"
          channel={service}
          src={avatarUrl || fallbackURL}
          size="small"
        />
        <Styled.ChannelName>
          <Text size="sm" color={textColor} weight="medium" as="label">
            {handle}
          </Text>
          {additionalInfo && (
            <Text size="xs" color={textColor} weight="medium">
              {additionalInfo}
            </Text>
          )}
        </Styled.ChannelName>
        {renderUnhoveredEndItem({
          textColor,
          service,
          isLocked: locked,
          isDisconnected: disconnected,
          count,
          sentCount,
        })}
        {shouldShowNewPostButton && (
          <IconButton
            label="New Post"
            variant="primary"
            tooltip="New post"
            size="small"
            onClick={onCreatePost}
            className="new-post-button"
          >
            <PlusIcon />
          </IconButton>
        )}
      </Styled.ChannelContainer>
    )

    return shouldShowEducationPopover ? (
      <ProfileEducationPopover>{channelContainer}</ProfileEducationPopover>
    ) : (
      channelContainer
    )
  }

  return (
    <>
      {!isNewPostEnabled ? (
        <ListItemContainer locked={locked}>
          <SidebarListItem
            title={title}
            onItemClick={(): void =>
              handleClick({ onClick: onClick.bind(null, id) })
            }
            badges={
              NEW_CHANNELS.includes(type) && sentCount === 0 ? (
                <Tag color="green">New</Tag>
              ) : (
                `${getCount({ pendingCount })}`
              )
            }
            /* eslint-disable no-nested-ternary */
            badgeIcon={
              locked ? (
                <Locked color={grayDarker} />
              ) : disconnected ? (
                <Warning color={red} />
              ) : null
            }
            /* eslint-enable no-nested-ternary */
            user={user}
            selected={selected}
          />
        </ListItemContainer>
      ) : (
        <ChannelContainerWithNewPostButton />
      )}
    </>
  )
}

export default ProfileListItem
