/**
 * Component that shows the list of available profiles
 */
import PropTypes from 'prop-types'
import React from 'react'
import AppActionCreators from '../action-creators/AppActionCreators'
import {
  userDataPropType,
  visibleNotificationsPropType,
} from './ComposerPropTypes'
import styles from './css/ProfileSection.module.css'
import Profile from './Profile'
import ProfileGroups from './ProfileGroups'
import Button from './shared/Button'

class ProfileSection extends React.Component {
  scrollHandlers = new Set()

  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  constructor(props) {
    super(props)
    // @ts-expect-error TS(2339) FIXME: Property 'profilesScrollContainer' does not exist ... Remove this comment to see the full error message
    this.profilesScrollContainer = React.createRef()
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  onScroll = (e) => {
    // @ts-expect-error TS(2339) FIXME: Property 'profilesScrollContainer' does not exist ... Remove this comment to see the full error message
    if (e.target !== this.profilesScrollContainer.current) return

    const { scrollTop } = e.target
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    this.scrollHandlers.forEach((handler) => handler(e, scrollTop))
  }

  onProfilesToggleClick = () => AppActionCreators.toggleAllProfiles()

  // @ts-expect-error TS(7006) FIXME: Parameter 'handler' implicitly has an 'any' type.
  addContainerScrollHandler = (handler) => {
    this.scrollHandlers.add(handler)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'handler' implicitly has an 'any' type.
  removeContainerScrollHandler = (handler) => {
    this.scrollHandlers.delete(handler)
  }

  render() {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'appState' does not exist on type 'Readon... Remove this comment to see the full error message
      expandedProfileSubprofileDropdownId,
      // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Readon... Remove this comment to see the full error message
      profiles,
      // @ts-expect-error TS(2339) FIXME: Property 'userData' does not exist on type 'Readon... Remove this comment to see the full error message
      userData,
      // @ts-expect-error TS(2339) FIXME: Property 'visibleNotifications' does not exist on ... Remove this comment to see the full error message
      visibleNotifications,
      // @ts-expect-error TS(2339) FIXME: Property 'organizations' does not exist on type 'R... Remove this comment to see the full error message
      organizations,
    } = this.props
    const { hasProfileGroupsFeature } = organizations?.selected || {}
    const { profileGroups } = userData

    const shouldBeConsideredBusinessUser = hasProfileGroupsFeature
    const hasEnoughProfiles = profiles.length > 9

    const selectedProfilesIds = profiles
      // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
      .filter((profile) => profile.isSelected)
      // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
      .map((profile) => profile.id)
    const hasNoProfilesSelected = selectedProfilesIds.length === 0

    const profilesTogglerClassName = [styles.profilesToggler].join(' ')

    return (
      <section className={styles.profileSection}>
        {shouldBeConsideredBusinessUser && hasEnoughProfiles && (
          <Button
            className={profilesTogglerClassName}
            onClick={this.onProfilesToggleClick}
          >
            <span className={styles.profilesTogglerCopy}>
              {hasNoProfilesSelected ? 'Select All' : 'Select None'}
            </span>
          </Button>
        )}

        {shouldBeConsideredBusinessUser && hasEnoughProfiles && (
          <ProfileGroups
            groups={profileGroups}
            selectedProfilesIds={selectedProfilesIds}
          />
        )}

        <div className={styles.profilesContainer}>
          <div
            role="group"
            aria-labelledby="selectProfilesLabel"
            onScroll={this.onScroll}
            className={styles.profilesScrollContainer}
            // @ts-expect-error TS(2339) FIXME: Property 'profilesScrollContainer' does not exist ... Remove this comment to see the full error message
            ref={this.profilesScrollContainer}
          >
            {/* it's hidden from visual UI but will still be used as an accessible name for the group */}
            <span id="selectProfilesLabel" hidden>
              Select profiles
            </span>

            {/* @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type. */}
            {profiles.map((profile) => (
              <Profile
                profile={profile}
                organizationId={organizations?.selected?.id}
                expandedProfileSubprofileDropdownId={
                  expandedProfileSubprofileDropdownId
                }
                addContainerScrollHandler={this.addContainerScrollHandler}
                removeContainerScrollHandler={this.removeContainerScrollHandler}
                visibleNotifications={visibleNotifications}
                className={styles.profile}
                key={profile.id}
              />
            ))}
          </div>
        </div>
      </section>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
ProfileSection.propTypes = {
  profiles: PropTypes.arrayOf({
    // @ts-expect-error TS(2345) FIXME: Argument of type '{ selected: PropTypes.Requireabl... Remove this comment to see the full error message
    selected: PropTypes.shape({
      id: PropTypes.string,
      isSelected: PropTypes.bool,
    }),
  }),
  expandedProfileSubprofileDropdownId: PropTypes.string.isRequired,
  userData: userDataPropType.isRequired,
  visibleNotifications: visibleNotificationsPropType.isRequired,
  organizations: PropTypes.shape({
    selected: PropTypes.shape({
      id: PropTypes.string,
      hasProfileGroupsFeature: PropTypes.bool,
    }),
  }),
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
ProfileSection.defaultProps = {
  organizations: {},
  profiles: [],
}

export default ProfileSection
