/* eslint-disable camelcase */
import React, { useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { differenceInCalendarDays, addHours } from 'date-fns'
import { DragDropContext, type DropResult } from 'react-beautiful-dnd'

import usePostLimit from '~publish/pages/Calendar/hooks/usePostLimit'
import { useDropPostInSlot } from '~publish/pages/Calendar/hooks/useDropPostInSlot'
import { actions as calendarActions } from '../../reducer'
import HoursCells from '../Hours'
import {
  useCalendar,
  type PostForCalendarHookResponse,
} from '~publish/pages/Calendar/hooks/useCalendarAndPostsList'
import { isDraft as checkIsDraft } from '~publish/pages/Calendar/util/isDraft'
import { useWeeklyDates } from '~publish/pages/Calendar/hooks/useWeeklyDates'
import type { CalendarView_PostFragment } from '~publish/gql/graphql'

const findPostById = <P extends { id: string }>(
  posts: P[] = [],
  postId: string,
): P | undefined => posts.find((post) => post.id === postId)

type OnDragEndProps = {
  result: DropResult
  posts: CalendarView_PostFragment[]
  updatePostDueAt: (args: {
    post: CalendarView_PostFragment
    timestamp: number
  }) => void
}

const onDragEnd = ({
  result,
  posts = [],
  updatePostDueAt,
}: OnDragEndProps): void => {
  const { destination, source, draggableId: postId } = result

  if (!destination) {
    return
  }

  // If we drop in the same day cell
  // We don't compare the index here because we check against the timestamp
  if (destination.droppableId === source.droppableId) {
    return
  }

  const timestamp = Number(destination.droppableId) / 1000
  const post = findPostById(posts, postId)

  if (!post) return

  updatePostDueAt({ post, timestamp })
}

type DaysOfWeekProps = {
  currentDate: Date
  parentRef: React.RefObject<HTMLDivElement | null>
  selectedChannelIds: string[] | void
  hasTwentyFourHourTimeFormat: boolean
  onDropPostSuccess: (args: { newDate: number; isDraft: boolean }) => void
  onDropPostError: (error?: Error | null) => void
  setOpenModal: (openModal: {
    open: boolean
    ctaString: string
    service: string
  }) => void
  postsQuery: PostForCalendarHookResponse
}

const DaysOfWeek = ({
  currentDate,
  parentRef,
  selectedChannelIds,
  hasTwentyFourHourTimeFormat,
  onDropPostSuccess,
  onDropPostError,
  setOpenModal,
  postsQuery,
}: DaysOfWeekProps): JSX.Element => {
  const dispatch = useDispatch()
  const scrollToRef = useRef<HTMLTableRowElement | null>(null)
  const currentLocalHour = new Date().getHours()
  const { weekStart, weekEnd } = useWeeklyDates(currentDate)

  const { data, loading, refetch, variables } = postsQuery

  const shouldRefetch = useSelector(
    (state: { calendar: { shouldRefetch: boolean } }) =>
      state.calendar.shouldRefetch,
  )
  useEffect(() => {
    if (shouldRefetch) {
      refetch()
      dispatch(calendarActions.clearShouldRefetch())
    }
  }, [shouldRefetch, dispatch, refetch])

  const postLimit = usePostLimit(24)

  const parentEl = parentRef?.current
  const scrollToEl = scrollToRef?.current

  useEffect(() => {
    // Wait for content to finish loading before scrolling because
    // it will most likely change the scroll height of the parent
    if (!loading && scrollToEl && parentEl) {
      // Account for the table header and a bit extra to give it some space
      const offset = scrollToEl.offsetTop - 60
      // Scroll table container to the current hour
      parentEl.scrollTo(0, offset)
    }
  }, [scrollToEl, parentEl, loading])

  const { updatePostDueAt } = useDropPostInSlot({
    onSuccess: (post) => {
      const newDate = post.dueAt
      const isDraft = checkIsDraft(post.status)
      if (!newDate) return
      onDropPostSuccess({ newDate, isDraft })
    },
    onError: () => {
      onDropPostError()
    },
    variables,
  })

  const numberDaysToDisplay =
    Math.abs(differenceInCalendarDays(weekStart, weekEnd)) + 1
  // Generates 24 rows, one per hour
  const weekRows = [...Array(24)].map((_, i) => {
    const hour = addHours(weekStart, i)
    return (
      <tr
        key={`weekly-row-${hour}`}
        data-testid={`weekly-row-${hour}`}
        ref={currentLocalHour === i ? scrollToRef : null}
      >
        <HoursCells
          hour={i}
          numberDaysToDisplay={numberDaysToDisplay}
          startDate={weekStart}
          data={data}
          is24HourFormat={hasTwentyFourHourTimeFormat}
          postLimit={postLimit}
          setOpenModal={setOpenModal}
          selectedChannelIds={selectedChannelIds}
        />
      </tr>
    )
  })

  const calendar = useCalendar(data)

  const posts = (calendar?.posts ?? []) as CalendarView_PostFragment[]

  return (
    <DragDropContext
      onDragEnd={(dragResult): void =>
        onDragEnd({ result: dragResult, updatePostDueAt, posts })
      }
    >
      {weekRows}
    </DragDropContext>
  )
}

export default DaysOfWeek
