import styled from 'styled-components'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import TextareaAutosize from 'react-textarea-autosize'
import { grayDarker, gray } from '@bufferapp/ui/style/colors'

export const PostTypeWrapper = styled.div`
  position: relative;
  clear: left;
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #ced7df;
  justify-content: space-between;
`

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 12px 6px 0 0;
  flex: 1;

  &.date-wrapper {
    margin: 12px 24px 0 0;
  }

  .date-input {
    width: 175px;
  }
`

export const FieldWrapper = styled.div`
  display: flex;
`

export const RightFloatWrapper = styled.div<{
  removeTopMargin?: boolean
  withBottomMargin?: boolean
}>`
  position: relative;
  clear: left;
  display: flex;
  justify-content: flex-end;
  margin: ${(props): string => (props.removeTopMargin ? '0' : '12px')} 12px
    ${(props): string => (props.withBottomMargin ? '12px' : '0')} 0;
`

export const RadioLabel = styled.label`
  font-weight: 500;
  font-size: 14px;
  margin-right: 16px;
  color: ${grayDarker};
`

export const RadioInput = styled.input`
  margin-top: 0px;
  margin-right: 6px;
  cursor: pointer;
`

export const Label = styled.label`
  position: relative;
  font-weight: 600;
  font-size: 12px;
  color: #343e47;
  width: 150px;
`

export const TimeLabel = styled(Label)`
  width: 120px;
`

export const TextWrapLabel = styled.label`
  position: relative;
  font-weight: 600;
  font-size: 12px;
  color: #343e47;
  width: 160px;
  line-height: 15px;
`

export const SelectLabel = styled.label`
  position: relative;
  font-weight: 600;
  font-size: 12px;
  color: #343e47;
  width: 150px;
`

export const Select = styled.select`
  position: relative;
  color: ${grayDarker};
  font-size: 12px;
  width: 180px;
  height: 32px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='%23636363' height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'><path fillRule='evenodd' d='M11.146 6.854A.5.5 0 0010.793 6H5.207a.5.5 0 00-.353.854l2.792 2.792a.5.5 0 00.708 0l2.792-2.792z' clipRule='evenodd'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 6px;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  margin-left: 1px;
  padding-left: 8px;
`

export const Textarea = styled(TextareaAutosize)`
  resize: none;
  min-height: 64px;
  max-height: 120px;
  overflow-y: scroll;
`

export const DateTimeInput = styled.input`
  border-radius: 4px;
  padding: 8px;
  border: 1px solid #e6ebef;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 12px;
  height: 32px;
  width: 100%;
  outline: none;
  color: ${grayDarker};
  text-align: left;
  background: none;
  line-height: 12px;

  &:hover {
    border-color: ${gray};
  }

  &:focus,
  &[aria-expanded='true'] {
    outline: 2px solid #4a67fc;
    outline-offset: 1px;
  }
`
