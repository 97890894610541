import React from 'react'
import { Text, Button, Heading, Flex } from '@buffer-mono/popcorn'
import { useTranslation } from 'react-i18next'

type ChooseMethodParams = {
  transition: (arg0: string) => void
  setupApp: () => void
  loading: boolean
  editMode: boolean
}

const ChooseMethod = ({
  transition,
  setupApp,
  loading,
  editMode,
}: ChooseMethodParams): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: an... Remove this comment to see the full error message */}
      {loading && <Text size="large">{t('common.pleaseWait')}</Text>}
      <div style={{ display: loading ? 'none' : 'block' }}>
        <Flex direction="column" align="center" gap="space-300">
          <Flex
            direction="column"
            align="center"
            justify="center"
            gap="space-150"
          >
            <Heading align="center" size="small" as="h3">
              {!editMode && t('preferences.security.enableTwoFactor')}
              {editMode && t('preferences.security.changeTwoFactor')}
            </Heading>
            <Text>{t('preferences.security.sendSecutiryCodes')}</Text>
          </Flex>
          <Flex direction="column" align="center" gap="space-100">
            <Button
              size="large"
              variant="primary"
              onClick={setupApp}
              style={{ width: '200px', justifyContent: 'center' }}
            >
              {t('preferences.security.authenticatorApp')}
            </Button>
            <Button
              size="large"
              variant="tertiary"
              onClick={(): void => transition('CHOOSE_SMS')}
              style={{ width: '200px', justifyContent: 'center' }}
            >
              {t('preferences.security.textMessage')}
            </Button>
          </Flex>
        </Flex>
      </div>
    </>
  )
}

export default ChooseMethod
