/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import {
  ToggleGroup,
  Flex,
  BoardIcon,
  GalleryIcon,
  Button,
  PlusIcon,
  MessageCircleHeartIcon,
  Heading,
} from '@buffer-mono/popcorn'

import {
  useOrganizationBilling,
  useOrganizationId,
  useOrganizationLimits,
} from '~publish/legacy/accountContext'
import { selectProfiles } from '~publish/legacy/profile-sidebar/selectors'
import { history, useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { useUploader } from '~publish/legacy/uploads/hooks/useUploader'
import { UploadSource } from '~publish/legacy/uploads/values/UploadSource'
import { selectUserIdAndEmail } from '~publish/legacy/user/selectors'
import { addPermanentNotification } from '~publish/legacy/notifications-provider/notificationsSlice'
import { UploaderRestrictions } from '~publish/legacy/uploads/values/UploaderRestrictions'
import { GenerateIdeasButton } from '~publish/legacy/ai/components/GenerateIdeasButton'
import { selectHasContentFeature } from '~publish/legacy/organizations/selectors'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import { FeedbackWidget } from '~publish/components/FeedbackWidget'
import { usePageTitle } from '~publish/hooks/usePageTitle'

import { createNew, createPage } from '../../legacy/routes'
import { createPostFromIdea } from './state/thunks/createPostFromIdea'
import { IDEAS_UPLOADER_ID } from './components/config'
import { IdeaEditor } from './components/IdeaEditor'
import { Board } from './components/Board'
import { UpgradePathForCreate } from './components/UpgradePathForCreate'
import { IdeasLimitReachedModal } from './components/modals/IdeasLimitReachedModal'
import { useIdeasCount } from './hooks/useIdeasCount'
import { useIdeasEditor } from './hooks/useIdeasEditor'
import { IdeasGallery } from './components/IdeasGallery'

import styles from './CreatePage.module.css'
import { uploadsLogger } from '~publish/legacy/uploads/loggers'
import { FilterByTag } from '~publish/components/FilterByTag'
import { graphql } from '~publish/gql'
import { sanitizeNullableArray } from '~publish/helpers/typeGuards'
import { useSplitEnabled } from '@buffer-mono/features'

const LOCAL_STORAGE_VIEW_KEY = 'contentPlannerLastViewed'

const VIEWS = {
  GALLERY: 'gallery',
  BOARD: 'board',
}

export const GetCreateInfo = graphql(/* GraphQL */ `
  query GetCreateInfo($organizationId: OrganizationId!) {
    tags(input: { organizationId: $organizationId }) {
      id
      ...FilterByTag_Tag
    }
  }
`)

function CreatePage(): JSX.Element {
  usePageTitle('Create')
  const organizationId = useOrganizationId()

  const { isEnabled: isSecondaryButtonTreatmentEnabled } = useSplitEnabled(
    'geid-secondary-button-treatment-with-global-action',
  )

  const DEFAULT_VIEW = VIEWS.BOARD

  const [selectedTagIds = [], setSelectedTagIds] =
    useQueryParam<string[]>('tagIds')

  const { data } = useQuery(GetCreateInfo, {
    variables: { organizationId },
  })

  const [view, setView] = useQueryParam('view')
  React.useEffect(() => {
    if (!view || Object.values(VIEWS).includes(view) === false) {
      const initialView =
        localStorage.getItem(LOCAL_STORAGE_VIEW_KEY) || DEFAULT_VIEW
      setView(initialView, { replace: true })
    }
  }, [view])

  const [showIdeasLimitReachedModal, setShowIdeasLimitReachedModal] =
    React.useState<boolean>(false)

  const user = useAppSelector(selectUserIdAndEmail)
  const profiles = useAppSelector(selectProfiles)
  const hasContentFeature = useAppSelector(selectHasContentFeature)

  const ideaListRef = React.useRef(undefined)
  const location = useLocation()
  const dispatch = useAppDispatch()

  const { ideas: ideasLimit } = useOrganizationLimits()
  const { canStartTrial, subscription } = useOrganizationBilling()
  const { ideasCount } = useIdeasCount()
  const [source, setSource] = React.useState<string | undefined>(undefined)

  const {
    isOpen,
    currentIdea,
    errorMessage,
    errorCode,
    onOpenChange,
    openEditor,
    saveIdea,
    getCurrentContent,
    closeEditor,
    plateEditor,
    tags,
    setSelectedTags,
    groupId,
    setGroupId,
    title,
    setTitle,
  } = useIdeasEditor()

  const ideasLimitReachedModalType = (): 'paid' | 'trial' | 'upgrade' => {
    const planId = subscription?.plan?.id

    if (planId && planId !== 'free') {
      return 'paid'
    }

    return canStartTrial ? 'trial' : 'upgrade'
  }

  const showError = (message: string): void => {
    dispatch(addPermanentNotification(`⚠️ ${message}`))
  }

  const { uploader } = useUploader({
    id: IDEAS_UPLOADER_ID,
    onUnmount: 'destroy',
    fileRestrictions: UploaderRestrictions.forIdeas(),
    eventHandlers: {
      'file-validation-failed': (_, error) => showError(error.message),
      'upload-failed': (_, error) => showError(error.message),
      'file-fetch-failed': () =>
        showError(
          'We are really sorry but we cannot get the file at this moment. Please, try it later.',
        ),
    },
  })

  const isIdeasLimitReached = ideasCount >= ideasLimit

  const onCreatePost = (): void => {
    const idea = getCurrentContent()
    if (idea) {
      dispatch(createPostFromIdea({ profiles, idea, user }))

      closeEditor()
    }
  }

  const onOpenEditor = React.useCallback(
    (open: boolean) => {
      const isAtLimit = ideasCount >= ideasLimit
      if (isAtLimit && open) {
        setShowIdeasLimitReachedModal(true)
      } else {
        onOpenChange(open)
      }
    },
    [ideasCount, ideasLimit],
  )

  const handleDropIfPossible = React.useCallback(
    (files: File[], groupId?: string) => {
      if (files.length > 0 && !isOpen && !isIdeasLimitReached) {
        uploadsLogger(`[useDropTarget] ${files.length} files were dropped`)
        uploader.addFiles(files, {
          source: UploadSource.dragAndDrop(),
        })
        openEditor()
        if (groupId) setGroupId(groupId)
      } else if (!isOpen && isIdeasLimitReached) {
        setShowIdeasLimitReachedModal(true)
      }
    },
    [isOpen, setGroupId, openEditor, uploader, isIdeasLimitReached],
  )

  React.useEffect(() => {
    if (ideasCount > ideasLimit) {
      setShowIdeasLimitReachedModal(true)
    }
  }, [ideasCount])

  // Open ideas limit reached modal when ideas limit reached before saving idea
  React.useEffect(() => {
    if (errorCode && errorCode === 'LimitReachedError') {
      setShowIdeasLimitReachedModal(true)
      closeEditor()
    }
  }, [errorCode])

  // Open Composer if route is /create/new
  // It will populate the editor with the content of the text
  // URL parameter
  // TODO: how to add media via URL params
  React.useEffect(() => {
    const shouldCreateIdea =
      (location.pathname === createNew.route ||
        location.pathname === '/content/new') &&
      !isOpen
    const search = new URLSearchParams(location.search)
    const text = search.get('text') ?? ''
    const mediaUrls = search.getAll('media')
    const source = search.get('source') ?? undefined
    const cta = search.get('cta') ?? undefined

    if (!shouldCreateIdea) return

    uploader.addFilesFromUrls(
      mediaUrls.map((url) => ({
        remoteUrl: url,
        useSafeImage: true,
      })),
      UploadSource.extension(),
    )

    const idea = {
      content: {
        text,
        aiAssisted: true,
      },
    }

    openEditor(idea, { source, cta })
    history.push(`${createPage.route}?view=${view}`)
  }, [isOpen, location, openEditor])

  const handleViewSelect = (selectedValue: string): void => {
    if (!selectedValue) {
      return
    }
    setView(selectedValue)
    localStorage.setItem(LOCAL_STORAGE_VIEW_KEY, selectedValue)
  }

  if (!hasContentFeature) {
    return <UpgradePathForCreate />
  }

  return (
    <div className={styles.page} data-testid="create-page">
      <Dialog.Root
        open={isOpen}
        modal={false}
        onOpenChange={(open): void => onOpenEditor(open)}
      >
        <Dialog.Portal container={ideaListRef.current}>
          <IdeaEditor
            onSaveIdea={saveIdea.bind(null, source)}
            errorMessage={errorMessage}
            containerRef={ideaListRef.current}
            onCreatePost={onCreatePost}
            onOpenChange={onOpenChange}
            currentIdea={currentIdea}
            plateEditor={plateEditor}
            onSelectTags={setSelectedTags}
            preSelectedTags={tags}
            groupId={groupId}
            setGroupId={setGroupId}
            title={title}
            setTitle={setTitle}
          />
        </Dialog.Portal>
        <Flex justify="between" as="header" className={styles.header}>
          <>
            <Flex gap="space-300" align="center">
              <Heading as="h2" size="medium">
                Create
              </Heading>
              <GenerateIdeasButton
                source={
                  view === VIEWS.GALLERY
                    ? 'create-gallery-ideaGenerator-newIdea-1'
                    : 'create-board-ideaGenerator-newIdea-1'
                }
              />
            </Flex>
            <Flex gap="space-100" align="center">
              <FeedbackWidget id="create-1" source="create">
                <Button variant="tertiary" size="large">
                  <MessageCircleHeartIcon />
                  Share Feedback
                </Button>
              </FeedbackWidget>
              <FilterByTag
                tags={sanitizeNullableArray(data?.tags)}
                value={selectedTagIds}
                onSelect={setSelectedTagIds}
              />
              <ToggleGroup
                size="large"
                value={view}
                onChange={handleViewSelect}
              >
                <ToggleGroup.Item value={VIEWS.BOARD}>
                  <BoardIcon />
                  Board
                </ToggleGroup.Item>
                <ToggleGroup.Item value={VIEWS.GALLERY}>
                  <GalleryIcon />
                  Gallery
                </ToggleGroup.Item>
              </ToggleGroup>
              <Dialog.Trigger asChild>
                <Button
                  id="create-idea-button"
                  variant={
                    isSecondaryButtonTreatmentEnabled ? 'secondary' : 'primary'
                  }
                  size="large"
                  onClick={(): void => {
                    setSource(
                      view === VIEWS.GALLERY
                        ? 'create-gallery-toolbar-newIdea-1'
                        : 'create-board-toolbar-newIdea-1',
                    )
                  }}
                >
                  <PlusIcon />
                  New Idea
                </Button>
              </Dialog.Trigger>
            </Flex>
          </>
        </Flex>
        {view === VIEWS.GALLERY && (
          <IdeasGallery
            onCreateNewIdea={openEditor}
            onEditIdea={openEditor}
            onFileDrop={handleDropIfPossible}
          />
        )}
        {view === VIEWS.BOARD && (
          <Board
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            onCreateIdea={openEditor}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error Type '(idea?: NewIdea | undefined, ideaSource?: string | undefined) => void' is not assignable to type '(idea: IdeaCard_IdeaFragment) => void'
            onEditIdea={openEditor}
            onFileDrop={handleDropIfPossible}
          />
        )}
      </Dialog.Root>
      {showIdeasLimitReachedModal && (
        <IdeasLimitReachedModal
          onClose={setShowIdeasLimitReachedModal}
          type={ideasLimitReachedModalType()}
        />
      )}
    </div>
  )
}

export { CreatePage }
