import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'

import { useAccount } from '~publish/legacy/accountContext'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'

import { selectNotificationsEnabled } from '~publish/legacy/profile-sidebar/selectors'
import { actions as modalActions } from '~publish/legacy/modals/reducer'

import { CLIENT_NAMES, SEGMENT_NAMES } from '~publish/legacy/constants'
import type { DraftPreview } from '../../post-preview/previews/types'
import { selectModalPlacement } from '~publish/legacy/modals/selectors'
import { actions as coachmarkActions } from '~publish/legacy/coachmarks'
import { selectHasViewedRemindersConfirmation } from '~publish/legacy/coachmarks/selectors'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'
import AppStore from '../../../stores/AppStore'

export type BeforeFirstActionReturnType = {
  stopDefaultAction: boolean
  newAction?: () => void
}

export function useBeforeFirstAction({
  draftPreview,
}: {
  draftPreview: DraftPreview
}): BeforeFirstActionReturnType {
  const user = useAccount()
  const dispatch = useAppDispatch()
  const selectedProfilesId = AppStore.getSelectedProfiles().map(
    (profile) => profile.id,
  )

  const notificationsEnabled = useAppSelector((state) => {
    return selectNotificationsEnabled(state, selectedProfilesId)
  })

  const remindersSetUpWizardPlacement: string = useAppSelector((state) =>
    selectModalPlacement(state),
  )
  const hasViewedConfirmation: boolean = useAppSelector((state) =>
    selectHasViewedRemindersConfirmation(state),
  )

  const banner = useDismissableBanner('notificationsCoachmarkConfirmation')
  const userShowPreference = banner.isActive

  const isReminder = draftPreview?.isReminder

  if (!isReminder) {
    return {
      stopDefaultAction: false,
    }
  }

  // Posting a reminder and does NOT have notifications enabled
  if (!notificationsEnabled) {
    const handleNotificationsWizard = (): void => {
      BufferTracker.remindersSetupCtaClicked({
        organizationId: user?.account?.currentOrganization?.id || null,
        clientName: CLIENT_NAMES.web,
        cta: SEGMENT_NAMES.CREATE_A_POST,
        placement: remindersSetUpWizardPlacement,
      })
      dispatch(modalActions.showRemindersWizardModal({ placement: 'composer' }))
    }

    return { stopDefaultAction: true, newAction: handleNotificationsWizard }
  }

  // Posting a reminder and has notifications enabled
  if (userShowPreference && !hasViewedConfirmation) {
    return {
      stopDefaultAction: true,
      newAction: () => dispatch(coachmarkActions.showRemindersConfirmation()),
    }
  }

  return {
    stopDefaultAction: false,
  }
}
