import RPCClient from '~publish/legacy/composer/composer/utils/RPCClient'
import { logError } from '~publish/legacy/utils/logError'
import {
  type LinkedInEntityDetails,
  SupportedEntity,
} from './nodes/LinkedInAnnotationElement'

// Map uses === semantics, so in order to compare by value, we're
// using a string hash rather than an object as the key
// @ts-expect-error TS(7006) FIXME: Parameter 'profileId' implicitly has an 'any' type... Remove this comment to see the full error message
function hashCacheKey(profileId, url) {
  return `${profileId}-${url}`
}

const supportedEntitiesString = Object.values(SupportedEntity).join('|')
export const LINKEDIN_ENTITY_URL_REGEX = new RegExp(
  `(?:(?:https|http)://(?:www.)?linkedin.com/(?<entityType>${supportedEntitiesString})/)(?<vanityName>[a-zA-Z0-9-&'%._]*)`,
)

type LinkedinEntityLookUpResponse = {
  id: number
  vanityName: string
  localizedName: string
  entity: string
}

/**
 * Class for interacting with LinkedIn URL entities that can be converted to annotations
 *
 * Results are cached, and a same request done simultaneously will be
 * queued to return the same info as the already-running request.
 */

export class LinkedInAnnotation {
  // Result cache
  static cache = new Map<string, LinkedinEntityLookUpResponse>()

  static isLinkedInEntityUrl(url: string): boolean {
    return LINKEDIN_ENTITY_URL_REGEX.test(url)
  }

  // Find and parse all urls in the provided text, filtering out any failed urls
  static async parseLinkedInEntitiesUrlInText(
    urls: string[],
    profileId: string,
  ): Promise<LinkedInEntityDetails[]> {
    return Promise.all(
      urls
        // process only the urls that match a LinkedIn URL
        .filter(LinkedInAnnotation.isLinkedInEntityUrl)
        // and fetch the entity details
        .map(async (url) => {
          try {
            return this.getLinkedInEntityDetails(url, profileId)
          } catch (e) {
            // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            logError(e, { metaData: { url } })
          }
        }),
    ).then((entities) =>
      // filter out potentially undefined values
      entities.filter((e): e is LinkedInEntityDetails => !!e),
    )
  }

  // Returns a Promise that resolves with the full details of the entity as an object
  static async getLinkedInEntityDetails(
    url: string,
    profileId: string,
  ): Promise<LinkedInEntityDetails | undefined> {
    const regexResult = LINKEDIN_ENTITY_URL_REGEX.exec(url)
    const vanityName = regexResult?.groups?.vanityName

    // Casting here should be accurate as long as we use the SupportedEntity values in the regex
    const entityType = regexResult?.groups?.entityType as
      | SupportedEntity
      | undefined

    if (vanityName && entityType) {
      const entityDetails = await this.lookUpEntity(
        decodeURI(vanityName),
        entityType,
        profileId,
      )
      if (entityDetails) {
        const { id, localizedName, entity } = entityDetails
        return {
          id,
          vanityName,
          // entityType,
          localizedName,
          entity,
          link: url,
        }
      }
    }
  }

  // Returns a Promise that resolves with the basic details of the entity as an object
  static lookUpEntity(
    vanityName: string,
    entityType: SupportedEntity,
    profileId: string,
  ): Promise<LinkedinEntityLookUpResponse> | undefined {
    const params = {
      vanityName,
      entityType,
      profile_id: profileId,
    }

    const cacheKey = hashCacheKey(vanityName, entityType)

    // If the result is cached, return it right away
    if (this.cache.has(cacheKey)) {
      const cachedResult = this.cache.get(
        cacheKey,
      ) as LinkedinEntityLookUpResponse
      return Promise.resolve(cachedResult)
    }

    const response: Promise<LinkedinEntityLookUpResponse> | undefined =
      RPCClient.call('composerApiProxy', {
        url: '1/profiles/linkedin_entity_lookup.json',
        args: params,
        HTTPMethod: 'GET',
      }).then(
        (result: { success: boolean; data: LinkedinEntityLookUpResponse }) => {
          if (result.success) {
            // If successful, update the cache
            this.cache.set(cacheKey, result.data)
            return result.data
          }
          return undefined
        },
      )

    return response
  }
}
