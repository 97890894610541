import clsx from 'clsx'
import React from 'react'
import { type Draft, DraftMethods } from '../../entities/Draft'

import CloseButton from '../shared/CloseButton'
import styles from '../css/MediaAttachmentThumbnail.module.css'

import { getQueueSafeImageUrl } from '~publish/legacy/utils/SafeImage'
import { escapeParens } from '~publish/legacy/composer/composer/utils/StringUtils'
import ComposerActionCreators from '../../action-creators/ComposerActionCreators'
import { Document } from '../../entities/Document'

interface DocumentAttachmentProps {
  draft: Draft
  className: string
}

export const DocumentAttachment = ({
  className,
  draft,
}: DocumentAttachmentProps): JSX.Element => {
  const document = DraftMethods.getDocumentAttachment(draft)

  if (!Document.isDocument(document)) return <></>

  const onCloseButtonClick = (): void => {
    ComposerActionCreators.removeDraftDocument(draft.id)
  }

  return (
    <div className={clsx(styles.thumbnail, className)}>
      <div className={styles.buttonWrapper}>
        <img
          alt=""
          src={getQueueSafeImageUrl(escapeParens(document.thumbnailUrl))}
          data-testid="media-attachment-thumbnail-image"
          crossOrigin={'anonymous'}
          className={styles.thumbnailImage}
        />
      </div>

      <CloseButton
        className={[styles.closeButton].join(' ')}
        onClick={onCloseButtonClick}
        label="Remove media"
      />
    </div>
  )
}
