import styled, { css, type FlattenSimpleInterpolation } from 'styled-components'
import {
  transitionAnimationTime,
  transitionAnimationType,
  // @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
} from '@bufferapp/components/lib/style/animation'

type CSSRule = string | number | false | FlattenSimpleInterpolation | undefined

export const PostWrapper = styled.div<{
  hidden: boolean
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 24px;
  width: 100%;
  transition: all ${transitionAnimationTime} ${transitionAnimationType};

  ${(props): CSSRule =>
    props.hidden &&
    css`
      max-height: 0;
      opacity: 0;
    `}
`
