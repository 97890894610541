import { useMemo } from 'react'
import {
  useCalendarAndPostsList,
  type PostForCalendarHookResponse,
} from './useCalendarAndPostsList'
import { useTwentyFourHourTimeFormat } from './useTwentyFourHourTimeFormat'
import { useNavigation, type UseNavigationReturn } from './useNavigation'
import { useCalendarFilters, type CalendarFilters } from './useCalendarFilters'

export type CalendarState = {
  postsQuery: PostForCalendarHookResponse
  navigation: UseNavigationReturn
  filters: CalendarFilters
}

export const useCalendarState = (): CalendarState => {
  const use24Hrs = useTwentyFourHourTimeFormat()
  const navigation = useNavigation()
  const filters = useCalendarFilters()
  const postsQuery = useCalendarAndPostsList({
    currentDate: navigation.currentDate,
    channelsSelected: filters.channelsFilter.queryParams,
    hasTwentyFourHourTimeFormat: use24Hrs,
    calendarMode: navigation.granularity,
    tagIds: filters.tagsFilter.queryParams,
  })

  return useMemo(
    () => ({
      postsQuery,
      navigation,
      filters,
    }),
    [postsQuery, navigation, filters],
  )
}
