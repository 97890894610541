import { actionTypes as asyncDataFetchActionTypes } from '@bufferapp/async-data-fetch'

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = {}, action) => {
  switch (action.type) {
    case `clientAccess_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        ...action.result,
      }
    default:
      return state
  }
}
