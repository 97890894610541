import React, { useReducer } from 'react'
import {
  Button,
  ChevronDownIcon,
  Combobox,
  Input,
  Text,
} from '@buffer-mono/popcorn'

import ComposerActionCreators from '~publish/legacy/composer/composer/action-creators/ComposerActionCreators'
import type {
  YoutubeCategory,
  YoutubeChannelData,
} from '~publish/legacy/composer/composer/entities/Draft'

import {
  YOUTUBE_CATEGORIES_US_REGION,
  YOUTUBE_LICENSE_MAP,
  YOUTUBE_PRIVACY_STATUS_MAP,
} from '../constants'

import {
  CheckboxGroupContainer,
  FormColumnContainer,
  InputCharacterCount,
  Label,
  Wrapper,
} from '../styles'
import styles from './ShortBar.module.css'

type ComboboxOpenState = {
  category: boolean
  privacyStatus: boolean
  license: boolean
}

const comboboxInitialOpenState: ComboboxOpenState = {
  category: false,
  privacyStatus: false,
  license: false,
}

const reducer = (
  state: ComboboxOpenState,
  action: { type: 'category' | 'privacyStatus' | 'license'; payload: boolean },
): ComboboxOpenState => {
  return {
    ...comboboxInitialOpenState,
    [action.type]: action.payload,
  }
}

const ShortBar = ({ data }: { data: YoutubeChannelData }): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, comboboxInitialOpenState)
  const onInputChange = (
    val: string | boolean | YoutubeCategory,
    attr: keyof YoutubeChannelData,
  ): void => {
    ComposerActionCreators.updateDraftYoutubeData({
      ...data,
      [attr]: val,
    })
  }

  return (
    <>
      <Wrapper>
        <Label className="outer-label">Title</Label>
        <Input
          onChange={(e): void => onInputChange(e.target.value, 'title')}
          value={data.title ?? ''}
          aria-label="Video title"
          className={'input ' + styles.input}
        />
        {data.title?.length > 90 && (
          <InputCharacterCount count={data.title?.length ?? 0} maxCount={100} />
        )}
      </Wrapper>

      <Wrapper>
        <FormColumnContainer data-testid="categoryFormContainer">
          <Label className="outer-label">Category</Label>
          <Combobox
            value={data.category?.id}
            onChange={(id): void => {
              if (id) {
                const category = YOUTUBE_CATEGORIES_US_REGION.find(
                  (region) => region.id === id,
                )

                if (category) {
                  onInputChange(category, 'category')
                  dispatch({ type: 'category', payload: false })
                }
              }
            }}
            open={state.category}
            onOpenChange={(open): void => {
              dispatch({ type: 'category', payload: open })
            }}
            multiple={false}
          >
            <Combobox.Trigger>
              <Button variant="secondary" className={styles.trigger}>
                <Text size="xs">{data.category?.title || 'None'}</Text>
                <ChevronDownIcon />
              </Button>
            </Combobox.Trigger>
            <Combobox.Content
              align="start"
              onEscapeKeyDown={(event: KeyboardEvent): void => {
                event.stopPropagation()
              }}
            >
              <Combobox.List>
                {YOUTUBE_CATEGORIES_US_REGION.map((region) => (
                  <Combobox.Item key={region.id} value={region.id}>
                    {region.title}
                  </Combobox.Item>
                ))}
              </Combobox.List>
            </Combobox.Content>
          </Combobox>
        </FormColumnContainer>
        <FormColumnContainer>
          <Label width="initial">Visibility</Label>

          <Combobox
            value={data.privacyStatus}
            onChange={(id): void => {
              if (id) {
                onInputChange(id, 'privacyStatus')
                dispatch({ type: 'privacyStatus', payload: false })
              }
            }}
            multiple={false}
            open={state.privacyStatus}
            onOpenChange={(open): void => {
              dispatch({ type: 'privacyStatus', payload: open })
            }}
          >
            <Combobox.Trigger>
              <Button variant="secondary" className={styles.trigger}>
                <Text size="xs">
                  {YOUTUBE_PRIVACY_STATUS_MAP[data.privacyStatus]}
                </Text>
                <ChevronDownIcon />
              </Button>
            </Combobox.Trigger>
            <Combobox.Content
              align="start"
              onEscapeKeyDown={(event: KeyboardEvent): void => {
                event.stopPropagation()
              }}
            >
              <Combobox.List>
                {Object.keys(YOUTUBE_PRIVACY_STATUS_MAP).map(
                  (privacyStatusKey) => (
                    <Combobox.Item
                      key={privacyStatusKey}
                      value={privacyStatusKey}
                    >
                      {
                        YOUTUBE_PRIVACY_STATUS_MAP[
                          privacyStatusKey as keyof typeof YOUTUBE_PRIVACY_STATUS_MAP
                        ]
                      }
                    </Combobox.Item>
                  ),
                )}
              </Combobox.List>
            </Combobox.Content>
          </Combobox>
        </FormColumnContainer>
      </Wrapper>

      <Wrapper>
        <Label className="outer-label">License</Label>

        <Combobox
          value={data.license}
          onChange={(id): void => {
            if (id) {
              onInputChange(id, 'license')
              dispatch({ type: 'license', payload: false })
            }
          }}
          multiple={false}
          open={state.license}
          onOpenChange={(open): void => {
            dispatch({ type: 'license', payload: open })
          }}
        >
          <Combobox.Trigger>
            <Button variant="secondary" className={styles.trigger}>
              <Text size="xs">{YOUTUBE_LICENSE_MAP[data.license]}</Text>
              <ChevronDownIcon />
            </Button>
          </Combobox.Trigger>
          <Combobox.Content
            align="start"
            onEscapeKeyDown={(event: KeyboardEvent): void => {
              event.stopPropagation()
            }}
          >
            <Combobox.List>
              {Object.keys(YOUTUBE_LICENSE_MAP).map((license) => (
                <Combobox.Item key={license} value={license}>
                  {
                    YOUTUBE_LICENSE_MAP[
                      license as keyof typeof YOUTUBE_LICENSE_MAP
                    ]
                  }
                </Combobox.Item>
              ))}
            </Combobox.List>
          </Combobox.Content>
        </Combobox>
      </Wrapper>

      <CheckboxGroupContainer>
        <Label htmlFor="notifySubscribers">
          <input
            type="checkbox"
            id="notifySubscribers"
            name="notifySubscribers"
            onChange={(e): void =>
              onInputChange(e.target.checked, 'notifySubscribers')
            }
            checked={data.notifySubscribers}
          />
          Notify Subscribers
        </Label>

        <Label htmlFor="embeddable">
          <input
            type="checkbox"
            id="embeddable"
            name="embeddable"
            onChange={(e): void =>
              onInputChange(e.target.checked, 'embeddable')
            }
            checked={data.embeddable}
          />
          Allow Embedding
        </Label>

        <Label htmlFor="madeForKids">
          <input
            type="checkbox"
            id="madeForKids"
            name="madeForKids"
            onChange={(e): void => {
              onInputChange(e.target.checked, 'madeForKids')
            }}
            checked={data.madeForKids}
          />
          Made for Kids
        </Label>
      </CheckboxGroupContainer>
    </>
  )
}

export { ShortBar }
