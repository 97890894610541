/* eslint-disable no-nested-ternary */
import { Modal, Text } from '@bufferapp/ui'
import CheckmarkIcon from '@bufferapp/ui/Icon/Icons/Checkmark'
import styled, {
  createGlobalStyle,
  css,
  type FlattenSimpleInterpolation,
  type Keyframes,
} from 'styled-components'

import {
  blue,
  gray,
  grayDark,
  grayDarker,
  grayLight,
  grayLighter,
  yellowDark,
  yellowLightest,
} from '@bufferapp/ui/style/colors'

export const GlobalStyles = createGlobalStyle`
  .fadeInStyle {
    animation-name: ${({ theme }): Keyframes =>
      theme?.animation?.keyframes?.fadeIn};
    animation-duration: 400ms;
    animation-timing-function: ease-in-out;
  }
    `

export const ModalWrapper = styled.div`
  background-color: black;
  & > div > section {
    background-size: cover;
    height: 620px;
    transition: background-image 400ms ease-in-out;
  }
`

/*
 * STYLES FOR THE MODAL WINDOW
 */

export const RemindersWizardModal = styled(Modal)`
  padding: 0;
  height: 620px;
`

export const Body = styled.div`
  display: flex;
  height: 100%;
`

export const StepContainer = styled.div`
  padding: 30px 40px 0px 356px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; // This is to push the button to the bottom
`

/*
 * STYLES FOR THE STEPS
 */

export const MainArea = styled.div`
  animation-name: ${({ theme }): Keyframes =>
    theme?.animation?.keyframes?.fadeIn};
  animation-duration: 400ms;
  animation-timing-function: ease-in-out;
  p {
    font-size: 14px;
    line-height: 20px;
    font-family: 'Roboto';
    font-weight: 400;
    color: ${grayDark};
  }
`

export const Title = styled(Text)`
  margin: 0;
`

export const StepsContainer = styled.div`
  margin: 32px 0 10px;
  width: 100%;
`

interface StepHeadlineProps {
  step?: number
  active?: boolean
  done?: boolean
}

/* Steps not done are preceding with the step number in a coloured circle */
/* Steps done are preceding with a checkmark in a coloured circle */
export const StepHeadline = styled.h4<StepHeadlineProps>`
  font-size: 16px;
  line-height: 24px;
  font-family: 'Roboto';
  font-weight: 700;
  color: ${(props): string => (props.active || props.done ? grayDarker : gray)};
  margin-bottom: 0;
  margin-top: 7px;

  ${(props): FlattenSimpleInterpolation | undefined =>
    props.step
      ? css`
          &:before {
            content: '${props.step}';
            display: inline-block;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            font-size: 14px;
            background-color: ${props.active ? blue : gray};
            color: #fff;
            text-align: center;
            line-height: 24px;
            margin-right: 15px;
          }
        `
      : undefined}

  ${(props): FlattenSimpleInterpolation | undefined =>
    props.done
      ? css`
          &:after {
            content: '';
            width: 2px;
            height: 27px;
            background-color: ${grayLight};
            margin-left: 11px;
            margin-top: 7px;
            display: block;
          }
        `
      : undefined}
`

interface StepDescriptionProps {
  borderBlue?: boolean
}
export const StepDescription = styled.div<StepDescriptionProps>`
  margin-top: -5px;
  margin-left: 12px;
  padding-left: 26px;
  padding-bottom: 10px;

  ${(props): FlattenSimpleInterpolation | undefined =>
    props.borderBlue
      ? css`
          border-left: 2px solid ${blue};
        `
      : undefined}
`

export const ButtonsArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
`

export const fadeInStyle = css`
  animation: fadeIn 0.3s ease-in-out;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

/*
 * STYLES SPECIFIC FOR THE STEP ONE
 */

export const FixedWidthWrapper = styled.div`
  max-width: 253px;
`

export const QRArea = styled.div`
  border: 1px solid ${grayLight};
  text-align: center;
  border-radius: 4px;
  padding: 8px;
  margin-top: 16px;
  background-color: ${grayLighter};
  font-size: 0; /* This is to remove the space between the img and the div */

  > img {
    width: 96px;
    height: 96px;
  }
`

export const AppDownloadButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  max-width: 245px;
`

/*
 * STYLES SPECIFIC FOR THE STEP TWO
 */
export const ErrorMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;
  border: 1px solid ${yellowDark};
  background-color: ${yellowLightest};
  border-radius: 4px;
  margin-bottom: 16px;

  svg {
    fill: ${yellowDark};
  }

  > span,
  a {
    color: ${yellowDark};
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`

export const CheckmarkIconDone = styled(CheckmarkIcon)`
  width: 20px;
  padding: 0 2px;
  height: 24px;
  border-radius: 50%;
  font-size: 14px;
  background-color: ${grayLight};
  text-align: center;
  line-height: 24px;
  margin-right: 15px;
  vertical-align: middle;
  margin-left: 4px;
`

/*
 * STYLES SPECIFIC FOR THE STEP THREE
 */
export const List = styled.ul`
  margin: 0;
  padding: 0;
`

export const ListItem = styled.li`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${grayDark};
  list-style: none;
  margin-bottom: 16px;
  margin-left: 7px;
`

export const CheckmarkBlue = styled(CheckmarkIcon)`
  fill: ${blue};
  margin-right: 7px;
`

export const TextLink = styled.span`
  color: ${blue};
`
