import React from 'react'

import {
  Avatar,
  Flex,
  Image,
  Link,
  Paragraph,
  Text,
  XRetweetIcon,
} from '@buffer-mono/popcorn'
import { isOfType } from '~publish/helpers/typeGuards'

import { usePostData } from '../PostCardContext'
import styles from './PostCardAttachments.module.css'

const Separator = (): JSX.Element => <span aria-hidden>&middot;</span>

const SubText = ({ children }: React.PropsWithChildren): JSX.Element => (
  <Text size="sm" color="subtle" className={styles.retweetUsername}>
    {children}
  </Text>
)

const RetweetAttachment = ({
  retweet,
}: {
  retweet: {
    text: string
    user: {
      name: string
      username: string
      avatar: string
    }
  }
}): JSX.Element => (
  <section className={styles.retweet}>
    <Avatar
      size="medium"
      src={retweet.user?.avatar ?? undefined}
      alt={retweet.user?.name || ''}
      className={styles.retweetAvatar}
    />
    <Flex gap="space-50" className={styles.retweetAuthor}>
      <div className={styles.retweetAuthorName}>
        <XRetweetIcon className={styles.retweetIcon} color="subtle" />
        <Text size="sm" weight="bold" className={styles.retweetUsername}>
          {retweet.user?.name}
        </Text>
        <Separator />
      </div>
      <SubText>{'@' + retweet.user?.username}</SubText>
    </Flex>
    <Text size="sm">{retweet.text}</Text>
  </section>
)

const LinkAttachment = ({
  linkAttachment,
}: {
  linkAttachment: {
    title: string
    url: string
    text: string
    expandedUrl?: string | null
    thumbnail?: string | null
  }
}): JSX.Element => (
  <section className={styles.link}>
    {linkAttachment.thumbnail && (
      <Image
        className={styles.linkImage}
        src={linkAttachment.thumbnail}
        alt={linkAttachment.title}
        loading="lazy"
      />
    )}
    <div className={styles.linkContent}>
      <Text size="sm" weight="bold">
        {linkAttachment.title}
      </Text>
      <Link
        className={styles.linkUrl}
        href={linkAttachment.url}
        target="_blank"
        rel="nooopever noreferrer"
      >
        {linkAttachment.expandedUrl || linkAttachment.url}
      </Link>
      <Paragraph>{linkAttachment.text}</Paragraph>
    </div>
  </section>
)

export const PostCardAttachments = (): JSX.Element | null => {
  const { metadata } = usePostData()

  const hasRetweet =
    isOfType(metadata, 'TwitterPostMetadata') && metadata.retweet
  const hasLinkAttachment =
    isOfType(metadata, 'FacebookPostMetadata') ||
    isOfType(metadata, 'LinkedInPostMetadata') ||
    (isOfType(metadata, 'BlueskyPostMetadata') && metadata.linkAttachment)

  const retweet = hasRetweet ? metadata.retweet : null
  const linkAttachment = hasLinkAttachment ? metadata.linkAttachment : null

  return (
    <>
      {retweet && <RetweetAttachment retweet={retweet} />}
      {linkAttachment && <LinkAttachment linkAttachment={linkAttachment} />}
    </>
  )
}
