import React from 'react'

import { getCurrentTimeZone } from '~publish/helpers/dateFormatters'

const TimeZoneContext = React.createContext({
  timeZone: getCurrentTimeZone(),
})

export const useTimezone = (): string => {
  return React.useContext(TimeZoneContext).timeZone
}

export const TimezoneProvider: React.FC<{
  timeZone: string
  children: React.ReactNode
}> = ({ timeZone, ...props }) => {
  return <TimeZoneContext.Provider {...props} value={{ timeZone }} />
}
