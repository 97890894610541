import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  CheckmarkIconDone,
  MainArea,
  StepDescription,
  StepHeadline,
  StepsContainer,
  Title,
} from '../styles'

export const StepTwoMainArea = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <MainArea>
      <Title type="h2">{t('remindersSetupWizard.headline')}</Title>
      <StepsContainer>
        {/* LOGIN TO THE BUFFER APP - DONE */}
        <StepHeadline active={false} done>
          <CheckmarkIconDone /> {t('remindersSetupWizard.stepOne.headline')}
        </StepHeadline>
        {/* ENABLE BUFFER APP NOTIFICATIONS */}
        <StepHeadline step={2} active done={false}>
          {t('remindersSetupWizard.stepTwo.headline')}
        </StepHeadline>
        <StepDescription>
          <p
            dangerouslySetInnerHTML={{
              __html: t('remindersSetupWizard.stepTwo.description'),
            }}
          />
        </StepDescription>
      </StepsContainer>
    </MainArea>
  )
}
