import { Image } from '@bufferapp/components'
import { useSplitEnabled } from '@buffer-mono/features'
import { Avatar, Button, Text } from '@bufferapp/ui'
import { gray } from '@bufferapp/ui/style/colors'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import type { HeaderDetails } from '~publish/legacy/campaign/types'
import { Notes } from '~publish/legacy/notes'
import type { PublishingNote } from '~publish/gql/graphql'

const CardHeaderWrapper = styled.div<{ withButton?: boolean }>`
  display: flex;
  border-bottom: 1px solid ${gray};
  padding: ${({ withButton }): string =>
    withButton ? '8px 16px' : '12px 16px'};
`

const ContentWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
`

const ChannelWrapper = styled.span`
  display: flex;
  align-items: center;
`

const ChannelDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ChannelHandle = styled(Text)`
  margin-left: 16px;
`

const ChannelLocation = styled(Text)`
  margin-left: 16px;
  font-size: 12px;
  font-weight: 400;
`

const CreatorWrapper = styled.span`
  display: flex;
  align-items: center;
`

const AvatarWrapper = styled.span`
  margin-right: 9px;
`

const TextWithStyles = styled(Text)`
  font-size: 12px;
  line-height: 20px;
  margin: 0;
`

const getCreatedText = (creatorEmail?: string): string =>
  creatorEmail ? `${creatorEmail} created this ` : 'Created '

interface Props {
  postId: string
  headerDetails: HeaderDetails
  notes: PublishingNote[]
}
const CardHeader = ({ postId, headerDetails, notes }: Props): JSX.Element => {
  const { isEnabled: isDraftsNotesEnabled } = useSplitEnabled(
    'build-week-drafts-comment',
  )
  const {
    channel,
    creatorEmail,
    avatarUrl,
    createdAt,
    hideCreatorDetails,
    onPreviewClick,
    showHeader,
  } = headerDetails

  if (!showHeader) return <></>

  const hasChannelDetails = channel?.handle && channel?.type
  const showDraftnotes = isDraftsNotesEnabled
  const network = channel?.type

  return (
    <CardHeaderWrapper
      withButton={!!onPreviewClick}
      className="wrapper-component"
    >
      <ContentWrapper>
        {hasChannelDetails && (
          <ChannelWrapper>
            <Avatar
              src={channel.avatarUrl}
              fallbackUrl="https://s3.amazonaws.com/buffer-ui/Default+Avatar.png"
              alt={channel.handle}
              size="small"
              type="social"
              network={network}
            />
            <ChannelDescriptionWrapper>
              <ChannelHandle type="label" color="grayDarker">
                {channel.handle}
              </ChannelHandle>
              {channel.serviceLocation?.location && (
                <ChannelLocation type="label" color="grayDark">
                  {channel.serviceLocation.location}
                </ChannelLocation>
              )}
            </ChannelDescriptionWrapper>
          </ChannelWrapper>
        )}
        <CreatorWrapper>
          {creatorEmail && !hasChannelDetails && (
            <AvatarWrapper>
              <Image
                src={avatarUrl}
                border="circle"
                height="1.25rem"
                width="1.25rem"
              />
            </AvatarWrapper>
          )}
          {!hideCreatorDetails && (
            <TextWithStyles type="p" color="grayDark">
              {getCreatedText(creatorEmail)}
              {createdAt}
            </TextWithStyles>
          )}
        </CreatorWrapper>
        {showDraftnotes && (
          <Notes entityId={postId} entityType="post" notes={notes} />
        )}
      </ContentWrapper>
      {onPreviewClick && (
        // @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; size: string;... Remove this comment to see the full error message
        <Button
          type="secondary"
          label="Preview"
          size="small"
          onClick={onPreviewClick}
        />
      )}
    </CardHeaderWrapper>
  )
}

CardHeader.propTypes = {
  postId: PropTypes.string,
  headerDetails: PropTypes.shape({
    creatorEmail: PropTypes.string,
    avatarUrl: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    hideCreatorDetails: PropTypes.bool,
    onPreviewClick: PropTypes.func,
    channel: PropTypes.shape({
      avatarUrl: PropTypes.string,
      handle: PropTypes.string,
      type: PropTypes.string,
      serviceLocation: PropTypes.shape({
        location: PropTypes.string,
      }),
    }),
  }).isRequired,
  notes: PropTypes.arrayOf(PropTypes.shape({})),
}

CardHeader.defaultProps = {
  postId: null,
  notes: [],
}

export default CardHeader
