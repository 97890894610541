import { useCallback, useEffect, useState } from 'react'

export function readLocalStorageValue<T>(key: string, defaultValue: T): T {
  try {
    const item = window.localStorage.getItem(key)
    return item ? JSON.parse(item) : defaultValue
  } catch (error) {
    return defaultValue
  }
}

function writeLocalStorageValue<T>(key: string, value: T): void {
  const stringifiedValue = JSON.stringify(value)
  const oldValue = window.localStorage.getItem(key)

  window.localStorage.setItem(key, stringifiedValue)
  const evt = new StorageEvent('storage', {
    storageArea: window.localStorage,
    key,
    oldValue,
    newValue: stringifiedValue,
    url: window.location.href,
  })
  window.dispatchEvent(evt)
}

/**
 * Hook to save state in local storage
 */
export function useLocalStorageState<T>(
  key: string,
  defaultValue: T,
): [T, (value: T) => void] {
  const [value, setValue] = useState<T>(() =>
    readLocalStorageValue(key, defaultValue),
  )

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent): void => {
      if (event.key === key) {
        setValue(readLocalStorageValue(key, defaultValue))
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [key, defaultValue])

  const setValueInLocalStorage = useCallback(
    (newValue: T): void => {
      setValue(newValue)
      try {
        writeLocalStorageValue(key, newValue)
      } catch (error) {
        // Ignore error, we should log it somewhere
        console.error(error)
      }
    },
    [key],
  )

  return [value, setValueInLocalStorage]
}
