import { Button, PromotionalBanner } from '@buffer-mono/popcorn'
import React from 'react'
import type { PostTab } from '~publish/components/PostTabs'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import {
  useHasEntitlement,
  useIsLegacyPlan,
} from '~publish/legacy/accountContext'
import { SEGMENT_NAMES } from '~publish/legacy/constants'
import { CtaButtonWrapper } from '../CtaButtonWrapper'
import { RegisteredBanner } from '../RegisteredBanner'

export const CollaborationUpgradePathBanner = (): JSX.Element | null => {
  const bannerId = 'collaborationUpgradePath'
  const banner = useDismissableBanner(bannerId, {
    trackDismiss: true,
  })
  const [tab = 'queue'] = useQueryParam<PostTab>('tab')
  const isLegacyPlan = useIsLegacyPlan()
  const hasAccessTeamPanel = useHasEntitlement('teamsPanel')

  const onCtaClick = (): void => {
    const { MODALS, actions } = window?.appshell || {}
    actions.openModal(MODALS.planSelector, {
      cta: SEGMENT_NAMES.DRAFTS_BANNER_UPGRADE,
      upgradePathName: 'approvals-upgrade',
    })
  }

  const canRenderBanner =
    tab === 'drafts' && banner.isActive && !isLegacyPlan && !hasAccessTeamPanel

  return (
    <RegisteredBanner id={bannerId} canRender={canRenderBanner}>
      <PromotionalBanner onDismiss={banner.dismiss}>
        <PromotionalBanner.Content>
          <PromotionalBanner.Heading>
            You don&apos;t have to work alone
          </PromotionalBanner.Heading>
          <PromotionalBanner.Description>
            Collaborate on drafts and review posts before publish!
          </PromotionalBanner.Description>
          <PromotionalBanner.Actions>
            <CtaButtonWrapper
              cta={`publish-${SEGMENT_NAMES.DRAFTS_BANNER_UPGRADE}`}
              options={{
                upgradePathName: 'approvals-upgrade',
              }}
            >
              <Button onClick={onCtaClick}>Upgrade to Invite Your Team</Button>
            </CtaButtonWrapper>
          </PromotionalBanner.Actions>
        </PromotionalBanner.Content>
        <PromotionalBanner.Image src="https://buffer-publish.s3.amazonaws.com/images/collaboration-banner.png" />
      </PromotionalBanner>
    </RegisteredBanner>
  )
}
