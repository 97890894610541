import * as datefns from 'date-fns'
import * as datefnsTz from 'date-fns-tz'

const getFormattedTime = (
  dueAt: number,
  timezone: string,
  use24HourTime: boolean,
): string => {
  const zonedDate = datefnsTz.toZonedTime(dueAt, timezone)
  if (use24HourTime) {
    return datefns.format(zonedDate, 'k:mm')
  }

  return datefns.format(zonedDate, 'h:mm aa')
}

export default getFormattedTime
