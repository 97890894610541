import { Heading, Link, Text, UnstyledButton } from '@buffer-mono/popcorn'
import clsx from 'clsx'
import React from 'react'
import { graphql, getFragmentData } from '~publish/gql'

import type { PostCardContent_AnnotationFragment } from '~publish/gql/graphql'
import styles from './PostCard.module.css'
import { usePostData } from './PostCardContext'

const CLAMP_TEXT_LINES_THRESHOLD = 3

export const PostCardContent_Annotation = graphql(/* GraphQL */ `
  fragment PostCardContent_Annotation on Annotation {
    type
    indices
    content
    text
    url
  }
`)

const generateTextWithAnnotations = ({
  text,
  annotations,
}: {
  text: string
  annotations?: readonly PostCardContent_AnnotationFragment[] | null
}): Array<JSX.Element | string> => {
  const elements: Array<JSX.Element | string> = []
  let currentIndex = 0

  if (!annotations || annotations.length === 0) {
    elements.push(text)
    return elements
  }

  annotations.forEach((annotation) => {
    const startIndex = annotation.indices[0]
    // Support for partial annotations if text is truncated
    const endIndex =
      annotation.indices[1] < text.length ? annotation.indices[1] : text.length

    if (startIndex < text.length) {
      if (currentIndex !== startIndex) {
        elements.push(text.substring(currentIndex, startIndex))
      }

      elements.push(
        <Link
          href={annotation.url}
          key={elements.length}
          external
          className={styles.link}
        >
          {annotation.text}
        </Link>,
      )
      currentIndex = endIndex
    }
  })

  if (currentIndex !== text.length) {
    elements.push(text.substring(currentIndex))
  }

  return elements
}

export const PostCardContent = (): JSX.Element | null => {
  const { text, metadata } = usePostData()
  const [isTextClamped, setIsTextClamped] = React.useState(false)
  const textRef = React.useRef<HTMLSpanElement>(null)

  React.useEffect(() => {
    if (!textRef.current) {
      return
    }
    const lineHeight = parseFloat(getComputedStyle(textRef.current).lineHeight)
    const maxHeight = lineHeight * CLAMP_TEXT_LINES_THRESHOLD
    setIsTextClamped(textRef.current.scrollHeight > maxHeight)
  }, [text])

  const annotations = getFragmentData(
    PostCardContent_Annotation,
    metadata?.annotations,
  )

  const textElements = generateTextWithAnnotations({
    text,
    annotations,
  })

  const title = metadata && 'title' in metadata ? metadata.title : ''

  return (
    <div className={styles.content}>
      {title && (
        <Heading as="h3" size="small">
          {title}
        </Heading>
      )}
      {text && (
        <Text as="p">
          <Text
            ref={textRef}
            className={clsx(
              styles.annotatedText,
              isTextClamped && styles.clampedText,
            )}
            aria-expanded={!isTextClamped}
            data-no-dnd
          >
            {textElements}
          </Text>
          {isTextClamped && (
            <UnstyledButton
              onClick={(): void => setIsTextClamped(false)}
              className={styles.readMoreButton}
            >
              more
            </UnstyledButton>
          )}
        </Text>
      )}
    </div>
  )
}

PostCardContent.displayName = 'PostCardContent'
