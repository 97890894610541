import React, { useState, useEffect, useRef } from 'react'
import { Button } from '@bufferapp/ui'
import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'
import ArrowRightIcon from '@bufferapp/ui/Icon/Icons/ArrowRight'
import CheckmarkIcon from '@bufferapp/ui/Icon/Icons/Checkmark'
import LoadIcon from '@bufferapp/ui/Icon/Icons/Load'
import { useTranslation } from 'react-i18next'
import { ButtonsArea, ErrorMessage } from '../styles'
import { getSendProcessLabel, sendTestNotification } from '../../utils'
import type { StepProps } from '../../types'
import { StepTwoMainArea } from './StepTwoMainArea'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { selectCurrentProfileId } from '~publish/legacy/profile-sidebar/selectors'
import { actions as profilesActions } from '~publish/legacy/profiles/reducer'
import { useAccount } from '~publish/legacy/accountContext'
import { trackButtonClick, trackStepViewed } from '../utils'
import { selectModalPlacement } from '~publish/legacy/modals/selectors'

const StepTwo = ({ handleNextStep }: StepProps): JSX.Element => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [isSent, setIsSent] = useState(false)
  const [testRun, setTestRun] = useState(0)
  const [sendingError, setSendingError] = useState({
    isError: false, // error false could contain a message as warning
    message: '',
  })
  const [renderButtons, setRenderButtons] = useState(false)
  const renderButtonsRef = useRef(false)

  const dispatch = useAppDispatch()
  const selectedProfileId = useAppSelector(selectCurrentProfileId)

  const remindersSetUpWizardPlacement: string = useAppSelector((state) =>
    selectModalPlacement(state),
  )

  const handleTryAgain = (): void => {
    trackButtonClick(
      'try-again',
      currentOrganization?.id,
      remindersSetUpWizardPlacement,
    )
    setRenderButtons(false)
    setTestRun((prevRuns) => prevRuns + 1)
  }

  const updateError = (isError: boolean, message: string): void => {
    setSendingError((prevError) => {
      const newError = { ...prevError }
      newError.isError = isError
      newError.message = message
      return newError
    })
  }

  const account = useAccount()
  const currentOrganization = account?.account?.currentOrganization

  useEffect(() => {
    if (currentOrganization) {
      trackStepViewed(2, currentOrganization?.id)
    }
  }, [currentOrganization])

  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    if (testRun >= 1) {
      sendTestNotification(setIsLoading, setIsSent).then((res) => {
        if (res?.success === false || res?.error) {
          updateError(
            true,
            res?.error?.code === 1001
              ? t('remindersSetupWizard.stepTwo.noDeviceFoundWarningMessage')
              : t('remindersSetupWizard.stepTwo.warningMessage'),
          )
        } else {
          updateError(
            false,
            testRun >= 2
              ? t('remindersSetupWizard.stepTwo.warningMessage')
              : '',
          )
        }
        // Notification sent successfully, we show again buttons to send another notification
        // after 800ms
        timeoutId = setTimeout(() => {
          setIsSent(false)
          renderButtonsRef.current = true
          setRenderButtons(renderButtonsRef.current)
        }, 800)
      })
    }

    return () => clearTimeout(timeoutId) // Cleanup the timeout on component unmount
  }, [testRun, t])

  /*
   * TRY AGAIN BUTTON PROPS
   */
  const tryAgainButtonProps = {
    type: 'secondary',
    className: renderButtons ? 'fadeInStyle' : '',
    disabled: isLoading || isSent,
    onClick: handleTryAgain,
    label: getSendProcessLabel(
      t('remindersSetupWizard.stepTwo.buttonLabelTryAgain'),
      isLoading,
      isSent,
      t,
    ),
    icon: isLoading ? <LoadIcon /> : isSent && <CheckmarkIcon />,
    'aria-label': 'Try again',
  }

  /*
   * NOTIFICATION RECEIVED BUTTON PROPS
   */
  const notificationReceivedButtonProps = {
    type: 'primary',
    className: renderButtons ? 'fadeInStyle' : '',
    onClick: (): void => {
      trackButtonClick(
        'notification-received',
        currentOrganization?.id,
        remindersSetUpWizardPlacement,
      )
      handleNextStep()
      // If the test notification was successful and
      // the current selected profile id is found,
      // refetch the profile to update the UI
      if (selectedProfileId) {
        dispatch(
          profilesActions.fetchSingleProfile({
            profileId: selectedProfileId,
            message: '',
          }),
        )
      }
    },
    label: t('remindersSetupWizard.stepTwo.buttonLabelNotificationReceived'),
    'aria-label': 'Notification received',
    icon: <ArrowRightIcon color="white" />,
    iconEnd: true,
  }

  /*
   * SEND NOTIFICATION BUTTON PROPS
   */
  const sendTestNotificationButtonProps = {
    type: 'primary',
    disabled: false /* to preserve the blue color of the button */,
    onClick: (): void => {
      trackButtonClick(
        'try-notifications',
        currentOrganization?.id,
        remindersSetUpWizardPlacement,
      )

      if (!isLoading && !isSent) {
        setTestRun((prevRuns) => prevRuns + 1)
      }
    },
    label: getSendProcessLabel(
      t('remindersSetupWizard.stepTwo.buttonLabel'),
      isLoading,
      isSent,
      t,
    ),
    'aria-label': 'Send Test notification',
    icon: isLoading ? (
      <LoadIcon color="white" />
    ) : (
      isSent && <CheckmarkIcon color="white" />
    ),
  }

  return (
    <>
      <StepTwoMainArea />
      <div>
        {sendingError.message !== '' && (
          <ErrorMessage
            className={sendingError.message !== '' ? 'fadeInStyle' : ''}
          >
            <WarningIcon />
            <span dangerouslySetInnerHTML={{ __html: sendingError.message }} />
          </ErrorMessage>
        )}
        <ButtonsArea>
          {testRun >= 1 && renderButtons && !sendingError.isError ? (
            <>
              {/* TRY AGAIN BUTTON */}
              {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; className: string; disabled:... Remove this comment to see the full error message */}
              <Button
                type={tryAgainButtonProps.type}
                className={tryAgainButtonProps.className}
                disabled={tryAgainButtonProps.disabled}
                onClick={tryAgainButtonProps.onClick}
                label={tryAgainButtonProps.label}
                aria-label={tryAgainButtonProps['aria-label']}
                icon={tryAgainButtonProps.icon}
              />
              {/* I RECEIVED THE NOTIFICATION BUTTON */}
              {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; className: string; onClick: ... Remove this comment to see the full error message */}
              <Button
                type={notificationReceivedButtonProps.type}
                className={notificationReceivedButtonProps.className}
                onClick={notificationReceivedButtonProps.onClick}
                label={notificationReceivedButtonProps.label}
                aria-label={notificationReceivedButtonProps['aria-label']}
                icon={notificationReceivedButtonProps.icon}
                iconEnd={notificationReceivedButtonProps.iconEnd}
              />
            </>
          ) : (
            // @ts-expect-error TS(2740) FIXME: Type '{ type: string; disabled: boolean; onClick: ... Remove this comment to see the full error message
            <Button
              type={sendTestNotificationButtonProps.type}
              disabled={sendTestNotificationButtonProps.disabled}
              onClick={sendTestNotificationButtonProps.onClick}
              label={sendTestNotificationButtonProps.label}
              aria-label={sendTestNotificationButtonProps['aria-label']}
              icon={sendTestNotificationButtonProps.icon}
            />
          )}
        </ButtonsArea>
      </div>
    </>
  )
}

export default StepTwo
