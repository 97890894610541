import React, { useCallback } from 'react'

import { Tooltip } from '@bufferapp/ui'
import { Add } from '@bufferapp/ui/Icon'

import { usePostComposer, type CTA } from '~publish/hooks/usePostComposer'

import { AddUpdateButton, ButtonWrapper } from './style'

interface AddButtonProps {
  cta: CTA
  labelDate: string
  fullWidth?: boolean
  defaultTimeslot: number
  selectedChannelIds: string[] | void
}

const AddButton: React.FC<AddButtonProps> = ({
  cta,
  labelDate,
  fullWidth = false,
  defaultTimeslot,
  selectedChannelIds,
}) => {
  const { triggerAttributes, createNewPostInComposer } = usePostComposer()

  const openComposer = useCallback((): void => {
    createNewPostInComposer({
      cta,
      channels:
        selectedChannelIds === undefined || selectedChannelIds.length === 0
          ? 'all'
          : selectedChannelIds,
      prefillPostData: {
        dueAt: defaultTimeslot,
      },
    })
  }, [createNewPostInComposer, cta, defaultTimeslot, selectedChannelIds])

  const addButtonContent = (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; label: string; position... Remove this comment to see the full error message
    // TODO: Migrate to Popcorn tooltip
    <Tooltip label="Add a post" position="top">
      <AddUpdateButton
        tabIndex="-1"
        data-testid="button-element"
        type="secondary"
        fullWidth={!!fullWidth}
        icon={<Add />}
        hasIconOnly
        onClick={openComposer}
        label={`Schedule post for ${labelDate}`}
        {...triggerAttributes}
      />
    </Tooltip>
  )

  return fullWidth ? (
    addButtonContent
  ) : (
    <ButtonWrapper>{addButtonContent}</ButtonWrapper>
  )
}

export default AddButton
