import BaseValidator from '~publish/legacy/composer/composer/lib/validation/BaseValidator'
import type ValidationResult from '~publish/legacy/validation/ValidationResult'
import ValidationSuccess from '~publish/legacy/validation/ValidationSuccess'
import ValidationFail from '~publish/legacy/validation/ValidationFail'
import { getMaxCharactersForTitleMessage } from '~publish/legacy/composer/composer/lib/validation/utils/validationErrorMessages'
import { VALIDATION_CODE } from '~publish/legacy/validation/constants'

export default class TiktokValidator extends BaseValidator {
  protected validateVideo(): ValidationResult {
    if (this.draft.isReminder) {
      return new ValidationSuccess()
    }

    return super.validateVideo()
  }

  protected maxCharactersInTitle(): ValidationResult {
    if (
      this.draft.service.titleCharLimit !== null &&
      (this.draft.channelData?.tiktok?.title?.length ?? 0) >
        this.draft.service.titleCharLimit
    ) {
      return new ValidationFail(
        getMaxCharactersForTitleMessage(this.draft.service.titleCharLimit),
        VALIDATION_CODE.MAX_LIMIT_REACHED,
      )
    }
    return new ValidationSuccess()
  }

  protected validateForChannel(): ValidationResult[] {
    return [this.maxCharactersInTitle()]
  }
}
