import { actionTypes as dataFetchActionTypes } from '@bufferapp/async-data-fetch'
import { actions as analyticsActions } from '~publish/legacy/analytics-middleware/actions'
import {
  getPageNameFromPath,
  getChannelIfNeeded,
} from '~publish/legacy/analytics-middleware/utils/Pathname'
import { LOCATION_CHANGE } from 'redux-first-history'

import { actionTypes } from './reducer'

import Bugsnag from '@bugsnag/browser'

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch, getState }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    next(action)
    switch (action.type) {
      case `user_${dataFetchActionTypes.FETCH_SUCCESS}`: {
        dispatch({ type: actionTypes.BUGSNAG, result: action.result })
        break
      }

      case actionTypes.BUGSNAG:
        Bugsnag.setUser(action.result.id)
        Bugsnag.addMetadata(
          'user',
          'adminLink',
          `https://buffer.com/admin/user/${action.result.id}`,
        )

        break

      case LOCATION_CHANGE: {
        const path = action.payload.location.pathname
        /* when a user first hits publish.buffer.com, we select a profile for them and the routes changes
         We don't want to track the initial load before the profile is selected */
        if (path !== '/') {
          const metadata = {
            platform: 'new_publish',
            product: 'publish',
            name: getPageNameFromPath(path) || null,
            path,
            title: document.title || null,
            url: window.location.origin || null,
            referrer: document.referrer || null,
            search: action.payload.search || null,
            // don't need channel if route isnt associated with profileId
            channel: getChannelIfNeeded({ path, getState }),
          }
          dispatch(analyticsActions.trackEvent('Page Viewed', metadata))
        }
        break
      }
      default:
        break
    }
  }
