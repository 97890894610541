import {
  Avatar,
  Flex,
  IconButton,
  PencilIcon,
  Text,
  Tooltip,
  TrashIcon,
} from '@buffer-mono/popcorn'
import clsx from 'clsx'
import { formatDistanceToNowStrict } from 'date-fns'
import React from 'react'
import type { Note as NoteType } from '~publish/gql/graphql'
import { EditNoteForm } from './EditNoteForm'
import styles from '../NotesWidget.module.css'
import { NoteContent } from './NoteContent'

const DEFAULT_AVATAR = 'https://s3.amazonaws.com/buffer-ui/Default+Avatar.png'

export interface NoteProps {
  note: NoteType
  isAuthor: boolean
  onEdit: (noteId: string, text: string) => Promise<void>
  onDelete: (noteId: string) => Promise<void>
}

export const Note = React.forwardRef<HTMLLIElement, NoteProps>(
  ({ note, isAuthor, onEdit, onDelete }: NoteProps, noteRef): JSX.Element => {
    const [isOverflown, setIsOverflown] = React.useState(false)
    const [isEditing, setIsEditing] = React.useState(false)
    const authorEmailRef = React.useRef<HTMLDivElement>(null)

    const { text, createdAt, author, updatedAt } = note

    const avatar = author?.avatar ?? DEFAULT_AVATAR
    const authorEmail = isAuthor ? 'You' : author?.email

    React.useEffect(() => {
      if (!authorEmailRef.current) return
      const element = authorEmailRef.current
      // Check if the element is overflown (aka: has ellipsis)
      setIsOverflown(element.scrollWidth > element.clientWidth)
    }, [])

    const showActions =
      note.allowedActions.includes('updateNote') ||
      note.allowedActions.includes('deleteNote')

    const handleSubmitEdit = async (editedText: string): Promise<void> => {
      if (
        !note.allowedActions.includes('updateNote') ||
        !editedText.trim() ||
        editedText === text
      ) {
        return
      }
      onEdit(note.id, editedText)
      setIsEditing(false)
    }

    const handleDeleteNote = (): void => {
      if (!note.allowedActions.includes('deleteNote')) {
        return
      }
      onDelete(note.id)
    }

    const authorTextElement = (
      <Text
        ref={authorEmailRef}
        size="md"
        weight="medium"
        className={styles.author}
      >
        {authorEmail}
      </Text>
    )

    return (
      <li
        ref={noteRef}
        className={clsx(
          styles.note,
          isAuthor && !isEditing && styles.canEditNote,
        )}
      >
        <Avatar src={avatar} alt="Avatar" size="small" />
        <Flex className={styles.noteContent} direction="column" gap="space-50">
          {isEditing ? (
            <EditNoteForm
              value={text}
              onSubmit={handleSubmitEdit}
              onCancel={(): void => setIsEditing(false)}
            />
          ) : (
            <>
              <Flex
                className={styles.noteHeader}
                gap="space-100"
                align="baseline"
              >
                {isOverflown ? (
                  <Tooltip content={authorEmail}>{authorTextElement}</Tooltip>
                ) : (
                  authorTextElement
                )}
                <Text
                  className={styles.createdAt}
                  as="time"
                  weight="medium"
                  color="subtle"
                  dateTime={createdAt}
                >
                  {formatDistanceToNowStrict(createdAt, {
                    addSuffix: true,
                  })}
                </Text>
              </Flex>
              <NoteContent
                text={text}
                isEdited={!!updatedAt && updatedAt !== createdAt}
              />
            </>
          )}
        </Flex>
        {showActions && (
          <Flex gap="space-100" className={styles.noteActions}>
            {note.allowedActions.includes('updateNote') && (
              <IconButton
                label="Edit note"
                variant="secondary"
                tooltip="Edit note"
                onClick={(): void => setIsEditing(true)}
              >
                <PencilIcon />
              </IconButton>
            )}
            {note.allowedActions.includes('deleteNote') && (
              <IconButton
                label="Delete note"
                variant="critical"
                tooltip="Delete note"
                onClick={handleDeleteNote}
              >
                <TrashIcon />
              </IconButton>
            )}
          </Flex>
        )}
      </li>
    )
  },
)

Note.displayName = 'Note'
