import { actionTypes as dataFetchActionTypes } from '@bufferapp/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actions as notificationActions } from '@bufferapp/notifications'

import getNotificationMessage from './utils/getNotificationMessage'

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch, getState }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    next(action)
    switch (action.type) {
      case 'APP_INIT': {
        /**
         * APP_INIT also includes the query params at the time of load
         * so we'll put those in the global window object for use later.
         */
        let notification = null
        const { queryParams: query } = action
        const type = query.get('nt')
        const key = query.get('nk')
        const variable = query.get('nv')
        if (type && key) {
          notification = {
            type: query.get('nt'), // Notification Type
            key: query.get('nk'), // Notification Key
          }
          if (variable) {
            // @ts-expect-error TS(2339) FIXME: Property 'variable' does not exist on type '{ type... Remove this comment to see the full error message
            notification.variable = variable // Notification Variable
          }
        }
        // @ts-expect-error TS(2551) FIXME: Property '_notification' does not exist on type 'W... Remove this comment to see the full error message
        window._notification = notification
        break
      }
      case `profiles_${dataFetchActionTypes.FETCH_SUCCESS}`: {
        const profilesLoaded = getState().profileSidebar.loading === false
        if (!profilesLoaded) {
          break
        }

        // @ts-expect-error TS(2551) FIXME: Property '_notification' does not exist on type 'W... Remove this comment to see the full error message
        if (window._notification) {
          // @ts-expect-error TS(2551) FIXME: Property '_notification' does not exist on type 'W... Remove this comment to see the full error message
          const notificationType = window._notification.type

          const message = getNotificationMessage(
            notificationType,
            // @ts-expect-error TS(2551) FIXME: Property '_notification' does not exist on type 'W... Remove this comment to see the full error message
            window._notification.key,
            // @ts-expect-error TS(2551) FIXME: Property '_notification' does not exist on type 'W... Remove this comment to see the full error message
            window._notification.variable,
          )

          if (message) {
            dispatch(
              notificationActions.createNotification({
                notificationType,
                message,
              }),
            )
          }
        }
        break
      }
      default:
        break
    }
  }
