import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Text } from '@bufferapp/components'
import { LinkifiedText } from '~publish/legacy/shared-components/LinkifiedText'
import PostExtraData from '../PostExtraData/PostExtraData'

const ContentTextWrapper = styled.span`
  padding-right: 1rem;
  flex-grow: 1;
  overflow: hidden;
  overflow-wrap: break-word;
`

const UpdateTextContent = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'basic' implicitly has an 'any' ty... Remove this comment to see the full error message
  basic,
  // @ts-expect-error TS(7031) FIXME: Binding element 'text' implicitly has an 'any' typ... Remove this comment to see the full error message
  text,
  // @ts-expect-error TS(7031) FIXME: Binding element 'links' implicitly has an 'any' ty... Remove this comment to see the full error message
  links,
  // @ts-expect-error TS(7031) FIXME: Binding element 'channelData' implicitly has an 'a... Remove this comment to see the full error message
  channelData,
  // @ts-expect-error TS(7031) FIXME: Binding element 'annotations' implicitly has an 'a... Remove this comment to see the full error message
  annotations,
}) => {
  const updatedLinks = [...links]
  // @ts-expect-error TS(7006) FIXME: Parameter 'annotation' implicitly has an 'any' typ... Remove this comment to see the full error message
  annotations?.forEach((annotation) => {
    const { localizedName, start, length, link } = annotation
    updatedLinks.push({
      displayString: localizedName,
      rawString: localizedName,
      indices: [start, start + length],
      url: link,
    })
  })

  return (
    <ContentTextWrapper>
      {channelData && <PostExtraData channelData={channelData} />}
      {basic ? (
        <Text color="shuttleGray" size="mini" whitespace="pre-wrap">
          {text}
        </Text>
      ) : (
        <LinkifiedText
          color="shuttleGray"
          links={updatedLinks}
          size="mini"
          whitespace="pre-wrap"
          newTab
        >
          {text}
        </LinkifiedText>
      )}
    </ContentTextWrapper>
  )
}

UpdateTextContent.propTypes = {
  basic: PropTypes.bool,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      displayString: PropTypes.string,
      expandedUrl: PropTypes.string,
      indices: PropTypes.arrayOf(PropTypes.number),
      rawString: PropTypes.string,
    }),
  ),
  text: PropTypes.string.isRequired,
  channelData: PropTypes.shape({
    share_to_feed: PropTypes.string,
  }),
  annotations: PropTypes.arrayOf(PropTypes.shape({})),
}

UpdateTextContent.defaultProps = {
  basic: false,
  links: [],
  channelData: null,
  annotations: [],
}

export default UpdateTextContent
