import React from 'react'
import { useSplitEnabled } from '@buffer-mono/features'
import { InfoIcon, Tooltip } from '@buffer-mono/popcorn'
import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'

import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { actions as remindersActions } from '~publish/legacy/reminders'
import { showInstagramNotificationsPreview } from '~publish/legacy/reminders/selectors'
import { useAccount } from '~publish/legacy/accountContext'

import { Instagram, Phone } from '@bufferapp/ui/Icon'
import { InstagramNotificationsPreview } from './components/InstagramNotificationsPreview'
import { CLIENT_NAMES } from '~publish/legacy/constants'
import * as Styles from './PostPreview.styles'
import { PREVIEWS_MAP } from './previews'
import type { DraftPreview, ProfilePreview } from './previews/types'
import { DEFAULT_AVATAR } from './previews/Common/utils'
import { grayDark, orange } from '@bufferapp/ui/style/colors'

export const PostPreview: React.FC<{
  draftPreview: DraftPreview
  profilePreview: ProfilePreview
}> = ({ draftPreview, profilePreview }) => {
  const { isEnabled: isInstagramRemindersEnabled } = useSplitEnabled(
    'instagram-reminders',
  )
  const { account } = useAccount()

  const { name: serviceName } = profilePreview ?? {}
  const dispatch = useAppDispatch()

  const showNotificationsPreviewFromState = useAppSelector(
    showInstagramNotificationsPreview,
  )

  if (!profilePreview || !draftPreview || draftPreview.service.isOmni) {
    return null
  }

  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const preview = PREVIEWS_MAP[serviceName] || null
  if (!preview) return null

  const { title, helpText, Preview: PreviewComponent } = preview

  const isInstagramReminder =
    isInstagramRemindersEnabled &&
    draftPreview.isReminder &&
    serviceName.toLowerCase() === 'instagram'

  const showInstagramRemindersMobilePreview =
    isInstagramReminder && showNotificationsPreviewFromState

  return (
    <Styles.Wrapper id="post-preview">
      <Styles.HeaderWrapper>
        <Styles.TitleWrapper>
          <Styles.Title>{title}</Styles.Title>
          <Tooltip content={helpText}>
            <Styles.Icon>
              <InfoIcon size="small" color="subtle" />
            </Styles.Icon>
          </Tooltip>
        </Styles.TitleWrapper>
        {isInstagramReminder && (
          <Styles.ToggleWrapper>
            <Tooltip content="You'll get a mobile notification to publish this content when it's time.">
              <Styles.ToggleButton
                id="reminders-preview"
                onClick={(): void => {
                  dispatch(
                    remindersActions.showInstagramNotificationsPreview(true),
                  )

                  BufferTracker.remindersPostPreviewViewClicked({
                    organizationId: account?.currentOrganization?.id || '',
                    view: 'reminder',
                    clientName: CLIENT_NAMES.web,
                    service: serviceName,
                  })
                }}
                selected={showNotificationsPreviewFromState}
              >
                <Phone
                  color={showNotificationsPreviewFromState ? orange : grayDark}
                />
              </Styles.ToggleButton>
            </Tooltip>
            <Tooltip content="This preview is an approximation of how this post could look like, editing is possible before publishing.">
              <Styles.ToggleButton
                id="default-preview"
                onClick={(): void => {
                  dispatch(
                    remindersActions.showInstagramNotificationsPreview(false),
                  )

                  BufferTracker.remindersPostPreviewViewClicked({
                    organizationId: account?.currentOrganization?.id || '',
                    view: 'post',
                    clientName: CLIENT_NAMES.web,
                    service: serviceName,
                  })
                }}
                selected={!showNotificationsPreviewFromState}
              >
                <Instagram
                  color={!showNotificationsPreviewFromState ? orange : grayDark}
                />
              </Styles.ToggleButton>
            </Tooltip>
          </Styles.ToggleWrapper>
        )}
      </Styles.HeaderWrapper>
      {showInstagramRemindersMobilePreview ? (
        <InstagramNotificationsPreview />
      ) : (
        <PreviewComponent
          draft={draftPreview}
          profile={{
            ...profilePreview,
            avatar: profilePreview.avatar || DEFAULT_AVATAR,
          }}
        />
      )}
    </Styles.Wrapper>
  )
}
