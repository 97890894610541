import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Dialog,
  Button,
  Flex,
  Image,
  Heading,
  Paragraph,
} from '@buffer-mono/popcorn'

import { getURL } from '~publish/legacy/utils/formatters'
import { actions as modalActions } from '~publish/legacy/modals/reducer'

import { type ServiceInfo, connectAChannelServiceData } from './configuration'

import styles from './ConnectAChannelModal.module.css'
import { useTracking } from '~publish/legacy/tracking/useTracking'
import { getCTAValue, getUpgradePathName } from './utils'

export const getServiceData = (serviceId: string): ServiceInfo | undefined => {
  return connectAChannelServiceData.find((serv) => serv.id === serviceId)
}

const ConnectAChannelModal = ({
  service,
}: {
  service: string
}): JSX.Element | null => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const dispatch = useDispatch()
  const serviceData = getServiceData(service)

  const onClose = (): void => {
    dispatch(modalActions.hideConnectAChannelModal())
  }

  /* We preload the images to avoid flickering */
  useEffect(() => {
    if (!serviceData?.banner_img_url) return

    const img = new window.Image()
    img.src = serviceData.banner_img_url
    img.onload = () => setImageLoaded(true)
  }, [serviceData?.banner_img_url])

  if (!serviceData) return null

  if (imageLoaded) {
    return (
      <Dialog open={true} onOpenChange={onClose}>
        <Dialog.Content size="medium" className={styles.content}>
          <Flex direction="column" align="center" gap="space-300">
            <Image
              alt={`${serviceData.label} banner`}
              className={styles.banner}
              src={serviceData.banner_img_url}
            />
            <Flex direction="column" align="center" gap="space-100">
              <Heading size="medium" align="center" color="neutral">
                {serviceData.title}
              </Heading>
              <Paragraph align="center" color="subtle">
                {serviceData.description}
              </Paragraph>
            </Flex>
            <ConnectAChannelModalFooter
              serviceData={serviceData}
              onClose={onClose}
            />
          </Flex>
        </Dialog.Content>
      </Dialog>
    )
  } else {
    return null
  }
}

/*
 * The footer contains two buttons:
 *  - Connect to the selected channel => starts the proces to connect
 *  - Connect Other channels => goes to channel list page
 */

const ConnectAChannelModalFooter = ({
  serviceData,
  onClose,
}: {
  serviceData: ServiceInfo
  onClose: () => void
}): JSX.Element => {
  const [processing, setProcessing] = useState(false)
  const {
    trackCTAViewed,
    trackCTAClicked,
    trackChannelCTAViewed,
    trackChannelCTAClicked,
  } = useTracking()

  const payloadChannelCTA = {
    cta: getCTAValue(serviceData.id, 'connectButton'),
    upgradePathName: getUpgradePathName(serviceData.id),
    channel: serviceData.id,
  }

  const payloadOtherChannelsCTA = {
    cta: getCTAValue(serviceData.id, 'connectOtherChannels'),
    upgradePathName: getUpgradePathName(serviceData.id),
  }

  useEffect(() => {
    trackChannelCTAViewed(payloadChannelCTA)
    trackCTAViewed(payloadOtherChannelsCTA)
  }, [])
  /*
   * Takes the user to the Channels list page
   */
  const goToChannelsPage = (): void => {
    trackCTAClicked(payloadOtherChannelsCTA)
    const connectURL = getURL.getAccountChannelsURL()
    window.location.assign(connectURL)
  }

  /*
   * Starts the process and closes the modal
   */
  const startConnection = (): void => {
    const { actions } = window.appshell || {}

    actions?.connectChannel({
      selectedService: serviceData.id,
    })

    setProcessing(false)
    onClose()
  }

  /*
   * If appshell is available, call connectChannel method
   * in other case, wait for appshell to be loaded
   */
  const handleOnClick = (): void => {
    trackChannelCTAClicked(payloadChannelCTA)

    /* we disabled the button to avoid multiple clicks
    while we wait for window.apshell  */
    setProcessing(true)

    const { actions } = window.appshell || {}

    if (!actions) {
      window.addEventListener('load', startConnection)
      return
    }

    window.removeEventListener('load', startConnection)
    startConnection()
  }

  return (
    <Flex direction="column" gap="space-100" align="center">
      <Button
        aria-label={serviceData.buttonLabel}
        data-testid="connect-channel-modal-connect-button"
        variant="primary"
        className={styles.button}
        size="large"
        onClick={handleOnClick}
        disabled={processing}
      >
        {serviceData.icon}
        {processing ? 'Processing...' : serviceData.buttonLabel}
      </Button>

      <Button
        arial-label="Connect Other channels"
        data-testid="connect-channel-modal-connect-other-button"
        variant="tertiary"
        className={styles.button}
        size="large"
        onClick={goToChannelsPage}
      >
        or Connect Other Channels
      </Button>
    </Flex>
  )
}

export { ConnectAChannelModal }
