import * as Dialog from '@radix-ui/react-dialog'

import CrossIcon from '@bufferapp/ui/Icon/Icons/Cross'
import React from 'react'

import {
  CloseDialogButton,
  InnerWrapper,
  ModalWrapper,
  StyledOverlay,
} from './styles'

import { SearchImagesForm } from '../search-images-form/SearchImagesForm'
import type { IntegrationsService, IntegrationsContext } from '../../types'

export const SearchImagesModal = ({
  open,
  handleClose,
  service,
  context: editor,
}: {
  open: boolean
  handleClose: () => void
  service: IntegrationsService | null
  context: IntegrationsContext
}): JSX.Element | null => {
  if (service === null) return <></>

  const handleOpenToggle = (open: boolean): void => {
    if (!open) handleClose()
  }

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenToggle}>
      <Dialog.Portal>
        <StyledOverlay />
        <ModalWrapper onPointerDownOutside={handleClose}>
          <InnerWrapper data-testid="ideas-modal-window">
            <SearchImagesForm editor={editor} service={service} />
            <Dialog.Close asChild>
              <CloseDialogButton aria-label="Close" type="button">
                <CrossIcon />
              </CloseDialogButton>
            </Dialog.Close>
          </InnerWrapper>
        </ModalWrapper>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
