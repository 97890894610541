import styled from 'styled-components'
import CharacterCount from '../CharacterCount'

export const CommentCharacterCount = styled(CharacterCount)`
  position: absolute;
  top: 9px;
  right: 4px;
  background-color: #e6ebef;
  border-radius: 4px;
  padding: 3px 3px;
  line-height: 12px;
`
