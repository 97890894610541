import React from 'react'
import { Badge, Button, Text, HoverCard } from '@buffer-mono/popcorn'

import { useAppDispatch } from '~publish/legacy/store'
import { actions as guideActions } from '~publish/legacy/guides'

import { useTriggerRemindersSetupWizard } from '~publish/legacy/modals/hooks/useTriggerRemindersSetupWizard'
import { useNotificationsEnabled } from '~publish/legacy/hooks/useNotificationsEnabled'

import { SEGMENT_NAMES } from '~publish/legacy/constants'

import * as Styles from './styles'

type StepPropTypes = {
  index: number
  step: {
    title: string
    content: string
    callback?: () => void
    placement: string
  }
  size: number
  isLastStep: boolean
  closeProps: {
    onClick: (event: React.MouseEvent<HTMLElement>) => void
  }
  primaryProps: {
    onClick: (event: React.MouseEvent<HTMLElement>) => void
  }
}

export function Step({
  index,
  step,
  size,
  isLastStep,
  closeProps,
  primaryProps,
}: StepPropTypes): JSX.Element {
  const dispatch = useAppDispatch()
  const handleTriggerRemindersSetupWizard = useTriggerRemindersSetupWizard({
    placement: 'composer',
    cta: SEGMENT_NAMES.REMINDERS_INSTAGRAM_GUIDE_COMPLETED,
  })
  const hasNotificationsSetUp = useNotificationsEnabled()

  const currentStepForDisplay = index + 1
  const totalStepsForDisplay = size

  const handleCloseGuide = (event: React.MouseEvent<HTMLElement>) => {
    closeProps.onClick(event)

    if (step.callback) {
      // Don't show again and reset notification preview state
      step.callback()
    }
  }

  return (
    <HoverCard open>
      <Styles.Wrapper placement={step.placement}>
        <div>
          <Badge size="medium" className="badge section">
            {currentStepForDisplay}/{totalStepsForDisplay}
          </Badge>
        </div>
        <Text as="span" size="md" className="title section">
          <strong>{step.title}</strong>
        </Text>
        <Text as="p" size="sm" className="text section">
          {step.content}
        </Text>
        {!isLastStep && (
          <Button
            as="button"
            size="medium"
            variant="primary"
            onClick={(event) => {
              primaryProps.onClick(event)

              if (step.callback) {
                step.callback()
              }

              dispatch(
                guideActions.setInstagramNotificationsComposerGuideStep({
                  step: index + 1,
                }),
              )
            }}
          >
            Next
          </Button>
        )}
        {isLastStep && (
          <Styles.ActionsWrapper>
            {hasNotificationsSetUp ? (
              <Button
                as="button"
                size="medium"
                variant="primary"
                onClick={(event) => {
                  handleCloseGuide(event)
                }}
              >
                Got It
              </Button>
            ) : (
              <>
                <Button
                  as="button"
                  size="medium"
                  variant="primary"
                  onClick={(event) => {
                    handleCloseGuide(event)
                    // Open Reminders set up wizard
                    handleTriggerRemindersSetupWizard()
                  }}
                >
                  Set Up Notifications
                </Button>
                <Button
                  as="button"
                  size="medium"
                  variant="tertiary"
                  onClick={(event) => {
                    handleCloseGuide(event)
                  }}
                >
                  Maybe Later
                </Button>
              </>
            )}
          </Styles.ActionsWrapper>
        )}
      </Styles.Wrapper>
      <Styles.Beacon placement={step.placement} />
    </HoverCard>
  )
}
