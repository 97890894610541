import NotificationActionCreators from '~publish/legacy/composer/composer/action-creators/NotificationActionCreators'
import { NotificationScopes } from '~publish/legacy/composer/composer/AppConstants'
import RPCClient from '~publish/legacy/composer/composer/utils/RPCClient'

export const failedFacebookAutocompleteRequestMessage = `Oops! We lost access to your Facebook account, and can't provide 
Facebook page tag suggestions as a result. Would you be up for 
refreshing that channel connection and trying again?`

export type FacebookMentionResponseItem = {
  id: string
  link: string
  name: string
}

export const getFacebookPrivateSuggestions = (
  search: string,
  profileId: string,
): Promise<FacebookMentionResponseItem[]> => {
  if (!search.trim()) return Promise.resolve([])

  return RPCClient.call('composerApiProxy', {
    url: `/1/search/facebook_pages.json`,
    args: {
      q: search,
      profile_id: profileId,
    },
    // @ts-expect-error TS(7006) FIXME: Parameter 'results' implicitly has an 'any' type.
  }).then((results) => {
    if (typeof results === 'object' && results.error) {
      NotificationActionCreators.queueError({
        scope: NotificationScopes.AUTOCOMPLETE,
        message: failedFacebookAutocompleteRequestMessage,
        isUnique: true,
      })
    }
    return results
  })
}
