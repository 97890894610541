import React from 'react'
import {
  Input,
  Label,
  Wrapper,
  FieldWrapper,
} from '../../channel-fields/styles'
import ComposerActionCreators from '../../../action-creators/ComposerActionCreators'
import { startPageDataPropType } from '../StartPagePropTypes'
import type { StartPageChannelData } from '../../../entities/Draft/types'

const LinkBar = ({ data }: { data: StartPageChannelData }): JSX.Element => {
  const onInputChange = (
    val: string,
    attr: keyof StartPageChannelData,
  ): void => {
    const updatedData = { ...data }
    updatedData[attr] = val

    ComposerActionCreators.updateDraftStartPageData(updatedData)
  }

  return (
    <Wrapper>
      <FieldWrapper>
        <Label htmlFor="start-page-link">Link (Optional)</Label>
        <Input
          id="start-page-link"
          onChange={(e): void => onInputChange(e.target.value, 'link')}
          value={data.link ?? ''}
          placeholder="Example: https://buffer.com"
        />
      </FieldWrapper>
    </Wrapper>
  )
}

LinkBar.propTypes = {
  data: startPageDataPropType.isRequired,
}

export default LinkBar
