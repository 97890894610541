import React from 'react'
import PropTypes from 'prop-types'

import AppActionCreators from '../../action-creators/AppActionCreators'
import { SaveButtonTypes, ButtonsQueuingTypesMap } from '../../AppConstants'
import Dropdown, { DropdownTrigger, DropdownContent } from '../Dropdown'
import DateTimeSlotPicker from '../DateTimeSlotPicker'
import Button from '../shared/Button'
import { userDataPropType, profilesPropType } from '../ComposerPropTypes'
import styles from '../css/UpdateSaver.module.css'

const UpdateSaverItem = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'type' implicitly has an 'any' typ... Remove this comment to see the full error message
  type,
  // @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
  children,
  // @ts-expect-error TS(7031) FIXME: Binding element 'userData' implicitly has an 'any'... Remove this comment to see the full error message
  userData,
  // @ts-expect-error TS(7031) FIXME: Binding element 'disabled' implicitly has an 'any'... Remove this comment to see the full error message
  disabled,
  // @ts-expect-error TS(7031) FIXME: Binding element 'timezone' implicitly has an 'any'... Remove this comment to see the full error message
  timezone,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isMenuItem' implicitly has an 'an... Remove this comment to see the full error message
  isMenuItem,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isSecondaryItem' implicitly has a... Remove this comment to see the full error message
  isSecondaryItem,
  // @ts-expect-error TS(7031) FIXME: Binding element 'weekStartsMonday' implicitly has ... Remove this comment to see the full error message
  weekStartsMonday,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isInlineSchedulerDisplayed' impli... Remove this comment to see the full error message
  isInlineSchedulerDisplayed,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectedProfiles' implicitly has ... Remove this comment to see the full error message
  selectedProfiles,
  // @ts-expect-error TS(7031) FIXME: Binding element 'draftMode' implicitly has an 'any... Remove this comment to see the full error message
  draftMode,
}) => {
  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  const onButtonClick = (e) => {
    const queuingType = ButtonsQueuingTypesMap.get(type)

    AppActionCreators.saveDrafts(queuingType, {
      shouldSkipEmptyTextAlert: false,
      hasCampaignsToTagsFeature: true,
    })

    e.stopPropagation()
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'timestamp' implicitly has an 'any' type... Remove this comment to see the full error message
  const onDateTimeSlotPickerSubmit = (timestamp) => {
    const queuingType = ButtonsQueuingTypesMap.get(type)

    AppActionCreators.saveDrafts(queuingType, {
      customScheduleTime: timestamp,
      shouldSkipEmptyTextAlert: false,
      hasCampaignsToTagsFeature: true,
    })
  }

  const getClassName = () => {
    if (isMenuItem) return styles.dropdownMenuItem
    if (isSecondaryItem) return styles.secondaryButton

    return styles.button
  }

  const schedulishSaveButtonTypes = [
    SaveButtonTypes.SCHEDULE_POST,
    SaveButtonTypes.SCHEDULE_DRAFT,
  ]

  const internalButtonType =
    schedulishSaveButtonTypes.includes(type) && !isInlineSchedulerDisplayed
      ? 'SCHEDULE_POST_DROPDOWN'
      : 'BUTTON'
  const isScheduleDraftAction =
    type === SaveButtonTypes.SCHEDULE_DRAFT ||
    (type === SaveButtonTypes.SAVE && draftMode)

  switch (internalButtonType) {
    case 'SCHEDULE_POST_DROPDOWN':
      return (
        <Dropdown className={styles.dropdownContainerAsMenuItem}>
          <DropdownTrigger className={styles.dropdownTriggerAsMenuItem}>
            {children}
          </DropdownTrigger>
          <DropdownContent className={styles.dropdownContentAsMenuItem}>
            <DateTimeSlotPicker
              onSubmit={onDateTimeSlotPickerSubmit}
              shouldUse24hTime={userData.uses24hTime}
              timezone={timezone}
              weekStartsMonday={weekStartsMonday}
              // @ts-expect-error TS(2322) FIXME: Type '{ onClick: (e: any) => any; onSubmit: (times... Remove this comment to see the full error message
              selectedProfiles={selectedProfiles}
              submitButtonCopy={
                isScheduleDraftAction ? 'Add Draft' : 'Schedule'
              }
              showDraftScheduleNotice={isScheduleDraftAction}
            />
          </DropdownContent>
        </Dropdown>
      )

    default:
      return (
        <Button
          type="button"
          className={getClassName()}
          disabled={disabled}
          onClick={onButtonClick}
        >
          {children}
        </Button>
      )
  }
}

UpdateSaverItem.propTypes = {
  type: PropTypes.oneOf(Object.keys(SaveButtonTypes)).isRequired,
  children: PropTypes.node.isRequired,
  userData: userDataPropType.isRequired,
  isInlineSchedulerDisplayed: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  timezone: PropTypes.string,
  isMenuItem: PropTypes.bool,
  isSecondaryItem: PropTypes.bool,
  draftMode: PropTypes.bool,
  weekStartsMonday: PropTypes.bool.isRequired,
  selectedProfiles: profilesPropType.isRequired,
}

UpdateSaverItem.defaultProps = {
  disabled: false,
  isMenuItem: false,
  isSecondaryItem: false,
  draftMode: false,
  timezone: undefined, // defaults are set in the DateTimeSlotPicker component
}

export default UpdateSaverItem
