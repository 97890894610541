import PropTypes from 'prop-types'
import React from 'react'
import { RemindersBar } from '~publish/legacy/reminders'
import { ChannelFields } from '~publish/legacy/composer/composer/components/channel-fields'
import AppActionCreators from '../action-creators/AppActionCreators'
import ComposerActionCreators from '../action-creators/ComposerActionCreators'
import { ErrorTypes, NotificationScopes } from '../AppConstants'
import AppStore from '../stores/AppStore'
import { NotificationStore } from '../stores/NotificationStore'
import { AttachmentSwitch } from './attachment-switch/AttachmentSwitch'
import AttachmentGlance from './AttachmentGlance'
import ComposerNetworkIcon from './ComposerNetworkIcon'
import { EditorWrapper } from './EditorWrapper'
import InstagramFeedback from './InstagramFeedback'
import LinkAttachment from './LinkAttachment'
import MediaAttachment from './MediaAttachment'
import EditorNotices from './notices/EditorNotices'
import NotificationContainer from './NotificationContainer'
import RetweetAttachment from './RetweetAttachment'
import SuggestedMediaBox from './SuggestedMediaBox'

import { ComposerIntegrationsBar } from '../../../integrations-bar/ComposerIntegrationsBar'
import GBPPostTypeBar from './channel-bar/GBPPostTypeBar'
import InstagramPostTypeBar from './channel-bar/InstagramPostTypeBar'
import YoutubePostTypeBar from './channel-bar/YoutubePostTypeBar'
import { draftPropType } from './ComposerPropTypes'
import styles from './css/Composer.module.css'
import MastodonNotice from './notices/MastodonNotice'
import { IGVideosAsReelsNotice } from './notices/IGVideosAsReelsNotice'
import Thread from './Thread'
import FacebookPostTypeBar from './channel-bar/FacebookPostTypeBar'
import { BypassValidationWithReminderMessage } from './notices/BypassValidationWithReminderMessage'
import { RemindersOmniIndicator } from '~publish/legacy/composer/composer/components/RemindersOmniIndicator'
import type Draft from '../entities/Draft/Draft'

type Props = {
  draft: Draft
  forceEditorFocus: boolean
  expandedComposerId: string | null
  children: React.ReactNode
  isRemindersEnabled: boolean
}

class Composer extends React.Component<
  Props,
  {
    didRenderOnce: boolean
    shouldAutoFocusEditor: boolean
    emojiContainer: any
  }
> {
  constructor(props: Props) {
    super(props)

    this.state = {
      didRenderOnce: false,
      shouldAutoFocusEditor: false,
      emojiContainer: null,
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props): void {
    /**
     * Auto-focus editor upon expanding composer. A composer can be considered
     * as expanding:
     * - When it was previously collapsed, and user expanded it
     * - When the app hasn't loaded, and the composer is auto-expanded on load
     */

    const { draft, expandedComposerId } = this.props
    const { didRenderOnce } = this.state
    const willBeExpanded = AppStore.getExpandedComposerId() === draft.id
    const isExpanded = expandedComposerId === draft.id && didRenderOnce

    const shouldAutoFocusEditor =
      (!isExpanded && willBeExpanded) || nextProps.forceEditorFocus
    this.setState({ didRenderOnce: true, shouldAutoFocusEditor })

    if (nextProps.forceEditorFocus) AppActionCreators.stopForcingEditorFocus()
  }

  onComposerClick = (event: React.MouseEvent | React.FocusEvent): void => {
    if (!this.isExpanded()) {
      event.preventDefault()

      this.expand()
    }
  }

  // Ensure other parts of the app ignore click events coming from inside the composer
  // (e.g. when collapsing an expanded composer when a click is registered outside).
  // Don't prevent default for file input clicks or links, but prevent them from bubbling up,
  // so that the default browser action (file selection) still happens.
  onUpdateZoneClick = (event: React.MouseEvent): void => {
    if (!this.isExpanded()) {
      return
    }

    const target = event.target as HTMLElement

    const isFileInputTargeted =
      target.tagName === 'INPUT' && target.getAttribute('type') === 'file'
    const isLinkTargeted = target.tagName === 'A'

    const isCheckboxInputTargeted =
      target.tagName === 'INPUT' && target.getAttribute('type') === 'checkbox'

    const isLabelTargeted = target.tagName === 'LABEL'

    if (
      isFileInputTargeted ||
      isLinkTargeted ||
      isCheckboxInputTargeted ||
      isLabelTargeted
    ) {
      event.stopPropagation()
    } else {
      event.preventDefault()
    }
  }

  onEditorFocus = (): void => this.expand()

  isExpanded = (): boolean => {
    const { draft } = this.props

    return AppStore.getExpandedComposerId() === draft.id
  }

  // Determine if that composer is displayed as "behind" another active composer
  isDisplayedBehind = (): boolean => !this.isExpanded()

  expand = (): void => {
    if (this.isExpanded()) {
      return
    }
    const { draft } = this.props

    ComposerActionCreators.expand(draft.id)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'emojiContainer' implicitly has an 'any'... Remove this comment to see the full error message
  setEmojiContainer = (emojiContainer): void => {
    this.setState({
      emojiContainer,
    })
  }

  render() {
    const { draft } = this.props

    if (!draft.isEnabledOrOmni()) return <div />

    const { children, isRemindersEnabled } = this.props

    const { shouldAutoFocusEditor, emojiContainer } = this.state

    const attachmentGlanceHasNoThumbnail =
      draft.getAttachmentThumbnails() === null
    const visibleNotifications = NotificationStore.getVisibleNotifications()

    // TODO: find a way to get rid of this, it's used only for updateZoneClassName
    const shouldShowAlertIcons =
      AppStore.getAppState().whatPreventsSaving.filter(
        (what) => what.composerId === draft.id,
      ).length > 0 && AppStore.getAppState().isOmniboxEnabled === false
    // TODO: find a way to get rid of this, it's used only for updateZoneClassName
    const shouldShowOmniboxNotices =
      (visibleNotifications.some(
        (notif) =>
          notif.scope === NotificationScopes.MC_OMNIBOX_EDIT_NOTICE &&
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          notif.data.id === draft.id,
      ) ||
        draft.isReminder) &&
      !this.isExpanded()

    const updateZoneClassName = [
      draft.isSaved
        ? styles.savedUpdateZone
        : this.isExpanded()
        ? styles.expandedUpdateZone
        : styles.updateZone,
      draft.hasAttachment() && !this.isExpanded() && draft.hasSavingError
        ? styles.editableErrorUpdateZone
        : '',
      draft.isSaved ? styles.lockedDisplayedBehindAnotherZone : '',
      this.isExpanded()
        ? styles.newUpdateZone
        : styles.displayedBehindAnotherUpdateZone,
    ].join(' ')

    const composerClassName = [
      this.isDisplayedBehind()
        ? styles.composerDisplayedBehindAnother
        : styles.composer,
      draft.isSaved ? styles.lockedComposer : '',
      draft.service.isOmni ? styles.omnibox : '',
      !AppStore.getAppState().isOmniboxEnabled && this.isExpanded()
        ? styles.newStandardComposer
        : '',
    ].join(' ')

    const isImageFirst = draft.isImageFirst() && draft.service.isYoutube()

    const suggestedMediaBoxClassName = [
      // eslint-disable-next-line no-nested-ternary
      isImageFirst
        ? styles.imageFirstSuggestedMediaBox
        : !draft.hasAttachmentSwitch()
        ? styles.suggestedMediaBoxAlignedBottom
        : '',
    ].join(' ')

    const editorContainerClassName = [
      isImageFirst ? styles.imageFirstContainer : styles.editorMediaContainer,
    ].join(' ')
    const editorClassName = [
      isImageFirst ? styles.imageFirstWrapper : '',
      styles.editorContainer,
    ].join(' ')
    const shouldShowThreadComponents =
      draft.canHaveThread() && this.isExpanded()
    const shouldShowEditor =
      !draft.isStoryPost() && !draft.isFacebookStoryPost()

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div
        className={composerClassName}
        onClick={this.onComposerClick}
        onFocus={this.onComposerClick}
      >
        <ComposerNetworkIcon draft={draft} />

        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div onClick={this.onUpdateZoneClick} className={updateZoneClassName}>
          <GBPPostTypeBar draft={draft} />
          <InstagramPostTypeBar draft={draft} />
          <FacebookPostTypeBar draft={draft} />
          <YoutubePostTypeBar draft={draft} />
          {!shouldShowThreadComponents && (
            <>
              <RemindersOmniIndicator draft={draft} />
              <MastodonNotice draft={draft} />
              <IGVideosAsReelsNotice draft={draft} />
              <EditorNotices draft={draft} />
              <div className={editorContainerClassName}>
                <div className={editorClassName}>
                  {shouldShowEditor && (
                    <EditorWrapper
                      draft={draft}
                      // @ts-expect-error TS(2322) FIXME: Type '{ draft: any; onFocus: () => void; shouldAut... Remove this comment to see the full error message
                      onFocus={this.onEditorFocus}
                      shouldAutoFocus={shouldAutoFocusEditor}
                      attachmentGlanceHasNoThumbnail={
                        attachmentGlanceHasNoThumbnail
                      }
                      setEmojiContainer={this.setEmojiContainer}
                    />
                  )}
                  <MediaAttachment draft={draft} />
                </div>
                <LinkAttachment draft={draft} />
                <RetweetAttachment draft={draft} />
                {this.isExpanded() && <AttachmentSwitch draft={draft} />}
                <SuggestedMediaBox
                  draft={draft}
                  className={suggestedMediaBoxClassName}
                />
                {this.isExpanded() && draft.service.isInstagram() && (
                  <BypassValidationWithReminderMessage
                    visible={!draft.isReminder}
                  />
                )}
                {this.isExpanded() && (
                  <ComposerIntegrationsBar
                    draft={draft}
                    emojiContainer={emojiContainer}
                  />
                )}
                {this.isExpanded() && isRemindersEnabled && (
                  <RemindersBar draft={draft} />
                )}
              </div>
            </>
          )}
          {shouldShowThreadComponents && (
            <Thread
              editorClassName={editorClassName}
              editorContainerClassName={editorContainerClassName}
              suggestedMediaBoxClassName={suggestedMediaBoxClassName}
              draft={draft}
              onEditorFocus={this.onEditorFocus}
              shouldAutoFocus={shouldAutoFocusEditor}
              emojiContainer={emojiContainer}
              setEmojiContainer={this.setEmojiContainer}
            />
          )}

          {this.isExpanded() && <ChannelFields draft={draft} />}
          <InstagramFeedback draft={draft} />
          <AttachmentGlance draft={draft} />

          <NotificationContainer
            visibleNotifications={visibleNotifications}
            scope={`${NotificationScopes.PROFILE_QUEUE_LIMIT}-${draft.service.name}`}
            classNames={{
              container: styles.composerInfoMessageContainer,
              notification: styles.composerInfoMessage,
              notificationCloseButton: styles.composerInfoMessageCloseButton,
            }}
          />
          <NotificationContainer
            visibleNotifications={visibleNotifications}
            scope={`${NotificationScopes.UPDATE_SAVING}-${ErrorTypes.INLINE}-${draft.service.name}`}
            classNames={{
              notification: styles.inlineError,
            }}
          />

          {children}
        </div>
      </div>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
Composer.propTypes = {
  draft: draftPropType.isRequired,
  forceEditorFocus: PropTypes.bool.isRequired,
  expandedComposerId: PropTypes.string,
  children: PropTypes.node,
  isRemindersEnabled: PropTypes.bool,
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
Composer.defaultProps = {
  children: null,
  expandedComposerId: null,
  isRemindersEnabled: false,
}

export default Composer
