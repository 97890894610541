export const getBaseURL = () => {
  if (window.location.hostname === 'publish.local.buffer.com') {
    return 'local.buffer.com'
  }
  return 'buffer.com'
}
export const getClassicBufferURL = () => {
  if (window.location.hostname === 'publish.local.buffer.com') {
    return 'https://local.buffer.com/classic'
  }
  return 'https://buffer.com/classic'
}
export const getBackToClassicNewPublishBufferURL = () => {
  if (window.location.hostname === 'publish.local.buffer.com') {
    return 'https://local.buffer.com/back_publish'
  }
  return 'https://buffer.com/back_publish'
}
export const getConnectSocialAccountURL = () => {
  if (window.location.hostname === 'publish.local.buffer.com') {
    return 'https://local.buffer.com/manage/accounts/connect'
  }
  return 'https://buffer.com/manage/accounts/connect'
}
export const getManageSocialAccountURL = () => {
  if (window.location.hostname === 'publish.local.buffer.com') {
    return 'https://local.buffer.com/manage/organization'
  }
  return 'https://buffer.com/manage/organization'
}
export const getManageTeamURL = () => {
  if (window.location.hostname === 'publish.local.buffer.com') {
    return 'https://local.buffer.com/manage/team-members'
  }
  return 'https://buffer.com/manage/team-members'
}
export const getConnectBitlyURL = (profileId: any) => {
  if (window.location.hostname === 'publish.local.buffer.com') {
    return `https://local.buffer.com/oauth/bitly/${profileId}/auth`
  }
  return `https://buffer.com/oauth/bitly/${profileId}/auth`
}
export const getDisconnectBitlyURL = (profileId: any) => {
  if (window.location.hostname === 'publish.local.buffer.com') {
    return `https://local.buffer.com/profile/disconnect_bitly_auth/${profileId}`
  }
  return `https://buffer.com/profile/disconnect_bitly_auth/${profileId}`
}
export const getStartTrialURL = ({
  plan,
  cycle,
  trialType,
  cta,
  nextUrl,
}: any) => {
  const nextParam = nextUrl ? `&next=${nextUrl}` : ''
  // temporarily adding to rule out that unknown ctas are not coming from publish
  const ctaParam = cta
    ? `&cta=${cta}`
    : `&cta=publish-test-getStartTrialUrl-start${trialType}Trial-1`
  // we are slowly deprecating trialType in favor of plan
  const planParam = trialType
    ? `trialType=${trialType}`
    : `plan=${plan}&cycle=${cycle}`
  if (window.location.hostname === 'publish.local.buffer.com') {
    return `https://local.buffer.com/billing/start-trial?${planParam}${ctaParam}${nextParam}`
  }
  return `https://buffer.com/billing/start-trial?${planParam}${ctaParam}${nextParam}`
}
export const getBillingURL = ({ cta }: any) => {
  const ctaParam = cta ? `&cta=${cta}` : ''
  if (window.location.hostname === 'publish.local.buffer.com') {
    return `https://local.buffer.com/app/account/receipts?content_only=true${ctaParam}`
  }
  return `https://buffer.com/app/account/receipts?content_only=true${ctaParam}`
}
export const getRemindersURL = ({ profileId, cta, nextUrl }: any) => {
  const ctaParam = cta ? `cta=${cta}` : ''
  const encodedUrl = nextUrl && encodeURI(nextUrl)
  const nextParam = encodedUrl ? `&next=${encodedUrl}` : ''
  if (window.location.hostname === 'publish.local.buffer.com') {
    return `https://local.buffer.com/oauth/instagram/setup_reminders/${profileId}?${ctaParam}${nextParam}`
  }
  return `https://buffer.com/oauth/instagram/setup_reminders/${profileId}?${ctaParam}${nextParam}`
}
export const getPublishUrl = () => {
  if (window.location.hostname === 'publish.local.buffer.com') {
    return 'https://publish.local.buffer.com'
  }
  return 'https://publish.buffer.com'
}
export const getAnalyzeReportUrl = (campaignId: string): string => {
  if (!campaignId) {
    // Send user to reports route if campaignId isn't valid
    return 'https://analyze.buffer.com/reports'
  }
  if (window.location.hostname === 'publish.local.buffer.com') {
    return `https://analyze.local.buffer.com/campaigns/${campaignId}`
  }
  return `https://analyze.buffer.com/campaigns/${campaignId}`
}

export const getAnalyzeReportUrlForTags = (tagId: string): string => {
  if (!tagId) {
    // Send user to reports route if campaignId isn't valid
    return 'https://analyze.buffer.com/reports'
  }
  if (window.location.hostname === 'publish.local.buffer.com') {
    return `https://analyze.local.buffer.com/tags/${tagId}`
  }
  return `https://analyze.buffer.com/tags/${tagId}`
}

export const getAccountURL = (): string => {
  if (window.location.hostname === 'publish.local.buffer.com') {
    return 'https://account.local.buffer.com:8080'
  }
  return 'https://account.buffer.com'
}

export const getAccountChannelsURL = () => {
  if (window.location.hostname === 'publish.local.buffer.com') {
    return 'https://account.local.buffer.com:8080/channels'
  }
  return 'https://account.buffer.com/channels'
}
export const getChannelConnectURL = () => {
  if (window.location.hostname === 'publish.local.buffer.com') {
    return 'https://account.local.buffer.com:8080/channels/connect'
  }
  return 'https://account.buffer.com/channels/connect'
}
export const getMigrationHubURL = () => {
  if (window.location.hostname === 'publish.local.buffer.com') {
    return 'https://account.local.buffer.com:8080/new-plans'
  }
  return 'https://account.buffer.com/new-plans'
}
export default {
  getBaseURL,
  getClassicBufferURL,
  getBackToClassicNewPublishBufferURL,
  getConnectSocialAccountURL,
  getManageSocialAccountURL,
  getManageTeamURL,
  getConnectBitlyURL,
  getDisconnectBitlyURL,
  getStartTrialURL,
  getBillingURL,
  getRemindersURL,
  getPublishUrl,
  getAnalyzeReportUrl,
  getAnalyzeReportUrlForTags,
  getAccountChannelsURL,
  getChannelConnectURL,
  getMigrationHubURL,
}
