import React from 'react'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import IdeasEditorSidepanel from '../IdeasEditorSidePanel'
import { AIAssistant } from '~publish/legacy/ai/components/AIAssistant/AIAssistant'

export const AIAssistantIdeasSidePanel = ({
  editor,
  onClose,
  state,
  onAnimationEnd,
}: {
  editor: BufferEditor
  onClose: () => void
  state?: string
  onAnimationEnd?: () => void
}): JSX.Element => {
  return (
    <IdeasEditorSidepanel
      onClose={onClose}
      state={state}
      onAnimationEnd={onAnimationEnd}
    >
      <AIAssistant editor={editor} />
    </IdeasEditorSidepanel>
  )
}
