import BaseValidator from '~publish/legacy/composer/composer/lib/validation/BaseValidator'
import type ValidationResult from '~publish/legacy/validation/ValidationResult'
import ValidationSuccess from '~publish/legacy/validation/ValidationSuccess'
import ValidationFail from '~publish/legacy/validation/ValidationFail'
import { REQUIRES_TEXT_OR_ATTACHMENT } from '~publish/legacy/composer/composer/lib/validation/utils/validationErrorMessages'
import {
  type Draft,
  DraftMethods,
} from '~publish/legacy/composer/composer/entities/Draft'
import { VALIDATION_CODE } from '~publish/legacy/validation/constants'

export default class ThreadsValidator extends BaseValidator {
  protected validateVideo(): ValidationResult {
    const postVideos = (
      this.draft.thread?.map((threadedUpdate) => threadedUpdate.video) || [
        this.draft.video,
      ]
    ).filter(Boolean)
    const hasThreads = this.draft.hasThread()

    if (postVideos.length) {
      const {
        videoMaxSize,
        videoMaxDurationMs,
        videoMinFrameRate,
        videoMaxFrameRate,
        videoMaxWidth,
      } = this.draft.service

      for (let i = 0; i < postVideos.length; i++) {
        const video = postVideos[i]

        if (!video) {
          continue
        }

        if (videoMaxSize && video.size > videoMaxSize) {
          return new ValidationFail(
            `Video size exceeds the limit of ${videoMaxSize / 1024 / 1024} MB${
              hasThreads ? ` for Post #${i + 1}` : ''
            }`,
            VALIDATION_CODE.MAX_LIMIT_REACHED,
            undefined,
            {
              threadId: i,
            },
          )
        }

        if (videoMaxDurationMs && video.duration > videoMaxDurationMs) {
          return new ValidationFail(
            `Video duration exceeds the limit of ${
              videoMaxDurationMs / 1000
            } seconds${hasThreads ? ` for Post #${i + 1}` : ''}`,
            VALIDATION_CODE.MAX_LIMIT_REACHED,
            undefined,
            {
              threadId: i,
            },
          )
        }

        if (video.frameRate) {
          if (videoMinFrameRate && video.frameRate < videoMinFrameRate) {
            return new ValidationFail(
              `Video frame rate is below the limit of ${videoMinFrameRate}${
                hasThreads ? ` for Post #${i + 1}` : ''
              }`,
              VALIDATION_CODE.VIDEO_FRAME_RATE,
              undefined,
              {
                threadId: i,
              },
            )
          }

          if (videoMaxFrameRate && video.frameRate > videoMaxFrameRate) {
            return new ValidationFail(
              `Video frame rate exceeds the limit of ${videoMaxFrameRate}${
                hasThreads ? ` for Post #${i + 1}` : ''
              }`,
              VALIDATION_CODE.MAX_LIMIT_REACHED,
              undefined,
              {
                threadId: i,
              },
            )
          }
        }

        if (videoMaxWidth && video.width > videoMaxWidth) {
          return new ValidationFail(
            `Video width exceeds the limit of ${videoMaxWidth}${
              hasThreads ? ` for Post #${i + 1}` : ''
            }`,
            VALIDATION_CODE.MAX_LIMIT_REACHED,
            undefined,
            {
              threadId: i,
            },
          )
        }
      }
    }

    return new ValidationSuccess()
  }

  protected validateAttachmentOrTextPresence(): ValidationResult[] {
    const drafts = this.draft.thread || [this.draft]
    const hasThreads = this.draft.hasThread()

    return drafts.map((threadedDraft, i) => {
      const draft = i === this.activeThreadId ? this.draft : threadedDraft

      if (
        !DraftMethods.hasText(draft as Draft) &&
        !DraftMethods.hasAttachment(draft as Draft)
      ) {
        return new ValidationFail(
          `${REQUIRES_TEXT_OR_ATTACHMENT}${
            hasThreads ? ` for Post #${i + 1}` : ''
          }`,
          VALIDATION_CODE.MISSING_VALUE,
          undefined,
          {
            threadId: i,
          },
        )
      }

      const charLimit = DraftMethods.getCharLimit(this.draft) || 0
      const characterCount = threadedDraft.characterCount

      if (characterCount && characterCount > charLimit) {
        return new ValidationFail(
          `Text length exceeds the limit of ${charLimit}${
            hasThreads ? ` for Post #${i + 1}` : ''
          }`,
          VALIDATION_CODE.MAX_LIMIT_REACHED,
          undefined,
          {
            threadId: i,
          },
        )
      }

      return new ValidationSuccess()
    })
  }

  protected validateNumberOfThreads(): ValidationResult {
    if (!this.draft.thread) {
      return new ValidationSuccess()
    }

    if (!this.draft.service.maxThreads) {
      return new ValidationSuccess()
    }

    if (this.draft.thread?.length > this.draft.service.maxThreads) {
      return new ValidationFail(
        `Number of ${this.draft.service.nameOfPost} exceeds the limit of ${this.draft.service.maxThreads}`,
        VALIDATION_CODE.MAX_LIMIT_REACHED,
      )
    }

    return new ValidationSuccess()
  }

  /**
   * Validate the actual draft, that is when isDraft == true
   * @protected
   */
  protected validateDraft(): ValidationFail[] {
    return [
      ...this.validateAttachmentOrTextPresence(),
      this.validateVideo(),
      this.validateNumberOfThreads(),
    ].filter((validation): validation is ValidationFail =>
      validation.isValidationFail(),
    )
  }

  /**
   * Validate the actual update, that is when isDraft == false
   * @protected
   */
  protected validateUpdate(): ValidationFail[] {
    return this.validateDraft()
  }
}
