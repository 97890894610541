import type React from 'react';
import { useEffect, useMemo, useState } from 'react'
import { UpdateStatus } from '~publish/legacy/post/types'
import { type planType, usePlan } from '~publish/legacy/accountContext'
import { useQueryParam } from '~publish/hooks/useQueryParam'

type ValidStatus =
  | UpdateStatus.Draft
  | UpdateStatus.PendingApproval
  | UpdateStatus.Pending
  | UpdateStatus.Sent

export interface CalendarFilter {
  type?: ('post' | 'draft' | 'story')[]
  status?: ValidStatus[]
  needsApproval?: boolean
}

export interface CalendarFilterItem {
  id: string
  title:
    | 'All Posts'
    | 'Drafts'
    | 'Scheduled'
    | 'Sent'
    | 'Pending Approval'
    | 'Awaiting Approval'
  filter: CalendarFilter
}

const menuItems: CalendarFilterItem[] = [
  {
    id: '1',
    title: 'All Posts',
    filter: {},
  },
  {
    id: '2',
    filter: {
      type: ['draft'],
      status: [UpdateStatus.Draft, UpdateStatus.PendingApproval],
    },
    title: 'Drafts',
  },
  {
    id: '3',
    filter: {
      type: ['post'],
      status: [UpdateStatus.Pending],
    },
    title: 'Scheduled',
  },
  {
    id: '4',
    title: 'Sent',
    filter: {
      type: ['post'],
      status: [UpdateStatus.Sent],
    },
  },
]

export function getPlanItems(plan: planType | undefined): CalendarFilterItem[] {
  if (!plan) return []

  if (plan.id) {
    if (!['team', 'agency'].includes(plan.id) && plan.isOneBufferOrganization) {
      return []
    }
  }

  return [
    {
      id: '5',
      title: plan.isAdmin ? 'Pending Approval' : 'Awaiting Approval',
      filter: {
        type: ['draft'],
        status: [UpdateStatus.PendingApproval],
      },
    },
  ]
}

export const useMenuItems = (): [
  CalendarFilterItem[],
  React.Dispatch<React.SetStateAction<CalendarFilterItem[]>>,
] => {
  const plan = usePlan()
  const [items, setItems] = useState([...menuItems])

  useEffect(() => {
    setItems([...menuItems, ...getPlanItems(plan)])
  }, [plan])

  return [items, setItems]
}

const useSelectedItem = (
  items: CalendarFilterItem[],
  selected: string | undefined,
): CalendarFilterItem => {
  return useMemo(
    () => items.find((i) => i.id === selected) || items[0],
    [items, selected],
  )
}

type MenuState = {
  selectedItem: CalendarFilterItem
  setFilterValue: (id: string) => void
}

export const useMenuState = (): MenuState => {
  const [items] = useMenuItems()
  const [filterPresetId, setFilterValue] =
    useQueryParam<string>('filter_preset')

  const selectedFilterPresetId = filterPresetId ?? '1'

  const selectedItem = useSelectedItem(items, selectedFilterPresetId)

  return {
    selectedItem,
    setFilterValue,
  }
}

export const useActiveFilter = (): CalendarFilter => {
  const { selectedItem } = useMenuState()
  return selectedItem.filter
}
