import twitter, { type EntityWithIndices } from 'twitter-text'
import { isValidHandle } from '@atproto/syntax'

import styles from './helpers.module.css'
import { removeUrlProtocol } from '~publish/legacy/composer/composer/utils/StringUtils'
import { concatMentionsToEntities } from '../common'

type BlueskyLink = {
  displayString: string
  indices: [number, number]
  rawString: string
  url: string
  className?: string
}

const getBlueskyMentions = (text: string): BlueskyLink[] | null => {
  const regex = /(?<=^|\s)@([a-zA-Z0-9.-]+)/g
  const matches = text.match(regex)

  if (!matches) {
    return null
  }

  return matches
    .map((match: string): BlueskyLink | null => {
      const mention = match.slice(1)

      if (isValidHandle(mention)) {
        const start = text.indexOf(match)

        return {
          displayString: match,
          rawString: match,
          url: `https://bsky.app/profile/${mention}`,
          className: styles.link,
          indices: [start, start + match.length],
        }
      }

      return null
    })
    .filter(Boolean) as BlueskyLink[]
}

const getEntities = (
  text: string,
  entities: EntityWithIndices[],
): BlueskyLink[] => {
  const mentions = getBlueskyMentions(text)

  return concatMentionsToEntities(mentions, entities)
}

const parseBlueskyLinks = (text: string): BlueskyLink[] => {
  const entities = twitter
    .extractEntitiesWithIndices(text)
    .map((entity: EntityWithIndices): BlueskyLink | null => {
      if ('url' in entity && entity.url) {
        return {
          displayString: removeUrlProtocol(entity.url),
          indices: entity.indices,
          rawString: entity.url,
          url: entity.url,
          className: styles.link,
        }
      }

      if ('hashtag' in entity && entity.hashtag) {
        return {
          displayString: `#${entity.hashtag}`,
          indices: entity.indices,
          rawString: `#${entity.hashtag}`,
          url: `https://bsky.app/hashtag/${entity.hashtag}`,
          className: styles.link,
        }
      }

      return null
    })
    .filter(Boolean) as BlueskyLink[]

  return text ? getEntities(text, entities) : []
}

export { parseBlueskyLinks }
