import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { actions as modalActions } from '~publish/legacy/modals/reducer'
import { useAppSelector } from '~publish/legacy/store'
import { StepOne } from '../StepOne'
import { StepTwo } from '../StepTwo'
import { StepThree } from '../StepThree'
import {
  Body,
  StepContainer,
  RemindersWizardModal,
  GlobalStyles,
  ModalWrapper,
} from '../styles'
import { useAccount } from '~publish/legacy/accountContext'
import { trackButtonClick } from '../utils'
import { selectModalPlacement } from '~publish/legacy/modals/selectors'

const stepComponents = [StepOne, StepTwo, StepThree]

const imagesForSteps = [
  'https://buffer-publish.s3.amazonaws.com/images/wizard-step-01.png',
  'https://buffer-publish.s3.amazonaws.com/images/wizard-step-02.png',
  'https://buffer-publish.s3.amazonaws.com/images/wizard-step-03.png',
]

const RemindersWizard = (): JSX.Element => {
  const dispatch = useDispatch()
  const [step, setStep] = useState(1)
  const account = useAccount()
  const currentOrganization = account?.account?.currentOrganization

  useEffect(() => {
    imagesForSteps.forEach((image) => {
      const img = new Image()
      img.src = image
    })
  }, [])

  const remindersSetUpWizardPlacement: string = useAppSelector((state) =>
    selectModalPlacement(state),
  )

  const onCloseButtonCallback = (): void => {
    trackButtonClick(
      'skip',
      currentOrganization?.id,
      remindersSetUpWizardPlacement,
    )
    dispatch(modalActions.hideRemindersWizardModal())
  }

  const handleNextStep = (): void => {
    // Logic for moving to the next step
    setStep((prevStep) => (prevStep > 2 ? 1 : prevStep + 1))
  }

  const CurrentStepComponent = stepComponents[step - 1]

  return (
    <ModalWrapper>
      <RemindersWizardModal
        action={null}
        width="wide"
        background={`url(${
          imagesForSteps[step - 1]
        }) left top / cover no-repeat`}
        closeButton={{
          callback: onCloseButtonCallback,
        }}
      >
        <Body>
          <GlobalStyles />
          <StepContainer>
            <CurrentStepComponent handleNextStep={handleNextStep} />
          </StepContainer>
        </Body>
      </RemindersWizardModal>
    </ModalWrapper>
  )
}

export default RemindersWizard
