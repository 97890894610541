import { createAsyncThunk } from '@reduxjs/toolkit'
import callRpc from '~publish/legacy/utils/call-rpc'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actions as notificationActions } from '@bufferapp/notifications/lib/reducer'
import type { AppDispatch, RootState } from '~publish/legacy/store'
import {
  PostActionNotificationSuccess,
  PostActionNotificationError,
} from '../types'

export const sharePostNow = createAsyncThunk<
  boolean,
  { postId: string },
  { state: RootState; dispatch: AppDispatch }
>('posts/sharePostNow', async ({ postId }, { dispatch, rejectWithValue }) => {
  try {
    const response = await callRpc('sharePostNow', {
      updateId: postId,
    })

    if (!response.success) {
      throw new Error(response.message)
    }

    dispatch(
      notificationActions.createNotification({
        notificationType: 'success',
        message: PostActionNotificationSuccess.SHARE_NOW,
      }),
    )

    return true
  } catch (error) {
    dispatch(
      notificationActions.createNotification({
        notificationType: 'error',
        message: PostActionNotificationError.SHARE_NOW,
      }),
    )

    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    return rejectWithValue(error.message)
  }
})
