import notificationMessages from './notifications.json'

// @ts-expect-error TS(7006) FIXME: Parameter 'message' implicitly has an 'any' type.
function replaceVariable(message, variable) {
  return message.replace(/{{__variable__}}/, variable)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'type' implicitly has an 'any' type.
function getNotificationMessage(type, key, variable = null) {
  const typePresent = Object.prototype.hasOwnProperty.call(
    notificationMessages,
    type,
  )
  if (!typePresent) {
    return null
  }

  const messagePresent = Object.prototype.hasOwnProperty.call(
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    notificationMessages[type],
    key,
  )
  if (!messagePresent) {
    return null
  }

  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const message = notificationMessages[type][key]

  if (variable) {
    return replaceVariable(message, variable)
  }

  return message
}

export default getNotificationMessage
