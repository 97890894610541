/**
 * Component that handles the multiple Save buttons in the composer.
 */
import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'
import { useSplitEnabled } from '@buffer-mono/features'
import Button from '@bufferapp/ui/Button'
import FlashIcon from '@bufferapp/ui/Icon/Icons/Flash'
import dayjs from 'dayjs'
import dayjsTimezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'
import { selectContentGenerationStatus } from '~publish/legacy/ai/state/slice'
import { AppEnvironments } from '~publish/legacy/constants'
import { useAppSelector } from '~publish/legacy/store'
import ComposerActionCreators from '../../action-creators/ComposerActionCreators'

import { useAccount } from '../../../../accountContext'
import { NotificationScopes, SaveButtonTypes } from '../../AppConstants'
import {
  appStatePropType,
  metaDataPropType,
  profilesPropType,
  userDataPropType,
  visibleNotificationsPropType,
} from '../ComposerPropTypes'
import DateTimeSlotPicker from '../DateTimeSlotPicker'
import Dropdown, { DropdownContent, DropdownTrigger } from '../Dropdown'
import NotificationContainer from '../NotificationContainer'
import {
  addedToQueueCopyMap,
  addingToQueueCopyMap,
  getDraftButtons,
  getSaveButton,
  getSaveButtonsCopy,
} from './action-buttons'
import { DraftSaveButtons } from './DraftSaveButtons'
import OmniboxButtons from './OmniboxButtons'
import { StackedSaveButtons } from './StackedSaveButtons'
import UpdateSaverItem from './UpdateSaverItem'

import { TempTooltip } from '~publish/legacy/shared-components/TempTooltip/TempTooltip'
import ModalActionCreators from '../../shared-components/modal/actionCreators'
import styles from '../css/UpdateSaver.module.css'
import { RemoveScheduleButton } from './style'
import { getFormattedWhatPreventsSavingMessages } from './utils'
import { useBeforeFirstAction } from './hooks/useBeforeFirstAction'
import { ConfirmationCoachMark } from '~publish/legacy/reminders/components/new-reminders/components/confirmation-coachmark/ConfirmationCoachmark'
import { getSignUpDateInEpochsMs } from '~publish/helpers/user'
import { getThreadedUpdatesUpgradePath } from '~publish/legacy/composer/composer/utils/upgradePaths'

dayjs.extend(utc)
dayjs.extend(dayjsTimezone)

const ActionButtons = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const UpdateSaver = (props) => {
  const [
    isInlineSchedulerDropdownExpanded,
    setIsInlineSchedulerDropdownExpanded,
  ] = useState(false)
  const user = useAccount()

  const commonTrackingProps =
    user?.account?.currentOrganization?.commonTrackingProperties || null

  const { contentGenerationInProgress } = useAppSelector((state) =>
    selectContentGenerationStatus(state),
  )

  /* We get the copies for the save buttons from the configurarion in
   the feature flip geid-menu-options-in-composer */

  type SplitConfig = {
    ADD_TO_QUEUE?: string
    SHARE_NEXT?: string
    SHARE_NOW?: string
    SCHEDULE_POST?: string
  }

  const {
    isEnabled: newSaveButtonsCopiesEnabled,
    config: newSaveButtonsCopies,
  } = useSplitEnabled<SplitConfig>('geid-copies-save-button-in-composer', {
    signUpDate: getSignUpDateInEpochsMs(user.account),
  })

  /**
   * DateTimeSlotPicker is displayed within a DropDown component, which takes care
   * of showing/hiding it when necessary. To do that, the DropDown component
   * listens to clicks in the whole document, and when a click happens on a
   * DOM element outside of DropdownContent, it is hidden. That's awesome, but
   * in some situations with DateTimeSlotPicker, users will click on buttons, and
   * the UI will change in such a way that the buttons that were clicked will
   * disappear: when that's the case, the DropDown component will wrongly deduct
   * that the click happened outside of the DropDown, and will proceed to hiding
   * the DateTimeSlotPicker.
   * To prevent this, we stop click event bubbling at the DateTimeSlotPicker level :)
   */
  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  const onDateTimeSlotPickerClick = (e) => e.stopPropagation()

  const onInlineSchedulerDateTimeSlotPickerChange = (
    // @ts-expect-error TS(7006) FIXME: Parameter 'selectedDateTime' implicitly has an 'an... Remove this comment to see the full error message
    selectedDateTime,
    // @ts-expect-error TS(7006) FIXME: Parameter 'isPinnedToSlot' implicitly has an 'any'... Remove this comment to see the full error message
    isPinnedToSlot,
  ): void => {
    const scheduledAt = selectedDateTime.unix()
    ComposerActionCreators.updateDraftsScheduledAt(scheduledAt, isPinnedToSlot)
  }

  const collapseInlineSchedulerDropdown = () => {
    setIsInlineSchedulerDropdownExpanded(false)
  }

  const expandInlineSchedulerDropdown = () => {
    setIsInlineSchedulerDropdownExpanded(true)
  }

  const onRemoveSchedule = () => {
    ComposerActionCreators.updateDraftsScheduledAt(null, false)
    collapseInlineSchedulerDropdown()
  }

  const {
    appState,
    userData,
    metaData,
    visibleNotifications,
    timezone,
    moreThanOneProfileSelected,
    areAllDraftsSaved,
    saveButtons,
    isSlotPickingAvailable,
    availableSchedulesSlotsForDay,
    isPinnedToSlot,
    selectedProfiles,
    sentPost,
    previewMode,
    draftMode,
    whatPreventsSavingMessages: preventsSavingMessages,
    whatPreventsSavingDraftMessages,
    shouldShowThreadsMigrationModal,
    threadsMigrationModalService,
    draftPreview,
  } = props
  let { scheduledAt } = props

  const {
    isSavingPossible,
    isSavingDraftPossible,
    isDraftsSavePending,
    draftSaveQueueingType,
    isOmniboxEnabled,
  } = appState

  const doSelectedProfilesHaveSlots = selectedProfiles.some(
    // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
    (profile) => profile.profileHasPostingSchedule,
  )
  const beforeFirstAction = useBeforeFirstAction({ draftPreview })

  if (saveButtons.length === 0) return null

  const { weekStartsMonday } = userData

  const inlineButtonsWrapperClassName = [
    isDraftsSavePending
      ? styles.savePendingInlineButtonsWrapper
      : styles.inlineButtonsWrapper,
    'schedule-post-button',
  ].join(' ')

  const stackedButtonsWrapperClassName = [
    isDraftsSavePending
      ? styles.savePendingStackedButtonsWrapper
      : styles.stackedButtonsWrapper,
    'schedule-post-button',
  ].join(' ')

  const notificationContainerClassNames = {
    container: styles.notificationContainer,
    notification: styles.notification,
  }

  const saveButtonsCopy = getSaveButtonsCopy({
    moreThanOneProfileSelected,
    draftMode,
    isSplitEnabled: newSaveButtonsCopiesEnabled,
    configFromSplit: newSaveButtonsCopies,
  })

  // @ts-expect-error TS(7006) FIXME: Parameter 'buttonType' implicitly has an 'any' typ... Remove this comment to see the full error message
  const getActiveSaveButtonCopy = (buttonType, queueingType) => {
    if (isDraftsSavePending) return addingToQueueCopyMap.get(queueingType)
    if (areAllDraftsSaved) return addedToQueueCopyMap.get(queueingType)

    return saveButtonsCopy.get(buttonType)
  }

  // if the user doesn't have any slots available, then they will
  // only see these two buttons in the composer.
  const shouldUpdateButtons =
    saveButtons &&
    saveButtons[0] !== SaveButtonTypes.ADD_TO_DRAFTS &&
    saveButtons[0] !== SaveButtonTypes.SAVE &&
    !doSelectedProfilesHaveSlots

  const buttons = shouldUpdateButtons
    ? ['SHARE_NOW', 'SCHEDULE_POST']
    : saveButtons

  const [inlineSaveButtonTypes, stackedSaveButtonTypes] = getSaveButton(buttons)

  const isEditPost = buttons && buttons[0] === SaveButtonTypes.SAVE
  const displayThreadsPaywallButtons =
    shouldShowThreadsMigrationModal && !isEditPost && !draftMode
  const displayInlineSaveButtons =
    !isOmniboxEnabled &&
    inlineSaveButtonTypes.length > 0 &&
    !displayThreadsPaywallButtons
  const displayStackedSaveButtons =
    (!isOmniboxEnabled || previewMode) &&
    stackedSaveButtonTypes.length > 0 &&
    !displayThreadsPaywallButtons
  let firstStackedButtonType
  let otherStackedButtonsTypes
  let firstStackedButtonCopy

  if (displayStackedSaveButtons) {
    ;[firstStackedButtonType, ...otherStackedButtonsTypes] =
      stackedSaveButtonTypes
    firstStackedButtonCopy = getActiveSaveButtonCopy(
      firstStackedButtonType,
      draftSaveQueueingType,
    )
  }

  if (sentPost) scheduledAt = null
  const shouldDisplayInlineScheduler = scheduledAt !== null
  let scheduledAtMoment
  let humanReadableScheduledAt

  if (shouldDisplayInlineScheduler) {
    scheduledAtMoment = dayjs.unix(scheduledAt)
    if (timezone) scheduledAtMoment = scheduledAtMoment.tz(timezone)

    const humanReadableFormat = userData.uses24hTime
      ? 'MMM D, H:mm'
      : 'MMM D, h:mm A'
    humanReadableScheduledAt = scheduledAtMoment.format(humanReadableFormat)
  }
  const showRemoveSchedule = draftMode && scheduledAtMoment
  const shouldDisplayTime =
    (isEditPost && !doSelectedProfilesHaveSlots) || draftMode

  const showOmniboxButtons = isOmniboxEnabled && !previewMode
  const showAddToDrafts = !showOmniboxButtons && !draftMode && !isEditPost

  const isDraftSaveDisabled = !isSavingDraftPossible
  const isPostSaveDisabled = !isSavingPossible
  const isSaveDisabled =
    (draftMode ? isDraftSaveDisabled : isPostSaveDisabled) ||
    contentGenerationInProgress

  const hasInitialDate = metaData.emptySlotMode || shouldDisplayInlineScheduler
  const warningMessages = draftMode
    ? whatPreventsSavingDraftMessages
    : preventsSavingMessages
  const whatPreventsSavingMessages = getFormattedWhatPreventsSavingMessages({
    warningMessages,
  })

  const { isOneBufferOrganization } = user?.account?.currentOrganization || {}
  const planId =
    user?.account?.currentOrganization?.billing?.subscription?.plan?.id
  const isDraftPaywallButtonEnabled =
    planId === 'essentials' || planId === 'free'
  const showApprovalPaywall =
    isOneBufferOrganization && isDraftPaywallButtonEnabled
  const threadedUpdatesUpgradePath = getThreadedUpdatesUpgradePath(
    threadsMigrationModalService,
  )

  if (displayThreadsPaywallButtons) {
    BufferTracker.ctaViewed({
      organizationId: user?.account?.currentOrganization?.id || null,
      cta: `publish-composer-createPost-upgradeToScheduleThread-1`,
      upgradePathName: threadedUpdatesUpgradePath,
      product: 'publish',
      ...commonTrackingProps,
    })
  }

  return (
    <section className={styles.section} data-testid="update-saver-buttons">
      {(shouldDisplayInlineScheduler || shouldDisplayTime) && (
        <div className={styles.postSchedule} data-testid="inline-scheduler">
          <span className={styles.postScheduleText}>
            Schedule date:
            <span className={styles.humanReadableScheduledAt}>
              {' '}
              {shouldDisplayInlineScheduler
                ? humanReadableScheduledAt
                : 'No Time Set'}
            </span>
          </span>
          <div>
            <Dropdown
              isDropdownExpanded={isInlineSchedulerDropdownExpanded}
              onHide={collapseInlineSchedulerDropdown}
              onShow={expandInlineSchedulerDropdown}
              className={styles.inlineDropdownContainer}
            >
              <DropdownTrigger className={styles.tertiaryButton}>
                {shouldDisplayInlineScheduler ? 'Edit' : 'Set Time'}
              </DropdownTrigger>
              <DropdownContent className={styles.rightAlignedDropdownContent}>
                <DateTimeSlotPicker
                  key={scheduledAtMoment?.toISOString()}
                  onClick={onDateTimeSlotPickerClick}
                  onChange={onInlineSchedulerDateTimeSlotPickerChange}
                  onSubmit={collapseInlineSchedulerDropdown}
                  shouldUse24hTime={userData.uses24hTime}
                  timezone={timezone}
                  weekStartsMonday={weekStartsMonday}
                  initialDateTime={scheduledAtMoment}
                  isSlotPickingAvailable={isSlotPickingAvailable}
                  availableSchedulesSlotsForDay={availableSchedulesSlotsForDay}
                  isPinnedToSlot={isPinnedToSlot}
                  metaData={metaData}
                  submitButtonCopy="Done"
                  doSelectedProfilesHaveSlots={doSelectedProfilesHaveSlots}
                  showDraftScheduleNotice={draftMode}
                />
              </DropdownContent>
            </Dropdown>
            {showRemoveSchedule && (
              <RemoveScheduleButton
                type="button"
                data-testid="remove-schedule-time"
                className={styles.tertiaryButton}
                onClick={onRemoveSchedule}
              >
                Remove
              </RemoveScheduleButton>
            )}
          </div>
        </div>
      )}

      <ActionButtons>
        {showOmniboxButtons && (
          <OmniboxButtons
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            previewMode={previewMode}
            data-testid="omnibox-buttons"
          />
        )}
        {showAddToDrafts && (
          // TODO: Replace TempTooltip with Popcorn Tooltip once Popcorn is ready to be used
          <TempTooltip
            content={isDraftSaveDisabled ? whatPreventsSavingMessages : null}
            side="left"
            arrow
          >
            <div
              data-testid="draft-save-buttons-section"
              className={inlineButtonsWrapperClassName}
              data-disabled={isDraftSaveDisabled}
            >
              <DraftSaveButtons
                isDisabled={isDraftSaveDisabled}
                timezone={timezone}
                selectedProfiles={selectedProfiles}
                draftSaveItems={getDraftButtons({
                  hasInitialDate,
                  showApprovalPaywall,
                })}
                showApprovalPaywall={showApprovalPaywall}
              />
            </div>
          </TempTooltip>
        )}

        {displayInlineSaveButtons && (
          // TODO: Replace TempTooltip with Popcorn Tooltip once Popcorn is ready to be used
          <TempTooltip
            content={isSaveDisabled ? whatPreventsSavingMessages : null}
            side="left"
            arrow
          >
            <div
              data-testid="inline-save-buttons"
              className={inlineButtonsWrapperClassName}
              data-disabled={isSaveDisabled}
            >
              {inlineSaveButtonTypes.map((saveButtonType, i) => (
                <UpdateSaverItem
                  key={saveButtonType}
                  type={saveButtonType}
                  draftMode={draftMode}
                  appState={appState}
                  userData={userData}
                  timezone={timezone}
                  weekStartsMonday={weekStartsMonday}
                  isInlineSchedulerDisplayed={shouldDisplayInlineScheduler}
                  isSecondaryItem={i < inlineSaveButtonTypes.length - 1}
                  selectedProfiles={selectedProfiles}
                >
                  {/* @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message */}
                  {draftSaveQueueingType === saveButtonType
                    ? getActiveSaveButtonCopy(
                        saveButtonType,
                        draftSaveQueueingType,
                      )
                    : saveButtonsCopy.get(saveButtonType)}
                </UpdateSaverItem>
              ))}
            </div>
          </TempTooltip>
        )}

        {displayStackedSaveButtons && (
          // TODO: Replace TempTooltip with Popcorn Tooltip once Popcorn is ready to be used
          <TempTooltip
            content={!previewMode ? whatPreventsSavingMessages : undefined}
            side="left"
            arrow
          >
            <div
              data-testid="stacked-save-buttons-section"
              className={stackedButtonsWrapperClassName}
              data-disabled={isSaveDisabled || previewMode}
            >
              <ConfirmationCoachMark selectedProfiles={selectedProfiles}>
                <div>
                  <StackedSaveButtons
                    isSavingPossible={isSavingPossible}
                    isSavingDraftPossible={isSavingDraftPossible}
                    draftMode={draftMode}
                    timezone={timezone}
                    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    firstStackedButtonLabel={firstStackedButtonCopy}
                    firstStackedButtonType={firstStackedButtonType}
                    otherStackedButtonsTypes={otherStackedButtonsTypes}
                    selectedProfiles={selectedProfiles}
                    beforeFirstAction={beforeFirstAction}
                    saveButtonsCopy={saveButtonsCopy}
                  />
                </div>
              </ConfirmationCoachMark>
            </div>
          </TempTooltip>
        )}

        {displayThreadsPaywallButtons && (
          // @ts-expect-error TS(2740) FIXME: Type '{ "data-testid": string; icon: Element; type... Remove this comment to see the full error message
          <Button
            data-testid="threads-free-paywall-button"
            icon={<FlashIcon color="white" />}
            type="primary"
            label="Upgrade to Schedule Thread"
            onClick={(): void => {
              BufferTracker.ctaClicked({
                organizationId: user?.account?.currentOrganization?.id || '',
                cta: `publish-composer-createPost-upgradeToScheduleThread-1`,
                upgradePathName: threadedUpdatesUpgradePath,
                product: 'publish',
                clientName: 'publishWeb',
                ...commonTrackingProps,
              })
              ModalActionCreators.openModal('ThreadsFreeOBPaywall', {
                ctaString: 'composer-upgradeButton-upgradeToScheduleThread-1',
                service: threadsMigrationModalService,
              })
            }}
          />
        )}
      </ActionButtons>

      {metaData.appEnvironment === AppEnvironments.EXTENSION && (
        <NotificationContainer
          visibleNotifications={visibleNotifications}
          scope={NotificationScopes.UPDATE_SAVING_AGGREGATE}
          classNames={notificationContainerClassNames}
        />
      )}
    </section>
  )
}

const savingMessagePropType = PropTypes.shape({
  composerId: PropTypes.string,
  message: PropTypes.string,
})

UpdateSaver.propTypes = {
  appState: appStatePropType.isRequired,
  metaData: metaDataPropType.isRequired,
  userData: userDataPropType.isRequired,
  visibleNotifications: visibleNotificationsPropType.isRequired,
  isSlotPickingAvailable: PropTypes.bool.isRequired,
  saveButtons: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(SaveButtonTypes)))
    .isRequired,
  selectedProfiles: profilesPropType.isRequired,
  timezone: PropTypes.string,
  moreThanOneProfileSelected: PropTypes.bool,
  areAllDraftsSaved: PropTypes.bool,
  whatPreventsSavingMessages: PropTypes.arrayOf(savingMessagePropType),
  whatPreventsSavingDraftMessages: PropTypes.arrayOf(savingMessagePropType),
  scheduledAt: PropTypes.number,
  availableSchedulesSlotsForDay: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.shape({
        isSlotFree: PropTypes.bool.isRequired,
        timestamp: PropTypes.number.isRequired,
      }),
    ),
  ]),
  isPinnedToSlot: PropTypes.bool,
  sentPost: PropTypes.bool,
  previewMode: PropTypes.bool,
  draftMode: PropTypes.bool,
  shouldShowThreadsMigrationModal: PropTypes.bool,
  threadsMigrationModalService: PropTypes.string,
}

UpdateSaver.defaultProps = {
  isPinnedToSlot: null,
  timezone: null,
  sentPost: false,
  previewMode: false,
  draftMode: false,
  areAllDraftsSaved: false,
  moreThanOneProfileSelected: false,
  scheduledAt: null,
  availableSchedulesSlotsForDay: [],
  whatPreventsSavingMessages: [],
  whatPreventsSavingDraftMessages: [],
  shouldShowThreadsMigrationModal: false,
  threadsMigrationModalService: 'omni',
}

export default UpdateSaver
