import { useCallback } from 'react'
import {
  type Draft,
  DraftMethods,
} from '~publish/legacy/composer/composer/entities/Draft'
import { handleUploadFinished } from '~publish/legacy/composer/composer/action-creators/handleUploadFinished'
import { handleUploadStarted } from '~publish/legacy/composer/composer/action-creators/handleUploadStarted'
import { ComposerNotifiers } from '~publish/legacy/composer/composer/action-creators/ComposerNotifiers'
import { useSplitEnabled } from '@buffer-mono/features'
import { useUploader } from './useUploader'

export const useComposerUploader = ({
  draft,
}: {
  draft: Draft
}): ReturnType<typeof useUploader> => {
  const showError = useCallback((message: string) => {
    ComposerNotifiers.queueError({ message: `⚠️ ${message}` })
  }, [])

  const { isEnabled: isLinkedInCarouselEnabled } = useSplitEnabled(
    'CORE-linkedin-carousels',
  )

  return useUploader({
    id: DraftMethods.getUploaderId(draft),
    onUnmount: 'keep',
    fileRestrictions: () =>
      DraftMethods.getUploaderRestrictions(draft, {
        isLinkedInCarouselEnabled,
      }),
    eventHandlers: {
      'upload-started': (upload) =>
        handleUploadStarted({ upload, draftId: draft.id }),
      'upload-finished': (upload) =>
        handleUploadFinished({ upload, draftId: draft.id }),
      'file-validation-failed': (_file, error) => showError(error.message),
      'upload-failed': (_, error) => showError(error.message),
      'upload-prevented': (error) => showError(error.message),
      'file-fetch-failed': () =>
        showError(
          `We are really sorry but we cannot get the file at this moment. Please, try it later.`,
        ),
      'file-upload-attempted': () => ComposerNotifiers.removeNotifications(),
    },
    getCustomCount: (): number => DraftMethods.getFileAttachmentCount(draft),
  })
}
