/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import styled from 'styled-components'
import { Calendar, Coupon, Link, List, Location, Pin } from '@bufferapp/ui/Icon'
import InstagramCommentIcon from '@bufferapp/ui/Icon/Icons/InstagramComment'

import { grayDarker, grayLighter } from '@bufferapp/ui/style/colors'
import MetadataItem from '../MetadataItem'

const Metadata = styled.div`
  padding: 0 16px 16px;
  flex-direction: column;
  display: flex;
  background-color: ${grayLighter};
  > :not(:last-child) {
    margin-bottom: 8px;
  }
`

// Fix for the bug that hides the link icon randomly for GBP links
const LinkIconWrapper = styled.div`
  visibility: visible;
`

const MetadataList = ({
  commentText,
  geoLocationText,
  destinationUrl,
  boardName,
  startEndDate,
  code,
  link,
  terms,
  spLink,
  isReminder,
}: {
  destinationUrl?: string
  commentText?: string
  geoLocationText?: string
  startEndDate?: string | null
  boardName?: string
  terms?: string
  link: string
  spLink: string
  isReminder?: boolean
  code: string
}): JSX.Element => {
  return (
    <Metadata>
      {/* First Comment */}
      {commentText && (
        <MetadataItem
          icon={
            <InstagramCommentIcon color={grayDarker} title="IG first comment" />
          }
          text={commentText}
        />
      )}
      {/* Location */}
      {geoLocationText && (
        <MetadataItem
          icon={<Location color={grayDarker} title="location" />}
          text={geoLocationText}
        />
      )}
      {/* Pinterest Source Url */}
      {destinationUrl && (
        <MetadataItem
          icon={<Link color={grayDarker} title="source url" />}
          text={destinationUrl}
        />
      )}
      {/* Pinterest board */}
      {boardName && (
        <MetadataItem
          icon={<Pin color={grayDarker} title="Pinterest board" />}
          text={boardName}
        />
      )}
      {/* Google Business Profile offer/event start and end date */}
      {startEndDate && (
        <MetadataItem
          icon={<Calendar color={grayDarker} title="start date and end date" />}
          text={startEndDate}
        />
      )}
      {/* Google Business Profile coupon code */}
      {code && (
        <MetadataItem
          icon={<Coupon color={grayDarker} title="coupon code" />}
          text={code}
        />
      )}
      {/* Google Business Profile link  or Start Page link */}
      {(link || spLink) && !isReminder && (
        <MetadataItem
          icon={
            <LinkIconWrapper>
              <Link color={grayDarker} title="link" />
            </LinkIconWrapper>
          }
          text={link || spLink}
        />
      )}
      {/* Google Business Profile terms & conditions */}
      {terms && (
        <MetadataItem
          icon={<List color={grayDarker} title="terms and conditions" />}
          text={terms}
        />
      )}
    </Metadata>
  )
}

export default MetadataList
