import type { OldUploadNotifiers } from '~publish/legacy/uploads/types'
import { NotificationScopes } from '../AppConstants'
import NotificationActionCreators from './NotificationActionCreators'
import { ActionTypes } from '../state/ActionTypes'
import AppDispatcher from '../dispatcher'

export const ComposerNotifiers: OldUploadNotifiers = {
  uploadStarted: ({ id, uploaderInstance, source }) => {
    return AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_DRAFT_FILE_UPLOAD_STARTED,
      id,
      uploaderInstance,
      source,
    })
  },
  uploadedDraftImage: ({
    id,
    uploaderInstance,
    url,
    location,
    width,
    height,
    source,
  }) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_ADD_DRAFT_UPLOADED_IMAGE,
      id,
      uploaderInstance,
      url,
      location,
      width,
      height,
      source,
    })
  },
  uploadedDraftVideo: ({
    id,
    uploaderInstance,
    uploadId,
    file,
    transcodeVideo,
    videoMetadata,
    name,
  }) => {
    if (transcodeVideo === true) {
      AppDispatcher.handleViewAction({
        actionType: ActionTypes.COMPOSER_ADD_DRAFT_UPLOADED_VIDEO,
        id,
        uploaderInstance,
        uploadId,
      })
    } else {
      AppDispatcher.handleViewAction({
        actionType:
          ActionTypes.COMPOSER_ADD_DRAFT_UPLOADED_VIDEO_WITHOUT_TRANSCODING,
        draftId: id,
        uploaderInstance,
        uploadId,
        file,
        videoMetadata,
        name,
      })
    }
  },
  draftGifUploaded: ({
    id,
    uploaderInstance,
    url,
    stillGifUrl,
    width,
    height,
  }) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_ADD_DRAFT_UPLOADED_GIF,
      id,
      uploaderInstance,
      url,
      stillGifUrl,
      width,
      height,
    })
  },
  queueError: ({ message }) => {
    NotificationActionCreators.queueError({
      scope: NotificationScopes.FILE_UPLOAD,
      message,
    })
  },
  updateFileUploadProgress: ({ id, uploaderInstance, progress }) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_DRAFT_FILE_UPLOAD_PROGRESS,
      id,
      uploaderInstance,
      progress,
    })
  },
  removeNotifications: () =>
    NotificationActionCreators.removeAllNotificationsByScope(
      NotificationScopes.FILE_UPLOAD,
    ),
}
