import styled from 'styled-components'
import CharacterCount from '../CharacterCount'
import { blue, grayDarker } from '@bufferapp/ui/style/colors'

export const CheckboxGroupContainer = styled.div`
  display: flex;
  align-items: space-between;
  margin-left: 125px;
  padding: 16px 0;
  accent-color: ${blue};

  label {
    flex: 1;
    padding: 0;
  }

  input {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    margin-left: 0 !important;
  }

  input,
  label {
    cursor: pointer;
  }
`

interface FormColumnContainerProps {
  width?: string
}

export const FormColumnContainer = styled.div<FormColumnContainerProps>`
  display: flex;
  align-items: center;
  width: ${({ width }) => width || '50%'};
`

interface LabelProps {
  width?: string
}

export const Label = styled.label<LabelProps>`
  position: relative;
  font-weight: 600;
  font-size: 12px;
  color: ${grayDarker};
  width: ${({ width }) => width || '125px'};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  /* Labels NOT on the outer left in the Composer */
  padding: 0 30px;

  /* Labels on the outer left in the Composer */
  &.outer-label {
    flex: 0 0 125px;
    padding: 0 28px 0 10px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  margin: 12px 6px 0 0;
  position: relative;

  .input {
    width: 100%;
  }
`

export const YoutubeComposerBarWrapper = styled.div`
  padding: 0 0 10px;
`

export const InputCharacterCount = styled(CharacterCount)`
  display: inline-block;
  border-radius: 4px;
  padding: 3px 3px;
  line-height: 12px;
  position: absolute;
  right: 6px;
  top: 6px;
`
