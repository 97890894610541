import { Flex, Separator } from '@buffer-mono/popcorn'
import React from 'react'
import ProfilesDisconnectedBanner from '~publish/legacy/profiles-disconnected-banner'
import { useAppSelector } from '~publish/legacy/store'
import getErrorBoundary from '~publish/legacy/web/components/ErrorBoundary'
import { selectGeneralSettings } from './selectors'
import { GoogleAnalytics } from './components/GoogleAnalytics'
import {
  LinkShortening,
  SHORTENER_DISABLED_SERVICES,
} from './components/LinkShortening'
import { QueueActions } from './components/QueueActions'
import { RemindersSettings } from './components/RemindersSettings'
import { selectCurrentProfile } from '~publish/legacy/profile-sidebar/selectors'

const ErrorBoundary = getErrorBoundary(true)

const GeneralSettings = (): React.JSX.Element => {
  const profile = useAppSelector(selectCurrentProfile)
  const generalSettings = useAppSelector(selectGeneralSettings)

  if (!profile) return <></>

  const isManager = profile.isManager
  const isRemindersOnly = generalSettings.isRemindersOnly
  const isDisconnectedProfile = profile.isDisconnected
  const supportsMobileReminders =
    profile.service === 'instagram' ||
    (profile.service === 'facebook' && profile.service_type === 'group')

  const showGoogleAnalytics = !SHORTENER_DISABLED_SERVICES.includes(
    profile.service,
  )

  return (
    <ErrorBoundary>
      {isDisconnectedProfile && <ProfilesDisconnectedBanner />}
      <Flex direction="column" data-testid="general-settings-wrapper">
        {supportsMobileReminders && (
          <RemindersSettings hideToggle={isRemindersOnly} />
        )}
        <LinkShortening />
        {showGoogleAnalytics && (
          <>
            <Separator />
            <GoogleAnalytics />
          </>
        )}
        {isManager && (
          <>
            <Separator />
            <QueueActions />
          </>
        )}
      </Flex>
    </ErrorBoundary>
  )
}

export { GeneralSettings }
