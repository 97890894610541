import {
  type AnyAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { actionTypes as dataFetchActionTypes } from '@bufferapp/async-data-fetch'
import type { RootState } from '~publish/legacy/store'
import type { Tag } from './types'
import { actionTypes as campaignActionTypes } from '~publish/legacy/campaign/reducer'
import { campaignParser } from '~publish/legacy/duplicate-server/parsers'

const tagsAdapter = createEntityAdapter<Tag>()

export const tagsSlice = createSlice({
  name: 'tags',
  initialState: tagsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      `getCampaignsList_${dataFetchActionTypes.FETCH_SUCCESS}`,
      (state, action) => {
        const { result } = action as unknown as { result: Tag[] }
        tagsAdapter.setAll(state, result)
      },
    )

    builder.addCase(
      campaignActionTypes.PUSHER_CAMPAIGN_UPDATED,
      (state, action) => {
        const { campaign } = action as unknown as { campaign: Tag }
        const tag: Tag = campaignParser(campaign)
        tagsAdapter.setOne(state, tag)
      },
    )

    builder.addCase(
      campaignActionTypes.PUSHER_CAMPAIGN_CREATED,
      (state, action) => {
        const { campaign } = action as unknown as { campaign: Tag }
        const tag: Tag = campaignParser(campaign)
        tagsAdapter.addOne(state, tag)
        tagsAdapter.upsertMany(state, [tag])
      },
    )
    builder.addCase(
      `canViewReport_${dataFetchActionTypes.FETCH_SUCCESS}`,
      (state, action: AnyAction) => {
        const { tagId } = action.args
        const canViewReport = action.result
        tagsAdapter.updateOne(state, { id: tagId, changes: { canViewReport } })
      },
    )
  },
})

// globalized selectors
const { selectById: selectTagById, selectAll: selectAllTags } =
  tagsAdapter.getSelectors((state: RootState) => state[tagsSlice.name])

export { selectTagById, selectAllTags }
