import { actionTypes as dataFetchActionTypes } from '@bufferapp/async-data-fetch'

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = {}, action) => {
  switch (action.type) {
    case `user_${dataFetchActionTypes.FETCH_SUCCESS}`:
      return { ...action.result, loaded: true }
    default:
      return state
  }
}
