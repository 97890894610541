import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@bufferapp/components'
import MessageIcon from '@bufferapp/ui/Icon/Icons/Message'
import PersonIcon from '@bufferapp/ui/Icon/Icons/Person'
import { Tooltip } from '@bufferapp/ui'
import { grayDarker } from '@bufferapp/ui/style/colors'
import ConfirmDeleteButton from '../ConfirmDeleteButton'
import {
  ALLOWED_COLORS,
  ButtonStyled,
  ButtonWrapper,
  CardFooterContent,
  CardFooterWrapper,
  CardFooterWrapperWithBorder,
  CommentIconWrapper,
  DraftActionButton,
  EditButton,
  IconWrapper,
  Message,
  PreMessage,
  RequeueButton,
  UserTagsIconWrapper,
} from './styles'

const CardFooter = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'hideButtons' implicitly has an 'a... Remove this comment to see the full error message
  hideButtons,
  // @ts-expect-error TS(7031) FIXME: Binding element 'icon' implicitly has an 'any' typ... Remove this comment to see the full error message
  icon,
  // @ts-expect-error TS(7031) FIXME: Binding element 'preMessage' implicitly has an 'an... Remove this comment to see the full error message
  preMessage,
  // @ts-expect-error TS(7031) FIXME: Binding element 'message' implicitly has an 'any' ... Remove this comment to see the full error message
  message,
  // @ts-expect-error TS(7031) FIXME: Binding element 'messageColor' implicitly has an '... Remove this comment to see the full error message
  messageColor,
  // @ts-expect-error TS(7031) FIXME: Binding element 'messageLink' implicitly has an 'a... Remove this comment to see the full error message
  messageLink,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onDeleteClick' implicitly has an ... Remove this comment to see the full error message
  onDeleteClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onEditClick' implicitly has an 'a... Remove this comment to see the full error message
  onEditClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onSubmitClick' implicitly has an ... Remove this comment to see the full error message
  onSubmitClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'submitLabel' implicitly has an 'a... Remove this comment to see the full error message
  submitLabel,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onRequeueClick' implicitly has an... Remove this comment to see the full error message
  onRequeueClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'requeueLabel' implicitly has an '... Remove this comment to see the full error message
  requeueLabel,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onRevertApprovalClick' implicitly... Remove this comment to see the full error message
  onRevertApprovalClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onDraftActionButtonClick' implici... Remove this comment to see the full error message
  onDraftActionButtonClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'submitDraftsLabel' implicitly has... Remove this comment to see the full error message
  submitDraftsLabel,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hasFirstComment' implicitly has a... Remove this comment to see the full error message
  hasFirstComment,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isPerformingAction' implicitly ha... Remove this comment to see the full error message
  isPerformingAction,
  // @ts-expect-error TS(7031) FIXME: Binding element 'actionMessage' implicitly has an ... Remove this comment to see the full error message
  actionMessage,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isDragging' implicitly has an 'an... Remove this comment to see the full error message
  isDragging,
  // @ts-expect-error TS(7031) FIXME: Binding element 'disableBorder' implicitly has an ... Remove this comment to see the full error message
  disableBorder,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hasUserTags' implicitly has an 'a... Remove this comment to see the full error message
  hasUserTags,
  // @ts-expect-error TS(7031) FIXME: Binding element 'shouldShowEditButton' implicitly ... Remove this comment to see the full error message
  shouldShowEditButton,
  // @ts-expect-error TS(7031) FIXME: Binding element 'draftActionButtonTooltipMessage' ... Remove this comment to see the full error message
  draftActionButtonTooltipMessage,
  // @ts-expect-error TS(7031) FIXME: Binding element 'draftActionButtonDisabled' implic... Remove this comment to see the full error message
  draftActionButtonDisabled,
  // @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
  children,
}) => {
  const hideAllButtons = hideButtons || isPerformingAction || messageLink
  const textColor = ALLOWED_COLORS.includes(messageColor)
    ? messageColor
    : grayDarker
  const WrapperComponent = disableBorder
    ? CardFooterWrapper
    : CardFooterWrapperWithBorder

  if (children) {
    return (
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      <WrapperComponent isDragging={isDragging} disableBorder={disableBorder}>
        {children}
      </WrapperComponent>
    )
  }

  return (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <WrapperComponent isDragging={isDragging} disableBorder={disableBorder}>
      {/* FOOTER MESSAGE */}
      <CardFooterContent>
        {icon && <IconWrapper>{icon}</IconWrapper>}
        {messageLink ? (
          <Link href={messageLink} unstyled newTab>
            <Message type="p" textColor={textColor}>
              {message}
            </Message>
          </Link>
        ) : (
          <>
            {preMessage && (
              <PreMessage type="p">
                {preMessage}
                &nbsp;
              </PreMessage>
            )}
            <Message type="p" textColor={textColor}>
              {message}
            </Message>
          </>
        )}
        {hasFirstComment && (
          <CommentIconWrapper title="Post includes a comment">
            <MessageIcon
              data-tip="Includes first comment"
              data-padding="8px 8px 10px 8px"
            />
          </CommentIconWrapper>
        )}
        {hasUserTags && (
          <UserTagsIconWrapper
            title="Post includes tagged users"
            hasFirstComment={hasFirstComment}
          >
            <PersonIcon />
          </UserTagsIconWrapper>
        )}
      </CardFooterContent>

      {/* FOOTER BUTTONS */}
      {!hideAllButtons && (
        <ButtonWrapper>
          {onDeleteClick && (
            <>
              <ConfirmDeleteButton onDeleteClick={onDeleteClick} />
            </>
          )}
          {shouldShowEditButton && onEditClick && (
            <>
              <EditButton
                type="text"
                label="Edit"
                size="small"
                onClick={onEditClick}
                data-testid="shared-components-edit-btn"
              />
            </>
          )}

          {/* POSTS AND STORIES ACTIONS ONLY */}
          {onSubmitClick && (
            <ButtonStyled
              type="secondary"
              label={submitLabel}
              size="small"
              onClick={onSubmitClick}
            />
          )}
          {onRequeueClick && (
            <RequeueButton
              type="primary"
              label={requeueLabel}
              size="small"
              onClick={onRequeueClick}
            />
          )}

          {/* DRAFTS ACTIONS ONLY */}
          {onRevertApprovalClick && (
            <ButtonStyled
              type="secondary"
              label="Move to Drafts"
              size="small"
              onClick={onRevertApprovalClick}
            />
          )}
          {onDraftActionButtonClick && (
            // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; label: any; position: s... Remove this comment to see the full error message
            <Tooltip label={draftActionButtonTooltipMessage} position="bottom">
              <DraftActionButton
                type="secondary"
                label={submitDraftsLabel}
                size="small"
                onClick={onDraftActionButtonClick}
                disabled={draftActionButtonDisabled}
              />
            </Tooltip>
          )}
        </ButtonWrapper>
      )}

      {/* FOOTER ACTONS */}
      {isPerformingAction && <Message type="p">{actionMessage}</Message>}
    </WrapperComponent>
  )
}

CardFooter.propTypes = {
  hideButtons: PropTypes.bool,
  icon: PropTypes.node,
  preMessage: PropTypes.string,
  message: PropTypes.string,
  messageColor: PropTypes.string,
  messageLink: PropTypes.string,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onSubmitClick: PropTypes.func,
  submitLabel: PropTypes.string,
  onRequeueClick: PropTypes.func,
  requeueLabel: PropTypes.string,
  onRevertApprovalClick: PropTypes.func,
  onDraftActionButtonClick: PropTypes.func,
  submitDraftsLabel: PropTypes.string,
  hasFirstComment: PropTypes.bool,
  isPerformingAction: PropTypes.bool,
  actionMessage: PropTypes.string,
  isDragging: PropTypes.bool,
  disableBorder: PropTypes.bool,
  hasUserTags: PropTypes.bool,
  shouldShowEditButton: PropTypes.bool,
  draftActionButtonTooltipMessage: PropTypes.string,
  draftActionButtonDisabled: PropTypes.bool,
  children: PropTypes.node,
}

CardFooter.defaultProps = {
  hideButtons: false,
  icon: null,
  preMessage: null,
  message: '',
  messageColor: grayDarker,
  messageLink: null,
  onDeleteClick: null,
  onEditClick: null,
  onSubmitClick: null,
  submitLabel: 'Share Now',
  onRequeueClick: null,
  requeueLabel: 'Reschedule',
  onRevertApprovalClick: null,
  onDraftActionButtonClick: null,
  submitDraftsLabel: 'Approve',
  hasFirstComment: false,
  isPerformingAction: false,
  actionMessage: 'Submitting...',
  isDragging: false,
  disableBorder: false,
  hasUserTags: false,
  shouldShowEditButton: true,
  draftActionButtonTooltipMessage: null,
  draftActionButtonDisabled: true,
  children: null,
}

export default CardFooter
