import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import Text from '@bufferapp/ui/Text'
import ChannelsIcon from '@bufferapp/ui/Icon/Icons/Channels'
import { useSplitEnabled } from '@buffer-mono/features'

import type {
  DropProfileProps,
  Profile,
} from '~publish/legacy/profile-sidebar/types'
import {
  allChannels,
  getMatch,
  getParams,
  channel,
} from '~publish/legacy/routes'

import LoadingProfileListItem from '../LoadingProfileListItem'
import ProfileList from '../ProfileList'
import ProfileSearch from '../ProfileSearch'
import ManageSocialAccounts from '../ManageSocialAccounts'
import SidebarButtons from '../SidebarButtons'
import {
  ProfileSidebarStyle,
  ProfileListStyle,
  ListItem,
  BadgesWrapper,
} from './styles'
import { ChannelSuggestions } from '../ChannelSuggestions/ChannelSuggestions'
import { BetaBadge } from '@buffer-mono/popcorn'
import { useLocation, useRouteMatch } from 'react-router-dom'
import {
  useAccount,
  useCurrentOrganization,
} from '~publish/legacy/accountContext'
import { AllChannelsCoachmark } from '~publish/pages/AllChannels/AllChannelsCoachmark'
import { usePublishRevamp } from '~publish/hooks/usePublishRevamp'

const Wrapper = styled.div``

const renderLoadingProfiles = (): JSX.Element => (
  <Wrapper>
    <LoadingProfileListItem />
    <LoadingProfileListItem offset="100ms" />
    <LoadingProfileListItem offset="200ms" />
    <LoadingProfileListItem offset="300ms" />
    <LoadingProfileListItem offset="400ms" />
  </Wrapper>
)

const EmptySearch = (): JSX.Element => (
  <Text color="gray" type="p">
    No matches found
  </Text>
)

type ProfileSidebarProps = {
  loading: boolean
  selectedProfileId: string
  profiles: Array<Profile>
  searchText: string
  onProfileClick: (
    profile: Profile,
    options?: { shouldRedirectToChannelPage?: boolean },
  ) => void
  onDropProfile: (args: DropProfileProps) => void
  profileLimit: number
  onSearchProfileChange: (searchText: string) => void
  canManageSocialAccounts: boolean
  reachedChannelLimit: boolean
  manageChannelsURL: string
  ownerEmail: string
  onBlueprintsButtonClick: () => void
  onCalendarButtonClick: () => void
  isBlueprintsSelected: boolean
  isCalendarSelected: boolean
}

type Split = {
  isEnabled: boolean
}

const AllPostsMenuItem = (): JSX.Element => {
  const dispatch = useDispatch()
  const { account } = useAccount()
  const { isBetaEnabled } = useCurrentOrganization()
  const isChannelPage = !!useRouteMatch(channel.route)
  const { isEnabled: isChannelsTourFeatureEnabled } =
    useSplitEnabled('channels-tour')

  const allPostsSplit: Split = useSplitEnabled('all-posts')
  const isEnabled = allPostsSplit.isEnabled && isBetaEnabled
  const canEnable =
    allPostsSplit.isEnabled && account.currentOrganization.role === 'admin'

  if (!isEnabled && !canEnable) {
    return <></>
  }

  const isAllPostsPageOpened = !!getMatch({
    pathname: location.pathname,
    route: allChannels.route,
  })

  return (
    <>
      {isChannelsTourFeatureEnabled && !isChannelPage && (
        <AllChannelsCoachmark spotlightTop="20px" />
      )}
      <ListItem
        id="all-posts"
        title="All Channels"
        onItemClick={(): void => {
          dispatch(allChannels.goTo())
        }}
        icon={<ChannelsIcon size="large" />}
        selected={isAllPostsPageOpened}
        badges={
          <BadgesWrapper>
            <BetaBadge />
          </BadgesWrapper>
        }
      />
    </>
  )
}

const ProfileSidebar = ({
  loading = false,
  selectedProfileId,
  profiles = [],
  searchText = '',
  onProfileClick,
  onDropProfile,
  profileLimit = 0,
  onSearchProfileChange,
  // Flags for showing connection shortcut buttons
  canManageSocialAccounts = true,
  reachedChannelLimit = false,
  manageChannelsURL,
  ownerEmail = 'the owner',
  // Page buttons
  onBlueprintsButtonClick,
  onCalendarButtonClick,
  isBlueprintsSelected = false,
  isCalendarSelected = false,
}: ProfileSidebarProps): JSX.Element => {
  const [queueExpanded, setQueueExpanded] = useState(true)
  const [isPublishRevampEnabled] = usePublishRevamp()

  const { pathname } = useLocation()
  const route = channel.route
  const params = getParams({ pathname, route })
  const channelId = params?.id

  const toggleQueueExpanded = (): void => {
    setQueueExpanded(!queueExpanded)
  }

  // Only show suggestions if there are less than 4 channels connected
  // AND the currentOrganization has admin access to manage channels
  const shouldShowChannelSuggestions = Boolean(
    profiles.length < 4 && canManageSocialAccounts,
  )

  const filteredProfiles = React.useMemo(
    () => profiles.filter((profile) => !profile.filtered),
    [profiles],
  )

  return (
    <ProfileSidebarStyle data-testid="channel-list-sidebar">
      {loading && renderLoadingProfiles()}
      <SidebarButtons
        onCalendarButtonClick={onCalendarButtonClick}
        isCalendarSelected={isCalendarSelected}
        onBlueprintsButtonClick={onBlueprintsButtonClick}
        isBlueprintsSelected={isBlueprintsSelected}
        toggleQueueExpanded={toggleQueueExpanded}
        queueExpanded={queueExpanded}
      />

      {queueExpanded && (
        <>
          {profiles.length > 9 && (
            <ProfileSearch
              onSearchProfileChange={onSearchProfileChange}
              searchText={searchText}
            />
          )}
          {/* Display all channels menu item only for accounts with more than 2 channels */}
          {profiles.length > 1 && isPublishRevampEnabled && (
            <AllPostsMenuItem />
          )}

          <ProfileListStyle data-hide-scrollbar>
            {filteredProfiles.length > 0 && (
              <ProfileList
                selectedProfileId={selectedProfileId || channelId}
                profiles={filteredProfiles}
                onProfileClick={onProfileClick}
                profileLimit={profileLimit}
                onDropProfile={onDropProfile}
              />
            )}
            {!filteredProfiles.length && profiles.length > 0 && EmptySearch()}
            <ChannelSuggestions
              profiles={profiles}
              filteredProfiles={filteredProfiles}
              shouldShowChannelSuggestions={shouldShowChannelSuggestions}
              reachedChannelLimit={reachedChannelLimit}
            />
          </ProfileListStyle>
        </>
      )}
      <ManageSocialAccounts
        canManageSocialAccounts={canManageSocialAccounts}
        reachedChannelLimit={reachedChannelLimit}
        manageChannelsURL={manageChannelsURL}
        ownerEmail={ownerEmail}
        hasProfiles={profiles && !!profiles.length}
        shouldShowAddChannels={!shouldShowChannelSuggestions}
      />
    </ProfileSidebarStyle>
  )
}

export default ProfileSidebar
