import { createAsyncThunk } from '@reduxjs/toolkit'
import callRpc from '~publish/legacy/utils/call-rpc'
import type { AppDispatch, RootState } from '~publish/legacy/store'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actions as notificationActions } from '@bufferapp/notifications/lib/reducer'
import { actions as modalActions } from '~publish/legacy/modals/reducer'
import { setLoader } from '../slice'

type UpdateTagResponse = {
  _id: string
  id: string
  globalOrganizationId: string
  name: string
  color: string
  locked: boolean
  lastUpdated: string
  updatedAt: number
  createdAt: number
  dateRange: null
  sent: number
  scheduled: number
  draftsCount: number
  approvalsCount: number
  isAggregation: boolean
  channels: any[]
  items: any[]
}

export const updateTag = createAsyncThunk<
  UpdateTagResponse,
  {
    id: string
    name: string
    color: string
    organizationId: string
  },
  { dispatch: AppDispatch; state: RootState }
>('tag/update', async ({ id, name, color }, { dispatch, rejectWithValue }) => {
  try {
    dispatch(setLoader({ isLoading: true }))
    const response = await callRpc('updateCampaign', {
      campaignId: id,
      name,
      color,
    })

    if (!response.id) {
      throw new Error(response.message)
    }

    dispatch(
      notificationActions.createNotification({
        notificationType: 'success',
        message: 'Great! Your tag was successfully updated!',
      }),
    )

    dispatch(setLoader({ isLoading: false }))
    dispatch(modalActions.hideTagFormModal())

    return response
  } catch (error) {
    dispatch(
      notificationActions.createNotification({
        notificationType: 'error',
        message: `Oops! There was an error updating the tag`,
      }),
    )
    dispatch(setLoader({ isLoading: false }))

    return rejectWithValue(error)
  }
})
