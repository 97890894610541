import { getUploader } from '~publish/legacy/uploads/lib/uploader/UploadersMap'
import type { Upload } from '../../entities/Upload'
import { selectUploadById } from '../selectors'
import { uploadRemoved } from '../slice'

export const removeUpload =
  ({ id }: Pick<Upload, 'id'>) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
  async (dispatch, getState): Promise<void> => {
    const upload = selectUploadById(getState(), id)

    if (!upload) return

    if (upload.uploaderId) {
      getUploader(upload).removeUpload(upload)
    }

    if (upload) {
      dispatch(uploadRemoved(upload))
    }
  }
