import React, { useEffect } from 'react'
import { useAppSelector } from '~publish/legacy/store'
import { useAccount } from '~publish/legacy/accountContext'
import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'
import SimpleModal from '@bufferapp/ui/SimpleModal'
import Text from '@bufferapp/ui/Text'
import Button from '@bufferapp/ui/Button'
import CheckmarkIcon from '@bufferapp/ui/Icon/Icons/Checkmark'

import { selectAIAssistantPlacement } from '~publish/legacy/ai/state/slice'

import { blue } from '@bufferapp/ui/style/colors'

import ModalActionCreators from '~publish/legacy/composer/composer/shared-components/modal/actionCreators'

import * as Styles from './AIAssistantMPUpgradePlan.styles'

export function AIAssistantMPUpgradePlan({ ctaButton }: { ctaButton: string }) {
  const aiAssistantPlacement = useAppSelector((state) => {
    return selectAIAssistantPlacement(state)
  })

  const accountContext = useAccount()
  const account = accountContext.account
  const organizationId = account.currentOrganization.id!

  useEffect(() => {
    if (aiAssistantPlacement) {
      BufferTracker.aiAssistantUpgradeModalViewed({
        organizationId,
        clientName: 'publishWeb',
        cta: `publish-${aiAssistantPlacement}-upgradeModal-${ctaButton}-1`,
      })
    }
  }, [organizationId, aiAssistantPlacement])

  const MIGRATION_HUB_URL =
    'https://account.buffer.com/new-plans?cta=publish-aiAssistant-upgradeModal-findOutMore-1'

  return (
    <SimpleModal
      closeAction={() => {
        // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
        ModalActionCreators.closeModal()
      }}
    >
      <Styles.Wrapper>
        <Styles.ContentWrapper>
          <Text type="h2">Unlock Buffer's AI Assistant</Text>
          <Text type="p">
            You're on a <strong>legacy Buffer plan</strong>. Switch over to the
            newest version to unlock Buffer's AI Assistant. Plus you'll receive:
          </Text>
          <ul>
            <li>
              <CheckmarkIcon color={blue} />
              Full access to the AI Assistant
            </li>
            <li>
              <CheckmarkIcon color={blue} />
              Unlimited team members (on the team plan)
            </li>
            <li>
              <CheckmarkIcon color={blue} />
              Access to Analytics included on all plans
            </li>
            <li>
              <CheckmarkIcon color={blue} />
              Access to all new AI features guaranteed
            </li>
            <li>
              <CheckmarkIcon color={blue} />
              Access to our beta program
            </li>
            <li>
              <CheckmarkIcon color={blue} />
              Access to Twitter Threads
            </li>
          </ul>
          {/* @ts-expect-error TS(2740) FIXME: Type '{ className: string; id: string; type: strin... Remove this comment to see the full error message */}
          <Button
            type="primary"
            onClick={(): void => {
              // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
              ModalActionCreators.closeModal()
              window.open(MIGRATION_HUB_URL, '_blank')
            }}
            label="Find Out More"
          />
        </Styles.ContentWrapper>
        <Styles.ImageWrapper></Styles.ImageWrapper>
      </Styles.Wrapper>
    </SimpleModal>
  )
}
