import {
  Badge,
  Tabs,
  UpgradeBadge,
  VisuallyHidden,
  type TabPanelProps,
  type TabProps,
  type TabsProps,
} from '@buffer-mono/popcorn'
import React from 'react'
import { selectApprovalEntitlement } from '~publish/legacy/campaign/selectors'
import { formatCompactNumber } from '~publish/helpers/numberFormatters'

import { useAppSelector } from '~publish/legacy/store'
import { ApprovalsUpgradePath } from './ApprovalsUpgradePath'
import { PostEmptyStateByTab } from './PostEmptyStateByTab'

type PostTab = 'queue' | 'drafts' | 'approvals' | 'sent'

type PostTabsProps = Omit<TabsProps, 'value' | 'onChange'> & {
  value: PostTab
  onChange: (value: PostTab) => void
}

/**
 * Wrapper around Tabs component that provides a set of tabs for post list
 */
const PostTabs = ({
  children,
  value,
  onChange,
  ...props
}: PostTabsProps): JSX.Element => {
  return (
    <Tabs
      value={value}
      onChange={(tab): void => onChange(tab as PostTab)}
      {...props}
    >
      {children}
    </Tabs>
  )
}

type PostTabsTabProps = Omit<TabProps, 'value' | 'children'> & {
  value: PostTab
  count: number | null | undefined
}

const labelByTab: Record<PostTab, string> = {
  queue: 'Queue',
  drafts: 'Drafts',
  approvals: 'Approvals',
  sent: 'Sent',
}

const PostTabsTab = ({ value, count }: PostTabsTabProps): JSX.Element => {
  const hasApprovalEntitlement = useAppSelector(selectApprovalEntitlement)

  if (value === 'approvals' && !hasApprovalEntitlement) {
    return (
      <Tabs.Tab value={value}>
        {labelByTab[value]} <UpgradeBadge size="xsmall" />
      </Tabs.Tab>
    )
  }

  return (
    <Tabs.Tab id={`${value}-tab`} value={value}>
      {labelByTab[value]}{' '}
      {count !== undefined && count !== null && (
        <Badge size="small" style={{ pointerEvents: 'none' }}>
          {formatCompactNumber(count)}
          <VisuallyHidden> posts</VisuallyHidden>
        </Badge>
      )}
    </Tabs.Tab>
  )
}

type PostTabsPanelProps = Omit<TabPanelProps, 'value'> & {
  value: PostTab
  children: React.ReactNode
}

const PostTabsPanel = ({
  value,
  ...props
}: PostTabsPanelProps): JSX.Element => {
  const hasApprovalEntitlement = useAppSelector(selectApprovalEntitlement)

  if (value === 'approvals' && !hasApprovalEntitlement) {
    return (
      <Tabs.Panel value={value} {...props}>
        <ApprovalsUpgradePath />
      </Tabs.Panel>
    )
  }

  return <Tabs.Panel value={value} {...props} />
}

const PostTabsObject = Object.assign(PostTabs, {
  Tab: PostTabsTab,
  TabList: Tabs.TabList,
  Panel: PostTabsPanel,
})

export { PostTabsObject as PostTabs, PostEmptyStateByTab }
export type { PostTab }
