import {
  BufferTracker,
  Product,
  Client,
  notifyViolations,
} from '@bufferapp/buffer-tracking-browser-ts'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import { store } from '~publish/legacy/store'
import { env } from '~publish/env'
import type { CommonTrackingProperties } from '~publish/legacy/accountContext/types'

export const CTA_VIEWED = 'cta-viewed'
export const CTA_CLICKED = 'cta-clicked'

const IS_DEVELOPMENT = (): boolean => {
  return env.VITE_NODE_ENV === 'development' || env.VITE_NODE_ENV === 'test'
}

notifyViolations(BufferTracker, IS_DEVELOPMENT())

type TrackingMetadata = {
  organizationId: string
  product: Product
}

const getTrackingMetadata = (): TrackingMetadata => {
  const organizationId = selectCurrentOrganizationId(store.getState())

  return {
    organizationId,
    product: Product.Publish,
  }
}

export const sendPaywallEvent = (
  event: string,
  cta: string,
  upgradePathName: string,
  commonTrackingProps: CommonTrackingProperties | null,
): void => {
  switch (event) {
    case CTA_VIEWED:
      {
        const eventCtaViewedData = {
          ...getTrackingMetadata(),
          cta,
          upgradePathName,
          ...commonTrackingProps,
        }
        BufferTracker.ctaViewed(eventCtaViewedData)
      }
      break
    case CTA_CLICKED:
      {
        const eventCtaClickedData = {
          ...getTrackingMetadata(),
          clientName: Client.PublishWeb,
          cta,
          upgradePathName,
          ...commonTrackingProps,
        }
        BufferTracker.ctaClicked(eventCtaClickedData)
      }
      break
    default:
      break
  }
}
