import React from 'react'
import type { RpcUpdate } from '~publish/legacy/post/types'
import style from './style'

class FailedPostComponent extends React.Component<{
  postId: string
  post: RpcUpdate
}> {
  static onRefresh(): void {
    location.reload()
  }

  render(): JSX.Element {
    const post = this.props.post
    return (
      <div style={style.errorBoundaryBordered}>
        <div style={style.errorSmallBoundaryTitle}>
          Well this is embarrassing...
        </div>
        <div style={style.errorSmallBoundaryMessage}>
          Something’s gone wrong with this post so I've informed my human
          creators.
          <br />
          If you need further assistance please email us at{' '}
          <a href="mailto:hello@buffer.com">hello@buffer.com</a>.
        </div>
        <div style={style.smallErrorBoundaryMessage}>
          (Failed post Id: {this.props.postId}
          {post.day && post.dueTime
            ? ` - Post due date: ${post.day} at ${post.dueTime}`
            : ''}
          )
        </div>
      </div>
    )
  }
}

export default FailedPostComponent
