import {
  type AnyObject,
  createComboboxPlugin,
  createPlateEditor,
  type CreatePlateEditorOptions,
  createPlugins,
} from '@udecode/plate'
import type { BufferEditor, BufferValue } from '../BufferEditor/types.plate'
import { createEmojiSearchPlugin } from '../plugins/emoji'
import { createLinkPlugin } from '../plugins/link'
import { SimpleLinkElementUI } from '../plugins/link/ui/SimpleLinkElementUI'
import { createGenerateContentPlugin } from '../plugins/generate-content'
import { IDEAS_EDITOR_ID } from './types.plate'
import { createContentGenerationPlugin } from '~publish/legacy/ai/plugins/content-generation'

export const createIdeasEditor = (
  options: CreatePlateEditorOptions<BufferValue, BufferEditor> = {},
): BufferEditor => {
  return createPlateEditor<BufferValue, BufferEditor>({
    id: IDEAS_EDITOR_ID,
    plugins: createPlugins<BufferValue, BufferEditor>([
      createComboboxPlugin<AnyObject, BufferValue>(),
      createEmojiSearchPlugin(),
      createLinkPlugin({
        component: SimpleLinkElementUI,
      }),
      createGenerateContentPlugin(),
      createContentGenerationPlugin(),
      // createTextGenerationHotKeyPlugin(),
    ]),
    ...options,
  })
}
