import React, { useCallback } from 'react'
import { Combobox, Text, AllPostsIcon } from '@buffer-mono/popcorn'
import { FilterButton } from '~publish/components/FilterButton'
import { useMenuItems, useMenuState } from './useFilterState'

export const TypeFilter = (): React.ReactElement => {
  const [items] = useMenuItems()

  const { selectedItem, setFilterValue } = useMenuState()

  const onSelect = useCallback(
    (selected: string): void => {
      const item = items.find((i) => i.id === selected)
      if (item) {
        setFilterValue(item.id)
      }
    },
    [setFilterValue, items],
  )

  return (
    <Combobox multiple={false} value={selectedItem.id} onChange={onSelect}>
      <Combobox.Trigger>
        <FilterButton icon={<AllPostsIcon />} variant="tertiary" size="medium">
          {selectedItem.title}
        </FilterButton>
      </Combobox.Trigger>
      <Combobox.Content>
        <Combobox.List>
          {items.map(({ id, title }) => (
            <Combobox.Item key={id} value={id}>
              <Text weight="medium">{title}</Text>
            </Combobox.Item>
          ))}
        </Combobox.List>
      </Combobox.Content>
    </Combobox>
  )
}
