import { Text, UnstyledButton } from '@buffer-mono/popcorn'
import { BufferTracker, Product } from '@bufferapp/buffer-tracking-browser-ts'
import React, { useEffect } from 'react'
import { useNotesCtaView } from '~publish/components/NotesWidget'
import {
  useAccount,
  useOrganizationBilling,
} from '~publish/legacy/accountContext'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import { useAppSelector } from '~publish/legacy/store'
import styles from './UpgradeToTeamPlan.module.css'

export const UpgradeToTeamPlan = (): JSX.Element => {
  const { account } = useAccount()
  const organizationId = useAppSelector(selectCurrentOrganizationId)
  const { canStartTrial } = useOrganizationBilling()

  const view = useNotesCtaView()
  const state = canStartTrial ? 'trial' : 'upgrade'
  const cta = `${view}-notesFeed-${state}-1`
  const commonTrackingProperties =
    account?.currentOrganization?.commonTrackingProperties || null

  useEffect(() => {
    BufferTracker.ctaViewed({
      organizationId,
      cta: `${Product.Publish}-${cta}`,
      upgradePathName: `notesFeed-${state}`,
      product: Product.Publish,
      ...commonTrackingProperties,
    })
  })

  const handleUpgradePathClick = (): void => {
    const { MODALS, actions } = window?.appshell || {}
    if (canStartTrial) {
      actions.openModal(MODALS.startTrial, {
        ctaView: view,
        ctaLocation: 'post',
        ctaButton: 'startA14DaysFreeTrial',
        cta,
        upgradePathName: 'notesFeed-trial',
      })
    } else {
      actions.openModal(MODALS.planSelector, {
        cta,
        upgradePathName: `notesFeed-upgrade`,
      })
    }
    BufferTracker.ctaClicked({
      organizationId,
      product: Product.Publish,
      cta,
      clientName: 'publishWeb',
      upgradePathName: `notesFeed-${state}`,
      ...commonTrackingProperties,
    })
  }

  return (
    <aside className={styles.upgradeContainer}>
      <img
        alt="Collaboration icons"
        src="https://buffer-publish.s3.amazonaws.com/images/collaboration-icons.svg"
      />
      {canStartTrial ? (
        <Text as="p">
          Want to collaborate with your team using Notes?
          <UnstyledButton
            className={styles.button}
            onClick={handleUpgradePathClick}
          >
            Start a 14-day free trial,
          </UnstyledButton>
          <span> no credit card required.</span>
        </Text>
      ) : (
        <Text as="p">
          <UnstyledButton
            className={styles.button}
            onClick={handleUpgradePathClick}
          >
            Invite your team
          </UnstyledButton>
          <span> to share and receive notes.</span>
        </Text>
      )}
    </aside>
  )
}
