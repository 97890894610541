import * as Dialog from '@radix-ui/react-dialog'
import styled from 'styled-components'
import { borderRadius } from '@bufferapp/ui/style/borders'
import {
  grayDark,
  white,
  grayDefault,
  grayDarker,
} from '@bufferapp/ui/style/colors'

export const StyledOverlay = styled(Dialog.Overlay)`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(50, 59, 67, 0.8);
  opacity: 0.9;
  z-index: 4000;
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const ModalWrapper = styled(Dialog.Content)`
  position: fixed;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4000;
  background-color: ${white};
  border-radius: 8px;
`

export const Main = styled.main`
  width: 503px;
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  background: ${white};
  border-radius: ${borderRadius};
  text-align: center;

  h3 {
    font-size: 18px;
    line-height: 28px;
    color: ${grayDarker};
    margin-bottom: 0px;
    margin-top: 0px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    color: ${grayDark};
    margin-top: 8px;
    margin-bottom: 16px;
  }
`

export const Footer = styled.footer`
  font-size: 12px;
  line-height: 16px;
  color: ${grayDark};
  margin-top: 16px;
  text-align: center;
  a {
    color: ${grayDark};
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0px;
`

export const CloseDialogButton = styled.button`
  border: none;
  width: 20px;
  height: 20px;
  background: none;
  svg {
    fill: ${grayDefault};
    width: 20px;
    height: 20px;
  }
  &:hover {
    svg {
      fill: ${grayDarker};
    }
    cursor: pointer;
  }
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: right;
`
