import { actionTypes as profileActionTypes } from '~publish/legacy/profile-sidebar/reducer'
import {
  actions as dataFetchActions,
  actionTypes as dataFetchActionTypes,
} from '@bufferapp/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actions as notificationActions } from '@bufferapp/notifications'
import { campaignTab } from '~publish/legacy/routes'
import { CampaignTab } from '~publish/legacy/campaign/types'
import { PostActionNotificationSuccess } from '~publish/legacy/post/types'
import { emptyQueue } from '~publish/legacy/general-settings/thunks/emptyQueue'
import { cleanQueue } from '~publish/legacy/general-settings/thunks/cleanQueue'
import { actionTypes } from './actionTypes'
import { movePostToDrafts } from '../post/thunks'

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch, getState }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    next(action)
    switch (action.type) {
      case profileActionTypes.SELECT_PROFILE: {
        dispatch(
          dataFetchActions.fetch({
            name: 'queuedPosts',
            args: {
              profileId: action.profile.id,
              isFetchingMore: false,
              count: 300,
            },
          }),
        )
        break
      }
      case `updateSchedule_${dataFetchActionTypes.FETCH_SUCCESS}`:
      case `updatePausedSchedules_${dataFetchActionTypes.FETCH_SUCCESS}`:
      case `toggleInstagramReminders_${dataFetchActionTypes.FETCH_SUCCESS}`:
      case `${cleanQueue.fulfilled}`:
      case `${emptyQueue.fulfilled}`: {
        // depeding on the action the profileId is at a different location
        const profileId = action.args?.profileId || action.meta?.arg?.profileId

        dispatch(
          dataFetchActions.fetch({
            name: 'queuedPosts',
            args: {
              profileId,
              isFetchingMore: false,
              count: 300,
            },
          }),
        )
        break
      }
      case 'COMPOSER_EVENT':
        // eslint-disable-next-line no-case-declarations
        const fromIdea = action.data?.fromIdea
        if (action.eventType === 'saved-drafts' && !fromIdea) {
          dispatch(
            notificationActions.createNotification({
              notificationType: 'success',
              message: action.data.message,
            }),
          )
        }
        break
      case `requeuePost_${dataFetchActionTypes.FETCH_SUCCESS}`:
        dispatch(
          dataFetchActions.fetch({
            name: 'queuedPosts',
            args: {
              profileId: action.args.profileId,
              isFetchingMore: false,
              isReordering: true,
              count: 300,
            },
          }),
        )
        break
      case `queuedPosts_${dataFetchActionTypes.FETCH_SUCCESS}`:
        if (action.args.isReordering) {
          dispatch(
            notificationActions.createNotification({
              notificationType: 'success',
              message: "We've re-added this post to your queue!",
            }),
          )
        }
        break
      case `deletePost_${dataFetchActionTypes.FETCH_SUCCESS}`: {
        const label = action.args?.isDraft ? 'draft' : 'post'
        dispatch(
          notificationActions.createNotification({
            notificationType: 'success',
            message: `Okay, we've deleted that ${label}!`,
          }),
        )
        /**
         * We also re-fetch the queue when a post is deleted
         * as this may have caused posts to change schedule
         */
        dispatch(
          dataFetchActions.fetch({
            name: 'queuedPosts',
            args: {
              profileId: action.args.profileId,
              isFetchingMore: false,
              hideLoading: true,
              count: 300,
            },
          }),
        )
        break
      }
      case actionTypes.POST_CONFIRMED_DELETE:
        dispatch(
          dataFetchActions.fetch({
            name: 'deletePost',
            args: {
              updateId: action.updateId,
              profileId: action.profileId,
            },
          }),
        )
        break
      case actionTypes.POST_SHARE_NOW:
        dispatch(
          dataFetchActions.fetch({
            name: 'sharePostNow',
            args: {
              updateId: action.post.id,
              profileId: action.profileId,
              profileService: action.post.profile_service,
            },
          }),
        )
        break
      case actionTypes.POST_REQUEUE:
        dispatch(
          dataFetchActions.fetch({
            name: 'requeuePost',
            args: {
              updateId: action.post.id,
              profileId: action.profileId,
            },
          }),
        )
        break
      case actionTypes.VIEW_CAMPAIGN_PAGE: {
        const { campaignId, isSent } = action
        if (isSent) {
          dispatch(campaignTab.goTo({ campaignId, tab: CampaignTab.Sent }))
        } else {
          dispatch(campaignTab.goTo({ campaignId, tab: CampaignTab.Scheduled }))
        }
        break
      }
      case `sharePostNow_${dataFetchActionTypes.FETCH_SUCCESS}`:
        if (action.args.profileService === 'tiktok') {
          dispatch(
            notificationActions.createNotification({
              notificationType: 'success',
              message: PostActionNotificationSuccess.SHARE_NOW_ASYNC,
            }),
          )
        } else {
          dispatch(
            notificationActions.createNotification({
              notificationType: 'success',
              message: PostActionNotificationSuccess.SHARE_NOW,
            }),
          )
        }

        break
      case `sharePostNow_${dataFetchActionTypes.FETCH_FAIL}`:
        dispatch(
          notificationActions.createNotification({
            notificationType: 'error',
            message: action.error,
          }),
        )
        break
      case actionTypes.POST_DROPPED: {
        dispatch(
          dataFetchActions.fetch({
            name: 'dropPost',
            args: {
              updateId: action.updateId,
              timestamp: action.timestamp,
            },
          }),
        )
        break
      }
      case actionTypes.POSTS_SWAPPED: {
        dispatch(
          dataFetchActions.fetch({
            name: 'swapPosts',
            args: {
              updateSourceId: action.postSource.id,
              sourcePinned: action.postSource.postProps.pinned,
              sourceDueAt: action.postTarget.postProps.due_at,

              updateTargetId: action.postTarget.id,
              targetPinned: action.postTarget.postProps.pinned,
              targetDueAt: action.postSource.postProps.due_at,
            },
          }),
        )
        break
      }

      /**
       * Watch for Pusher events to keep post counts up-to-date throughout the app.
       */
      case actionTypes.POST_CREATED:
      case actionTypes.POST_DELETED:
      case actionTypes.POST_SENT:
      case movePostToDrafts.fulfilled.toString(): {
        const profileId = action.profileId || action.meta.arg.profileId

        if (profileId) {
          dispatch(
            dataFetchActions.fetch({
              name: 'getCounts',
              args: {
                profileId,
              },
            }),
          )
        }

        /**
         * We also re-fetch the queue
         */
        dispatch(
          dataFetchActions.fetch({
            name: 'queuedPosts',
            args: {
              profileId,
              isFetchingMore: false,
              hideLoading: true,
              count: 300,
            },
          }),
        )
        break
      }

      default:
        break
    }
  }
