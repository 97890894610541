import { useSplitEnabled } from '@buffer-mono/features'
import PropTypes from 'prop-types'
import React, { type RefObject, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import {
  selectAIAssistantOpened,
  selectAIAssistantResponsive,
  toggleAIAssistant,
} from '~publish/legacy/ai/state/slice'
import { actions as composerPopoverActions } from '~publish/legacy/composer-popover'
import {
  AppEnvironments,
  SERVICE_FACEBOOK,
  SERVICE_LINKEDIN,
} from '~publish/legacy/constants'
import { useAnimatedVisibility } from '~publish/legacy/hooks/useAnimatedVisibility'
import { selectHasProfiles } from '~publish/legacy/profile-sidebar/selectors'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { destroyComposerUploaders } from '~publish/legacy/uploads/lib/uploader/UploadersMap'
import ComposerActionCreators from '../../action-creators/ComposerActionCreators'
import { EditorStateProxy } from '../../entities/EditorStateProxy'
import AppStore from '../../stores/AppStore'
import ComposerStore from '../../stores/ComposerStore'
import type {
  AppState,
  AppStoreMetaData,
  ComposerProfile,
  ComposerSaveButton,
  ComposerUserData,
  OrganizationsData,
  Timeslot,
} from '../../stores/types'
import type { Notification } from '../../values/Notification'
import { AIAssistantSidePanel } from '../ai-assistant-side-panel/AIAssistantSidePanel'
import ChannelConnectPopover from '../channel-connect-popover'
import {
  appStatePropType,
  metaDataPropType,
  userDataPropType,
  visibleNotificationsPropType,
} from '../ComposerPropTypes'
import ComposerSection from '../ComposerSection'
import styles from '../css/App.module.css'
import HashtagManagerSidepanel from '../hashtag-manager-sidepanel'
import Header from '../header/index'
import Modals from '../Modals'
import NotificationContainer from '../NotificationContainer'
import { PostPreviewSidepanel } from '../post-preview-sidepanel/PostPreviewSidepanel'
import { PREVIEWS_SUPPORTED_SERVICES } from '../post-preview/previews'
import type {
  DraftPreview,
  ProfilePreview,
} from '../post-preview/previews/types'
import ProfileSection from '../ProfileSection'
import UpdateSaver from '../update-saver/UpdateSaver'
import { App, ComposerRoot } from './styles'
import AppDispatcher from '../../dispatcher'
import { ActionTypes } from '../../state/ActionTypes'

const onCloseHashTagSidepanel = (): void => {
  ComposerActionCreators.updateToggleSidebarVisibility(null, false)
}

const onCloseConnectChannelPopover = (): void => {
  ComposerActionCreators.updateConnectChannelPopoverVisible(null, false)
}

const onInsertHashtagGroupClick = (text: string): void => {
  ComposerActionCreators.insertHashtagInDraftCaption(text)
}

const selectedProfiles = (profiles: ComposerProfile[]): ComposerProfile[] => {
  return profiles.filter((profile) => profile.isSelected)
}

const notificationsContainerClassNames = (
  appEnvironment: keyof typeof AppEnvironments,
): { container: string; notification: string } => {
  const isOnExtension = appEnvironment === AppEnvironments.EXTENSION
  const container = isOnExtension
    ? styles.floatingNotificationsContainerOnExtension
    : styles.floatingNotificationsContainerOnPublish

  return {
    container,
    notification: styles.floatingNotification,
  }
}

const shouldShowInlineSubprofileDropdown = (args: {
  canSelectProfiles: boolean
  profiles: ComposerProfile[]
}): boolean => {
  return !args.canSelectProfiles && selectedProfiles(args.profiles).length === 1
}

const firstSelectedProfileTimezone = (
  profiles: ComposerProfile[],
): string | undefined => {
  const selected = selectedProfiles(profiles)
  const firstSelectedProfile = selected[0]
  if (firstSelectedProfile) return firstSelectedProfile.timezone
}

const isSlotPickingAvailable = (profiles: ComposerProfile[]): boolean => {
  return selectedProfiles(profiles).length === 1
}

const moreThanOneProfileSelected = (profiles: ComposerProfile[]): boolean => {
  return selectedProfiles(profiles).length > 1
}

export const getCurrentProfile = (): ComposerProfile | undefined => {
  const enabledComposer = AppStore.getExpandedComposerId()
  if (enabledComposer === 'omni') return

  const selectedProfilesFromStore = AppStore.getSelectedProfiles()
  const profilesForService = AppStore.getProfilesForService(enabledComposer)
  const selectedProfilesForService = selectedProfilesFromStore.filter(
    (profile) => profilesForService.map((p) => p.id).includes(profile.id),
  )
  if (Array.isArray(selectedProfilesForService))
    return selectedProfilesForService[0]
}

interface AppStatelessProps {
  metaData: AppStoreMetaData
  canSelectProfiles: boolean
  appState: AppState
  profiles: ComposerProfile[]
  visibleNotifications: Notification[]
  organizations: OrganizationsData
  topLevelNotificationContainerExcludedScopes: string[]
  scheduledAt: number | null
  isPinnedToSlot: boolean
  availableSchedulesSlotsForDay: false | undefined | Timeslot[]
  sentPost: boolean
  previewMode: boolean
  userData: ComposerUserData
  areAllDraftsSaved: boolean
  saveButtons: ComposerSaveButton[]
  onAppWrapperClick: (e: React.MouseEvent) => void
  onAppClick: (e: React.MouseEvent) => void
  appElementRef?: (ref: RefObject<HTMLDivElement>) => void
}
const AppStateless = ({
  onAppWrapperClick,
  onAppClick,
  metaData,
  canSelectProfiles,
  appState,
  profiles,
  visibleNotifications,
  topLevelNotificationContainerExcludedScopes,
  userData,
  organizations,
  scheduledAt,
  areAllDraftsSaved,
  saveButtons,
  isPinnedToSlot,
  availableSchedulesSlotsForDay,
  sentPost,
  previewMode,
}: AppStatelessProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const {
    isOmniboxEnabled,
    composerSidebarVisible,
    composerPostPreviewVisible,
    connectChannelPopoverVisible,
    whatPreventsSaving,
    whatPreventsSavingDraft,
    expandedProfileSubprofileDropdownId,
  } = appState

  const formattedProfiles = profiles.map((profile) => {
    return {
      ...profile,
      service: {
        ...profile.service,
        name: profile.service.name,
      },
    }
  })

  const { isEnabled: isRemindersEnabled } = useSplitEnabled('CORE-reminders')

  const enabledComposer = AppStore.getExpandedComposerId()
  const draft = ComposerStore.getDraft(enabledComposer)

  const showPreview = draft && PREVIEWS_SUPPORTED_SERVICES.includes(draft.id)
  const hasEditor = draft?.editorState

  const AIAssistantOpened = useAppSelector((state) =>
    selectAIAssistantOpened(state),
  )

  const shouldAIAssistantbeVisibleAndEnabledCondition: boolean =
    !!hasEditor && AIAssistantOpened

  const {
    isVisibleInDOM: AIPanelVisible,
    shouldBeVisible:
      AIPanelShouldBeVisible /* same value than shouldAIAssistantbeVisibleAndEnabledCondition */,
    onAnimationEnd: AIPanelAnimationEnd,
  } = useAnimatedVisibility({
    visibilityCondition: shouldAIAssistantbeVisibleAndEnabledCondition,
  })

  useEffect(() => {
    dispatch(composerPopoverActions.addSplit({ isRemindersEnabled }))
  }, [dispatch, isRemindersEnabled])

  const sidepanelVisibleOnTop = AppStore.getSidebarVisibleOnTop()

  const AIAssistantResponsive = useAppSelector((state) =>
    selectAIAssistantResponsive(state),
  )
  const hasProfiles = useAppSelector(selectHasProfiles)
  const allSelectedProfiles = selectedProfiles(profiles)

  const isAIAssistantVisibleOnTheLeft = AIPanelVisible && !AIAssistantResponsive
  const isAIAssistantVisibleOnTheRight = AIPanelVisible && AIAssistantResponsive

  const sidebarVisible =
    showPreview ||
    (composerSidebarVisible && !isOmniboxEnabled) ||
    connectChannelPopoverVisible

  useEffect(() => {
    return (): void => {
      destroyComposerUploaders()

      dispatch(toggleAIAssistant(false))
    }
  }, [dispatch])

  useEffect(() => {
    const composerPostPreviewVisible =
      sidebarVisible &&
      !composerSidebarVisible &&
      !isAIAssistantVisibleOnTheRight
    ComposerActionCreators.updateTogglePostPreviewVisibility(
      null,
      composerPostPreviewVisible,
    )
  }, [composerSidebarVisible, sidebarVisible, isAIAssistantVisibleOnTheRight])

  const { draftMode } = metaData
  const campaigns = metaData.campaigns ?? []
  const campaignId = metaData.campaignDetails?.id ?? null
  const preSelectedTags = metaData.tags ?? []
  const shouldShowTagsSelector = true
  const shouldDisplayCampaignHeader =
    organizations?.selected?.hasCampaignsFeature && !shouldShowTagsSelector

  /**
   * Deciding if we need to show Threads migration modal - it's the case
   * when there's a Twitter draft enabled, which has a Thread, and there's
   * at least one profile with canQueueMoreThreads = false
   */
  const canQueueMoreThreadsForService = (service: string) =>
    allSelectedProfiles
      .filter((p) => p.service.name === service)
      .reduce(
        (canQueueMoreThreads, p) =>
          p.canQueueMoreThreads && canQueueMoreThreads,
        true,
      )

  const services = ['twitter', 'threads', 'mastodon', 'bluesky']

  const canQueueMoreThreadsForAllServices = services.reduce(
    (acc, service) => ({
      ...acc,
      [service]: canQueueMoreThreadsForService(service),
    }),
    {} as Record<string, boolean>,
  )

  const canQueueMoreThreadsForAllProfiles =
    canQueueMoreThreadsForAllServices.threads &&
    canQueueMoreThreadsForAllServices.twitter

  const hasThread = services.some((service) =>
    ComposerStore.getDraft(service)?.hasThread(),
  )

  const shouldShowThreadsMigrationModal =
    services.some((service) => !canQueueMoreThreadsForAllServices[service]) &&
    hasThread

  const threadsMigrationModalService =
    !canQueueMoreThreadsForAllProfiles &&
    services.every((service) => ComposerStore.getDraft(service)?.hasThread())
      ? 'omni'
      : services.find(
          (service) =>
            !canQueueMoreThreadsForAllServices[service] &&
            ComposerStore.getDraft(service)?.hasThread(),
        )

  let draftPreview: DraftPreview | undefined
  let profilePreview: ProfilePreview | undefined
  const profile = getCurrentProfile()

  if (draft && profile) {
    draftPreview = {
      ...draft,
      isEmpty: draft.isEmpty(),
      text: draft.text,
      hasThread: draft.hasThread(),
      activeThreadId: ComposerStore.getActiveThreadId(),
      entities:
        profile.service?.name === SERVICE_FACEBOOK
          ? EditorStateProxy.getFacebookAutocompleteEntities(draft.editorState)
          : null,
      annotations:
        profile.service?.name === SERVICE_LINKEDIN
          ? EditorStateProxy.getAllLinkedinAnnotations(draft.editorState)
          : null,
    }

    profilePreview = {
      name: profile.service?.name,
      username: profile.service?.username,
      formattedUsername: profile.service?.formattedUsername,
      avatar: profile.images?.avatar,
      displayName: profile.displayName,
      serverUrl: profile.serverUrl,
      serviceType: profile.serviceType,
      isTwitterPremium: profile.isTwitterPremium ?? false,
    }
  }

  return (
    <ComposerRoot
      id="composer-root"
      className="editor-container"
      onClick={onAppWrapperClick}
      tabIndex={0}
      data-testid="composer"
    >
      <Modals />

      <NotificationContainer
        visibleNotifications={visibleNotifications}
        classNames={notificationsContainerClassNames(metaData.appEnvironment)}
        notScopes={topLevelNotificationContainerExcludedScopes}
        showCloseIcon
      />

      {/* hasEditor is redundant here, but it's needed for the type validation */}
      {isAIAssistantVisibleOnTheLeft && hasEditor && (
        <AIAssistantSidePanel
          editor={draft?.editorState}
          channel={draft?.service?.name}
          animated={true}
          state={AIPanelShouldBeVisible ? 'open' : 'closed'}
          onAnimationEnd={AIPanelAnimationEnd}
        />
      )}

      <App
        onClick={onAppClick}
        tabIndex={0}
        $fullHeight={!!enabledComposer}
        $sidebarRightVisible={sidebarVisible || isAIAssistantVisibleOnTheRight}
        $sidebarLeftVisible={isAIAssistantVisibleOnTheLeft}
        onTransitionEnd={(event): void => {
          if (event.target === event.currentTarget) {
            AppDispatcher.handleViewAction({
              actionType: ActionTypes.COMPOSER_TRANSITION_FINISHED,
            })
          }
        }}
      >
        <Header
          campaigns={campaigns}
          campaignId={campaignId}
          editMode={AppStore.getMetaData().editMode}
          draftMode={AppStore.getMetaData().draftMode}
          duplicateMode={!!AppStore.getMetaData().duplicatedFrom}
          showCampaignSelector={shouldDisplayCampaignHeader}
          preSelectedTags={preSelectedTags}
        />

        {hasProfiles && canSelectProfiles ? (
          <ProfileSection
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error TS(2322) FIXME: Type '{ profiles: ComposerProfile[]; expa... Remove this comment to see the full error message
            profiles={formattedProfiles}
            expandedProfileSubprofileDropdownId={
              expandedProfileSubprofileDropdownId
            }
            userData={userData}
            organizations={organizations}
            visibleNotifications={visibleNotifications}
          />
        ) : null}

        <ComposerSection
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          isOmniboxEnabled={isOmniboxEnabled}
          appState={appState}
          profiles={profiles}
          organizations={organizations}
          shouldShowInlineSubprofileDropdown={shouldShowInlineSubprofileDropdown(
            { canSelectProfiles, profiles },
          )}
          visibleNotifications={visibleNotifications}
          areAllDraftsSaved={areAllDraftsSaved}
          selectedProfiles={allSelectedProfiles}
          draftMode={draftMode}
        />

        <UpdateSaver
          appState={appState}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error TS(2322) FIXME: Type 'AppStoreMetaData' is not assignable... Remove this comment to see the full error message
          metaData={metaData}
          userData={userData}
          organizations={organizations}
          timezone={firstSelectedProfileTimezone(profiles)}
          saveButtons={saveButtons}
          scheduledAt={scheduledAt}
          isSlotPickingAvailable={
            isSlotPickingAvailable(profiles) && !draftMode
          }
          isPinnedToSlot={isPinnedToSlot}
          availableSchedulesSlotsForDay={availableSchedulesSlotsForDay}
          visibleNotifications={visibleNotifications}
          moreThanOneProfileSelected={moreThanOneProfileSelected(profiles)}
          areAllDraftsSaved={areAllDraftsSaved}
          whatPreventsSavingMessages={whatPreventsSaving}
          whatPreventsSavingDraftMessages={whatPreventsSavingDraft}
          isOmniboxEnabled={isOmniboxEnabled}
          selectedProfiles={allSelectedProfiles}
          sentPost={sentPost}
          previewMode={previewMode}
          draftMode={draftMode}
          draftPreview={draftPreview}
          shouldShowThreadsMigrationModal={shouldShowThreadsMigrationModal}
          threadsMigrationModalService={threadsMigrationModalService}
        />
        <ReactTooltip class={styles.tooltip} effect="solid" place="top" />
      </App>

      {composerPostPreviewVisible && draftPreview && profilePreview && (
        <PostPreviewSidepanel
          isVisible={composerPostPreviewVisible}
          draftPreview={draftPreview}
          profilePreview={profilePreview}
        />
      )}

      {/* All of these panels can be rendered at the same time, ones behind others
      property onTop places the last opened on top of the rest */}

      {/* This section needs to be refactorized to unify the way we show panels */}

      {/* Small screens will render the AI Assistant side panel on the right */}
      {/* Small screens will render the AI Assistant side panel on the right */}
      {isAIAssistantVisibleOnTheRight && hasEditor && (
        <AIAssistantSidePanel
          editor={draft?.editorState}
          channel={draft?.service?.name}
          animated={true}
          state={AIPanelShouldBeVisible ? 'open' : 'closed'}
          onAnimationEnd={AIPanelAnimationEnd}
          onTop={sidepanelVisibleOnTop === 'aiassistant'}
        />
      )}

      {composerSidebarVisible && (
        <HashtagManagerSidepanel
          onInsertHashtagGroupClick={onInsertHashtagGroupClick}
          onClose={onCloseHashTagSidepanel}
          onTop={sidepanelVisibleOnTop === 'hashtagmanager'}
        />
      )}

      {connectChannelPopoverVisible && !isAIAssistantVisibleOnTheRight && (
        <ChannelConnectPopover onClose={onCloseConnectChannelPopover} />
      )}
    </ComposerRoot>
  )
}

// TODO: use objectOf with shape for eslint disabled lines
AppStateless.propTypes = {
  onAppWrapperClick: PropTypes.func.isRequired,
  onAppClick: PropTypes.func.isRequired,
  appElementRef: PropTypes.func,
  metaData: metaDataPropType.isRequired,
  position: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  canSelectProfiles: PropTypes.bool.isRequired,
  appState: appStatePropType.isRequired,
  profiles: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  visibleNotifications: visibleNotificationsPropType.isRequired, // eslint-disable-line react/forbid-prop-types
  topLevelNotificationContainerExcludedScopes: PropTypes.any.isRequired, // eslint-disable-line
  userData: userDataPropType.isRequired,
  scheduledAt: PropTypes.number,
  areAllDraftsSaved: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  saveButtons: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  availableSchedulesSlotsForDay: PropTypes.any, // eslint-disable-line
  isPinnedToSlot: PropTypes.bool,
  sentPost: PropTypes.bool,
  previewMode: PropTypes.bool,
  organizations: PropTypes.object, // eslint-disable-line react/forbid-prop-types
}

AppStateless.defaultProps = {
  scheduledAt: null,
  isPinnedToSlot: false,
  sentPost: false,
  previewMode: false,
  organizations: {},
}

export default AppStateless
