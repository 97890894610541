import { actionTypes as profileActionTypes } from '~publish/legacy/profile-sidebar/reducer'
import { actions as profilesActions } from '~publish/legacy/profiles/reducer'
import {
  actions as asyncDataFetch,
  actions as dataFetchActions,
  actionTypes as dataFetchActionTypes,
} from '@bufferapp/async-data-fetch'
import { actionTypes } from './reducer'

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch, getState }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    next(action)
    switch (action.type) {
      case profileActionTypes.SELECT_PROFILE:
        dispatch(
          dataFetchActions.fetch({
            name: 'getLinkShortener',
            args: {
              profileId: action.profile.id,
            },
          }),
        )
        break
      case actionTypes.CHANGE_SELECTED_LINK_SHORTENER:
        dispatch(
          asyncDataFetch.fetch({
            name: 'changeLinkShortener',
            args: {
              profileId: action.profileId,
              domain: action.domain,
            },
          }),
        )
        break
      case actionTypes.TOGGLE_INSTAGRAM_REMINDERS:
        dispatch(
          dataFetchActions.fetch({
            name: 'toggleInstagramReminders',
            args: {
              profileId: action.profileId,
              allowReminders: action.allowReminders,
            },
          }),
        )
        break
      case `toggleInstagramReminders_${dataFetchActionTypes.FETCH_SUCCESS}`:
        dispatch(
          // @ts-expect-error TS(2345) FIXME: Argument of type '{ profileId: any; }' is not assi... Remove this comment to see the full error message
          profilesActions.fetchSingleProfile({
            profileId: action.args.profileId,
          }),
        )
        break
      default:
        break
    }
  }
