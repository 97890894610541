import React from 'react'

import {
  CriticalIcon,
  EmptyState,
  SearchIcon,
  Text,
  VisuallyHidden,
} from '@buffer-mono/popcorn'

import {
  PostCard,
  PostCardSkeleton,
  PostCard_Post,
} from '~publish/components/PostCard'
import { Loading } from '../Loading'

import {
  DateSeparator,
  SeparatorHeader,
  SeparatorHeaderSkeleton,
} from '../../../components/DateSeparator'
import { usePaginatedPostList } from './usePaginatedPostList'

import { getFragmentData, type FragmentType } from '~publish/gql'
import {
  isPausedPost,
  isScheduledPost,
  isUnscheduledApproval,
  isUnscheduledDraft,
  postStatusByTab,
  type PostListStatus,
} from '~publish/helpers/post'
import { range } from '~publish/helpers/range'
import styles from './PostList.module.css'
import { CollaborationUpgradePathBanner } from '~publish/components/PromotionalBanners/CollaborationUpgradePathBanner'

export const PostListSkeleton = (): JSX.Element => (
  <section role="feed" className={styles.feed} aria-busy>
    <VisuallyHidden>Loading posts</VisuallyHidden>
    {range(20).map((index) => (
      <React.Fragment key={index}>
        {index % 3 === 0 ? <SeparatorHeaderSkeleton /> : null}
        <PostCardSkeleton />
      </React.Fragment>
    ))}
  </section>
)

const PostListEmptyState = (): JSX.Element => {
  return (
    <EmptyState size="large">
      <EmptyState.Icon>
        <SearchIcon />
      </EmptyState.Icon>
      <EmptyState.Heading>No posts found</EmptyState.Heading>
      <EmptyState.Description>
        There are no posts that meet your selected filters
      </EmptyState.Description>
    </EmptyState>
  )
}

type PostListProps = {
  status: PostListStatus
  channelIds?: string[]
  tagIds?: string[]
  emptyState?: React.ReactNode
  showChannelNotices?: boolean
}

export const PostList = ({
  status,
  channelIds,
  tagIds,
  emptyState,
  showChannelNotices = false,
}: PostListProps): JSX.Element => {
  const { data, loading, error, lastElementRef, fetchingMore } =
    usePaginatedPostList({
      status: postStatusByTab[status],
      channelIds,
      tagIds,
      // For the 'sent' tab we want to see the most recently posted first
      sortPreset:
        status === 'sent' ? 'mostRecentlyPostedFirst' : 'upcomingFirst',
    })

  if (loading) {
    return <PostListSkeleton />
  }

  if (error) {
    return (
      <EmptyState variant="critical" size="large">
        <EmptyState.Icon>
          <CriticalIcon />
        </EmptyState.Icon>
        <EmptyState.Heading>
          Error happened, let our team know
        </EmptyState.Heading>
        <EmptyState.Description>
          Please let our team know about it, we&apos;ll fix it as soon as
          possible. <Text color="critical">{error.message}</Text>
        </EmptyState.Description>
      </EmptyState>
    )
  }

  // no posts
  if (!data?.posts.edges?.length) {
    return emptyState ? <>{emptyState}</> : <PostListEmptyState />
  }

  const flattenedPostEdges = data.posts.edges.map((edge) => edge.node)

  const posts = getFragmentData(PostCard_Post, flattenedPostEdges)
  const pausedPosts = posts.filter(isPausedPost)
  const unscheduledDrafts = posts.filter(isUnscheduledDraft)
  const unscheduledApprovals = posts.filter(isUnscheduledApproval)
  const scheduledPosts = posts.filter(isScheduledPost)

  const lastPostId = posts.at(-1)?.id

  return (
    <>
      <CollaborationUpgradePathBanner />
      <section
        role="feed"
        aria-busy={fetchingMore}
        className={styles.feed}
        data-testid="post-list"
      >
        {pausedPosts.length > 0 && (
          <>
            <SeparatorHeader key="unpublished-posts-header">
              Unpublished Posts
            </SeparatorHeader>
            {pausedPosts.map((post) => (
              <PostCard
                key={post.id}
                post={post as FragmentType<typeof PostCard_Post>}
                ref={post.id === lastPostId ? lastElementRef : null}
                showChannelNotices={showChannelNotices}
              />
            ))}
          </>
        )}
        {unscheduledDrafts.length > 0 && (
          <>
            <SeparatorHeader key="unscheduled-drafts-header">
              Unscheduled Drafts
            </SeparatorHeader>
            {unscheduledDrafts.map((post) => (
              <PostCard
                key={post.id}
                post={post as FragmentType<typeof PostCard_Post>}
                ref={post.id === lastPostId ? lastElementRef : null}
                showChannelNotices={showChannelNotices}
              />
            ))}
          </>
        )}
        {unscheduledApprovals.length > 0 && (
          <>
            <SeparatorHeader key="unscheduled-approvals-header">
              Unscheduled Approvals
            </SeparatorHeader>
            {unscheduledApprovals.map((post) => (
              <PostCard
                key={post.id}
                post={post as FragmentType<typeof PostCard_Post>}
                ref={post.id === lastPostId ? lastElementRef : null}
                showChannelNotices={showChannelNotices}
              />
            ))}
          </>
        )}
        {scheduledPosts.map((post, index, list) => {
          const currentDate = post.dueAt
          const prevDate = list[index - 1]?.dueAt ?? undefined

          return (
            <React.Fragment key={`date-header-${post.id}`}>
              <DateSeparator
                currentDate={currentDate}
                previousDate={prevDate}
              />
              <PostCard
                key={post.id}
                post={post as FragmentType<typeof PostCard_Post>}
                ref={post.id === lastPostId ? lastElementRef : null}
                showChannelNotices={showChannelNotices}
              />
            </React.Fragment>
          )
        })}

        {data?.posts.pageInfo.hasNextPage && (
          <Loading key="load-more" size="small" />
        )}
      </section>
    </>
  )
}
