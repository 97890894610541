import { actionTypes as dataFetchActionTypes } from '@bufferapp/async-data-fetch'
import { actionTypes as orgActionTypes } from '~publish/legacy/organizations'
import { actions, actionTypes } from './reducer'
import { shouldShowInstagramFirstCommentModal } from './util/showModal'
import { closeTagForm, openTagForm } from '~publish/legacy/tag-form-modal/slice'

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    next(action)
    switch (action.type) {
      case 'APP_INIT': {
        let modal = null
        const { queryParams: query } = action
        const modalKey = query.get('mk')
        const modalValue = query.get('mv')
        if (modalKey) {
          modal = { key: modalKey }
          if (modalValue) {
            // @ts-expect-error TS(2339) FIXME: Property 'value' does not exist on type '{ key: an... Remove this comment to see the full error message
            modal.value = modalValue
          }
        }
        // @ts-expect-error TS(2339) FIXME: Property '_showModal' does not exist on type 'Wind... Remove this comment to see the full error message
        window._showModal = modal
        break
      }
      case 'INIT_MODALS': {
        if (shouldShowInstagramFirstCommentModal()) {
          // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
          dispatch(actions.showInstagramFirstCommentModal())
        }
        break
      }

      case `profiles_${dataFetchActionTypes.FETCH_SUCCESS}`: {
        if (
          action.result &&
          // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
          action.result.some((profile) => profile.isDisconnected)
        ) {
          dispatch(actions.showProfilesDisconnectedModal())
        }
        break
      }

      case orgActionTypes.ORGANIZATION_SELECTED: {
        const {
          shouldShowProTrialExpiredModal,
          shouldShowBusinessTrialExpiredModal,
        } = action.selected

        if (
          shouldShowProTrialExpiredModal ||
          shouldShowBusinessTrialExpiredModal
        ) {
          dispatch(actions.showTrialCompleteModal())
        }
        break
      }

      case actionTypes.SHOW_TAG_FORM_MODAL:
        // eslint-disable-next-line no-case-declarations
        const { editMode, isFromSelector, tag } = action
        dispatch(openTagForm({ editMode, isFromSelector, tag }))
        break

      case actionTypes.HIDE_TAG_FORM_MODAL:
        dispatch(closeTagForm())
        break

      default:
        break
    }
  }
