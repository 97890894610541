import { COMPOSER_UPLOADER_PREFIX } from '~publish/legacy/constants'
import { Uploader, type UploaderEvents } from '../Uploader'
import { UploaderRestrictions } from '../../values/UploaderRestrictions'
import { createUppy } from '../../uppy/createUppy'
import { addUploaderTracking } from './addUploaderTracking'

/**
 * This is a private map of all the uploader instances.
 */
const _uploadersMap = new Map<string, Uploader>()

export function getUploader({ uploaderId }: { uploaderId: string }): Uploader {
  const uploader = _uploadersMap.get(uploaderId)

  if (!uploader) {
    throw new Error(`No uploader found for ${uploaderId}`)
  }

  return uploader
}

export function resetUploader({ uploaderId }: { uploaderId: string }): void {
  _uploadersMap.get(uploaderId)?.reset()
}

export function destroyUploader({ uploaderId }: { uploaderId: string }): void {
  _uploadersMap.get(uploaderId)?.close(() => _uploadersMap.delete(uploaderId))
}

export function getOrCreateUploader({
  uploaderId,
  organizationId,
  userId,
  fileRestrictions = UploaderRestrictions.new(),
  eventHandlers = {},
  getCustomCount,
}: {
  uploaderId: string
  organizationId: string
  userId: string
  fileRestrictions?: UploaderRestrictions | (() => UploaderRestrictions)
  eventHandlers?: Partial<UploaderEvents>
  getCustomCount: () => number
}): Uploader {
  const existing = _uploadersMap.get(uploaderId)
  if (existing) {
    return existing
  }

  const uppyInstance = createUppy({
    id: uploaderId,
    userId,
  })

  const uploaderInstance = new Uploader(
    uploaderId,
    userId,
    organizationId,
    uppyInstance,
    fileRestrictions,
    getCustomCount,
  )

  Object.keys(eventHandlers).forEach((eventName) => {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const handler = eventHandlers[eventName]
    if (handler) {
      uploaderInstance.on(eventName as keyof UploaderEvents, handler)
    }
  })

  addUploaderTracking(uploaderInstance)

  _uploadersMap.set(uploaderId, uploaderInstance)

  return uploaderInstance
}

export function destroyAllUploaders(): void {
  _uploadersMap.forEach((uploader) =>
    destroyUploader({ uploaderId: uploader.getId() }),
  )
}

export function destroyComposerUploaders(): void {
  _uploadersMap.forEach((uploader) => {
    if (uploader.getId().startsWith(COMPOSER_UPLOADER_PREFIX)) {
      destroyUploader({ uploaderId: uploader.getId() })
    }
  })
}
