/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import SimpleModal from '@bufferapp/ui/SimpleModal'

import { Image } from '@bufferapp/components'
import {
  BufferTracker,
  Product,
  Client,
} from '@bufferapp/buffer-tracking-browser-ts'
import { useTranslation } from 'react-i18next'
import {
  useAccount,
  useOrganizationLimits,
} from '~publish/legacy/accountContext'
import { SEGMENT_NAMES } from '~publish/legacy/constants'

import {
  ModalWrapperStyled,
  ModalActionsStyled,
  HeaderStyled,
  CenteredText,
  HeroImage,
  ListStyled,
  ListItemStyled,
  CheckmarkIconStyled,
  ButtonStyled,
  FooterStyled,
} from './styles'

interface Props {
  onClose: (close: boolean) => void
  type: 'paid' | 'trial' | 'upgrade'
}

export const IdeasLimitReachedModal = ({
  onClose,
  type,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const accountContext = useAccount()
  const account = accountContext?.account
  const organizationId = account?.currentOrganization?.id
  const { ideas: ideasLimit } = useOrganizationLimits()

  useEffect(() => {
    if (type !== 'paid') {
      const eventData = {
        product: Product.Publish,
        upgradePathName: `ideasLimit-${type}`,
        organizationId: organizationId || '',
        clientName: Client.PublishWeb,
      }

      BufferTracker.upgradePathViewed(eventData)
    }
  }, [])

  useEffect(() => {
    if (type === 'trial') {
      const { COMPONENTS, actions } = window?.appshell || {}
      actions.renderComponent({
        containerId: 'ideasReachLimitCta',
        componentKey: COMPONENTS.startTrialButton,
        options: {
          ctaView: 'ideasLimit',
          ctaLocation: 'content',
          ctaButton: 'startTrial',
          cta: SEGMENT_NAMES.IDEAS_LIMIT_TRIAL,
          upgradePathName: 'ideasLimit-trial',
        },
      })
    }
  }, [])

  const paidContent = (): JSX.Element => {
    return (
      <>
        <HeaderStyled type="h2">
          {t('content.modals.ideasLimitReached.paid.title', {
            limit: ideasLimit,
          })}
        </HeaderStyled>
        <CenteredText type="p">
          {t('content.modals.ideasLimitReached.paid.description')}
        </CenteredText>
      </>
    )
  }

  const modalActionButtons = (modalType: string): JSX.Element => {
    return (
      <ModalActionsStyled>
        {modalType === 'trial' && <div id="ideasReachLimitCta" />}
        {modalType === 'upgrade' && (
          <ButtonStyled
            type="primary"
            label={t('content.modals.ideasLimitReached.free.upgrade.button')}
            onClick={(): void => {
              onClose(false)
              const { MODALS, actions } = window?.appshell || {}
              actions.openModal(MODALS.planSelector, {
                cta: SEGMENT_NAMES.IDEAS_LIMIT_UPGRADE,
                upgradePathName: 'ideasLimit-upgrade',
              })
            }}
          />
        )}
      </ModalActionsStyled>
    )
  }

  const freeContent = (modalType: string): JSX.Element => {
    return (
      <>
        <HeaderStyled type="h2">
          {t(`content.modals.ideasLimitReached.free.title`, {
            limit: ideasLimit,
          })}
        </HeaderStyled>
        <CenteredText type="p">
          {t(`content.modals.ideasLimitReached.free.description`)}
        </CenteredText>
        <ListStyled>
          <ListItemStyled>
            <CheckmarkIconStyled />
            {t(`content.modals.ideasLimitReached.free.${modalType}.listItem1`)}
          </ListItemStyled>
          <ListItemStyled>
            <CheckmarkIconStyled />
            {t(`content.modals.ideasLimitReached.free.${modalType}.listItem2`)}
          </ListItemStyled>
          <ListItemStyled>
            <CheckmarkIconStyled />
            {t(`content.modals.ideasLimitReached.free.${modalType}.listItem3`)}
          </ListItemStyled>
        </ListStyled>
        {modalActionButtons(modalType)}
        {modalType === 'trial' && (
          <FooterStyled type="p">
            {t('content.modals.ideasLimitReached.free.trial.footer')}
          </FooterStyled>
        )}
      </>
    )
  }

  const getModalContent = (modalType: string): JSX.Element => {
    switch (modalType) {
      case 'paid':
        return paidContent()
      case 'trial':
      case 'upgrade':
        return freeContent(modalType)
      default:
        return paidContent()
    }
  }

  return (
    <SimpleModal closeAction={(): void => onClose(false)}>
      <ModalWrapperStyled>
        <HeroImage>
          <Image
            src="https://buffer-publish.s3.amazonaws.com/images/consumption-limit-banner2x.jpg"
            alt="Consumption limits reached"
            width="198px"
          />
        </HeroImage>
        {getModalContent(type)}
      </ModalWrapperStyled>
    </SimpleModal>
  )
}
