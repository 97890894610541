import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Text, Button, Link } from '@bufferapp/ui'
import { Image } from '@bufferapp/components'

const Wrapper = styled.div`
  height: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'height' does not exist on type 'ThemedSt... Remove this comment to see the full error message
    props.height ? props.height : '70vh'};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`
const Emoji = styled(Text)`
  font-size: 24px;
`

const TextContainer = styled.div`
  width: 400px;
`

const Subtitle = styled(Text)`
  margin: 0;
`

const Title = styled(Text)`
  margin: 0 0 8px;
`

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 24px;
`

const ButtonStyled = styled(Button)`
  margin-left: 4px;
  margin-right: 4px;
`

const EmptyState = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'title' implicitly has an 'any' ty... Remove this comment to see the full error message
  title,
  // @ts-expect-error TS(7031) FIXME: Binding element 'subtitle' implicitly has an 'any'... Remove this comment to see the full error message
  subtitle,
  // @ts-expect-error TS(7031) FIXME: Binding element 'emoji' implicitly has an 'any' ty... Remove this comment to see the full error message
  emoji,
  // @ts-expect-error TS(7031) FIXME: Binding element 'heroImg' implicitly has an 'any' ... Remove this comment to see the full error message
  heroImg,
  // @ts-expect-error TS(7031) FIXME: Binding element 'heroImgSize' implicitly has an 'a... Remove this comment to see the full error message
  heroImgSize,
  // @ts-expect-error TS(7031) FIXME: Binding element 'heroImgAlt' implicitly has an 'an... Remove this comment to see the full error message
  heroImgAlt,
  // @ts-expect-error TS(7031) FIXME: Binding element 'height' implicitly has an 'any' t... Remove this comment to see the full error message
  height,
  // @ts-expect-error TS(7031) FIXME: Binding element 'primaryAction' implicitly has an ... Remove this comment to see the full error message
  primaryAction,
  // @ts-expect-error TS(7031) FIXME: Binding element 'secondaryAction' implicitly has a... Remove this comment to see the full error message
  secondaryAction,
  // @ts-expect-error TS(7031) FIXME: Binding element 'link' implicitly has an 'any' typ... Remove this comment to see the full error message
  link,
}) => {
  return (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <Wrapper height={height}>
      {emoji && <Emoji type="h3">{emoji}</Emoji>}
      {heroImg && (
        <Image
          marginBottom="24px"
          alt={heroImgAlt}
          src={heroImg}
          width={heroImgSize?.width}
          height={heroImgSize?.height}
        />
      )}
      <TextContainer>
        {title && <Title type="h2">{title}</Title>}
        {subtitle && <Subtitle type="p">{subtitle}</Subtitle>}
      </TextContainer>
      <ButtonWrapper>
        {secondaryAction && (
          <ButtonStyled
            type="secondary"
            icon={secondaryAction.icon ?? null}
            onClick={secondaryAction.onClick}
            label={secondaryAction.label}
          />
        )}
        {primaryAction && (
          <ButtonStyled
            type="primary"
            icon={primaryAction.icon ?? null}
            iconEnd={primaryAction.iconEnd ?? null}
            onClick={primaryAction.onClick}
            label={primaryAction.label}
          />
        )}
        {link && (
          <Link newTab href={link.href}>
            {link.label}
          </Link>
        )}
      </ButtonWrapper>
    </Wrapper>
  )
}

EmptyState.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  heroImg: PropTypes.string,
  heroImgAlt: PropTypes.string,
  heroImgSize: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }),
  height: PropTypes.string,
  emoji: PropTypes.string,
  primaryAction: PropTypes.shape({
    onClick: PropTypes.func,
    label: PropTypes.string,
    icon: PropTypes.node,
    iconEnd: PropTypes.bool,
  }),
  secondaryAction: PropTypes.shape({
    onClick: PropTypes.func,
    label: PropTypes.string,
    icon: PropTypes.node,
  }),
  link: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string,
  }),
}

EmptyState.defaultProps = {
  title: null,
  subtitle: null,
  heroImg: null,
  heroImgAlt: null,
  heroImgSize: null,
  height: null,
  emoji: null,
  primaryAction: null,
  secondaryAction: null,
  link: null,
}

export default EmptyState
