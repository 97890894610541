import type { PublishingPost } from '~publish/gql/graphql'
import type { CalendarFilter } from '~publish/legacy/calendar/components/TypeFilter'

export function filterPost({
  post,
  filter,
}: {
  post?: PublishingPost
  filter: CalendarFilter
}): PublishingPost | undefined {
  if (!post) {
    return undefined
  }
  if (filter.type) {
    if (!filter.type.includes(post.type)) {
      return undefined
    }
  }

  if (filter.status) {
    if (!(filter.status as string[]).includes(post.status)) {
      return undefined
    }
  }

  if (typeof filter.needsApproval === 'boolean') {
    if (filter.needsApproval !== post.needsApproval) {
      return undefined
    }
  }

  return post
}
