// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'micr... Remove this comment to see the full error message
import RPCClient from 'micro-rpc-client'

const rpc = new RPCClient({
  url: '/rpc',
  sendCredentials: 'same-origin',
})

// @ts-expect-error TS(7006) FIXME: Parameter 'methodName' implicitly has an 'any' typ... Remove this comment to see the full error message
export default function callRpc(methodName, args) {
  return rpc.call(methodName, args)
}
