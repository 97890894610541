import React, { type CSSProperties } from 'react'

import { type IconSize, SimpleSpinner } from '@buffer-mono/popcorn'

import styles from './Loading.module.css'

export const Loading = ({
  style,
  size = 'medium',
}: {
  style?: CSSProperties
  size?: IconSize
}): JSX.Element => (
  <div className={styles.loading} style={style} role="progressbar">
    <SimpleSpinner size={size} />
  </div>
)
