import React from 'react'
import { Link } from 'react-router-dom'
import {
  ChannelAvatar,
  type ChannelAvatarProps,
  ClockIcon,
  PinIcon,
  Text,
  Tooltip,
} from '@buffer-mono/popcorn'

import type { PostStatus } from '~publish/gql/graphql'
import { channel as channelPage } from '~publish/legacy/routes'

import { usePostData } from '../PostCardContext'
import { TextSeparator } from './TextSeparator'
import { ChannelName } from './ChannelName'
import { PostDueDate } from './PostDueDate'
import { PostVia } from './PostVia'

import styles from './PostCardHeader.module.css'

export const PostCardHeader = (): JSX.Element | null => {
  const { channel, status, isCustomScheduled, isPinned, dueAt } = usePostData()
  const channelLink = channelPage.getRoute(channel.id, {
    tab: mapStatusToTab(status),
  })

  return (
    <header className={styles.wrapper}>
      <Link to={channelLink} className={styles.avatar}>
        <ChannelAvatar
          size="medium"
          alt={`${channel.name}, ${channel.service}`}
          src={channel.avatar ?? undefined}
          channel={channel.service as ChannelAvatarProps['channel']}
        />
      </Link>
      <ChannelName />

      <div className={styles.info}>
        <PostDueDate
          date={dueAt}
          status={status}
          channelTimeZone={channel.timezone}
        />
        {isCustomScheduled && (
          <>
            <TextSeparator />
            <Tooltip content="Post scheduled for a custom time outside of fixed posting times of the channel queue">
              <Text className={styles.addon}>
                <ClockIcon size="xsmall" />
                Custom
              </Text>
            </Tooltip>
          </>
        )}
        {isPinned && (
          <>
            <TextSeparator />
            <Text className={styles.addon}>
              <PinIcon size="xsmall" />
              Pinned
            </Text>
          </>
        )}
        <PostVia />
      </div>
    </header>
  )
}

PostCardHeader.displayName = 'PostCardHeader'

type TabType = 'drafts' | 'approvals' | 'queue' | 'sentPosts'
function mapStatusToTab(status: PostStatus): TabType {
  const statusToTabIdMap: Partial<Record<PostStatus, TabType>> = {
    draft: 'drafts',
    needs_approval: 'approvals',
    scheduled: 'queue',
    sending: 'queue',
    sent: 'sentPosts',
    error: 'queue',
  }

  return statusToTabIdMap[status] ?? 'queue'
}
