import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'
import { logError } from '~publish/legacy/utils/logError'
import { getMediaTypeFromMime } from '~publish/legacy/uploads/utils'
import type { LocalFile } from '~publish/legacy/uploads/values/LocalFile'
import type { Upload } from '~publish/legacy/uploads/entities/Upload'
import { uploadsLogger } from '../../loggers'
import type { Uploader } from '../Uploader'

type CommonFields = {
  product: string
  clientName: string
  uploaderId: string
  organizationId: string
  source: string
  trigger: string
  uploadTrackingId: string
  uploadExtension: string
  uploadMediaType: string
  uploadSize: number
}

export const addUploaderTracking = (uploader: Uploader): void => {
  const getCommonFields = (file: LocalFile | Upload): CommonFields => ({
    product: 'publish',
    clientName: 'publishWeb',
    uploaderId: uploader.getId(),
    organizationId: uploader.getOrganizationId(),
    source: `${file.meta?.source?.name}`,
    trigger: `${file.meta?.source?.trigger}`,
    uploadTrackingId: `${file.meta?.trackingId}`,
    uploadExtension: file.extension,
    uploadMediaType: getMediaTypeFromMime(file.type),
    uploadSize: file.size,
  })

  // Error tracking in Bugsnag
  uploader.on('file-fetch-failed', (remoteFile, error) => {
    logError(error, { metaData: { remoteFile } })
  })

  // Tracking in Segment
  uploader.on('file-upload-attempted', (files) => {
    files.forEach((file) => {
      BufferTracker.uploadAttempted(getCommonFields(file))
    })
  })

  uploader.on('upload-prevented', (error, files = []) => {
    if (files.length === 0) {
      error.message = `Upload prevented without files: ${error.message}`
      uploader.reportError(error, {})
      return
    }

    files.forEach((file) => {
      BufferTracker.uploadValidationFailed({
        ...getCommonFields(file),
        uploadValidationError: error.message,
      })
    })
  })

  uploader.on('file-validation-failed', (file, error) => {
    BufferTracker.uploadValidationFailed({
      ...getCommonFields(file),
      uploadValidationError: error.message,
    })
  })

  uploader.on('upload-started', (upload) => {
    uploadsLogger('tracking for upload-started', upload)
    BufferTracker.uploadStarted(getCommonFields(upload))
  })

  uploader.on('upload-finished', (upload) => {
    uploadsLogger('tracking for upload-finished', upload)
    BufferTracker.uploadCompleted({
      ...getCommonFields(upload),
      uploadId: upload.uploadId,
      uploadDuration: upload.videoMetadata?.durationMs ?? undefined,
    })
  })

  uploader.on('upload-failed', (upload, error) => {
    // Add error tracking in Bugsnag
    uploader.reportError(error, upload)

    BufferTracker.uploadFailed({
      ...getCommonFields(upload),
      uploadError: error.message,
    })
  })
}
