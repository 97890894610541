import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Notice } from '@buffer-mono/popcorn'

import { RegisteredNotice } from '~publish/components/RegisteredNotice'
import { useSelectedChannel } from './ChannelContext'
import styles from './QueuePausedNotice.module.css'

export const QueuePausedNotice = (): JSX.Element => {
  const selectedChannel = useSelectedChannel()
  const isManager = selectedChannel.accessLevel === 'fullPosting'
  const noticeText = isManager
    ? "None of your posts will go out, and you can't re-order posts in your queue."
    : "Unfortunately you don't have permission to unpause it."

  const canRender = selectedChannel?.isQueuePaused

  return (
    <RegisteredNotice id={'queue-paused-notice'} canRender={canRender}>
      <Notice variant="warning">
        <Notice.Heading>Your queue is currently paused.</Notice.Heading>
        <Notice.Text>
          {noticeText}{' '}
          <a
            href="https://support.buffer.com/article/515-pausing-your-queue?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.learnMorePausedQueue}
          >
            Learn more
          </a>
        </Notice.Text>

        {isManager && (
          <Notice.Actions>
            <Button
              variant="warning"
              as={Link}
              to={`/channels/${selectedChannel.id}/settings?tab=general`}
            >
              Resume Queue
            </Button>
          </Notice.Actions>
        )}
      </Notice>
    </RegisteredNotice>
  )
}
