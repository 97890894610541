import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { NavTag } from '~publish/legacy/shared-components'
import { getURL } from '~publish/legacy/utils/formatters'
import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'
import { useAccount } from '~publish/legacy/accountContext'
import { isFreeUser } from '~publish/helpers/user'

import {
  CircleIcon,
  SidebarItem,
  ListItemContainer,
  StyledAddIcon,
} from './style'

export type AddChannelButtonProps = {
  label?: string
  reachedChannelLimit?: boolean
}

const AddChannelButton = ({
  label,
  reachedChannelLimit,
}: AddChannelButtonProps): JSX.Element => {
  const { account } = useAccount()
  const billing = account?.currentOrganization?.billing
  const commonTrackingProperties =
    account?.currentOrganization?.commonTrackingProperties || null
  const organizationId = account?.currentOrganization?.id || null
  const isFree = isFreeUser(account)

  const handleAddChannelClick = (): void => {
    if (organizationId) {
      BufferTracker.sidebarAddChannelClicked({
        organizationId,
        currentChannelQuantity:
          account?.currentOrganization?.channels?.length || 0,
        isAtPlanLimit: reachedChannelLimit || null,
        atEventBillingState:
          commonTrackingProperties?.atEventBillingState || null,
        atEventBillingPlan:
          commonTrackingProperties?.atEventBillingPlan || null,
        atEventBillingCycle:
          commonTrackingProperties?.atEventBillingCycle || null,
        atEventBillingGateway:
          commonTrackingProperties?.atEventBillingGateway || null,
        atEventIsNewBuffer:
          commonTrackingProperties?.atEventIsNewBuffer || null,
        atEventChannelQty: commonTrackingProperties?.atEventChannelQty || null,
        hasPaymentDetails: billing?.paymentDetails?.hasPaymentDetails || null,
      })
    }

    if (isFree && reachedChannelLimit) {
      BufferTracker.ctaClicked({
        organizationId: account?.currentOrganization?.id || '',
        cta: 'publish-sidebar-channels-addChannels-1',
        upgradePathName: 'addChannel-upgrade',
        product: 'publish',
        clientName: 'publishWeb',
        ...commonTrackingProperties,
      })
    }

    window.location.assign(getURL.getChannelConnectURL())
  }

  useEffect(() => {
    if (isFree && reachedChannelLimit) {
      BufferTracker.ctaViewed({
        organizationId: account?.currentOrganization?.id || null,
        cta: 'publish-sidebar-channels-addChannels-1',
        currentChannelQuantity:
          account?.currentOrganization?.channels?.length || 0,
        isAtPlanLimit: true,
        product: 'publish',
        upgradePathName: 'addChannel-upgrade',
        ...commonTrackingProperties,
      })
    }
  }, [
    account?.currentOrganization,
    reachedChannelLimit,
    commonTrackingProperties,
    isFree,
  ])

  return (
    <ListItemContainer>
      <SidebarItem
        title={label}
        onItemClick={(): void => handleAddChannelClick()}
        /* eslint-disable no-nested-ternary */
        badges={reachedChannelLimit && <NavTag type="paywall" />}
        icon={
          <CircleIcon>
            <StyledAddIcon />
          </CircleIcon>
        }
        /* eslint-enable no-nested-ternary */
      />
    </ListItemContainer>
  )
}

AddChannelButton.propTypes = {
  label: PropTypes.string,
  reachedChannelLimit: PropTypes.bool,
}

AddChannelButton.defaultProps = {
  label: 'New Channel',
  reachedChannelLimit: true,
}

export default AddChannelButton
