import React from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import Masonry from 'react-masonry-css'
import {
  EmptyState,
  PlusIcon,
  Button,
  CriticalIcon,
  VisuallyHidden,
  Link,
} from '@buffer-mono/popcorn'

import { graphql } from '~publish/gql'
import type { FilterBy } from '~publish/gql/graphql'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import { useInfiniteScrollPagination } from '~publish/hooks/useInfiniteScrollPagination'
import type { Idea as IdeaType } from '~publish/pages/Create/types'
import { useAppSelector } from '~publish/legacy/store'

import { IdeaCard, type IdeaCardSkeletonProps } from '../IdeaCard'
import { useDeleteIdea } from '../../hooks/useDeleteIdea'

import styles from './IdeasGallery.module.css'
import { CreateUploadDropzone } from '../CreateUploadDropzone'

export const GetIdeasGallery = graphql(/* GraphQL */ `
  query GetIdeasGallery(
    $first: Int!
    $after: String
    $filter: FilterBy
    $organizationId: ID!
  ) {
    ideasV2(
      input: { organizationId: $organizationId, filter: $filter }
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          ...IdeaCard_Idea
        }
        cursor
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`)

// this is to keep the same order of skeletons between rerenders
const skeletonAssortment: Array<IdeaCardSkeletonProps['variant']> = [
  3, 1, 2, 4, 3, 1, 2, 3, 2, 1, 4, 1, 3,
]

const renderIdeaSkeletons = (length: number): JSX.Element[] => {
  const ideaSkeletons = Array.from(
    { length },
    (_, index) => skeletonAssortment[index % skeletonAssortment.length],
  )
  return ideaSkeletons.map((variant, index) => (
    <IdeaCard.Skeleton key={index} variant={variant} />
  ))
}

const IDEAS_PER_PAGE = 40

// TODO: move filter to use distinct type in GraphQL query
const getTagFilterString = (tagIds?: string[]): FilterBy | null => {
  if (!tagIds || !tagIds.length) {
    return null
  }

  return {
    by: 'tags',
    value: tagIds.join(','),
  }
}

export const IdeasGallery = ({
  onCreateNewIdea,
  onEditIdea,
  onFileDrop,
}: {
  onCreateNewIdea: () => void
  onEditIdea: (idea?: IdeaType, source?: string) => void
  onFileDrop?: (files: File[], groupId?: string) => void
}): JSX.Element => {
  // todo: exract separate hook to get org iD
  const organizationId: string = useAppSelector(selectCurrentOrganizationId)
  const [selectedTagIds] = useQueryParam<string[]>('tagIds')

  const deleteIdea = useDeleteIdea()

  const { data, loading, error, fetchMore, networkStatus } = useQuery(
    GetIdeasGallery,
    {
      variables: {
        first: IDEAS_PER_PAGE,
        organizationId,
        filter: getTagFilterString(selectedTagIds),
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  )

  const [lastElementRef] = useInfiniteScrollPagination({
    loading: loading || networkStatus === NetworkStatus.fetchMore,
    hasNextPage: data?.ideasV2.pageInfo.hasNextPage,
    fetchMore: () =>
      fetchMore({
        variables: {
          after: data?.ideasV2.pageInfo.endCursor,
        },
      }),
  })

  if (loading) {
    return (
      <div className={styles.wrapper}>
        <VisuallyHidden role="progressbar">Loading ideas...</VisuallyHidden>
        <IdeasMasonryLayout>
          {renderIdeaSkeletons(IDEAS_PER_PAGE)}
        </IdeasMasonryLayout>
      </div>
    )
  }

  if (error || !data) {
    return (
      <EmptyState>
        <EmptyState.Icon variant="critical">
          <CriticalIcon />
        </EmptyState.Icon>
        <EmptyState.Heading>Error while loading ideas</EmptyState.Heading>
        <EmptyState.Description>
          Try to refresh the page, contact our team if the problem persists.
        </EmptyState.Description>
      </EmptyState>
    )
  }

  const ideas = data.ideasV2.edges.map(({ node: idea }) => idea)
  const hasNextPage = data.ideasV2.pageInfo.hasNextPage

  if (!ideas.length) {
    return (
      <CreateUploadDropzone onDrop={onFileDrop}>
        <EmptyState className={styles.emptyStateWrapper} size="xlarge">
          <EmptyState.Illustration src="https://buffer-publish.s3.amazonaws.com/images/empty-ideas-gallery-illustration.png" />
          <EmptyState.Heading>A space to save your ideas</EmptyState.Heading>
          <EmptyState.Description>
            Create a new Idea or drag and drop images or videos here.
          </EmptyState.Description>
          <EmptyState.Actions>
            <Button size="large" onClick={onCreateNewIdea}>
              <PlusIcon /> New Idea
            </Button>
          </EmptyState.Actions>
          <Link
            href="https://support.buffer.com/article/589-creating-ideas-in-buffer"
            external
          >
            Learn about Ideas
          </Link>
        </EmptyState>
      </CreateUploadDropzone>
    )
  }

  return (
    <CreateUploadDropzone onDrop={onFileDrop}>
      <div
        className={styles.wrapper}
        role="feed"
        aria-busy={networkStatus === NetworkStatus.fetchMore}
      >
        <IdeasMasonryLayout>
          {ideas.map((idea, index) => (
            <IdeaCard
              key={idea.id}
              // add ref to last element to trigger pagination
              ref={
                hasNextPage && index === ideas.length - 1
                  ? lastElementRef
                  : null
              }
              idea={idea as IdeaType}
              onOpen={(idea): void => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error Idea and IdeaFragment are not compatible because of null
                return onEditIdea(idea, 'create-gallery-ideaCard-card-1')
              }}
              onDelete={(idea): Promise<void> => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error Idea and IdeaFragment are not compatible because of null
                return deleteIdea(idea, {
                  source: 'create-gallery-ideaCard-delete-1',
                })
              }}
            />
          ))}
          {hasNextPage && renderIdeaSkeletons(IDEAS_PER_PAGE)}
        </IdeasMasonryLayout>
      </div>
    </CreateUploadDropzone>
  )
}

IdeasGallery.displayName = 'IdeasGallery'

const IdeasMasonryLayout = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  return (
    // TODO: Masonry implementation creates unnatural focus order
    // we need to either use different layout or change the implemanetation
    // to calculate positions with javascript
    <Masonry
      breakpointCols={{
        default: 7,
        1800: 6,
        1580: 5,
        1300: 4,
        1120: 3,
        860: 2,
        560: 1,
      }}
      className={styles.masonry}
      columnClassName={styles.column}
    >
      {children}
    </Masonry>
  )
}

IdeasMasonryLayout.displayName = 'IdeasMasonryLayout'
