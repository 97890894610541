import { type ApolloError, useMutation } from '@apollo/client'
import { useCallback } from 'react'

import { graphql } from '~publish/gql'
import type { IdeaUpdateInput, UpdateIdeaMutation } from '~publish/gql/graphql'

import type { Idea } from '../types'
import { UNASSIGNED_GROUP_ID } from '../components/Board/helpers/helpers'

const UPDATE_IDEA = graphql(/* GraphQL */ `
  mutation UpdateIdea($input: UpdateIdeaInput!) {
    updateIdea(input: $input) {
      __typename
      ... on IdeaResponse {
        idea {
          id
          content {
            title
            text
            aiAssisted
            media {
              id
              url
              alt
              thumbnailUrl
              type
              size
              source {
                name
                id
              }
            }
            tags {
              id
              name
              color
            }
          }
          organizationId
          groupId
          position
          createdAt
        }
      }
      ... on MutationError {
        message
      }
    }
  }
`)

// HACK: this is a temporary solution to avoid sending the whole tag object to the server
// because types are not complete, data is spread and in some cases __typename is sent
// which is not allowed by the server, hence mutation fails
const transformIdeaForMutation = (
  idea: Idea,
  source?: string,
): IdeaUpdateInput => {
  return {
    ...idea,
    content: {
      ...idea.content,
      tags: idea.content.tags?.map((tag) => ({
        id: tag.id,
        name: tag.name,
        color: tag.color,
      })),
    },
    groupId: idea.groupId === UNASSIGNED_GROUP_ID ? null : idea.groupId,
    cta: source,
  }
}

export const useEditIdea = (): {
  updateIdea: (idea: Idea, options?: { source?: string }) => void
  updateIdeaData?: UpdateIdeaMutation | null
  updateIdeaError?: ApolloError
  updateIdeaLoading: boolean
} => {
  const [
    executeUpdateIdea,
    {
      data: updateIdeaData,
      error: updateIdeaError,
      loading: updateIdeaLoading,
    },
  ] = useMutation(UPDATE_IDEA, {})

  const updateIdea = useCallback(
    (idea: Idea, options?: { source?: string }) => {
      executeUpdateIdea({
        variables: {
          input: transformIdeaForMutation(idea, options?.source),
        },
      })
    },
    [executeUpdateIdea],
  )

  return { updateIdea, updateIdeaData, updateIdeaError, updateIdeaLoading }
}
