import { actionTypes as dataFetchActionTypes } from '@bufferapp/async-data-fetch'
import keyWrapper from '~publish/helpers/keywrapper'
import { actionTypes as profileActionTypes } from '~publish/legacy/profile-sidebar/reducer'
import { changeLinkShortener } from './thunks/changeLinkShortener'
import { getGoogleAnalyticsTrackingSettings } from './thunks/getGoogleAnalyticsTrackingSettings'
import { saveGoogleAnalyticsTrackingSettings } from './thunks/saveGoogleAnalyticsTrackingSettings'
import type { LinkShortener } from './types'

export const actionTypes = keyWrapper('GENERAL_SETTINGS', {
  CHANGE_SELECTED_LINK_SHORTENER: 0,
  TOGGLE_INSTAGRAM_REMINDERS: 0,
})

export type TrackingSettings = {
  utmCampaign: string
  utmMedium: string
  utmSource: string
}

export type GeneralSettingsState = {
  supportsMobileReminders: boolean
  isRemindersOnly: boolean
  googleAnalyticsEnabled: boolean
  remindersAreEnabled: boolean
  linkShortening: {
    linkShorteners: LinkShortener[]
    isBitlyConnected: boolean
  }
  trackingSettings?: TrackingSettings
}

const initialState: GeneralSettingsState = {
  supportsMobileReminders: false,
  isRemindersOnly: false,
  googleAnalyticsEnabled: false,
  remindersAreEnabled: false,
  linkShortening: {
    linkShorteners: [],
    isBitlyConnected: false,
  },
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action): GeneralSettingsState => {
  switch (action.type) {
    case profileActionTypes.SELECT_PROFILE:
      return {
        ...state,
        supportsMobileReminders:
          action.profile.service === 'instagram' ||
          // action.profile.service === 'tiktok' ||
          (action.profile.service === 'facebook' &&
            action.profile.service_type === 'group'),
        isRemindersOnly:
          action.profile.service === 'facebook' &&
          action.profile.service_type === 'group',
        googleAnalyticsEnabled:
          action.profile.googleAnalyticsEnabled === 'enabled',
        linkShortening: {
          linkShorteners: [],
          isBitlyConnected: false,
        },
        remindersAreEnabled: !action.profile.directPostingEnabled,
      }
    case `getLinkShortener_${dataFetchActionTypes.FETCH_START}`:
      return {
        ...state,
        linkShortening: {
          linkShorteners: [],
          isBitlyConnected: false,
        },
      }
    case `${changeLinkShortener.fulfilled}`:
    case `getLinkShortener_${dataFetchActionTypes.FETCH_SUCCESS}`: {
      const linkShorteners = changeLinkShortener.fulfilled.match(action)
        ? action.payload
        : action.result.linkShorteners
      return {
        ...state,
        linkShortening: {
          linkShorteners,
          isBitlyConnected: linkShorteners.some(
            (linkShortener: LinkShortener) =>
              linkShortener.selected && linkShortener.domain === 'bit.ly',
          ),
        },
      }
    }
    case `${getGoogleAnalyticsTrackingSettings.fulfilled}`: {
      return {
        ...state,
        trackingSettings: action.payload,
      }
    }
    case `${saveGoogleAnalyticsTrackingSettings.fulfilled}`: {
      return {
        ...state,
        trackingSettings: {
          utmCampaign: action.payload.utmCampaign,
          utmMedium: action.payload.utmMedium,
          utmSource: action.payload.utmSource,
        },
      }
    }
    case `toggleInstagramReminders_${dataFetchActionTypes.FETCH_START}`:
      return {
        ...state,
        remindersAreEnabled: action.args.allowReminders,
      }
    case `toggleInstagramReminders_${dataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...state,
        remindersAreEnabled: !state.remindersAreEnabled,
      }
    default:
      return state
  }
}

export const actions = {
  // @ts-expect-error TS(7031) FIXME: Binding element 'profileId' implicitly has an 'any... Remove this comment to see the full error message
  handleOnSelectLinkShortenerChange: ({ profileId, domain }) => ({
    type: actionTypes.CHANGE_SELECTED_LINK_SHORTENER,
    profileId,
    domain,
  }),
  // @ts-expect-error TS(7031) FIXME: Binding element 'profileId' implicitly has an 'any... Remove this comment to see the full error message
  handleOnShortenerChanged: ({ profileId, result }) => ({
    type: `changeLinkShortener_${dataFetchActionTypes.FETCH_SUCCESS}`,
    profileId,
    result,
  }),

  // @ts-expect-error TS(7031) FIXME: Binding element 'profileId' implicitly has an 'any... Remove this comment to see the full error message
  handleRemindersToggle: ({ profileId, allowReminders }) => ({
    type: actionTypes.TOGGLE_INSTAGRAM_REMINDERS,
    profileId,
    allowReminders,
  }),
}
