import { useDispatch } from 'react-redux'
import { graphql } from '~publish/gql'
import type {
  CalendarView_PostFragment,
  GetCalendarAndPostListQuery,
  PublishingPost,
} from '~publish/gql/graphql'
import { postsReceived } from '~publish/legacy/post/slice'

export type PostFragment = CalendarView_PostFragment

export const CalendarView_Post = graphql(/* GraphQL */ `
  fragment CalendarView_Post on PublishingPost {
    id
    type
    text
    status
    error {
      message
      link
      actionType
    }
    serviceLink
    via
    isReminder
    isCustomScheduled
    isPinned
    formattedTime
    isRetweet
    dueAt
    title
    createdBy
    needsApproval
    sourceUrl
    isBlueprint
    updateType
    threadCount
    channelData {
      googlebusiness {
        start_date
        end_date
        start_time
        end_time
        title
        code
        link
        terms
      }
      startPage {
        link
      }
      youtube {
        title
      }
      instagram {
        link
        service_geolocation_name
      }
      pinterest {
        is_repin
      }
    }
    statistics {
      retweets
      comments
      likes
      favorites
      mentions
      clicks
      reach_twitter
      reach
      shares
      reshares
      repins
      linkClicks
      views
      reactions
      impressions
      saves
      quotes
      replies
      reposts
    }
    geoLocation {
      id
      text
    }
    channel {
      id
      service
      avatar
      username
      serverUrl
      locationData {
        location
      }
    }
    subprofile {
      id
      name
    }
    attachments {
      ... on PublishingPostLink {
        url
        title
        description
        thumbnail
      }
      ... on PublishingPostRetweet {
        avatar
        accountName
        username
        text
        url
        sentAt
      }
      ... on PublishingPostFirstComment {
        text
      }
      ... on PublishingPostDestinationUrl {
        url
      }
    }
    media {
      thumbnail
      type
    }
    campaign {
      name
      color {
        value
      }
    }
    tags {
      id
      name
      color
    }
  }
`)

export const useOnUpdatePostsList = (): ((
  calendarData: GetCalendarAndPostListQuery,
) => void) => {
  // TODO if you can make these types work automatically, please feel free to do so!
  // For some reason I need to manually check typenames and cast :(
  const dispatch = useDispatch()
  return (calendarData: GetCalendarAndPostListQuery): void => {
    const { calendar } = calendarData
    if (calendar && calendar.__typename === 'Calendar') {
      const { posts } = calendar
      const publishingPosts = (posts ?? []).map(
        (post) => post as PublishingPost,
      ) as PublishingPost[]
      dispatch(postsReceived(publishingPosts))
    }
  }
}
