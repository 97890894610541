import { Button, PromotionalBanner } from '@buffer-mono/popcorn'
import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'
import React, { useEffect, useState } from 'react'
import { useAccount } from '~publish/legacy/accountContext'
import { SEGMENT_NAMES } from '~publish/legacy/constants'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import useDismissBanner, {
  BannerTypes,
} from '~publish/legacy/profile-page/hooks/useDismissBanner'
import { useAppSelector } from '~publish/legacy/store'

export const CollaborationUpgradePathBanner = (): JSX.Element | null => {
  const [dismissed, setDismissed] = useState(false)
  const organizationId = useAppSelector((state) =>
    selectCurrentOrganizationId(state),
  )

  const { account } = useAccount()
  const commonTrackingProperties =
    account?.currentOrganization?.commonTrackingProperties || null

  const { onDismissBanner } = useDismissBanner({
    banner: BannerTypes.collaborationUpgradePath,
  })

  const onDismissClicked = (): void => {
    BufferTracker.bannerDismissed({
      organizationId,
      clientName: 'publishWeb',
      product: 'publish',
      bannerName: 'collaborationUpgradePathBanner',
    })
    onDismissBanner()
    setDismissed(true)
  }

  useEffect(() => {
    BufferTracker.ctaViewed({
      organizationId,
      product: 'publish',
      cta: `publish-${SEGMENT_NAMES.DRAFTS_BANNER_UPGRADE}`,
      upgradePathName: 'approvals-upgrade',
      ...commonTrackingProperties,
    })
  })

  if (dismissed) return null

  return (
    <PromotionalBanner onDismiss={onDismissClicked}>
      <PromotionalBanner.Content>
        <PromotionalBanner.Heading>
          You don&apos;t have to work alone
        </PromotionalBanner.Heading>
        <PromotionalBanner.Description>
          Collaborate on drafts and review posts before publish!
        </PromotionalBanner.Description>
        <PromotionalBanner.Actions>
          <Button
            onClick={(): void => {
              const { MODALS, actions } = window?.appshell || {}
              actions.openModal(MODALS.planSelector, {
                cta: SEGMENT_NAMES.DRAFTS_BANNER_UPGRADE,
                upgradePathName: 'approvals-upgrade',
              })
              BufferTracker.ctaClicked({
                organizationId,
                clientName: 'publishWeb',
                product: 'publish',
                cta: `publish-${SEGMENT_NAMES.DRAFTS_BANNER_UPGRADE}`,
                upgradePathName: 'approvals-upgrade',
                ...commonTrackingProperties,
              })
            }}
          >
            Upgrade to Invite Your Team
          </Button>
        </PromotionalBanner.Actions>
      </PromotionalBanner.Content>
      <PromotionalBanner.Image src="https://buffer-publish.s3.amazonaws.com/images/collaboration-banner.png" />
    </PromotionalBanner>
  )
}
