import { useState } from 'react'
import { type DocumentNode, useMutation } from '@apollo/client'
import type {
  CalendarView_PostFragment,
  UpdatePostDueAtMutation,
  GetCalendarAndPostListQuery,
} from '~publish/gql/graphql'
import { UpdatePostDueAt, getViewPostFragment } from './useUpdatePostDueAt'
import {
  GetCalendarAndPostList,
  asCalendar,
  type QueryVariables,
} from './useCalendarAndPostsList'
import getFormattedTime from '~publish/pages/Calendar/util/getFormattedTime'

// Keep the new post list due at date in sync with the cache
const updatePostDueAtCache = (
  edges: GetCalendarAndPostListQuery['posts']['edges'],
  postId: string,
  updatedDueAt: number | null | undefined,
): GetCalendarAndPostListQuery['posts']['edges'] => {
  if (!edges) return edges

  return edges.map((edge) => ({
    ...edge,
    node: {
      ...edge.node,
      dueAt:
        edge.node.id === postId && updatedDueAt
          ? new Date(updatedDueAt * 1_000).toISOString()
          : edge.node.dueAt,
    },
  }))
}

type UseDropPostInSlotProps = {
  onSuccess: (post: CalendarView_PostFragment) => void
  onError: (error: { message: string } | undefined | null) => void
  variables: QueryVariables
}

type UseDropPostInSlotResponse = {
  error: { message: string } | undefined | null
  result: UpdatePostDueAtMutation | undefined | null
  updatePostDueAt: (args: {
    post: CalendarView_PostFragment
    timestamp: number
  }) => void
}

export function useDropPostInSlot({
  onSuccess,
  onError,
  variables,
}: UseDropPostInSlotProps): UseDropPostInSlotResponse {
  const [error, setError] = useState<{ message: string } | null>(null)
  const [updatePostDueAt, mutationResult] = useMutation(UpdatePostDueAt, {
    onCompleted: (data) => {
      const maybePost = getViewPostFragment(data)
      if (maybePost) {
        onSuccess(maybePost)
      }

      if (data?.updatePostDueAt?.success === false) {
        onError({ message: data?.updatePostDueAt.message })
      }
    },
    onError: (err) => {
      onError(err)
      setError(err)
    },
    update: (cache, mutationResult) => {
      const updatedPost = getViewPostFragment(mutationResult.data)
      if (!updatedPost) return
      const readData = cache.readQuery({
        query: GetCalendarAndPostList as DocumentNode,
        variables,
      }) as GetCalendarAndPostListQuery

      if (!readData) return
      const calendar = asCalendar(readData)
      if (!calendar) return

      cache.writeQuery({
        query: GetCalendarAndPostList as DocumentNode,
        variables,
        data: {
          ...readData,
          posts: updatePostDueAtCache(
            readData.posts.edges,
            updatedPost.id,
            updatedPost.dueAt,
          ),
        },
      })
    },
    refetchQueries: [GetCalendarAndPostList as DocumentNode],
  })

  return {
    error,
    result: mutationResult.data,
    updatePostDueAt: ({
      post,
      timestamp,
    }: {
      post: CalendarView_PostFragment
      timestamp: number
    }): void => {
      const formatted = getFormattedTime(
        timestamp * 1000,
        variables.timezone,
        variables.use24HourTime,
      )

      const newPost: CalendarView_PostFragment = {
        ...post,
        dueAt: timestamp,
        formattedTime: formatted,
      }

      updatePostDueAt({
        variables: { input: { postId: post.id, timestamp } },
        optimisticResponse: {
          updatePostDueAt: {
            success: true,
            message: 'Successfully updated post',
            post: newPost,
            __typename: 'PostUpdated',
          },
        },
      }).catch((e) => console.error(e))
    },
  }
}
