import React from 'react'
import Joyride, {
  ACTIONS,
  EVENTS,
  STATUS,
  type CallBackProps,
  type Step,
} from 'react-joyride'

import { useLocalStorageState } from '~publish/hooks/useLocalStorageState'

import { DialogTourIntro } from './DialogTourIntro'
import { StepCoachmark } from './StepCoachmark'

const steps: Step[] = [
  {
    target: '#tags-filter',
    title: 'Filter by Tags',
    content: 'Now you can filter your posts by tags.',
    disableBeacon: true,
    placement: 'bottom',
    offset: 0,
  },
  {
    target: '#drafts-tab',
    title: 'Organised Drafts and Approvals',
    content:
      'Drafts and Approvals are now separated by date with unscheduled at the top.',
    disableBeacon: true,
    placement: 'bottom',
    offset: 0,
    data: {
      image:
        'https://buffer-publish.s3.amazonaws.com/images/channels-tour-step-02.png',
    },
  },
  {
    target: '#settings-menu',
    title: 'Channel Settings',
    content: 'This is where you can find channel settings',
    disableBeacon: true,
    placement: 'bottom',
    offset: 0,
  },
]

export const ChannelTour = (): JSX.Element => {
  const [channelTourCurrentStep, setChannelTourCurrentStep] =
    useLocalStorageState('preference:single-channel-page-tour', '-1')
  // Temporarily commenting to avoid persisting for testing in production
  // const guide = useDismissableBanner('new-single-channel-page-tour')
  const [isDialogOpen, setDialogOpen] = React.useState(true)
  const [run, setRun] = React.useState(false)
  const [isPaused, setIsPaused] = React.useState(false)

  const [stepIndex, setStepIndex] = React.useState(
    parseInt(channelTourCurrentStep, 10),
  )

  React.useEffect(() => {
    const currentStep = parseInt(channelTourCurrentStep, 10)
    if (currentStep >= 0) {
      setRun(true)
      setDialogOpen(false)
    }
  }, [channelTourCurrentStep])

  const resetTour = (): void => {
    setChannelTourCurrentStep('-1')
    setRun(false)
    // Temporarily commenting to avoid persisting for testing in production
    // guide.dismiss()
  }

  const handleTourStart = (): void => {
    setDialogOpen(false)
    setRun(true)
    setChannelTourCurrentStep('0')
  }

  const handleDialogClose = (): void => {
    setDialogOpen(false)
    resetTour()
  }

  const joyrideCallback = (data: CallBackProps): void => {
    const { action, index, status, type } = data

    if (type === EVENTS.TOUR_STATUS && status === STATUS.RUNNING) {
      setIsPaused(false)
    }

    if (action === ACTIONS.CLOSE) {
      resetTour()
      setIsPaused(false)
    }

    if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      const newIndex = index + (action === ACTIONS.PREV ? -1 : 1)
      setStepIndex(newIndex)
      setChannelTourCurrentStep(newIndex.toString())
      setIsPaused(false)
    } else if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      resetTour()
      setIsPaused(false)
    }
  }

  const handleOverlayClick = (e: React.MouseEvent): void => {
    e.stopPropagation()
    setIsPaused(true)
  }

  const handleOverlayKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.stopPropagation()
      setIsPaused(true)
    }
  }

  const handleResumeTour = (): void => {
    setIsPaused(false)
  }

  // Temporarily commenting to avoid persisting for testing in production
  /*
  if (!guide.isActive) {
    return <></>
  }
  */

  return (
    <>
      {channelTourCurrentStep === '-1' && isDialogOpen && (
        <DialogTourIntro
          open={isDialogOpen}
          onDialogClose={handleDialogClose}
          onTourStart={handleTourStart}
        />
      )}
      <Joyride
        steps={steps}
        continuous
        showSkipButton
        run={run}
        stepIndex={stepIndex}
        callback={joyrideCallback}
        disableOverlayClose
        disableOverlay
        disableScrolling
        tooltipComponent={(props): JSX.Element =>
          isPaused ? (
            <StepCoachmark
              {...props}
              isPaused={isPaused}
              handleResumeTour={handleResumeTour}
            />
          ) : (
            <StepCoachmark
              {...props}
              open
              isPaused={isPaused}
              handleResumeTour={handleResumeTour}
            />
          )
        }
        styles={{
          options: {
            arrowColor: 'transparent',
            overlayColor: 'transparent',
          },
          tooltip: {
            padding: 0, // Remove padding to align with CoachMark
          },
        }}
      />
      {run && !isPaused && (
        <div
          id="tour-overlay"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9996,
            cursor: 'pointer',
            pointerEvents: 'auto',
          }}
          onClick={handleOverlayClick}
          onKeyDown={handleOverlayKeyDown}
          tabIndex={0}
          role="button"
          aria-label="Pause tour"
        />
      )}
    </>
  )
}
