import { createAsyncThunk } from '@reduxjs/toolkit'
import callRpc from '~publish/legacy/utils/call-rpc'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actions as notificationActions } from '@bufferapp/notifications/lib/reducer'
import { getPageNameFromPath } from '~publish/legacy/analytics-middleware/utils/Pathname'
import type { AppDispatch, RootState } from '~publish/legacy/store'
import {
  PostActionNotificationSuccess,
  PostActionNotificationError,
} from '../types'

export const assignCampaignToPost = createAsyncThunk<
  boolean,
  { postId: string; campaignId: string | null },
  { state: RootState; dispatch: AppDispatch }
>(
  'posts/assignCampaignToPost',
  async ({ postId, campaignId }, { dispatch, rejectWithValue }) => {
    try {
      const changedFrom = getPageNameFromPath(window.location.pathname) || null
      const response = await callRpc('assignCampaignToPost', {
        postId,
        campaignId,
        changedFrom,
      })

      if (!response.success) {
        throw new Error(response.message)
      }

      const message = campaignId
        ? PostActionNotificationSuccess.ASSIGN_CAMPAIGN_TO_POST
        : PostActionNotificationSuccess.REMOVE_CAMPAIGN_FROM_POST

      dispatch(
        notificationActions.createNotification({
          notificationType: 'success',
          message,
        }),
      )

      return true
    } catch (error) {
      const message = campaignId
        ? PostActionNotificationError.ASSIGN_CAMPAIGN_TO_POST
        : PostActionNotificationError.REMOVE_CAMPAIGN_FROM_POST

      dispatch(
        notificationActions.createNotification({
          notificationType: 'error',
          message,
        }),
      )

      return rejectWithValue(message)
    }
  },
)
