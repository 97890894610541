import React, { type CSSProperties } from 'react'

import { type IconSize, SimpleSpinner } from '@buffer-mono/popcorn'

import styles from './Loading.module.css'

type Props = {
  style?: CSSProperties
  size?: IconSize
}

export const Loading = React.forwardRef<HTMLDivElement, Props>(
  ({ style, size = 'medium' }, forwardedRef) => (
    <div
      className={styles.loading}
      style={style}
      role="progressbar"
      ref={forwardedRef}
    >
      <SimpleSpinner size={size} />
    </div>
  ),
)

Loading.displayName = 'Loading'
