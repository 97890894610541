import styled, { css, type FlattenSimpleInterpolation } from 'styled-components'
import { borderRadius } from '@bufferapp/ui/style/borders'
import { grayLight, gray, grayShadow, white } from '@bufferapp/ui/style/colors'
import { isDraft } from '~publish/pages/Calendar/util/isDraft'
import type { PostStatus, Tag } from '~publish/gql/graphql'

type CSSRule =
  | string
  | number
  | false
  | FlattenSimpleInterpolation
  | undefined
  | null

type GetBackgroundColorProps = {
  status: PostStatus
}

type TagColors = Pick<Tag, 'color'>

const getBackgroundColor = ({ status }: GetBackgroundColorProps): string => {
  const isADraft = isDraft(status)

  if (isADraft && status === 'needs_approval') return '#FCEFEA'
  if (isADraft) return '#EAEDFF'

  return 'inherit'
}

const getTagColoredStrip = (tags: TagColors[]): CSSRule => {
  const tagColors = tags.map((tag) => tag.color).slice(0, 3)

  let background

  switch (tagColors.length) {
    case 1:
      background = tagColors[0]
      break
    case 2:
      background = `linear-gradient(to bottom,
          ${tagColors[0]} 47%,
          ${white} 47% 53%,
          ${tagColors[1]} 53% 100%
        )`
      break
    case 3:
      background = `linear-gradient(to bottom,
          ${tagColors[0]} 30%,
          ${white} 30% 35%,
          ${tagColors[1]} 35% 65%,
          ${white} 65% 70%,
          ${tagColors[2]} 70% 00%
        )`
      break
    default:
      background = tagColors[0]
  }

  return css`
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: ${background};
    width: 4px;
    height: 100%;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  `
}

export const PostWrapper = styled.div<
  {
    small: boolean
    tags: TagColors[]
    campaignColor?: string | undefined | null
  } & GetBackgroundColorProps
>`
  border-radius: ${borderRadius};
  background-color: ${(props): CSSRule => getBackgroundColor(props)};
  padding: ${(props): CSSRule => (props.small ? '2px 0 2px 8px' : '8px')};

  &:after {
    ${(props): CSSRule =>
      props.campaignColor &&
      css`
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background-color: ${props.campaignColor};
        width: 4px;
        height: 100%;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 12px;
      `}
    ${(props): CSSRule => props.tags.length && getTagColoredStrip(props.tags)}
`

export const PostPreviewHoverWrapper = styled.div<{ canEdit: boolean }>`
  border-radius: ${borderRadius};
  border: 1px solid ${grayLight};
  box-shadow: ${grayShadow};
  box-sizing: border-box;
  margin-bottom: 8px;
  transition: all 0.3s ease-in-out;
  position: relative;

  &:hover {
    ${({ canEdit }): CSSRule =>
      canEdit &&
      css`
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 8px 24px;
        border: 1px solid ${gray};
        z-index: 1;
      `};
  }
`
