import React from 'react'
import { useTranslation } from 'react-i18next'
import Text from '@bufferapp/ui/Text'
import { Button } from '@bufferapp/ui'

import { CloseIcon } from '~publish/pages/Create/components/IdeaEditor/styles'
import {
  StyledOverlay,
  ModalWrapper,
  InnerWrapper,
  ButtonWrapper,
  StyledText,
  CloseDialogButton,
} from './styles'

const ConfirmCloseModal = ({
  onCancel,
  onConfirmClose,
}: {
  onCancel: () => void
  onConfirmClose: () => void
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <>
      <StyledOverlay />
      <ModalWrapper onPointerDownOutside={(e): void => e.preventDefault()}>
        <InnerWrapper>
          <Text type="h3">{t('content.modals.confirmClose.title')}</Text>
          <StyledText type="p">
            {t('content.modals.confirmClose.description')}
          </StyledText>
          <ButtonWrapper>
            {/* FIXME: Use Popcorn Button */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: any; onClick: () => v... Remove this comment to see the full error message */}
            <Button
              type="text"
              label={t('content.modals.confirmClose.cancel')}
              onClick={onCancel}
            />
            {/* FIXME: Use Popcorn Button */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: any; onClick: () => v... Remove this comment to see the full error message */}
            <Button
              type="danger"
              label={t('content.modals.confirmClose.confirm')}
              onClick={onConfirmClose}
            />
          </ButtonWrapper>
          <CloseDialogButton asChild>
            <CloseIcon size="large" />
          </CloseDialogButton>
        </InnerWrapper>
      </ModalWrapper>
    </>
  )
}

ConfirmCloseModal.propTypes = {}

export default ConfirmCloseModal
