import React from 'react'
import PropTypes from 'prop-types'
import TwoFactorAuth from '~publish/legacy/two-factor-auth'
import { Divider } from '@bufferapp/components'
import { Row } from '~publish/legacy/shared-components'
import { Text, Button } from '@bufferapp/ui'
import { useTranslation } from 'react-i18next'

// @ts-expect-error TS(7031) FIXME: Binding element 't' implicitly has an 'any' type.
const ChangePassword = ({ t }) => (
  <Row>
    <div
      style={{
        marginRight: '1rem',
      }}
    >
      <Text type="h3">{t('preferences.security.password')}</Text>
      <div
        style={{
          marginTop: '0.5rem',
        }}
      >
        <Text type="p">{t('preferences.security.passwordDescription')}</Text>
      </div>
    </div>
    {/* @ts-expect-error TS(2740) FIXME: Type '{ label: any; type: string; onClick: () => v... Remove this comment to see the full error message */}
    <Button
      label={t('preferences.security.changeYourPassword')}
      type="secondary"
      onClick={() =>
        window.location.assign(
          'https://account.buffer.com?redirect=https%3A%2F%2Fpublish.buffer.com',
        )
      }
    />
  </Row>
)

ChangePassword.propTypes = {
  t: PropTypes.func.isRequired,
}

const Security = () => {
  const { t } = useTranslation()

  return (
    <>
      <Text type="h2">{t('preferences.security.title')}</Text>
      <Text type="p">{t('preferences.security.addSecurity')}</Text>
      <Divider />
      <ChangePassword t={t} />
      <Divider />
      <TwoFactorAuth />
      <Divider />
    </>
  )
}

export default Security
