import { gql, useMutation } from '@apollo/client'
import { GET_ACCOUNT } from '~publish/legacy/accountContext'

const DISMISS_OBJECT = gql`
  mutation DismissObject(
    $organizationId: String!
    $dismissedObject: DismissedObjectInput!
  ) {
    addOrUpdateDismissedObject(
      organizationId: $organizationId
      dismissedObject: $dismissedObject
    ) {
      ... on AddOrUpdateDismissedObjectResponse {
        success
      }
      ... on AddOrUpdateDismissedObjectError {
        cause
        userFriendlyMessage
      }
    }
  }
`

type UseDismissObjectReturn = {
  onDismissObject: () => void
}

const useDismissObject = ({
  organizationId,
  dismissedObject,
}: {
  organizationId: string | null
  dismissedObject: { id: string; expiresAt?: string }
}): UseDismissObjectReturn => {
  const [dismissObject] = useMutation(DISMISS_OBJECT, {
    refetchQueries: [GET_ACCOUNT],
  })

  const onDismissObject = (): void => {
    if (!dismissedObject || !organizationId) {
      return
    }

    dismissObject({ variables: { organizationId, dismissedObject } })
  }

  return { onDismissObject }
}

export default useDismissObject
