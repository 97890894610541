import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { EmptyState } from '~publish/legacy/shared-components'
import {
  Layout,
  ModalContent,
  ModalBackground,
  ModalOverlay,
  ModalWrapper,
  ModalImage,
  HeaderStyled,
  TextStyled,
  ListStyled,
  ListItemStyled,
  CheckmarkIconStyled,
  ButtonStyled,
} from './styles'
import { useAccount } from '~publish/legacy/accountContext'
import { BufferTracker, Client } from '@bufferapp/buffer-tracking-browser-ts'

const UpgradePathForCreate = (): JSX.Element => {
  const { t } = useTranslation()

  const { account } = useAccount()
  useEffect(() => {
    if (account) {
      const commonTrackingProperties =
        account?.currentOrganization?.commonTrackingProperties || {}
      BufferTracker.upgradePathViewed({
        organizationId: account?.currentOrganization?.id || '',
        product: 'publish',
        upgradePathName: 'ideas-legacyUpgrade',
        clientName: Client.PublishWeb,
        ...commonTrackingProperties,
      })
    }
  }, [account])

  return (
    <Layout>
      <EmptyState
        title={t('content.upgradePath.empty.title')}
        subtitle={t('content.upgradePath.empty.subtitle')}
        heroImg="https://buffer-publish.s3.amazonaws.com/images/content-empty-ideas.svg"
        primaryAction={{
          label: t('content.upgradePath.empty.button'),
          onClick: (): void => {
            location.replace(
              'https://account.buffer.com/new-plans?cta=ideasModalBtn-migration-cta',
            )
          },
        }}
      />
      <ModalOverlay>
        <ModalBackground />
        <ModalWrapper>
          <ModalContent>
            <HeaderStyled type="h2">
              {t('content.upgradePath.modal.title')}
            </HeaderStyled>
            <TextStyled type="p">
              <Trans
                i18nKey="content.upgradePath.modal.description"
                components={[
                  <a
                    href="https://buffer.com/ideas"
                    target="_blank"
                    rel="noreferrer"
                    key="ideas-link"
                  >
                    Ideas
                  </a>,
                ]}
              />
            </TextStyled>
            <ListStyled>
              <ListItemStyled>
                <CheckmarkIconStyled />
                {t('content.upgradePath.modal.bullet1')}
              </ListItemStyled>
              <ListItemStyled>
                <CheckmarkIconStyled />
                {t('content.upgradePath.modal.bullet2')}
              </ListItemStyled>
              <ListItemStyled>
                <CheckmarkIconStyled />
                {t('content.upgradePath.modal.bullet3')}
              </ListItemStyled>
              <ListItemStyled>
                <CheckmarkIconStyled />
                {t('content.upgradePath.modal.bullet4')}
              </ListItemStyled>
              <ListItemStyled>
                <CheckmarkIconStyled />
                {t('content.upgradePath.modal.bullet5')}
              </ListItemStyled>
              <ListItemStyled>
                <CheckmarkIconStyled />
                {t('content.upgradePath.modal.bullet6')}
              </ListItemStyled>
            </ListStyled>
            {/* FIXME: This should be a link, otherwise you can't use CMD+Click */}
            <ButtonStyled
              type="primary"
              label={t('content.upgradePath.modal.button')}
              onClick={(): void => {
                location.replace(
                  'https://account.buffer.com/new-plans?cta=ideasModalBtn-migration-cta',
                )
              }}
            />
          </ModalContent>
          <ModalImage>
            <img
              alt="Upgrade plan"
              src="https://buffer-publish.s3.amazonaws.com/images/content-upgrade-path.png"
            />
          </ModalImage>
        </ModalWrapper>
      </ModalOverlay>
    </Layout>
  )
}

UpgradePathForCreate.propTypes = {}

UpgradePathForCreate.defaultProps = {}

export default UpgradePathForCreate
