// TODO: It's an established best practice to name the fragment after with an underscore,
// so disabling eslint errors for it for now. We need to figure out a better way to handle these more globally
/* eslint-disable camelcase, @typescript-eslint/naming-convention */

import React, { type HTMLAttributes } from 'react'
import clsx from 'clsx'

import styles from './MediaBadge.module.css'

export const MediaBadge = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>): JSX.Element | null => {
  return (
    <div className={clsx(styles.badge, className)} {...props}>
      {children}
    </div>
  )
}

MediaBadge.displayName = 'MediaBadge'
