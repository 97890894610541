/**
 * Date + time picker combo!
 *
 * DateTimeSlotPicker is timezone-aware, and works with 12/24h formats. If the
 * component is passed a different timezone through props after it's been
 * mounted, it'll update the date and time to that new timezone and go on with
 * its business. The component also ensures the selected date and time are in
 * the future, no matter the timezone.
 *
 * This component is uncontrolled (except for the timezone prop), and uses the
 * onSubmit callback in props to let its parent know of any scheduling action.
 */

import { InputDate } from '@bufferapp/components'
import { Notice, Text } from '@bufferapp/ui'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import dayjs from 'dayjs'
import timezonePlugin from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Select from './shared/Select'

import {
  Button as PopcornButton,
  Text as PopcornText,
} from '@buffer-mono/popcorn'
import { metaDataPropType } from './ComposerPropTypes'
import SlotPicker from './SlotPicker'
import TimePicker from './TimePicker'
import { gray, grayDark, grayDarker } from '@bufferapp/ui/style/colors'

dayjs.extend(utc)
dayjs.extend(timezonePlugin)

const DayPickerStyles = css`
  .DayPicker-wrapper > div:first-child {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    gap: var(--space-200) !important;
    width: inherit !important;
    top: 4px !important;

    div {
      float: none !important;
    }

    button {
      display: flex !important;
      align-items: center !important;
      height: 100% !important;
      padding: 4px !important;
      margin: -4px !important;
      border-radius: var(--border-radius-sm) !important;
      box-shadow: none !important;

      span {
        vertical-align: unset !important;
        display: flex !important;
      }

      &:disabled {
        cursor: not-allowed !important;
      }

      &:focus:not(:focus-visible) {
        outline: none !important;
      }

      &:focus-visible {
        outline: 2px solid var(--focus-color) !important;
      }
    }
  }

  .DayPicker-Month > div:first-child {
    display: flex !important;
    align-items: center !important;
    height: inherit !important;
    margin-bottom: var(--space-200) !important;

    span {
      font-size: var(--font-size-md) !important;
    }
  }

  .DayPicker-Day {
    font-weight: var(--font-weight-medium) !important;
    color: ${grayDarker} !important;

    &:not(.DayPicker-Day--selected):not(.DayPicker-Day--disabled):hover {
      background-color: var(--color-bg-subtle) !important;
    }
  }

  .DayPicker-Day--today {
    color: var(--color-text-brand) !important;
  }

  .DayPicker-Day--selected {
    color: var(--color-text-inverted) !important;
    background-color: var(--color-bg-brand) !important;
  }

  .DayPicker-Day--disabled {
    color: ${gray} !important;
    cursor: not-allowed !important;
  }

  .DayPicker-Day--outside {
    color: ${gray} !important;

    &:not(.DayPicker-Day--disabled):hover {
      color: ${grayDarker} !important;
      background-color: var(--color-bg-subtle) !important;
      cursor: pointer !important;
    }
  }
`

const DateTimePicker = styled.div`
  padding: 11px 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--space-200);
  color: ${grayDark};

  ${DayPickerStyles};
`

const PickerSwitchButton = styled(PopcornButton)`
  && {
    flex: 2;
    color: ${grayDarker};
    justify-content: center;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: var(--space-100);
`

const SubmitButton = styled(PopcornButton)`
  && {
    flex: 1;
    justify-content: center;
  }
`

const SlotPickerSelect = styled(Select)`
  margin-top: 16px;
`

const NoticeStyled = styled(Notice)`
  margin-top: 10px;
  text-align: left;
  padding: 8px;
  width: calc(100% - 16px);
`

const TextStyled = styled(Text)`
  margin: 0 0 0 -5px;
`

const DateTimeSlotPicker = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'timezone' implicitly has an 'any'... Remove this comment to see the full error message
  timezone,
  // @ts-expect-error TS(7031) FIXME: Binding element 'shouldUse24hTime' implicitly has ... Remove this comment to see the full error message
  shouldUse24hTime,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onSubmit' implicitly has an 'any'... Remove this comment to see the full error message
  onSubmit,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onClick' implicitly has an 'any' ... Remove this comment to see the full error message
  onClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'metaData' implicitly has an 'any'... Remove this comment to see the full error message
  metaData,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onChange' implicitly has an 'any'... Remove this comment to see the full error message
  onChange,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isDateOnly' implicitly has an 'an... Remove this comment to see the full error message
  isDateOnly,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isDropdownItem' implicitly has an... Remove this comment to see the full error message
  isDropdownItem,
  // @ts-expect-error TS(7031) FIXME: Binding element 'initialDateTime' implicitly has a... Remove this comment to see the full error message
  initialDateTime,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isSlotPickingAvailable' implicitl... Remove this comment to see the full error message
  isSlotPickingAvailable,
  // @ts-expect-error TS(7031) FIXME: Binding element 'availableSchedulesSlotsForDay' im... Remove this comment to see the full error message
  availableSchedulesSlotsForDay,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isPinnedToSlot' implicitly has an... Remove this comment to see the full error message
  isPinnedToSlot,
  // @ts-expect-error TS(7031) FIXME: Binding element 'submitButtonCopy' implicitly has ... Remove this comment to see the full error message
  submitButtonCopy,
  // @ts-expect-error TS(7031) FIXME: Binding element 'weekStartsMonday' implicitly has ... Remove this comment to see the full error message
  weekStartsMonday,
  // @ts-expect-error TS(7031) FIXME: Binding element 'doSelectedProfilesHaveSlots' impl... Remove this comment to see the full error message
  doSelectedProfilesHaveSlots,
  // @ts-expect-error TS(7031) FIXME: Binding element 'showDraftScheduleNotice' implicit... Remove this comment to see the full error message
  showDraftScheduleNotice,
}) => {
  const isTimezoneSet = !!timezone
  const todayDate = new Date().setSeconds(0) // Seconds must be 0 for precise scheduling
  // Determine initial date and time for the picker
  const todayDayjs = isTimezoneSet
    ? dayjs.tz(todayDate, timezone)
    : dayjs(todayDate)
  const preselectedDateTime =
    initialDateTime || todayDayjs.clone().add(3, 'hours')
  const draftScheduleNotice =
    "This draft won't be published until you schedule it as a finished post"

  const [selectedDateTime, setSelectedDateTime] = useState(preselectedDateTime)
  const [emptyByDefault, setEmptyByDefault] = useState(false)
  const [displaySlotPicker, setDisplaySlotPicker] = useState(false)

  // When clicking on buttons inside the date picker, the event is propagated
  // up to the Composer App level which causes the composer to collapse and
  // has other unexpected consequences.
  // To prevent this, we stop click event bubbling at the DateTimeSlotPicker level.
  // TODO: Use Popover component to capture clicks and prevent them from bubbling up
  const captureOnClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation()
    // Still run the passed onClick callback if defined
    onClick?.(event)
  }

  const shouldDisplaySlotPickerOnInit = () => {
    if (!isSlotPickingAvailable || !availableSchedulesSlotsForDay) return false

    const selectedTimestamp = selectedDateTime.unix()
    const isAnySlotSelected = availableSchedulesSlotsForDay.some(
      // @ts-expect-error TS(7006) FIXME: Parameter 'slot' implicitly has an 'any' type.
      (slot) => slot.timestamp === selectedTimestamp,
    )

    return isAnySlotSelected && isPinnedToSlot
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'date' implicitly has an 'any' type.
  const updateDate = (date) => {
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()
    let newSelectedDateTime

    if (isDateOnly) {
      newSelectedDateTime = selectedDateTime
        .year(year)
        .month(month)
        .date(day)
        .startOf('day')
    } else {
      newSelectedDateTime = selectedDateTime.year(year).month(month).date(day)
    }

    const pinnedToSlot = shouldDisplaySlotPickerOnInit()
    setSelectedDateTime(newSelectedDateTime)
    onChange(newSelectedDateTime, pinnedToSlot)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'time' implicitly has an 'any' type.
  const updateTime = (time) => {
    const hours = time.hour()
    const minutes = time.minute()
    const pinnedToSlot = shouldDisplaySlotPickerOnInit()
    let newSelectedDateTime = selectedDateTime.hour(hours).minute(minutes)
    newSelectedDateTime = timezone
      ? dayjs(newSelectedDateTime).tz(timezone, true)
      : newSelectedDateTime

    setSelectedDateTime(newSelectedDateTime)

    onChange(newSelectedDateTime, pinnedToSlot)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'timestamp' implicitly has an 'any' type... Remove this comment to see the full error message
  const updateTimeFromTimestamp = (timestamp) => {
    const pinnedToSlot = availableSchedulesSlotsForDay.some(
      // @ts-expect-error TS(7006) FIXME: Parameter 'slot' implicitly has an 'any' type.
      (slot) => slot.timestamp === timestamp,
    )
    let newSelectedDateTime = dayjs.unix(timestamp)
    if (timezone) newSelectedDateTime = newSelectedDateTime.tz(timezone)
    setSelectedDateTime(newSelectedDateTime)

    onChange(newSelectedDateTime, pinnedToSlot)
  }

  // Set to UTC, then negate the original offset
  // @ts-expect-error TS(7006) FIXME: Parameter 'm' implicitly has an 'any' type.
  const stripOffsetFromMoment = (m) => m.clone().utc().add(m.utcOffset(), 'm')

  // @ts-expect-error TS(7006) FIXME: Parameter 'timestamp' implicitly has an 'any' type... Remove this comment to see the full error message
  const onSlotPickerChange = (timestamp) => {
    updateTimeFromTimestamp(timestamp)
    setEmptyByDefault(false)
  }

  const onSwitchToSlotPickerClick = () => {
    // Update UI state
    setDisplaySlotPicker(true)

    // If a slot is available for the current date time, update store to
    // transition from custom time to pinned update mode
    if (!isSlotPickingAvailable || !availableSchedulesSlotsForDay) return

    const selectedTimestamp = selectedDateTime.unix()
    const pinnedToSlot = availableSchedulesSlotsForDay.some(
      // @ts-expect-error TS(7006) FIXME: Parameter 'slot' implicitly has an 'any' type.
      (slot) => slot.timestamp === selectedTimestamp,
    )
    onChange(selectedDateTime, pinnedToSlot)

    setEmptyByDefault(true)
  }

  const onSwitchToTimePickerClick = () => {
    setDisplaySlotPicker(false)
    onChange(selectedDateTime, false)
  }

  const onClickSubmit = () => {
    if (!initialDateTime) {
      onChange(selectedDateTime, false)
    }
    onSubmit(selectedDateTime.unix())
  }

  const selectedDays = {
    // @ts-expect-error TS(7006) FIXME: Parameter 'day' implicitly has an 'any' type.
    selected: (day) => {
      const selectedDateTimeNoOffset = stripOffsetFromMoment(selectedDateTime)
      const dayNoOffset = stripOffsetFromMoment(dayjs(day))
      return selectedDateTimeNoOffset.isSame(dayNoOffset, 'day')
    },
  }

  const shouldDisableSetButton =
    (!isDateOnly && !selectedDateTime.isAfter(new Date())) ||
    (isDateOnly && selectedDateTime.unix() < dayjs().startOf('day').unix())

  const canSwitchToSlots =
    !isDateOnly && isSlotPickingAvailable && doSelectedProfilesHaveSlots

  return (
    <DateTimePicker onClick={captureOnClick} data-testid="date-time-picker">
      <InputDate
        initialMonth={new Date()}
        onDayClick={updateDate}
        selectedDays={selectedDays.selected}
        firstDayOfWeek={weekStartsMonday ? 1 : 0}
      />

      {!isDateOnly && !displaySlotPicker && (
        <TimePicker
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          shouldUse24hTime={shouldUse24hTime}
          time={selectedDateTime}
          timezone={timezone}
          onChange={updateTime}
        />
      )}

      {!isDateOnly &&
        displaySlotPicker &&
        isSlotPickingAvailable &&
        (availableSchedulesSlotsForDay ? (
          <SlotPicker
            metaData={metaData}
            shouldUse24hTime={shouldUse24hTime}
            timezone={timezone}
            slots={availableSchedulesSlotsForDay}
            slot={selectedDateTime}
            onChange={onSlotPickerChange}
            emptyByDefault={emptyByDefault}
          />
        ) : (
          <SlotPickerSelect disabled value="">
            <option value="">Loading slots…</option>
          </SlotPickerSelect>
        ))}

      <ButtonContainer>
        {canSwitchToSlots && (
          <PickerSwitchButton
            variant="secondary"
            size="large"
            onClick={
              displaySlotPicker
                ? onSwitchToTimePickerClick
                : onSwitchToSlotPickerClick
            }
          >
            {displaySlotPicker
              ? 'Switch to custom time'
              : 'Switch to schedule slots'}
          </PickerSwitchButton>
        )}

        {!isDropdownItem && (
          <SubmitButton
            variant="primary"
            size="large"
            onClick={onClickSubmit}
            disabled={shouldDisableSetButton}
          >
            {submitButtonCopy}
          </SubmitButton>
        )}
      </ButtonContainer>
      {/* Below is needed for the dropdown to work after we implemented */}
      {/* scrollbar in the composer. When we decide in the future that */}
      {/* Composer should no longer be in the modal, we can remove */}
      {/* isDropdownItem prop and below conditional */}
      {isDropdownItem && (
        <DropdownMenu.Item>
          <SubmitButton
            variant="primary"
            size="small"
            onClick={onClickSubmit}
            disabled={shouldDisableSetButton}
          >
            {submitButtonCopy}
          </SubmitButton>
        </DropdownMenu.Item>
      )}

      {isTimezoneSet && (
        <PopcornText size="sm" align="center">
          {timezone}
        </PopcornText>
      )}

      {showDraftScheduleNotice && (
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        <NoticeStyled type="warning" disableAnimation>
          <TextStyled type="p">{draftScheduleNotice}</TextStyled>
        </NoticeStyled>
      )}
    </DateTimePicker>
  )
}

DateTimeSlotPicker.propTypes = {
  shouldUse24hTime: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSlotPickingAvailable: PropTypes.bool,
  doSelectedProfilesHaveSlots: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  timezone: PropTypes.string,
  weekStartsMonday: PropTypes.bool.isRequired,
  availableSchedulesSlotsForDay: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.shape({
        isSlotFree: PropTypes.bool.isRequired,
        timestamp: PropTypes.number.isRequired,
      }),
    ),
  ]),
  isPinnedToSlot: PropTypes.bool,
  metaData: metaDataPropType,
  // @ts-expect-error TS(2345) FIXME: Argument of type 'typeof import("/Users/mayauribe/... Remove this comment to see the full error message
  initialDateTime: PropTypes.instanceOf(dayjs),
  submitButtonCopy: PropTypes.string,
  showDraftScheduleNotice: PropTypes.bool,
  isDateOnly: PropTypes.bool,
  isDropdownItem: PropTypes.bool,
}

DateTimeSlotPicker.defaultProps = {
  isSlotPickingAvailable: false,
  doSelectedProfilesHaveSlots: false,
  onClick: () => {},
  onChange: () => {},
  submitButtonCopy: 'Schedule',
  availableSchedulesSlotsForDay: undefined,
  isPinnedToSlot: null,
  metaData: undefined,
  showDraftScheduleNotice: false,
  isDateOnly: false,
  isDropdownItem: false,
  timezone: null,
  initialDateTime: null,
}

export default DateTimeSlotPicker
