import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Card, Text } from '@bufferapp/components'
import { LinkifiedText } from '~publish/legacy/shared-components/LinkifiedText'
import UserDetails from '../UserDetails'

const Wrapper = styled.div`
  margin-bottom: 1rem;
`

const RetweetPanel = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'retweetComment' implicitly has an... Remove this comment to see the full error message
  retweetComment,
  // @ts-expect-error TS(7031) FIXME: Binding element 'retweetCommentLinks' implicitly h... Remove this comment to see the full error message
  retweetCommentLinks,
  // @ts-expect-error TS(7031) FIXME: Binding element 'basic' implicitly has an 'any' ty... Remove this comment to see the full error message
  basic,
  // @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
  children,
  // @ts-expect-error TS(7031) FIXME: Binding element 'retweetProfile' implicitly has an... Remove this comment to see the full error message
  retweetProfile,
}) => (
  <>
    {retweetComment && (
      <Wrapper>
        {basic ? (
          <Text color="black" size="mini">
            {retweetComment}
          </Text>
        ) : (
          <LinkifiedText
            color="black"
            links={retweetCommentLinks}
            newTab
            size="mini"
          >
            {retweetComment}
          </LinkifiedText>
        )}
      </Wrapper>
    )}
    <Card color="off-white" reducedPadding>
      <Wrapper>
        <UserDetails {...retweetProfile} />
      </Wrapper>
      {children}
    </Card>
  </>
)

RetweetPanel.propTypes = {
  retweetProfile: PropTypes.shape({
    avatarUrl: PropTypes.string,
    handle: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  retweetComment: PropTypes.string,
  retweetCommentLinks: PropTypes.arrayOf(
    PropTypes.shape({
      rawString: PropTypes.string,
      displayString: PropTypes.string,
      expandedUrl: PropTypes.string,
      indices: PropTypes.arrayOf(PropTypes.number),
    }),
  ),
  basic: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

RetweetPanel.defaultProps = {
  basic: false,
  retweetComment: undefined,
  retweetCommentLinks: [],
}

export default RetweetPanel
