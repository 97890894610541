import { graphql, getFragmentData } from '~publish/gql'

import type {
  CalendarView_PostFragment,
  UpdatePostDueAtMutation,
} from '~publish/gql/graphql'
import { CalendarView_Post } from './usePostFragment'

export const UpdatePostDueAt = graphql(/* GraphQL */ `
  mutation updatePostDueAt($input: UpdatePostDueAtInput!) {
    updatePostDueAt(input: $input) {
      ... on PostUpdated {
        success
        message
        post {
          ...CalendarView_Post
        }
      }
      ... on PostNotUpdated {
        success
        message
      }

      ... on PostNotFound {
        success
        message
      }

      ... on CoreWebAppCommonError {
        success
        message
      }
    }
  }
`)

export const getViewPostFragment = (
  data: UpdatePostDueAtMutation | null | undefined,
): CalendarView_PostFragment | undefined | null => {
  const maybePost =
    data?.updatePostDueAt?.__typename === 'PostUpdated'
      ? data?.updatePostDueAt?.post
      : undefined
  return getFragmentData(CalendarView_Post, maybePost)
}
