import twitter, { type EntityWithIndices } from 'twitter-text'

import styles from './helpers.module.css'
import { removeUrlProtocol } from '~publish/legacy/composer/composer/utils/StringUtils'

type ThreadLink = {
  displayString: string
  indices: [number, number]
  rawString: string
  url: string
  className?: string
}
const hashTagLimit = 1

const parseThreadsLinks = (text: string): ThreadLink[] => {
  let hashtags = 0

  return twitter
    .extractEntitiesWithIndices(text)
    .map((entity: EntityWithIndices): ThreadLink | null => {
      if ('url' in entity && entity.url) {
        return {
          displayString: removeUrlProtocol(entity.url),
          indices: entity.indices,
          rawString: entity.url,
          url: entity.url,
          className: styles.link,
        }
      }

      if ('hashtag' in entity && entity.hashtag && hashtags < hashTagLimit) {
        hashtags += 1

        return {
          displayString: `${entity.hashtag}`,
          indices: entity.indices,
          rawString: `#${entity.hashtag}`,
          url: `https://threads.net/search?q=${entity.hashtag}&serp_type=tags`,
          className: styles.link,
        }
      }

      if ('screenName' in entity && entity.screenName) {
        return {
          displayString: `@${entity.screenName}`,
          indices: entity.indices,
          rawString: `@${entity.screenName}`,
          url: `https://threads.net/${entity.screenName}`,
          className: styles.link,
        }
      }

      return null
    })
    .filter(Boolean) as ThreadLink[]
}

export { parseThreadsLinks }
