import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { selectUserId } from '~publish/legacy/user/selectors'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import type { Uploader, UploaderEvents } from '../lib/Uploader'
import { bindUploaderToReduxSlice } from '../state/bindUploaderToReduxSlice'

import type { OnFileReadyCb } from '../types'
import {
  destroyUploader,
  getOrCreateUploader,
  resetUploader,
} from '../lib/uploader/UploadersMap'
import type { UploaderRestrictions } from '../values/UploaderRestrictions'
import { selectCurrentFileCount } from '../state/selectors'
import { useStore } from 'react-redux'

/**
 * Returns an uploader instance for the given id with
 * uploader events bound to the redux store.
 * It also returns the current state of the uploader
 * and some callbacks.
 */
export const useUploader = ({
  id,
  fileRestrictions,
  eventHandlers = {},
  // TODO UPLOADS: 'keep' is a better default so that we make reset/destroy explicit
  onUnmount = 'reset',
  getCustomCount,
}: {
  id: string
  fileRestrictions?: UploaderRestrictions | (() => UploaderRestrictions)
  eventHandlers?: Partial<UploaderEvents>
  onUnmount?: 'destroy' | 'reset' | 'keep'
  getCustomCount?: () => number
}): {
  uploader: Uploader
  onFileReady: OnFileReadyCb
} => {
  const dispatch = useAppDispatch()
  const { getState } = useStore()
  const userId = useAppSelector(selectUserId)
  const organizationId = useAppSelector(selectCurrentOrganizationId)
  const getCustomerCountFromState = (): number =>
    selectCurrentFileCount(getState(), id)

  const uploader = getOrCreateUploader({
    uploaderId: id,
    organizationId,
    userId,
    fileRestrictions,
    eventHandlers,
    getCustomCount: getCustomCount ?? getCustomerCountFromState,
  })

  useEffect(() => {
    if (onUnmount === 'reset') {
      return () => {
        resetUploader({ uploaderId: id })
      }
    }
    if (onUnmount === 'destroy') {
      return () => {
        destroyUploader({ uploaderId: id })
      }
    }
  }, [id, onUnmount])

  bindUploaderToReduxSlice(uploader, { dispatch })

  return useMemo(
    () => ({
      uploader: uploader as Uploader,

      onFileReady: (file, source): void => {
        if (file)
          uploader.addFiles([file], {
            source,
          })
      },
    }),
    [uploader],
  )
}
