import React, { useState } from 'react'

import ComposerActionCreators from '../../../../action-creators/ComposerActionCreators'
import { buttonOptions, initialButtonOption } from './constants'
import type { GoogleBusinessChannelData } from '../../../post-preview/previews/types'
import { Button, Combobox, ChevronDownIcon } from '@buffer-mono/popcorn'

const ButtonSelect = ({
  data,
  onSetShouldShowLink,
}: {
  data: GoogleBusinessChannelData
  onSetShouldShowLink: (shouldShowLink: boolean) => void
}): JSX.Element => {
  const [isSelectOpen, setIsSelectOpen] = useState(false)
  const selectedOption =
    buttonOptions.filter((option) => option.value === data.cta)[0] ||
    initialButtonOption

  const onSelectOptionChange = (selectedItemValue: string): void => {
    setIsSelectOpen(false)
    onSetShouldShowLink(selectedItemValue !== 'none')

    ComposerActionCreators.updateDraftGoogleBusinessData({
      ...data,
      cta: selectedItemValue,
    })
  }

  return (
    <Combobox
      value={selectedOption.value}
      onChange={onSelectOptionChange}
      multiple={false}
      open={isSelectOpen}
      onOpenChange={setIsSelectOpen}
    >
      <Combobox.Trigger>
        <Button variant="secondary">
          {selectedOption.title} <ChevronDownIcon />
        </Button>
      </Combobox.Trigger>
      <Combobox.Content>
        <Combobox.List>
          {buttonOptions.map((item) => (
            <Combobox.Item key={item.value} value={item.value}>
              {item.title}
            </Combobox.Item>
          ))}
        </Combobox.List>
      </Combobox.Content>
    </Combobox>
  )
}

export default ButtonSelect
