import {
  Button,
  ChevronDownIcon,
  DropdownMenu,
  Flex,
  Heading,
  Link,
  Paragraph,
  toast,
} from '@buffer-mono/popcorn'
import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'
import React, { useState } from 'react'
import { useAccount } from '~publish/legacy/accountContext'
import Shortener from '~publish/legacy/composer/composer/utils/Shortener'
import { CLIENT_NAMES, SEGMENT_NAMES } from '~publish/legacy/constants'
import { changeLinkShortener } from '~publish/legacy/general-settings/thunks/changeLinkShortener'
import { selectCurrentProfile } from '~publish/legacy/profile-sidebar/selectors'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { getURL } from '~publish/legacy/utils/formatters'
import { selectIsBitlyConnected, selectLinkShorteners } from '../../selectors'
import type { LinkShortener } from '../../types'
import commonStyles from '../../GeneralSettings.module.css'
import styles from './LinkShortening.module.css'

type ShortenerDisabledMap = {
  [key: string]: {
    title: string
    link: string
  }
}

const parseShortener = (shortener?: LinkShortener): string => {
  if (!shortener) return ''

  if (shortener.login) {
    return `${shortener.domain} - ${shortener.login}`
  }
  return shortener.domain
}

const SHORTENER_DISABLED_MAP: ShortenerDisabledMap = {
  pinterest: {
    title: 'Pinterest',
    link: 'https://help.pinterest.com/en/articles/blocked-links-and-websites',
  },
  mastodon: {
    title: 'Mastodon',
    link: 'https://docs.joinmastodon.org/user/posting/#links',
  },
  instagram: {
    title: 'Instagram',
    link: 'https://help.instagram.com',
  },
}

export const SHORTENER_DISABLED_SERVICES = Object.keys(SHORTENER_DISABLED_MAP)

const LinkShortening = (): React.JSX.Element => {
  const user = useAccount()
  const organizationId = user?.account.currentOrganization?.id
  const dispatch = useAppDispatch()
  const profile = useAppSelector(selectCurrentProfile)
  const linkShorteners = useAppSelector(selectLinkShorteners)
  const isBitlyConnected = useAppSelector(selectIsBitlyConnected)

  const [isLoading, setLoading] = useState(false)

  const selectedValue = linkShorteners?.find((ll) => ll.selected)

  if (!profile) return <></>

  const isManager = profile.isManager

  const onChangeShortener = async (domain: string): Promise<void> => {
    setLoading(true)
    const profileId = profile.id

    const result = await dispatch(changeLinkShortener({ profileId, domain }))

    if (changeLinkShortener.fulfilled.match(result)) {
      toast.success(
        'Awesome! Your link shortening option has been successfully changed.',
      )
    } else {
      toast.error(
        `Sorry! Something went wrong while changing your link shortening option: ${result.error.message}. Would you be up for trying again?`,
      )
    }
    setLoading(false)

    Shortener.clearCache()
  }

  const onConnectBitlyURLWithTracking = (): void => {
    window.location.href = getURL.getConnectBitlyURL(profile.id)
    BufferTracker.customBitlyConnectButtonClicked({
      channel: profile.service,
      channelId: profile.id,
      clientName: CLIENT_NAMES.web,
      cta: SEGMENT_NAMES.BITLY_CONNECT_CLICKED,
      organizationId,
      product: 'publish',
    })
  }

  const onDisconnectBitlyURLWithTracking = (): void => {
    window.location.href = getURL.getDisconnectBitlyURL(profile.id)
    BufferTracker.customBitlyDisconnectButtonClicked({
      channel: profile.service,
      channelId: profile.id,
      clientName: CLIENT_NAMES.web,
      cta: SEGMENT_NAMES.BITLY_CONNECT_CLICKED,
      organizationId,
      product: 'publish',
    })
  }

  const bitlyConnectButton = isBitlyConnected ? (
    <DropdownMenu.Item
      className={styles.coloredDropdownMenuItem}
      onClick={onDisconnectBitlyURLWithTracking}
      data-testid="link-shortening-disconnect-bitly"
    >
      Disconnect Bit.ly
    </DropdownMenu.Item>
  ) : (
    <DropdownMenu.Item
      className={styles.coloredDropdownMenuItem}
      onClick={onConnectBitlyURLWithTracking}
      data-testid="link-shortening-connect-bitly"
    >
      Connect Bit.ly
    </DropdownMenu.Item>
  )

  const { title: disabledTitle, link: disabledLink } = SHORTENER_DISABLED_MAP[
    profile.service
  ] || {
    title: '',
    link: '',
  }
  const showNotice = SHORTENER_DISABLED_SERVICES.includes(profile.service)

  return (
    <Flex
      align="center"
      justify="between"
      className={commonStyles.fullWidth}
      data-testid="link-shortening-wrapper"
      gap="space-400"
    >
      <Flex direction="column" gap="space-50">
        <Heading size="small" as="h3">
          Link Shortening
        </Heading>
        {showNotice && (
          <Paragraph className={commonStyles.settingsParagraph}>
            Sadly, at the moment {disabledTitle} does not{' '}
            {disabledTitle === 'Mastodon' ? 'encourage' : 'allow'} posting of
            shortened links. <br />
            For more, read all about it{' '}
            <Link href={disabledLink} rel="noopener noreferrer" external={true}>
              here
            </Link>
            .
          </Paragraph>
        )}
        {!showNotice && (
          <Paragraph className={commonStyles.settingsParagraph}>
            Are your links feeling a little long? Well worry no longer, choose
            one of our link shorteners or connect your own bit.ly account and
            Buffer will make sure that your links are shortened whenever you
            post.
          </Paragraph>
        )}
      </Flex>
      {!showNotice && (
        <Flex
          direction="column"
          gap="space-150"
          className={commonStyles.actionsWrapper}
          align="center"
          data-testid="link-shortening-dropdown"
        >
          {linkShorteners && (
            // TODO: Create a Select component here
            <DropdownMenu
              className={styles.styledDropdownMenu}
              trigger={
                <Button
                  className={styles.fullWidthButton}
                  size="large"
                  loading={isLoading}
                  disabled={!isManager}
                  variant="secondary"
                  data-testid="link-shortening-dropdown-trigger"
                >
                  {parseShortener(selectedValue)}
                  <ChevronDownIcon />
                </Button>
              }
            >
              {linkShorteners.map((item) => (
                <DropdownMenu.Item
                  key={parseShortener(item)}
                  onClick={(): Promise<void> =>
                    onChangeShortener(parseShortener(item))
                  }
                >
                  {parseShortener(item)}
                </DropdownMenu.Item>
              ))}
              {isManager && (
                <>
                  <DropdownMenu.Separator />
                  {bitlyConnectButton}
                </>
              )}
            </DropdownMenu>
          )}
        </Flex>
      )}
    </Flex>
  )
}

export { LinkShortening }
