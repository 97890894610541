import {
  getFileNameFromPath,
  getMimeAndExtensionFromName,
  isSupportedMimeType,
} from '../utils'

const SAFEIMAGE_URL = 'https://safeimage.buffer.com/'

export type RemoteFile = {
  remoteUrl: string
  name?: string
  useSafeImage?: boolean
}

/**
 * fileFromUrl
 *
 * Get the file data fetched from the remoteUrl.Some urls could contain
 * the name and extension of the file but others don't so a optional name param is provided
 * so we can set a name when needed.
 *
 * @param
 *   name => should contain the extension, for example name='picture-1.jpg'
 * CASES:
 *   param name is provided => name and extension are used.
 *   parm name is not provided +
 *       url contain filename and extension => the name and extension in the url are used
 *       url contain only filename          => image/png type will be used
 */
export const fileFromUrl = ({
  remoteUrl,
  name,
  useSafeImage = false,
}: RemoteFile): Promise<File> => {
  if (!remoteUrl) return Promise.reject(Error('No URL provided'))

  let fetchUrl = remoteUrl

  let fileName = name || getFileNameFromPath(remoteUrl)
  let { mimeType } = getMimeAndExtensionFromName(fileName)

  // the mimeType found in the filename/path is trusted
  // if the mimeType is not supported, we convert to png via safeimage service
  if (!isSupportedMimeType(mimeType)) {
    useSafeImage = true
    mimeType = 'image/png'
    fileName = fileName ? `${fileName}.png` : `img-default-${Date.now()}.png`
    fetchUrl = `png/${remoteUrl}`
  }

  return fetch(`${useSafeImage ? SAFEIMAGE_URL : ''}${fetchUrl}`)
    .then((res) => res.blob())
    .then((blob) => new File([blob], fileName, { type: mimeType }))
}
