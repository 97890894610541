import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Text, Button, Tooltip } from '@bufferapp/ui'
import {
  grayLight,
  redDark,
  redLightest,
  boxShadow,
  blue,
  grayDarker,
} from '@bufferapp/ui/style/colors'
import { fontFamily, fontSize, lineHeight } from '@bufferapp/ui/style/fonts'
import { borderRadius } from '@bufferapp/ui/style/borders'
import countHashtagsInText from '../../utils/HashtagCounter'

import * as Styles from './styles'

const textareaWrapperStyle = {
  position: 'relative',
  flex: 1,
  padding: '16px 16px 52px',
  width: '100%',
  boxSizing: 'border-box',
  border: `1px solid ${grayLight}`,
  borderRadius,
}

const textareaWrapperFocusedStyle = {
  boxShadow: `0px 0px 0px 3px ${boxShadow}`,
  border: `1px solid ${blue}`,
}

const inputStyle = {
  color: grayDarker,
  padding: '13px 16px 12px',
  outline: 'none',
  width: '100%',
  boxSizing: 'border-box',
  border: `1px solid ${grayLight}`,
  margin: '8px 0px',
  borderRadius,
  fontSize,
  lineHeight,
  fontFamily,
}

const inputFocusedStyle = {
  boxShadow: `0px 0px 0px 3px ${boxShadow}`,
  border: `1px solid ${blue}`,
}

// @ts-expect-error TS(7031) FIXME: Binding element 'state' implicitly has an 'any' ty... Remove this comment to see the full error message
const getElementStyle = ({ state }, type) => {
  let style
  let focusedStyle
  let focusedState
  switch (type) {
    case 'textareaWrapper':
      style = textareaWrapperStyle
      focusedStyle = textareaWrapperFocusedStyle
      focusedState = state.textareaFocused
      break
    case 'input':
      style = inputStyle
      focusedStyle = inputFocusedStyle
      focusedState = state.inputFocused
      break
    default:
      break
  }

  if (focusedState) {
    style = { ...style, ...focusedStyle }
  }
  return style
}

// @ts-expect-error TS(7006) FIXME: Parameter 'hasError' implicitly has an 'any' type.
const counterLabelStyle = (hasError) => ({
  position: 'absolute',
  right: '16px',
  bottom: '16px',
  fontSize: '10px',
  lineHeight: '12px',
  textAlign: 'center',
  border: hasError ? `1px solid ${redLightest}` : `1px solid ${grayLight}`,
  color: hasError ? redDark : grayDarker,
  backgroundColor: hasError ? redLightest : 'transparent',
  borderRadius: '4px',
  padding: '2px 4px',
})

class HashtagGroupCreator extends Component {
  state = {
    textareaFocused: false,
    inputFocused: false,
    isSaveButtonDisabled: true,
  }

  constructor() {
    // @ts-expect-error TS(2554) FIXME: Expected 1-2 arguments, but got 0.
    super()

    this.handleTextareaChange = this.handleTextareaChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.disableSaveButton = this.disableSaveButton.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'prevProps' implicitly has an 'any' type... Remove this comment to see the full error message
  componentDidUpdate(prevProps) {
    if (
      // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      prevProps.name !== this.props.name ||
      // @ts-expect-error TS(2339) FIXME: Property 'text' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      prevProps.text !== this.props.text
    ) {
      this.disableSaveButton()
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  handleTextareaChange(event) {
    const { value } = event.target

    // @ts-expect-error TS(2339) FIXME: Property 'onChangeGroupText' does not exist on typ... Remove this comment to see the full error message
    this.props.onChangeGroupText(value)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  handleInputChange(event) {
    // @ts-expect-error TS(2339) FIXME: Property 'onChangeGroupName' does not exist on typ... Remove this comment to see the full error message
    this.props.onChangeGroupName(event.target.value)
  }

  disableSaveButton() {
    // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { name, text } = this.props
    const numberHashtagsLeft = countHashtagsInText(text)
    const isSaveButtonDisabled =
      numberHashtagsLeft < 0 || !text.trim() || !name.trim()
    this.setState({ isSaveButtonDisabled })
  }

  handleSubmit() {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'isEditing' does not exist on type 'Reado... Remove this comment to see the full error message
      isEditing,
      // @ts-expect-error TS(2339) FIXME: Property 'onUpdateHashtagGroup' does not exist on ... Remove this comment to see the full error message
      onUpdateHashtagGroup,
      // @ts-expect-error TS(2339) FIXME: Property 'onSaveHashtagGroup' does not exist on ty... Remove this comment to see the full error message
      onSaveHashtagGroup,
      // @ts-expect-error TS(2339) FIXME: Property 'onCancelHashtagGroup' does not exist on ... Remove this comment to see the full error message
      onCancelHashtagGroup,
    } = this.props
    if (isEditing && onUpdateHashtagGroup) {
      onUpdateHashtagGroup()
    } else if (onSaveHashtagGroup) {
      onSaveHashtagGroup()
    }
    onCancelHashtagGroup()
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { name, text, isEditing, onCancelHashtagGroup } = this.props
    const numberHashtagsLeft = countHashtagsInText(text)
    return (
      <>
        <Styles.Title>
          {isEditing ? 'Edit Hashtag Group' : 'Create Hashtag Group'}
        </Styles.Title>
        <Styles.Form autoComplete="off">
          <div>
            <Text htmlFor="hashtagGroupName" type="label">
              Hashtag Group Name
            </Text>
            <input
              style={getElementStyle({ state: this.state }, 'input')}
              placeholder="Your hashtag group name"
              id="hashtagGroupName"
              // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'number'.
              maxLength="200"
              type="text"
              value={name}
              onChange={this.handleInputChange}
              onFocus={() => this.setState({ inputFocused: true })}
              onBlur={() => this.setState({ inputFocused: false })}
            />
          </div>
          <div>
            <Styles.LabelWrapper>
              <Text htmlFor="hashtagGroupContent" type="label">
                Hashtag Group Content
              </Text>
            </Styles.LabelWrapper>
            <div
              style={getElementStyle({ state: this.state }, 'textareaWrapper')}
            >
              <Styles.TextArea
                placeholder="Your hashtags"
                id="hashtagGroupContent"
                // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                maxLength="2000"
                value={text}
                onChange={this.handleTextareaChange}
                onFocus={() => this.setState({ textareaFocused: true })}
                onBlur={() => this.setState({ textareaFocused: false })}
              />
              <div style={counterLabelStyle(numberHashtagsLeft < 0)}>
                {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; label: string; position... Remove this comment to see the full error message */}
                <Tooltip
                  label="Instagram will reject posts containing over 30 hashtags"
                  position="top"
                >
                  {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: (s... Remove this comment to see the full error message */}
                  <Text># Remaining: {numberHashtagsLeft}</Text>
                </Tooltip>
              </div>
            </div>
          </div>
        </Styles.Form>
        <Styles.ButtonsWrapper>
          <Styles.CancelButtonWrapper>
            {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: any;... Remove this comment to see the full error message */}
            <Button type="text" label="Cancel" onClick={onCancelHashtagGroup} />
          </Styles.CancelButtonWrapper>
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; disabled: boo... Remove this comment to see the full error message */}
          <Button
            type="secondary"
            label="Save Hashtag Group"
            disabled={this.state.isSaveButtonDisabled}
            onClick={this.handleSubmit}
          />
        </Styles.ButtonsWrapper>
      </>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
HashtagGroupCreator.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  snippetId: PropTypes.string,
  isEditing: PropTypes.bool,
  onChangeGroupName: PropTypes.func.isRequired,
  onChangeGroupText: PropTypes.func.isRequired,
  onCancelHashtagGroup: PropTypes.func.isRequired,
  onSaveHashtagGroup: PropTypes.func.isRequired,
  onUpdateHashtagGroup: PropTypes.func.isRequired,
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
HashtagGroupCreator.defaultProps = {
  name: null,
  text: null,
  isEditing: false,
}

export default HashtagGroupCreator
