/* eslint-disable camelcase */
import React from 'react'
import styled from 'styled-components'
import { getUnixTime, set, getDate, getMonth, getYear } from 'date-fns'
import { DragDropContext, type DropResult } from 'react-beautiful-dnd'
import usePostLimit from '~publish/pages/Calendar/hooks/usePostLimit'
import { useDropPostInSlot } from '~publish/pages/Calendar/hooks/useDropPostInSlot'
import { useMonthlyDates } from '~publish/pages/Calendar/hooks/useMonthlyDates'
import {
  useCalendar,
  type PostForCalendarHookResponse,
} from '~publish/pages/Calendar/hooks/useCalendarAndPostsList'
import dayCells from '../Days'
import { isDraft as checkIsDraft } from '~publish/pages/Calendar/util/isDraft'
import type { CalendarView_PostFragment } from '~publish/gql/graphql'

const Row = styled.tr<{ numberWeeks: number }>`
  // We are adding 1 to the calculation to account for the header
  // this theoretically will set the height to be smaller than needed but then
  // the css will grow the elements proportionally to fill the space
  height: calc(100% / (${(props): number => props.numberWeeks} + 1));
`

const getNewTimestamp = (
  unixTimestampSeconds: number,
  toDate: Date | number,
): number => {
  const fromDate = new Date(unixTimestampSeconds * 1000)
  const newDate = set(fromDate, {
    date: getDate(toDate),
    month: getMonth(toDate),
    year: getYear(toDate),
  })

  return getUnixTime(newDate)
}

const findPostById = (
  posts: CalendarView_PostFragment[],
  postId: string,
): CalendarView_PostFragment | undefined =>
  posts.find((post) => post.id === postId)

type OnDragEndProps = {
  result: DropResult
  posts: CalendarView_PostFragment[]
  updatePostDueAt: (args: {
    post: CalendarView_PostFragment
    timestamp: number
  }) => void
}

const onDragEnd = ({
  result,
  posts = [],
  updatePostDueAt,
}: OnDragEndProps): void => {
  const { destination, source, draggableId: postId } = result

  if (!destination) {
    return
  }

  // If we drop in the same day cell
  // We don't compare the index here because we check against the timestamp
  if (destination.droppableId === source.droppableId) {
    return
  }

  const post = findPostById(posts, postId)
  if (!post) return

  const timestamp = getNewTimestamp(
    post.dueAt ?? new Date().getTime(),
    Number(destination.droppableId),
  )

  updatePostDueAt({ post, timestamp })
}

type RowRenderer = {
  row: number
  cells: JSX.Element[]
}

type DaysOfMonthProps = {
  currentDate: Date
  selectedChannelIds: string[] | void
  isViewOnly: boolean
  onDropPostSuccess: (args: { newDate: number; isDraft: boolean }) => void
  onDropPostError: (error?: { message: string } | Error | null) => void
  setOpenModal: (openModal: {
    open: boolean
    ctaString: string
    service: string
  }) => void
  postsQuery: PostForCalendarHookResponse
}

const DaysOfMonth = ({
  currentDate,
  selectedChannelIds,
  isViewOnly,
  onDropPostSuccess,
  onDropPostError,
  setOpenModal,
  postsQuery,
}: DaysOfMonthProps): JSX.Element => {
  const cellsPerRow = 7
  const {
    monthStart,
    calendarStart,
    calendarEnd,
    numberOfWeeks,
    weekStartsOn,
  } = useMonthlyDates(currentDate)

  const { data, variables } = postsQuery

  const { updatePostDueAt } = useDropPostInSlot({
    onSuccess: (post): void => {
      const newDate = post.dueAt
      const isDraft = checkIsDraft(post.status)
      if (!newDate) return
      onDropPostSuccess({ newDate, isDraft })
    },
    onError: onDropPostError,
    variables,
  })

  const postLimit = usePostLimit(numberOfWeeks)
  const calendar = useCalendar(data)
  const posts = (calendar?.posts ?? []) as CalendarView_PostFragment[]

  return (
    <DragDropContext
      onDragEnd={(dragResult: DropResult): void =>
        onDragEnd({ result: dragResult, updatePostDueAt, posts })
      }
    >
      {dayCells({
        startDate: calendarStart,
        endDate: calendarEnd,
        monthStart,
        weekStartsOn,
        data,
        postLimit,
        setOpenModal,
        selectedChannelIds,
      })
        .reduce((row, cell, index) => {
          // create each row array with 7 cells:
          if (index % cellsPerRow === 0)
            row.push({
              row: row.length,
              cells: [],
            })
          row[row.length - 1].cells.push(cell)
          return row
        }, [] as RowRenderer[])
        .map((row) => {
          // surround every row with 'tr'
          return (
            <Row
              key={row.row}
              data-testid="monthly-row"
              numberWeeks={numberOfWeeks}
            >
              {row.cells}
            </Row>
          )
        })}
    </DragDropContext>
  )
}

export default DaysOfMonth
