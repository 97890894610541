import { useQuery } from '@apollo/client'

import { graphql } from '~publish/gql'
import { sanitizeNullableArray } from '~publish/helpers/typeGuards'
import { useAppSelector } from '~publish/legacy/store'

export const GetSidebarChannelsInfo = graphql(/* GraphQL */ `
  query GetSidebarChannelsInfo {
    # TODO: channels should be a top-level query
    account {
      id
      currentOrganization {
        id
        channels(product: publish) {
          id
          name
          service
          ...ChannelItem_Channel
        }
      }
    }
  }
`)

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export function useChannels() {
  const { data, ...rest } = useQuery(GetSidebarChannelsInfo)
  const profilesIdOrder =
    useAppSelector((state) =>
      state.profileSidebar.profiles.map((profile) => profile.id),
    ) ?? []

  const channels = sanitizeNullableArray(
    data?.account?.currentOrganization?.channels,
  ).sort((a, b) => {
    // TODO: Since we don't have order implemented on the GraphQL API,
    // we sort by the order of the profiles in the sidebar stored in Redux.
    // This should go away once we have order implemented on the API.
    const aIndex = profilesIdOrder.indexOf(a.id)
    const bIndex = profilesIdOrder.indexOf(b.id)
    if (aIndex === -1) {
      return 1
    }
    if (bIndex === -1) {
      return -1
    }
    return aIndex - bIndex
  })
  return { channels, ...rest }
}
