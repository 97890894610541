import React from 'react'
import RefreshIcon from '@bufferapp/ui/Icon/Icons/Refresh'
import ComposerActionCreators from '~publish/legacy/composer/composer/action-creators/ComposerActionCreators'
import { AIAssistantContext } from '../AIAssistant'
import { useAppSelector, useAppDispatch } from '~publish/legacy/store'
import { ActionButton } from '~publish/legacy/ai/components/AIAssistant/components/ActionButton'
import { ToneButton } from '~publish/legacy/ai/components/AIAssistant/components/ToneButton'
import { RetryButton } from '~publish/legacy/ai/components/AIAssistant/components/RetryButton'
import { CopyButton } from '~publish/legacy/ai/components/AIAssistant/components/CopyButton'
import { Loader } from '~publish/legacy/ai/components/AIAssistant/components/Loader'
import { FunFact } from '~publish/legacy/ai/components/AIAssistant/components/FunFact'
import { Error } from '~publish/legacy/ai/components/AIAssistant/components/Error'
import { Button } from '~publish/legacy/ai/components/AIAssistant/components/Button'

import {
  selectContentGenerationStatus,
  resetContentGeneration,
  resetSelection,
} from '~publish/legacy/ai/state/slice'

import * as Styled from './GenerationResult.styles'
import { setIdeaAsAiAssisted } from '~publish/pages/Create/state/ideasSlice'

/**
 *
 */
function GenerationResult({ disabled }: { disabled?: boolean }): JSX.Element {
  const dispatch = useAppDispatch()

  const { editor } = React.useContext(AIAssistantContext)

  const {
    contentGenerationInProgress,
    contentGenerationError,
    contentGenerationResults,
  } = useAppSelector((state) => selectContentGenerationStatus(state))

  const currentIdeaId = useAppSelector((state) => state.ideas.currentIdeaId)

  const onAddContent = (): void => {
    editor?.addGeneratedContent?.({ content })
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0
    dispatch(resetContentGeneration())
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0
    dispatch(resetSelection())

    // For Composer: handles setting all drafts as AI assisted.
    ComposerActionCreators.updateAiAssist()

    // For Ideas Editor: handles setting current idea as AI assisted.
    // When content is inserted/replaced, we target the idea from
    // the ideas state in the store and set it as ai assisted.
    dispatch(setIdeaAsAiAssisted({ id: currentIdeaId }))
  }

  const content = contentGenerationResults[0]

  const showLoader = contentGenerationInProgress
  const showError = contentGenerationError
  const showContent = content && !showLoader && !showError

  const shouldReplace = editor && editor?.getSelection?.()

  return (
    <>
      <Styled.VerticalWrapper>
        {contentGenerationError && <Error />}

        {showContent && (
          <Styled.Wrapper>
            <Styled.ContentWrapper disabled={disabled}>
              <Styled.Content
                disabled={disabled}
                data-testid="ai-assistant-content-generated"
              >
                {content.trim()}
              </Styled.Content>
              <Styled.ButtonWrapper>
                <CopyButton disabled={disabled} />
                <Styled.PrimaryButtonsWrapper>
                  <RetryButton disabled={disabled}>
                    <RefreshIcon />
                    Retry
                  </RetryButton>
                  <Button onClick={onAddContent} disabled={disabled}>
                    {shouldReplace ? 'Replace' : 'Insert'}
                  </Button>
                </Styled.PrimaryButtonsWrapper>
              </Styled.ButtonWrapper>
            </Styled.ContentWrapper>
            <Styled.ActionsWrapper id="ai-assistant-fine-tuning-container">
              {['rephrase', 'shorten', 'expand'].map((action, index) => (
                <ActionButton
                  target={content}
                  action={action}
                  disabled={disabled}
                  key={index}
                  testId={`ai-assistant-fine-tuning-${action}-button`}
                  secondary
                />
              ))}
              {['casual', 'formal'].map((tone, index) => (
                <ToneButton
                  tone={tone}
                  disabled={disabled}
                  key={index}
                  testId={`ai-assistant-fine-tuning-${tone}-button`}
                />
              ))}
            </Styled.ActionsWrapper>
          </Styled.Wrapper>
        )}
      </Styled.VerticalWrapper>
      {contentGenerationInProgress && (
        <Styled.LoadingWrapper>
          <Loader />
          <FunFact />
        </Styled.LoadingWrapper>
      )}
    </>
  )
}

export { GenerationResult }
