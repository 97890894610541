import styled from 'styled-components'

export const Section = styled.section`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  padding: 0 32px;
  margin: 20px 0;

  label {
    font-size: 16px;
    font-weight: 700;
  }
`
