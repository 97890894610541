import { useSplitEnabled } from '@buffer-mono/features'
import { usePublishRevamp } from '~publish/hooks/usePublishRevamp'

export const useNewCalendar = (): boolean => {
  const [isSingleChannelEnabled] = usePublishRevamp()
  return isSingleChannelEnabled
}

export const usePopcornCalendarPostCard = (): boolean => {
  const newCal = useNewCalendar()
  const { isEnabled: newCalPostItems } = useSplitEnabled(
    'popcorn-calendar-posts',
  )
  return newCal && newCalPostItems
}

export const useMergedCalendarAndPosts = (): boolean => {
  const newCal = useNewCalendar()
  const { isEnabled: mergedCalendarAndPosts } = useSplitEnabled(
    'merged-calendar-posts',
  )
  return newCal && mergedCalendarAndPosts
}
