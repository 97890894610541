import { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { replace } from 'redux-first-history'

import { useSplitEnabled } from '@buffer-mono/features'

import { setLastVisitedPage } from '~publish/legacy/utils/page-visits'
import { CALENDAR_PAGE } from '~publish/legacy/constants'
import { actions as modalActions } from '~publish/legacy/modals/reducer'
import { useAppDispatch } from '~publish/legacy/store'
import {
  getQueryParamFromURL,
  removeQueryParamsFromUrl,
} from '~publish/legacy/utils/manageQueryParamsInURL'
import { useNewCalendar } from '~publish/pages/Calendar/hooks/useNewCalendar'

// TODO: the usage of useNewCalendar  should be removed once is fully enabled
export function useConnectChannelModal(): void {
  const { isEnabled: isChannelRefactorEnabled } = useSplitEnabled(
    'GROWTH-channel-refactor',
  )
  const isNewCalendarEnabled = useNewCalendar()
  const location = useLocation<{ channel?: string }>()
  const channelSelectedThroughRoute = location.state?.channel

  const dispatch = useAppDispatch()

  const showConnectAChannelModal = useCallback((): void => {
    // check if connectChannel query parm is in the url to show the Connect a Channel Modal
    const channelToConnect = getQueryParamFromURL('connectChannel')

    if (channelToConnect && isChannelRefactorEnabled && isNewCalendarEnabled) {
      dispatch(modalActions.showConnectAChannelModal(channelToConnect))
      // remove the query param to avoid showing the modal again
      removeQueryParamsFromUrl(['connectChannel'])
    }
  }, [dispatch, isChannelRefactorEnabled, isNewCalendarEnabled])

  useEffect(() => {
    if (!isNewCalendarEnabled) return
    // Reset channel to select state included in some location changes
    if (channelSelectedThroughRoute) {
      dispatch(replace(location.pathname, {}))
    }
    if (location.pathname.includes('calendar')) {
      setLastVisitedPage(CALENDAR_PAGE)
    }

    /* if the calendar url contains the param connectChannel
      we will show the connectAChannel Modal */
    showConnectAChannelModal()
  }, [
    channelSelectedThroughRoute,
    dispatch,
    isNewCalendarEnabled,
    location.pathname,
    showConnectAChannelModal,
  ])
}
