import {
  type AnyAction,
  type CombinedState,
  configureStore,
  type ThunkDispatch,
} from '@reduxjs/toolkit'
import { combineReducers, type StateFromReducersMapObject } from 'redux'
import {
  type TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux'
import { createBrowserHistory as createHistory } from 'history'
import { createReduxHistoryContext } from 'redux-first-history'

import { getMiddleware } from './middlewares'
import { reducers as partialReducers } from './reducers'
import { env } from '~publish/env'

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createHistory(),
  })
const reducers = {
  ...partialReducers,
  router: routerReducer,
}
export type RootState = StateFromReducersMapObject<typeof reducers>

const defualtMiddlewareOptions = {
  serializableCheck: {
    ignoredActionPaths: [
      // TODO: Uploads Cleanup EPD-1426
      //       uploaderInstance and file can be removed here
      'args.uploaderInstance',
      'args.file',
      'queryParams',
    ],
    ignoredActions: [],
  },
}

// Setup store with basic root reducer for type safety
export const store = configureStore({
  reducer: combineReducers(reducers),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(defualtMiddlewareOptions).concat(
      getMiddleware(routerMiddleware),
    ),
  devTools: env.VITE_NODE_ENV !== 'production',
})

// This method combines all our reducers together
// to allow asynchronously adding more reducers
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const createReducer = (asyncReducers = {}) =>
  combineReducers({
    ...reducers,
    ...asyncReducers,
  })

let asyncReducers = {}

// This is how lazy-loaded packages can add their stores once loaded
// @ts-expect-error TS(7006) FIXME: Parameter 'reducerMap' implicitly has an 'any' typ... Remove this comment to see the full error message
export const injectReducers = function (reducerMap): void {
  asyncReducers = {
    ...asyncReducers,
    ...reducerMap,
  }
  store.replaceReducer(createReducer(asyncReducers))
}

export type AppDispatch = typeof store.dispatch

// Use throughout your app instead `useDispatch`  for better type inference
export const useAppDispatch = (): ThunkDispatch<
  CombinedState<RootState>,
  undefined,
  AnyAction
> => useDispatch<AppDispatch>()

// Use throught your app instead of useDispatch for better type inference
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const history = createReduxHistory(store)

export default store
