import React from 'react'
import { GlobeIcon, Tooltip, Text, Button } from '@buffer-mono/popcorn'
import {
  formatTimeZone,
  getCurrentTimeZone,
} from '~publish/helpers/dateFormatters'

import styles from './CurrentTimezoneDisplay.module.css'

export const CurrentTimezoneDisplay = (): JSX.Element => {
  return (
    <Tooltip content="Time zone detected by your browser">
      <Button variant="tertiary" className={styles.container} disabled>
        <GlobeIcon aria-hidden={false} aria-label="Timezone" />
        <Text size="sm" weight="medium" color="subtle">
          {formatTimeZone(getCurrentTimeZone())}
        </Text>
      </Button>
    </Tooltip>
  )
}
