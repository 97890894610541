import type { History } from 'history'
import React, { useEffect } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  type RouteProps,
} from 'react-router-dom'

import PagesWithSidebar from '~publish/legacy/app-pages/components/PagesWithSidebar'
import ProfilePages from '~publish/legacy/app-pages/components/ProfilePages'
import { CALENDAR_PAGE, QUEUE_PAGE } from '~publish/legacy/constants'
import { Preferences } from '~publish/legacy/preferences'
import { filterProfilesByOrg } from '~publish/legacy/profile-sidebar/utils'
import {
  allChannels,
  blueprintsPage,
  calendarMonth,
  calendarPage,
  calendarWeek,
  campaignsPage,
  channel,
  channelSettings,
  channelShopGrid,
  compose,
  createNew,
  createPage,
  linkToPost,
  newCalendarAllChannels,
  newCalendarSingleChannel,
  newPost,
  preferencesPage,
  profileChildTabPages,
  profilePages,
  profileTabPages,
  tagsPage,
} from '~publish/legacy/routes'
import { getLastVisitedPage } from '~publish/legacy/utils/page-visits'
import CreatePage from '~publish/pages/Create'
import { useNewCalendar } from '~publish/pages/Calendar/hooks/useNewCalendar'
import { useConnectChannelModal } from '~publish/hooks/useConnectChannelModal'
import {
  type CreateNewPostInComposerData,
  usePostComposer,
} from '~publish/hooks/usePostComposer'
import { selectProfiles } from '~publish/legacy/profile-sidebar/selectors'
import { useAppSelector } from '~publish/legacy/store'
import { selectCalendarAsHomepage } from '~publish/legacy/user/selectors'
import { NewPostPage } from '~publish/pages/NewPost'

import { useAccount, usePageLoad } from '../../../accountContext'
import ComposePage from '../../../compose-page'
import { PostDeepLinkResolver } from '../PostDeepLinkResolver/PostDeepLinkResolver'

import {
  getChannelLocationFromProfile,
  getProfileLocationFromChannel,
  getProfileSettingsLocationFromChannel,
} from './redirectionUtils'
import { usePublishRevamp } from '~publish/hooks/usePublishRevamp'

declare global {
  // window.__history is used to share the history object with the app shell to have smooth navigations
  interface Window {
    __history: History<unknown> | undefined
    __openComposer: (data: CreateNewPostInComposerData) => Promise<void>
  }
}

export const AppPages = (): JSX.Element => {
  // Get current selected org from account
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error TS(2339) FIXME: Property 'loading' does not exist on type 'Initial... Remove this comment to see the full error message
  const { account, loading } = useAccount()
  const { createNewPostInComposer } = usePostComposer()
  const allProfiles = useAppSelector(selectProfiles)
  const showCalendarAsHomepage = useAppSelector(selectCalendarAsHomepage)

  const history = useHistory()
  useEffect(() => {
    // Make history object available globally so that it can be used in the app shell
    window.__history = history
  }, [history])

  useEffect(() => {
    // Make openComposer available globally so that it can be used in the app shell
    window.__openComposer = createNewPostInComposer
  }, [createNewPostInComposer])

  const { initialPageLoad, updateInitialPageLoad } = usePageLoad()
  const selectedOrg = account?.currentOrganization?.id

  const profiles = filterProfilesByOrg(allProfiles, {
    id: selectedOrg,
  })

  const hasProfiles = profiles.length > 0

  /**
   * We're deprecating the `awaitingApproval` and `pendingApproval` routes
   * in favour of the `approvals` route. This is a temporary redirect to
   * ensure that users are redirected to the correct page.
   */
  const approvalsRoute = profileTabPages.getRoute({
    profileId: profiles[0]?.id,
    tabId: 'approvals',
  })
  const deprecatedAwaitingApprovalRoute = profileTabPages.getRoute({
    profileId: profiles[0]?.id,
    tabId: 'awaitingApproval',
  })
  const deprecatedPendingApprovalRoute = profileTabPages.getRoute({
    profileId: profiles[0]?.id,
    tabId: 'pendingApproval',
  })
  const RedirectToApprovals = ({ location }: RouteProps): JSX.Element => (
    <Redirect
      to={{
        ...location,
        pathname: approvalsRoute,
      }}
    />
  )

  useEffect(() => {
    updateInitialPageLoad()
  })
  useConnectChannelModal()
  const isNewCalendarEnabled = useNewCalendar()

  const defaultPath = (): string => {
    const lastVisitedPage = getLastVisitedPage()

    // NEW
    /**
     * Calculate the default path based on the following rules:
     * - Redirect to all channels calendar only if last visited page is the calendar
     * - Always redirects to all channels queue
     */
    if (isNewCalendarEnabled) {
      if (lastVisitedPage === CALENDAR_PAGE && !initialPageLoad) {
        return newCalendarAllChannels.buildPathname({ granularity: 'week' })
      }
      return allChannels.route
    }

    // LEGACY
    /**
     * Calculate the default path based on the following rules:
     * - Redirect to calendar if user has no profiles
     * - Always redirects to queue for old users
     * - Redirect to queue only if last visited page is the queue for new users
     */
    if (!hasProfiles && !loading) {
      return calendarPage.route
    }

    const queueIsLastVisitedPage =
      lastVisitedPage === QUEUE_PAGE && !initialPageLoad

    const shouldRedirectToQueue =
      !showCalendarAsHomepage || queueIsLastVisitedPage

    const selectedProfileId = profiles[0].id
    const queuePath = profileTabPages.getRoute({
      profileId: selectedProfileId,
      tabId: 'queue',
    })

    return shouldRedirectToQueue ? queuePath : calendarPage.route
  }

  // New Single channel
  const [shouldRedirectToChannelPage, { isReady: isPublishRevampReady }] =
    usePublishRevamp()

  //  Do not render until split is ready to avoid invalid redirections
  if (!isPublishRevampReady) {
    return <></>
  }

  return (
    <Switch>
      <Route path={compose.route}>
        <ComposePage />
      </Route>
      <Route path={preferencesPage.route}>
        <Preferences />
      </Route>
      <Route
        path={[
          campaignsPage.route,
          tagsPage.route,
          // Calendar Routes
          ...(isNewCalendarEnabled
            ? [newCalendarAllChannels.route, newCalendarSingleChannel.route]
            : [calendarWeek.route, calendarMonth.route]),
          allChannels.route,
          ...(shouldRedirectToChannelPage
            ? [channel.route, channelSettings.route, channelShopGrid.route]
            : []),
          blueprintsPage.route,
        ]}
      >
        <PagesWithSidebar />
      </Route>
      {/* Legacy all-posts route */}
      <Route
        path="/all-posts"
        render={({ location }): JSX.Element => (
          <Redirect to={{ ...location, pathname: allChannels.route }} />
        )}
      />
      {!shouldRedirectToChannelPage && (
        <Route
          path={channelSettings.route}
          render={({ location, match }): JSX.Element => (
            <Redirect
              to={getProfileSettingsLocationFromChannel({
                location,
                match,
              })}
            />
          )}
        />
      )}
      {!shouldRedirectToChannelPage && (
        <Route
          path={channel.route}
          render={({ location, match }): JSX.Element => (
            <Redirect to={getProfileLocationFromChannel({ location, match })} />
          )}
        />
      )}
      {shouldRedirectToChannelPage && (
        <Route
          path={[
            profileChildTabPages.route,
            profileTabPages.route,
            profilePages.route,
          ]}
          render={({ location, match }): JSX.Element => (
            <Redirect to={getChannelLocationFromProfile({ location, match })} />
          )}
        />
      )}
      {!isNewCalendarEnabled && (
        <Route
          path={calendarPage.route}
          render={(): JSX.Element => <Redirect to={calendarWeek.route} />}
        />
      )}
      {isNewCalendarEnabled && (
        <Route
          path={calendarMonth.route}
          render={(): JSX.Element => (
            <Redirect to={newCalendarAllChannels.getRoute('month')} />
          )}
        />
      )}
      {isNewCalendarEnabled && (
        <Route
          path={[calendarWeek.route, calendarPage.route]}
          render={(): JSX.Element => (
            <Redirect to={newCalendarAllChannels.getRoute('week')} />
          )}
        />
      )}
      <Route
        path={[deprecatedAwaitingApprovalRoute, deprecatedPendingApprovalRoute]}
      >
        <RedirectToApprovals />
      </Route>
      <Route path={linkToPost.route}>
        <PostDeepLinkResolver />
      </Route>

      {!shouldRedirectToChannelPage && (
        <Route path={profilePages.route}>
          <ProfilePages profiles={profiles} />
        </Route>
      )}
      {/* Create Routes */}
      {/* Redirect old /content paths for backwards compatibility */}
      <Route
        path="/content/new"
        key="content-redirect-new"
        render={({ location }): JSX.Element => (
          <Redirect to={{ ...location, pathname: createNew.route }} />
        )}
      />
      <Route
        path="/content"
        key="content-redirect-content"
        render={(): JSX.Element => <Redirect to={createNew.route} />}
      />
      <Route path={createPage.route} key="create-route">
        <CreatePage />
      </Route>
      <Route path={newPost.route}>
        <NewPostPage />
      </Route>
      {/* Default / catch-all redirect */}
      <Route
        path="*"
        render={(): JSX.Element => <Redirect to={defaultPath()} />}
      />
    </Switch>
  )
}
