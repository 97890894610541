import React from 'react'
import clsx from 'clsx'
import { useDroppable } from '@dnd-kit/core'
import { Text, UnstyledButton, VisuallyHidden } from '@buffer-mono/popcorn'
import { useTimeFormatter } from '~publish/hooks/useDateTimeFormatter'
import { getUnixTime } from 'date-fns'

import styles from './QueueSlot.module.css'
import { usePostComposer } from '~publish/hooks/usePostComposer'

type QueueSlotProps = {
  date: string
  channelId: string
}

export const QueueSlot = React.forwardRef<HTMLButtonElement, QueueSlotProps>(
  ({ date, channelId }, ref) => {
    const { triggerAttributes, createNewPinnedPostInComposer } =
      usePostComposer()

    const timeFormatter = useTimeFormatter()
    const { setNodeRef, isOver, active } = useDroppable({
      id: date,
      data: { type: 'slot', date },
    })

    return (
      <UnstyledButton
        {...triggerAttributes}
        className={clsx(
          styles.slot,
          isOver && styles.over,
          active?.id && styles.dragInProgress,
        )}
        data-datetime={date}
        data-testid="queue-slot"
        onClick={(): Promise<void> =>
          createNewPinnedPostInComposer({
            cta: 'publish-singleChannel-queue-slot-1',
            channels: [channelId],
            prefillPostData: {
              pinned: true,
              dueAt: getUnixTime(date),
            },
          })
        }
        ref={(node): void => {
          setNodeRef(node)
          if (typeof ref === 'function') ref(node)
          else if (ref) ref.current = node
        }}
      >
        <Text weight="medium">
          <VisuallyHidden>Schedule post for </VisuallyHidden>
          {timeFormatter(date)}
        </Text>
        <Text className={styles.overlay} weight="medium" aria-hidden>
          Schedule a Post
        </Text>
      </UnstyledButton>
    )
  },
)

QueueSlot.displayName = 'QueueSlot'
