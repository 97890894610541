import {
  createPluginFactory,
  type PlateEditor,
  StyledLeaf,
  withProps,
} from '@udecode/plate'
import { addGeneratedContent } from './commands/addGeneratedContent'
import { removeSelection } from './commands/removeSelection'
import { getContent } from './commands/getContent'
import { getSelection } from './commands/getSelection'

import { css } from 'styled-components'

/**
 *
 */
const onSelectionCheck = (editor: any): void => {
  const callback = editor.onSelectionCheckCallback as () => void
  callback && callback()
}

/**
 *
 */
const createContentGenerationPlugin = createPluginFactory({
  key: 'shouldAnimateGeneration',
  isLeaf: true,
  component: withProps(StyledLeaf, {
    styles: {
      root: css`
        span {
          animation: flash 1s ease-in-out;

          @keyframes flash {
            0% {
              background-color: rgba(156, 43, 255, 0.6);
            }
            100% {
              background-color: rgba(156, 43, 255, 0);
            }
          }
        }
      `,
    },
  }),
  withOverrides: (editor: PlateEditor): PlateEditor => {
    editor.addGeneratedContent = ({ content }: { content: string }): void => {
      addGeneratedContent({ editor, content })
      removeSelection({ editor })
    }

    editor.getContent = (): string => {
      return getContent({ editor })
    }

    editor.getSelection = (): string | null => {
      return getSelection({ editor })
    }

    editor.removeSelection = (): void => {
      removeSelection({ editor })
    }

    editor.onSelectionCheckCallback = null
    editor.onSelectionCheck = (callback: () => void): void => {
      editor.onSelectionCheckCallback = callback
    }

    return editor
  },

  handlers: {
    onChange: (editor: any) => (): void => {
      onSelectionCheck(editor)
    },
    onFocus: (editor: any) => (): void => {
      onSelectionCheck(editor)
    },
  },
})

export { createContentGenerationPlugin }
