import React from 'react'
import PropTypes from 'prop-types'
import HashtagGroupWrapper from '~publish/legacy/hashtag-group-manager'
import { ComposerSidepanel } from '~publish/legacy/shared-components'

const HashtagManagerSidepanel = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'onInsertHashtagGroupClick' implic... Remove this comment to see the full error message
  onInsertHashtagGroupClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onClose' implicitly has an 'any' ... Remove this comment to see the full error message
  onClose,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onTop' implicitly has an 'any' ... Remove this comment to see the full error message
  onTop,
}) => {
  return (
    <ComposerSidepanel
      isVisible={true}
      customStyles={{
        height: '100%',
        zIndex: onTop ? '100' : '0',
      }}
      showCloseButton
      onClose={onClose}
    >
      <HashtagGroupWrapper
        // @ts-expect-error TS(2322) FIXME: Type '{ viewMode: string; onInsertHashtagGroupClic... Remove this comment to see the full error message
        viewMode="manageHashtag"
        onInsertHashtagGroupClick={onInsertHashtagGroupClick}
      />
    </ComposerSidepanel>
  )
}

HashtagManagerSidepanel.propTypes = {
  isVisible: PropTypes.bool,
  onInsertHashtagGroupClick: PropTypes.func,
  onClose: PropTypes.func,
  onTop: PropTypes.bool,
}

HashtagManagerSidepanel.defaultProps = {
  isVisible: false,
  onInsertHashtagGroupClick: () => {},
  onClose: () => {},
  onTop: false,
}

export default HashtagManagerSidepanel
