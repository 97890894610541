import React, { useEffect } from 'react'
import { actions as dataFetchActions } from '@bufferapp/async-data-fetch'
import { useDispatch } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import { useAppSelector } from '~publish/legacy/store'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import TagsList from '~publish/legacy/campaigns-list/components/TagsList'
import { tagsPage } from '~publish/legacy/routes'
import type { Tag } from '~publish/legacy/campaign/types'

export interface ListCampaignsProps {
  campaigns: Array<Tag>
  goToAnalyzeReport: (campaign: Tag) => void
  showCampaignActions: boolean
  hideAnalyzeReport: boolean
  hasCampaignsFlip: boolean
  isLoading?: boolean
}

const ListCampaigns: React.FC<ListCampaignsProps> = ({
  campaigns,
  goToAnalyzeReport,
  showCampaignActions,
  hideAnalyzeReport,
  hasCampaignsFlip,
  isLoading = false,
}): JSX.Element => {
  const { pathname } = useLocation()

  const dispatch = useDispatch()
  const orgId = useAppSelector(selectCurrentOrganizationId)

  useEffect(() => {
    if (hasCampaignsFlip) {
      dispatch(
        dataFetchActions.fetch({
          name: 'getCampaignsList',
          args: { id: orgId },
        }),
      )
    }
  }, [dispatch, hasCampaignsFlip, orgId])

  if (pathname.includes('campaigns')) {
    return <Redirect to={tagsPage.route} />
  }

  return (
    <TagsList
      tags={campaigns}
      goToAnalyzeReport={goToAnalyzeReport}
      showTagActions={showCampaignActions}
      hideAnalyzeReport={hideAnalyzeReport}
      isLoading={isLoading}
    />
  )
}

export default ListCampaigns
