import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import * as Popover from '@radix-ui/react-popover'
import {
  focusEditor,
  getEndPoint,
  insertText,
  isEditorFocused,
  type PlateEditor,
  select,
  type Value,
} from '@udecode/plate'
import { init } from 'emoji-mart'
import React, { type ReactNode } from 'react'

import { blue } from '@bufferapp/ui/style/colors'
import {
  EmojiMartPickerStyleOverrides,
  NoResultsFound,
  PopoverContent,
  StyledArrow,
} from './styles'

init({ data })

// @ts-expect-error TS(7006) FIXME: Parameter 'editor' implicitly has an 'any' type.
const insertEmoji = (editor, emoji) => {
  if (!isEditorFocused(editor)) focusEditor(editor)
  if (!editor.selection) {
    const editorEnd = getEndPoint(editor, [])
    select(editor, {
      anchor: editorEnd,
      focus: editorEnd,
    })
  }
  insertText(editor, `${emoji} `) // Add a space after the emoji
}

/**
 * Custom component displayed when emoji search doesn't return emojis
 */
const NotFound = () => (
  <NoResultsFound>
    <img
      height="120"
      width="120"
      src="https://buffer-publish.s3.amazonaws.com/images/chart-error.png"
      alt=""
    />
    <p>No Emoji Found</p>
  </NoResultsFound>
)

export const EmojiPicker = <V extends Value>({
  editor,
  parentContainer,
  children,
}: {
  editor: PlateEditor<V>
  parentContainer?: HTMLElement
  children: ReactNode
}): JSX.Element => {
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  const onEmojiSelect = (value) => {
    insertEmoji(editor, value.native)
  }

  return (
    <Popover.Root>
      <Popover.Trigger asChild>{children}</Popover.Trigger>
      <Popover.Portal container={parentContainer}>
        <PopoverContent>
          <EmojiMartPickerStyleOverrides>
            <Picker
              color={blue}
              title="Pick your emoji"
              emoji="point_up"
              perLine={9}
              showPreview
              data={data}
              theme="dark"
              notFound={NotFound}
              onEmojiSelect={onEmojiSelect}
            />
          </EmojiMartPickerStyleOverrides>
          <StyledArrow />
        </PopoverContent>
      </Popover.Portal>
    </Popover.Root>
  )
}
