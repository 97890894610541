import React, { useMemo, useState } from 'react'
import {
  CriticalIcon,
  EmptyState,
  Separator,
  Skeleton,
  Text,
} from '@buffer-mono/popcorn'

import { actions as profileActions } from '~publish/legacy/profile-sidebar'
import { useAppDispatch } from '~publish/legacy/store'

import { getConnectableChannels } from './helpers'
import { useChannels } from './useChannels'
import { SideNavItem } from './SideNavItem'
import { NewChannelButton } from './NewChannelButton'
import { ConnectableChannelsSuggestions } from './ChannelSuggestions'
import { ManagementLinks } from './ManagementLinks'
import { AllChannelsItem } from './AllChannels'
import { ChannelSearchInput } from './ChannelSearchInput'
import { DraggableChannelList } from './DraggableChannelList'
import styles from './PageSidebar.module.css'
import { usePublishRevamp } from '~publish/hooks/usePublishRevamp'

const SEARCH_CHANNELS_THRESHOLD = 5

export function PageSidebar(): JSX.Element {
  const dispatch = useAppDispatch()
  const { channels, loading, error } = useChannels()
  const [searchQuery, setSearchQuery] = useState('')
  const connectableChannels = getConnectableChannels(channels)

  const filteredChannels = useMemo(() => {
    if (!searchQuery) return channels
    return channels.filter(
      (channel) =>
        channel.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        channel.service.toLowerCase().includes(searchQuery.toLowerCase()),
    )
  }, [channels, searchQuery])

  const onReorder = (dragIndex: number, hoverIndex: number): void => {
    // TODO: There is no GraphQL mutation to reorder channels, so we fallback to
    // the Redux implementation.
    // We need to first sort and then commit the changes in Redux.
    // This should go away once we have a GraphQL mutation to reorder channels.
    dispatch(
      profileActions.onDropProfile({
        commit: false,
        dragIndex,
        hoverIndex,
        profileLimit: 0,
      }),
    )
    dispatch(
      profileActions.onDropProfile({
        commit: true,
        dragIndex,
        hoverIndex,
        profileLimit: 0,
      }),
    )
  }

  return (
    <nav
      className={styles.container}
      aria-label="Sidebar"
      data-testid="channel-list-sidebar"
    >
      <ul className={styles.itemList}>
        {channels.length > SEARCH_CHANNELS_THRESHOLD && (
          <ChannelSearchInput
            value={searchQuery}
            onValueChange={setSearchQuery}
          />
        )}

        {error && <ErrorState error={error} />}
        {loading && <ChannelListSkeleton />}

        {!error && !loading && (
          <>
            {!error && (
              <>
                <AllChannelsItem />
                <Separator className={styles.separator} />
              </>
            )}
            {filteredChannels.length === 0 && channels.length > 0 && (
              <NoMatchesFound />
            )}
            {!error && (
              <ul className={styles.scrollableChannelList}>
                <DraggableChannelList
                  onReorder={onReorder}
                  filteredChannels={filteredChannels}
                />
                <ConnectableChannelsSuggestions
                  connectableChannels={connectableChannels}
                />
              </ul>
            )}
            {!error && <NewChannelButton />}
          </>
        )}
      </ul>
      <Separator className={styles.separator} style={{ marginTop: 'auto' }} />
      <ManagementLinks />
    </nav>
  )
}

function NoMatchesFound(): JSX.Element {
  return (
    <div>
      <div className={styles.emptyState}>No Channels found</div>
      <Separator className={styles.separator} />
    </div>
  )
}

function ChannelListSkeleton(): JSX.Element {
  return (
    <>
      {Array.from({ length: 5 }).map((_, index) => (
        <SideNavItem className={styles.skeleton} key={index}>
          <Skeleton width={32} height={32} rounded />
          <Skeleton width={100} height={12} rounded />
          <Skeleton
            width={22}
            height={18}
            rounded
            className={styles.skeletonCount}
          />
        </SideNavItem>
      ))}
    </>
  )
}

function ErrorState({ error }: { error: Error }): JSX.Element {
  return (
    <EmptyState variant="critical">
      <EmptyState.Icon>
        <CriticalIcon />
      </EmptyState.Icon>
      <EmptyState.Heading>Failed to load</EmptyState.Heading>
      <EmptyState.Description>
        Error happened, please let our team know about it.{' '}
        <Text color="critical">{error.message}</Text>
      </EmptyState.Description>
    </EmptyState>
  )
}
