import {
  format,
  isSameDay,
  isSameYear,
  isToday,
  isTomorrow,
  isYesterday,
  parseISO,
  startOfToday,
} from 'date-fns'
import { toZonedTime } from 'date-fns-tz'
import React from 'react'

import { Heading, Skeleton } from '@buffer-mono/popcorn'

import { useTimezone } from '~publish/hooks/useTimezone'

import styles from './DateSeparator.module.css'

export function SeparatorHeader({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  return (
    <Heading size="small" as="h3" className={styles.header}>
      {children}
    </Heading>
  )
}

export function DateSeparator({
  currentDate,
  previousDate,
}: {
  currentDate: string
  previousDate?: string
}): JSX.Element | null {
  const timezone = useTimezone()
  const currDate = timezone
    ? toZonedTime(currentDate, timezone)
    : parseISO(currentDate)
  const prevDate =
    previousDate && timezone
      ? toZonedTime(previousDate, timezone)
      : previousDate || undefined
  const isFirstPost = !prevDate
  const isDifferentDateGroup =
    currentDate && prevDate && !isSameDay(currDate, prevDate)

  const shouldRenderDateSeparator = isFirstPost || isDifferentDateGroup

  if (!shouldRenderDateSeparator) {
    return null
  }

  const { prefix, date } = formatDateSeparator(currDate.toISOString())

  return (
    <SeparatorHeader>
      {prefix} <small className={styles.subheader}>{date}</small>
    </SeparatorHeader>
  )
}

export function SeparatorHeaderSkeleton(): JSX.Element {
  return <Skeleton height={10} width={150} className={styles.skeleton} />
}

function formatDateSeparator(date: string): {
  prefix: string
  date: string
} {
  let prefix = format(date, 'EEEE')
  if (isYesterday(date)) prefix = 'Yesterday, ' + prefix
  if (isToday(date)) prefix = 'Today, ' + prefix
  if (isTomorrow(date)) prefix = 'Tomorrow, ' + prefix

  let dateFormat = 'MMMM dd'
  if (!isSameYear(date, startOfToday())) dateFormat = 'MMMM dd yyyy'

  return { prefix, date: format(date, dateFormat) }
}
