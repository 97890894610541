import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'
import { useLocation, useParams } from 'react-router-dom'
import { NotesCta } from '~publish/legacy/constants'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import { PostEntity } from '~publish/legacy/post/PostEntity'
import type { RpcUpdate } from '~publish/legacy/post/types'
import type { PublishingPost, EntityWithNotes } from '~publish/gql/graphql'
import { useAppSelector } from '~publish/legacy/store'
import { getChannelSelector, getPostSelector } from './helpers'

export enum SupportedNotesPages {
  Queue = 'queue',
  SentPosts = 'sentPosts',
  Approvals = 'approvals',
  Drafts = 'drafts',
  Campaigns = 'campaigns',
}

export const useNotesMigrationCta = (): NotesCta | undefined => {
  const notesPage = useNotesRoute()
  switch (notesPage) {
    case SupportedNotesPages.Queue:
      return NotesCta.QUEUE_CARD_HEADER_LEGACY_MIGRATION
    case SupportedNotesPages.SentPosts:
      return NotesCta.SENT_POSTS_CARD_HEADER_LEGACY_MIGRATION
    case SupportedNotesPages.Approvals:
      return NotesCta.APPROVALS_CARD_HEADER_LEGACY_MIGRATION
    case SupportedNotesPages.Drafts:
      return NotesCta.DRAFTS_CARD_HEADER_LEGACY_MIGRATION
    case SupportedNotesPages.Campaigns:
      return NotesCta.CAMPAIGNS_CARD_HEADER_LEGACY_MIGRATION

    default:
  }
}

export function useNotesRoute(): SupportedNotesPages | undefined {
  const { tabId } = useParams<{
    profileId: string
    tabId: keyof typeof SupportedNotesPages
  }>()
  const location = useLocation()

  // Return campaigns page first to not confuse with the queue page tabs
  if (location.pathname.includes('campaigns')) {
    return SupportedNotesPages.Campaigns
  }

  if (
    Object.values(SupportedNotesPages).includes(tabId as SupportedNotesPages)
  ) {
    return tabId as SupportedNotesPages
  }
}

interface UseNotesFeedViewedTrackingProps {
  entityId: string
  entityType: EntityWithNotes
}
export const useNotesFeedViewedTracking = ({
  entityId,
  entityType,
}: UseNotesFeedViewedTrackingProps): (() => void) | undefined => {
  const organizationId = useAppSelector(selectCurrentOrganizationId)
  const notesPage = useNotesRoute()

  const postSelector = getPostSelector({ entityId, notesPage })

  const post: PublishingPost | RpcUpdate | undefined =
    useAppSelector(postSelector)

  const profileId = PostEntity.getChannelId(post)
  const channelSelector = getChannelSelector({
    profileId,
    notesPage,
  })
  const channel = useAppSelector(channelSelector)

  if (!post || !channel) return
  const payload = {
    entityId,
    entityType,
    channel: channel.service,
    channelId: channel.id,
    channelType: channel.service_type,
    channelUsername: channel.service_username,
    clientName: 'publishWeb',
    entityStatus: post?.status || null,
    entityText: post?.text || '',
    organizationId,
  }

  return (): void => {
    BufferTracker.noteFeedViewed(payload)
  }
}
