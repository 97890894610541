import type { OnFileReadyCb } from '~publish/legacy/uploads/types'
import { useComposerUploader } from '~publish/legacy/uploads/hooks/useComposerUploader'
import type { Draft } from '../entities/Draft'
import { ComposerNotifiers } from '../action-creators/ComposerNotifiers'

// TODO UPLOADS: cleanup can use useUploader directly
export const useComposerOnFileReady = (
  draft: Draft,
): {
  onFileReady: OnFileReadyCb
  onError: (args: { message: string }) => void
} => {
  const { onFileReady } = useComposerUploader({ draft })

  const onError = ({ message }: { message: string }): void =>
    ComposerNotifiers.queueError?.({
      message,
    })

  return {
    onFileReady,
    onError,
  }
}
