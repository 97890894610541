import { format } from 'date-fns'
import { actionTypes as queueActionTypes } from '~publish/legacy/queue/actionTypes'
import keyWrapper from '~publish/helpers/keywrapper'

export const actionTypes = keyWrapper('STREAKS', {
  SHOULD_TRIGGER_STREAKS_MODAL: 0,
  CLEAR_TRIGGER_STREAKS_MODAL: 0,
})

export const initialState = {
  shouldTriggerModal: false,
  modalCopy: 'Your post is scheduled!',
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_TRIGGER_STREAKS_MODAL: {
      return { shouldTriggerModal: false, modalCopy: initialState.modalCopy }
    }
    case queueActionTypes.POST_SENT: {
      return { shouldTriggerModal: true, modalCopy: 'Your post is shared!' }
    }
    case queueActionTypes.POST_CREATED: {
      const copyString = `Your post is scheduled for ${format(
        new Date(action.post.due_at * 1000),
        'EEEE LLLL do',
      )} at ${action.post.dueTime}`
      return {
        shouldTriggerModal: true,
        modalCopy: copyString || initialState.modalCopy,
      }
    }
    default:
      return state
  }
}

export const actions = {
  shouldTriggerStreaksModal: () => ({
    type: actionTypes.SHOULD_TRIGGER_STREAKS_MODAL,
  }),
  clearTriggerStreaksModal: () => ({
    type: actionTypes.CLEAR_TRIGGER_STREAKS_MODAL,
  }),
}
